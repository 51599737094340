<template>
    <section class="mosaic-home">
        <div class="container-fluid">
            <!-- <h2 class="title-secondary">{{dataSource.placeholder_name}}</h2> -->
            <!-- <h2 class="title-secondary">{{getMyLanguage("MosaicHighlights","mosaic.title")}}</h2> -->

            <div class="row">
                <div class="col-6 col-lg-4">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic full" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-store-dne-token.jpg" class="banner-mosaic" alt='DNE Token' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-dne-token-mosaic.svg" class="logo-mosaic" alt="DNE Token">
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-6 col-lg-4">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic high" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-zenith-saude.jpg" class="banner-mosaic" alt='Zenith Saúde' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-zenith-saude-mosaic.svg" class="logo-mosaic" alt="Zenith Saúde">
                            </figcaption>
                        </figure>
                    </a>

                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic low" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-sport-zone.jpg" class="banner-mosaic" alt='Sport Zone' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-sport-zone-mosaic.png" class="logo-mosaic" alt="Sport Zone">
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-12 col-lg-4 d-flex d-lg-block">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic low" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-nike.jpg" class="banner-mosaic" alt='Nike' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-nike-mosaic.svg" class="logo-mosaic" alt="Nike">
                            </figcaption>
                        </figure>
                    </a>

                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic high" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-tilt-informatica.jpg" class="banner-mosaic" alt='Tilt Informática' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-tilt-informatica-mosaic.png" class="logo-mosaic" alt="Tilt Informática">
                            </figcaption>
                        </figure>
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-lg-2">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic high-2" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-adidas.jpg" class="banner-mosaic" alt='Adidas' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-adidas-mosaic.svg" class="logo-mosaic" alt="Adidas">
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-6 col-lg-2">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic high-2" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-amige-international.jpg" class="banner-mosaic" alt='Amige International' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-amige-international-mosaic.png" class="logo-mosaic" alt="Amige International">
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-4 col-lg-3">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic mosaic-small low-2" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-apple.jpg" class="banner-mosaic" alt='Apple' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-apple-mosaic.svg" class="logo-mosaic" alt="Apple">
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-4 col-lg-3">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic mosaic-small low-2" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-store-dne-token-small.jpg" class="banner-mosaic" alt='DNE Token' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-dne-token-mosaic.svg" class="logo-mosaic" alt="DNE Token">
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-4 col-lg-2">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic mosaic-small high-2" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-amige.jpg" class="banner-mosaic" alt='Amige Contabilidade' />
                            
                            <figcaption>
                                <img src="/images/mosaic/logo-amige-mosaic.svg" class="logo-mosaic" alt="Amige Contabilidade">
                            </figcaption>
                        </figure>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },

        data (){
            return {
                flagSelected: "en",
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_mosaic-home.scss" lang="scss" scoped />