<template>
  <div class="redirecting">
    
    <img src="~@/assets/images/header/logo-dne-market.svg" alt="DNE Market" class="logo-dne-market">
    <div class="load"></div>
    
  </div>
</template>

<script>
    export default {
        data() {
            return {
                flagSelected: "en",
                tk: ""
            };
        },

        async mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            this.tk = urlParams.get('tk')

            localStorage.removeItem('accessToken');
            localStorage.setItem('accessToken',this.tk);

            setTimeout(() => {
                window.location.href = `/${this.flagSelected}/merchant/company/0`
            }, 2500);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            }
        }
    };
</script>

<style>
    /* html, body, .main, .redirecting{
        height: 100vh;
    } */

    .redirecting{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: url('~@/assets/images/bgs/bg-15.svg') no-repeat top center #1f3561;
        background-size: cover;
    }

    .logo-dne-market{
        width: 200px;
        padding: 1rem;
        background: #fff;
        border-radius: 12px;
    }

    .footer, .cookie-consent{
        display: none !important;
    }
</style>