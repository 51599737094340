export const dataMixin = {
  data() {
    return {
        flagSelected: "en",
        headerTemplate: "merchant",
        page: "productManager",
        load: false,

        dataMerchantStore: {},
        loadMerchantStore: true,

        breadcrumb: [],

        flowRegister: {
            load: false,
            step: "stp01",
            selectedType: "",
            variable: [],
            varSearch: "",
            selectedImages: [],

            step01Validated: false,
            step02Validated: false,
            step03Validated: false,

            validate: {
                name: undefined,
                slug: undefined,
                // ean: undefined,
                // sku: undefined,
                description: undefined,
                brand_id: undefined,
                product_type_id: undefined,
                category_id: undefined,
                asset_id: undefined,
            }
        },

        flowUpload: {
            image: "",
            variable: null
        },

        dataProduct: {
            name: "",
            slug: "",
            ean: "",
            sku: "",
            description: "",
            brand_id: "35da4f02-c045-4897-9a78-a9e3b78e2dd1",
            product_type_id: "63ed5c30-8116-4e46-b792-0c5952b6b548",
            category_id: "53b4df6f-65d7-4c45-8078-b54783356aff",
            asset_id: "db348135-d40e-4803-b1b9-50876356f419",
            variants: {
                variant_1: {
                    variant_type_id: "",
                    variant_type:"",
                    variant_id: "",
                    variant: ""
                },
                variant_2: {
                    variant_type_id: "",
                    variant_type:"",
                    variant_id: "",
                    variant: ""
                }
            }
        },

        productReference: "",

        modal: {
            variables: false,
            variablesAction: "add",
            managerGallery: false,

            variableID: "",
            addImages: false,
            removeImages: false
        },

        errorsGet: {
            dataProduct: false
        },

        // TEMP
        upload: {
            File: null,
            selected: false,
            load: false
        },

        varsSearch: [
            {
                name: "Azul",
                price: "123",
                images: []
            },
            {
                name: "Vermelho",
                price: "128",
                images: []
            },
            {
                name: "Branco",
                price: "118",
                images: []
            }
        ]
    }
  }
};