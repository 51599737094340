<template>
    <div class="new-variant">
        <div class="load" v-if="load"></div>

        <div class="variant" v-if="!load">
            
            <!-- VARIANT 01 -->
            <div class="var-01">
                <h2>{{ getMyLanguage("SetCategories","variant-01") }}</h2>

                <div class="fields">

                    <!-- TYPE 01 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-type") }}</h3>

                        <div class="form" v-if="blockVariables.variant_1.variant_type_id && blockVariables.variant_1.variant_type_id != '00000000-0000-0000-0000-000000000000'">
                            <span>{{ dataVariants.filter(variant => variant.id == blockVariables.variant_1.variant_type_id)[0].type }}</span>
                        </div>

                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'type01'}"
                            v-if="!addNew.type.type_01 && (!blockVariables.variant_1.variant_type_id || blockVariables.variant_1.variant_type_id == '00000000-0000-0000-0000-000000000000')">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'type01'"
                                @click="dropListVariants(true, 'type01')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'type01'"
                                @click="dropListVariants(false, 'type01')"></div>

                            <label>
                                <input type="text" id="list_type01" class="form"
                                    @focus="dropListVariants(true, 'type01')"
                                    @keyup="filterListVariants('type01')"
                                    v-model="autocomplete.fields.variant_type_1">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'type01'">
                                <ul id="drop_list_type01">
                                    <li
                                        v-for="(itemList, index) in dataVariants"
                                        :key="index"
                                        @click="addListVariant(itemList, 'type01')">
                                        
                                        <a>{{itemList.type}}</a>
                                    </li>
                                </ul>

                                <div class="add" @click="addManuallyVariant('type01')">
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span> <strong>{{autocomplete.fields.variant_type_1}}</strong>
                                </div>
                            </div>
                        </div>


                        <!-- ADD TYPE 01 -->
                        <div class="field-add" v-if="addNew.type.type_01">
                            <input type="text" class="form"
                                :class="{form_add: addNew.post.variant_1.variant_type != add.variant_1.variant_type || !addNew.post.variant_1.variant_type}"
                                id="add_var01_type"
                                placeholder="Novo tipo de variante"
                                v-model="addNew.post.variant_1.variant_type">

                            <div class="add disabled" v-if="!addNew.post.variant_1.variant_type"></div>
                            
                            <div class="add"
                                v-if="addNew.post.variant_1.variant_type != add.variant_1.variant_type && addNew.post.variant_1.variant_type"
                                @click="addListManually('type01')"></div>
                        </div>
                    </div>


                    <!-- VAR 01 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-name") }}</h3>

                        <div class="form form-disabled" v-if="(!add.variant_1.variant_type_id || autocomplete.type == 'type01') && !addNew.type.type_01"></div>
                        
                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'var01'}"
                            v-if="add.variant_1.variant_type_id && autocomplete.type != 'type01' && !addNew.type.type_01 && !addNew.type.var_01">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'var01'"
                                @click="dropListVariants(true, 'var01')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'var01'"
                                @click="dropListVariants(false, 'var01')"></div>

                            <label>
                                <input type="text" id="list_var01" class="form"
                                    @focus="dropListVariants(true, 'var01')"
                                    @keyup="filterListVariants('var01')"
                                    v-model="autocomplete.fields.variant_1">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'var01'">
                                <ul id="drop_list_var01">
                                    <li
                                        v-for="(itemList, index) in variantsFilter.var01"
                                        :key="index">

                                        <a @click="addListVariant(itemList, 'var01')">
                                            {{ itemList.name }}
                                        </a>
                                    </li>
                                </ul>
                                
                                <div class="add" @click="addManuallyVariant('var01')">
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span> <strong>{{autocomplete.fields.variant_1}}</strong>
                                </div>
                            </div>
                        </div>

                        <!-- ADD VAR 01 -->
                        <div class="field-add" v-if="addNew.type.type_01 || addNew.type.var_01">
                            <input type="text" class="form"
                                :class="{form_add: addNew.post.variant_1.variant != add.variant_1.variant || !addNew.post.variant_1.variant}"
                                placeholder="Nova variante"
                                id="add_var01_var"
                                v-model="addNew.post.variant_1.variant">
                            
                            <div class="add disabled" v-if="!addNew.post.variant_1.variant"></div>
                            
                            <div class="add"
                                v-if="addNew.post.variant_1.variant != add.variant_1.variant && addNew.post.variant_1.variant"
                                @click="addListManually('variant01')"></div>

                            <div class="tooltip" v-if="addNew.tooltip">
                                <span>{{ getMyLanguage("SetCategories","enter-variant-name") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- VARIANT 02 -->
            <div class="var-02">
                <h2>{{ getMyLanguage("SetCategories","variant-02") }}</h2>

                <div class="fields">

                    <!-- TYPE 02 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-type") }}</h3>
                        
                        <div class="form" v-if="blockVariables.variant_2.variant_type_id && blockVariables.variant_2.variant_type_id != '00000000-0000-0000-0000-000000000000'">
                            <span>{{ dataVariants.filter(variant => variant.id == blockVariables.variant_2.variant_type_id)[0].type }}</span>
                        </div>

                        <div class="form form-disabled" v-if="!add.variant_1.variant_id && (!blockVariables.variant_1.variant_type_id || blockVariables.variant_1.variant_type_id == '00000000-0000-0000-0000-000000000000')"></div>

                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'type02'}"
                            v-if="!addNew.type.type_02 && add.variant_1.variant_id && (!blockVariables.variant_1.variant_type_id || blockVariables.variant_1.variant_type_id == '00000000-0000-0000-0000-000000000000')">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'type02'"
                                @click="dropListVariants(true, 'type02')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'type02'"
                                @click="dropListVariants(false, 'type02')"></div>

                            <label>
                                <input type="text" id="list_type02" class="form"
                                    @focus="dropListVariants(true, 'type02')"
                                    @keyup="filterListVariants('type02')"
                                    v-model="autocomplete.fields.variant_type_2">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'type02'">
                                <ul id="drop_list_type02">
                                    <li v-for="(itemList, index) in dataVariantsType02" :key="index"
                                        @click="addListVariant(itemList, 'type02')">
                                        <a>{{itemList.type}}</a>
                                    </li>
                                </ul>

                                <div class="add" @click="addManuallyVariant('type02')">
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span> <strong>{{autocomplete.fields.variant_type_2}}</strong>
                                </div>
                            </div>
                        </div>

                        <!-- ADD TYPE 02 -->
                        <div class="field-add" v-if="addNew.type.type_02">
                            <input type="text" class="form"
                                :class="{form_add: addNew.post.variant_2.variant_type != add.variant_2.variant_type || !addNew.post.variant_2.variant_type}"
                                id="add_var02_type"
                                placeholder="Novo tipo de variante"
                                v-model="addNew.post.variant_2.variant_type">
                            
                            <div class="add disabled" v-if="!addNew.post.variant_2.variant_type"></div>

                            <div class="add"
                                v-if="addNew.post.variant_2.variant_type != add.variant_2.variant_type && addNew.post.variant_2.variant_type"
                                @click="addListManually('type02')"></div>
                        </div>
                    </div>


                    <!-- VAR 02 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-name") }}</h3>

                        <div class="form form-disabled" v-if="(!add.variant_2.variant_type_id || autocomplete.type == 'type01' || autocomplete.type == 'type02')  && !addNew.type.type_02"></div>
                        
                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'var02'}"
                            v-if="add.variant_2.variant_type_id && autocomplete.type != 'type01' && autocomplete.type != 'type02' && !addNew.type.type_02 && !addNew.type.var_02">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'var02'"
                                @click="dropListVariants(true, 'var02')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'var02'"
                                @click="dropListVariants(false, 'var02')"></div>

                            <label>
                                <input type="text" id="list_var02" class="form"
                                    @focus="dropListVariants(true, 'var02')"
                                    @keyup="filterListVariants('var02')"
                                    v-model="autocomplete.fields.variant_2">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'var02'">
                                <ul id="drop_list_var02">
                                    <li v-for="(itemList, index) in variantsFilter.var02" :key="index">
                                        <a @click="addListVariant(itemList, 'var02')">
                                            {{ itemList.name }}
                                        </a>
                                    </li>
                                </ul>

                                <div class="add" @click="addManuallyVariant('var02')">
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span> <strong>{{autocomplete.fields.variant_2}}</strong>
                                </div>
                            </div>
                        </div>

                        <!-- ADD VAR 02 -->
                        <div class="field-add" v-if="addNew.type.type_02 || addNew.type.var_02">
                            <input type="text" class="form"
                                :class="{form_add: addNew.post.variant_2.variant != add.variant_2.variant || !addNew.post.variant_2.variant}"
                                placeholder="Nova variante"
                                id="add_var02_var"
                                v-model="addNew.post.variant_2.variant">

                            <div class="add disabled" v-if="!addNew.post.variant_2.variant"></div>
                            
                            <div class="add"
                                v-if="addNew.post.variant_2.variant != add.variant_2.variant && addNew.post.variant_2.variant"
                                @click="addListManually('variant02')"></div>

                            <div class="tooltip" v-if="addNew.tooltip">
                                <span>{{ getMyLanguage("SetCategories","enter-variant-name") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <pre>{{add}}</pre> -->
        <!-- <pre>Block: {{blockVariables}}</pre> -->
        <!-- <pre>Clone: {{cloneId}}</pre> -->

        <h3 class="title-secondary">
            {{nameProduct}}

            <span v-if="add.variant_1.variant != 'Empty' ">
                {{autocomplete.fields.variant_1}}
            </span>

            <span v-if="add.variant_2.variant != 'Empty' ">
                {{autocomplete.fields.variant_2}}
            </span>
        </h3>

        <!-- SAVE -->
        <div class="btn-center">
            <div class="btn-primary btn-disabled"
                v-if="!add.variant_1.variant_id || !add.variant_2.variant_id">
                    {{ getMyLanguage("SetCategories","save-continue") }}
                </div>

            <div class="btn-primary" @click="saveVariant()" v-else>
                {{ getMyLanguage("SetCategories","save-continue") }}
            </div>

            <div class="btn-cancel" @click="cancel()">
                {{ getMyLanguage("SetCategories","cancel") }}
            </div>
        </div>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiMerchant from '@/config/apiMerchant.js'

    export default {
        props: {
            nameProduct: {
                type: String
            },
            
            idProduct: {
                type: String
            },

            page: {
                type: String
            },

            cloneId: {
                type: String
            },

            blockVariables: {
                type: Array,
                default: () => [{ type_1: "00000000-0000-0000-0000-000000000000", type_2: "00000000-0000-0000-0000-000000000000" }]
            }
        },

        data(){
            return {
                dataVariants: [],
                variantsFilter: {
                    var01: [],
                    var02: []
                },

                load: true,
                alert: false,

                autocomplete: {
                    type: "",
                    showAdd: false,

                    fields: {
                        variant_type_1: "",
                        variant_1: "",
                        variant_type_2: "",
                        variant_2: ""
                    }
                },

                add: {
                    variant_1: {
                        variant_type_id: "",
                        variant_type: "",
                        variant_id: "",
                        variant: ""
                    },
                    variant_2: {
                        variant_type_id: "",
                        variant_type: "",
                        variant_id: "",
                        variant: ""
                    }
                },
                
                addNew: {
                    type: {
                        type_01: false,
                        var_01: false,
                        type_02: false,
                        var_02: false
                    },

                    tooltip: false,

                    post: {
                        variant_1: {
                            variant_type_id: "",
                            variant_type:"",
                            variant_id: "",
                            variant: ""
                        },
                        variant_2: {
                            variant_type_id: "",
                            variant_type:"",
                            variant_id: "",
                            variant: ""
                        }
                    }
                },

                flowAddVariant: {
                    load: false,
                    showSave: false,
                },


                responseTeste: {
                    status: "",
                    status_extra: "",
                    status_reason: "",
                    category: "Special Categories",
                    variant_type_1: "color",
                    variant_1: "Orange",
                    variant_type_2: "capacity",
                    variant_2: "1 TB",
                    id: "99999999-8888-7777-6666-555555555555",
                    reference: 1000049436,
                    status_id: "00000000-0000-0000-0000-000000000000",
                    store_id: "00000000-0000-0000-0000-000000000000",
                    store_reference: 1000047381,
                    warehouse_id: "71165298-61e2-4044-b989-e1d02a7065c9",
                    category_id: "53b4df6f-65d7-4c45-8078-b54783356aff",
                    product_id: "ee41e1d4-2541-4d2b-8a17-19ddd2c76a56",
                    variant_type_id_1: "ed612bee-c7aa-4f31-b572-927817fcc123",
                    variant_id_1: "fee03ba3-0913-43e5-9b50-1d43363bcfd8",
                    variant_type_id_2: "cf35b77d-15c1-43ad-8981-15334a3ed0be",
                    variant_id_2: "2dde02d7-e751-48f6-af36-ae3c58f704b3",
                    name: "Apple Iphone 12 Plus White 512 GB",
                    slug: "apple-iphone-12-plus-white-512-gb",
                    description: "apple-iphone-12-plus-white-512-gb",
                    short_description: "Apple iPhone 12 Plus",
                    sku: "apple-iphone-12-plus-white-512-gb",
                    tags: [],
                    shipping: {
                        shippable: true,
                        selectedSystem: "metric",
                        weight: 0.250,
                        height: 12,
                        width: 30,
                        length: 26,
                        cubing: 0,
                        shipping_packaging_id: "00000000-0000-0000-0000-000000000000"
                    },
                    instrument_id: "00000000-0000-0000-0000-000000000000",
                    price: 999,
                    quantity_available: 5,
                    quantity_minimum: 1,
                    quantity_maximum: 5
                }
            }
        },

        async mounted() {
            this.languageSelected()
            this.listVariants()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },
            
            listVariants(){
                apiMerchant.get(`api/v2/market/variant/types`)
                .then(response => {
                    this.dataVariants = response.data
                    // console.log(this.dataVariants)
                    
                    setTimeout(() => {
                        if ( this.blockVariables && this.blockVariables.variant_1.variant_type_id != "00000000-0000-0000-0000-000000000000" ){
                            this.add.variant_1.variant_type_id = this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_1.variant_type_id)[0].id
                            this.add.variant_1.variant_type = this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_1.variant_type_id)[0].type
                            
                            this.addListVariant(this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_1.variant_type_id)[0], "type01")
                        } else {
                            if ( this.dataVariants.length > 0 ){
                                this.dropListVariants(true, 'type01')
                            } else {
                                this.addManuallyVariant('type01')
                            }
                        }

                        if ( this.blockVariables && this.blockVariables.variant_2.variant_type_id != "00000000-0000-0000-0000-000000000000" ){
                            this.variantsFilter.var02 = this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_2.variant_type_id)[0].variants
                            this.add.variant_2.variant_type_id = this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_2.variant_type_id)[0].id
                            this.add.variant_2.variant_type = this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_2.variant_type_id)[0].type
                        }
                    }, 1500);

                    setTimeout(() => {
                        this.load = false
                    }, 1700);
                })
                .catch(error => {
                    console.log(error)
                })
            },


            dropListVariants(status, type){
                // this.addNew.type = ""

                if ( status ){
                    this.autocomplete.type = type
                } else{
                    this.autocomplete.type = null
                }
                
                setTimeout(() => {
                    if ( type == 'type01' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_type01').focus()
                            }, 10);
                        }
                    }
                    
                    if ( type == 'var01' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_var01').focus()
                            }, 10);
                        }
                    }
                    
                    if ( type == 'type02' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_type02').focus()
                            }, 10);
                        }
                    }
                    
                    if ( type == 'var02' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_var02').focus()
                            }, 10);
                        }
                    }
                }, 250)
            },

            filterListVariants(type) {
                let input, filter, ul, li, a, i, txtValue;
                input = document.getElementById(`list_${type}`);
                filter = input.value.toUpperCase();
                ul = document.getElementById(`drop_list_${type}`);
                li = ul.getElementsByTagName("li");

                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },

            addListVariant(value, type){
                if ( type == 'type01' ){
                    this.add.variant_1.variant_type_id = value.id
                    this.add.variant_1.variant_type = value.type
                    this.variantsFilter.var01 = value.variants

                    this.autocomplete.fields.variant_type_1 = value.type

                    this.add.variant_1.variant_id = ""
                    this.add.variant_1.variant = ""
                    this.autocomplete.fields.variant_1 = ""
                    
                    this.add.variant_2.variant_id = ""
                    this.add.variant_2.variant = ""
                    this.autocomplete.fields.variant_2 = ""

                    if ( this.variantsFilter.var01.length > 0 ){
                        this.dropListVariants(true, 'var01')
                    } else{
                        this.dropListVariants(false, 'type01')
                        this.addManuallyVariant('var01')

                        this.addNew.tooltip = true
                    }
                }

                if ( type == 'var01' ){
                    this.add.variant_1.variant_id = value.id
                    this.add.variant_1.variant = value.name

                    this.autocomplete.fields.variant_1 = value.name

                    if ( this.add.variant_1.variant_type_id != "00000000-0000-0000-0000-000000000000" ){
                        this.dataVariantsType02 = this.dataVariants.filter(variante => variante.id !== this.add.variant_1.variant_type_id)
                    } else {
                        this.dataVariantsType02 = this.dataVariants
                    }

                    this.dropListVariants(false, 'var01')
                    console.log(this.blockVariables)

                    if ( this.blockVariables.variant_2.variant_type_id != "00000000-0000-0000-0000-000000000000" ){
                        this.add.variant_2.variant_type_id = this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_2.variant_type_id)[0].id
                        this.add.variant_2.variant_type = this.dataVariants.filter(variant => variant.id == this.blockVariables.variant_2.variant_type_id)[0].type

                        this.dropListVariants(true, 'var02')
                    } else{
                        this.dropListVariants(true, 'type02')
                    }

                    if ( !this.add.variant_2.variant_type_id ){
                        this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_2.variant_type = "Empty"
                        this.add.variant_2.variant_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_2.variant = "Empty"
                        
                        this.autocomplete.fields.variant_type_2 = "Empty"
                        this.autocomplete.fields.variant_2 = "Empty"
                    }
                }

                if ( type == 'type02' ){
                    this.add.variant_2.variant_type_id = value.id
                    this.add.variant_2.variant_type = value.type
                    this.variantsFilter.var02 = value.variants

                    this.autocomplete.fields.variant_type_2 = value.type

                    this.add.variant_2.variant_id = ""
                    this.add.variant_2.variant = ""
                    this.autocomplete.fields.variant_2 = ""

                    if ( this.variantsFilter.var02.length > 0 ){
                        this.dropListVariants(true, 'var02')
                    } else{
                        this.dropListVariants(false, 'type02')
                        this.addManuallyVariant('var02')

                        this.addNew.tooltip = true
                    }

                    if ( this.add.variant_2.variant_type == "Empty" ){
                        this.add.variant_2.variant_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_2.variant = "Empty"
                        
                        this.autocomplete.fields.variant_2 = "Empty"
                    }
                }

                if ( type == 'var02' ){
                    this.add.variant_2.variant_id = value.id
                    this.add.variant_2.variant = value.name

                    this.autocomplete.fields.variant_2 = value.name
                    
                    this.dropListVariants(false, 'var02')

                    this.flowAddVariant.showSave = true
                }
            },


            // ADD MANUALLY NEW VARIANT
            addManuallyVariant(type){
                const typeVar = type

                if ( typeVar == 'type01' ){
                    this.addNew.type.type_01 = true
                    this.addNew.post.variant_1.variant_type = this.autocomplete.fields.variant_type_1
                    this.addNew.post.variant_1.variant = ""

                    this.add.variant_1.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_1.variant_type = this.addNew.post.variant_1.variant_type

                    setTimeout(() => {
                        document.getElementById('add_var01_type').focus()
                    }, 10);
                }
                
                if ( typeVar == 'var01' ){
                    this.addNew.type.var_01 = true
                    this.addNew.post.variant_1.variant = this.autocomplete.fields.variant_1

                    setTimeout(() => {
                        document.getElementById('add_var01_var').focus()
                    }, 10);
                }
                
                if ( typeVar == 'type02' ){
                    this.addNew.type.type_02 = true
                    this.addNew.post.variant_2.variant_type = this.autocomplete.fields.variant_type_2
                    this.addNew.post.variant_2.variant = ""

                    this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_2.variant_type = this.addNew.post.variant_2.variant_type

                    setTimeout(() => {
                        document.getElementById('add_var02_type').focus()
                    }, 20);
                }
                
                if ( typeVar == 'var02' ){
                    this.addNew.type.var_02 = true
                    this.addNew.post.variant_2.variant = this.autocomplete.fields.variant_2

                    setTimeout(() => {
                        document.getElementById('add_var02_var').focus()
                    }, 10);
                }
            },

            addListManually(type){
                if ( type == 'type01' ){
                    // if ( !this.add.variant_1.variant_type_id ){
                    //     this.add.variant_1.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    //     this.add.variant_1.variant_type = this.addNew.post.variant_1.variant_type
                    // }

                    this.add.variant_1.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_1.variant_type = this.addNew.post.variant_1.variant_type
                }

                if ( type == 'variant01' ){
                    // if ( !this.add.variant_1.variant_type_id ){
                    //     this.add.variant_1.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    //     this.add.variant_1.variant_type = this.addNew.post.variant_1.variant_type
                    // }

                    this.add.variant_1.variant_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_1.variant = this.addNew.post.variant_1.variant
                    this.autocomplete.fields.variant_1 = this.addNew.post.variant_1.variant

                    // this.dropListVariants(true, 'type02')
                }


                if ( type == 'type02' ){
                    this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_2.variant_type = this.addNew.post.variant_2.variant_type
                }
                
                if ( type == 'variant02' ){
                    // if ( !this.add.variant_2.variant_type_id ){
                    //     this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    //     this.add.variant_2.variant_type = this.addNew.post.variant_2.variant_type
                    // }

                    this.add.variant_2.variant_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_2.variant = this.addNew.post.variant_2.variant
                    this.autocomplete.fields.variant_2 = this.addNew.post.variant_2.variant

                    // this.dropListVariants(true, 'type02')
                }
            },

            
            // SAVE
            saveVariant(){
                // const variantData = {
                //     "variant_type_id_1": this.add.variant_1.variant_type_id,
                //     "variant_id_1": this.add.variant_1.variant_id,
                //     "variant_type_id_2": this.add.variant_2.variant_type_id,
                //     "variant_id_2": this.add.variant_2.variant_id
                // }

                let urlPost = ""

                if ( this.cloneId ){
                    urlPost = `/api/v2/market/variant/${this.idProduct}?product_variant_id=${this.cloneId}`
                } else {
                    urlPost = `/api/v2/market/variant/${this.idProduct}`
                }

                console.log(this.add)

                apiMerchant.post(urlPost, this.add)
                .then(response => {
                    let responseNewVariant = response.data
                    // console.log(response.status)

                    if ( response.status == 200 ){
                        this.$toast.info(this.getMyLanguage("SetCategories","toast-combination-exists"))
                    } else {
                        this.$emit('variantAdded', responseNewVariant);
                        this.$emit('closeAdd', "");
                    }
                })
                .catch(error => {
                    console.log(error)

                    this.$toast.error(this.getMyLanguage("SetCategories","toast-error-add-variant"))
                })
            },


                // if ( this.page == "products" ){

                //     this.$emit('variantAdded', this.responseTeste);
                //     this.$emit('closeAdd', "");
                // }

                // if ( this.page == "productManager" ){
                    
                //     this.$emit('variantAdded', this.responseTeste);
                //     this.$emit('closeAdd', "");
                // }

            cancel(){
                this.$emit('closeAdd', "");
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .new-variant{
        padding: 2rem 0;
        
        .title-secondary{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 2rem;

            span{
                display: block;
                margin-left: .5rem;
                color: $color-secondary
            }
        }

        .variant{
            @include shadown;
            background: $color-text-tertiary;
            border-radius: 12px;
            max-width: 600px;
            padding: 1rem;
            margin: 0 auto 3rem;
            
            .var-01{
                margin-bottom: 2rem;
                padding-bottom: 2.5rem;
                border-bottom: 2px dashed #ddd;
            }

            h2{
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 1rem;
            }

            h3{
                font-size: 16px;
                color: $color-secondary;
                margin-bottom: .25rem;
            }

            .fields{
                display: flex;
                align-items: center;

                @media (max-width: 576px){
                    flex-direction: column;
                }

                .field{
                    width: 100%;

                    &:first-child{
                        @media (max-width: 576px){
                            margin-block: 1rem;
                        }

                        @media (min-width: 576px){
                            margin-right: 1rem;
                        }
                    }

                    .autocomplete{
                        .cont-autocomplete{
                            ul{
                                padding: 0 !important;
                                margin-bottom: 1.5rem;

                                li{
                                    background: #f5f5f5;
                                    border-radius: 8px;
                                    padding: 0 !important;

                                    a{
                                        display: block;
                                        width: 100%;
                                        padding: 0 15px;
                                    }

                                    &.disabled{
                                        a{
                                            filter: blur(1px);
                                            cursor: no-drop;
                                        }
                                    }
                                }
                            }

                            .add{
                                background: $color-secondary;
                                color: $color-text-tertiary;
                                padding: .65rem .5rem;
                                border-radius: 12px;
                                text-align: center;
                            }
                        }
                    }

                    .form{
                        margin: 0;
                    }

                    .field-add{
                        position: relative;
                        display: flex;
                        align-items: center;

                        .form_add{
                            width: calc(100% - 44px);
                            
                        }

                        .add{
                            background: url(~@/assets/images/icons/check.svg) no-repeat center $color-secondary;
                            background-size: 65% auto;
                            border-radius: 50%;
                            width: 34px;
                            height: 34px;
                            margin-left: 10px;
                            animation: showAddVar .5s alternate;

                            &.disabled{
                                background-color: #ccc;
                                filter: blur(1px);
                            }

                            @media (min-width: 992px){
                                border: 3px solid transparent;
                                cursor: pointer;
                            }
                        }

                        @keyframes showAddVar {
                            0% {transform: scale(0);}
                            20% {transform: scale(0);}
                            70% {transform: scale(1.2);}
                            100% {transform: scale(1);}
                        }

                        .tooltip{
                            position: absolute;
                            left: .5rem;
                            top: calc(100% + 4px);
                            background: $color-pending;
                            color: $color-text-tertiary;
                            font-size: 12px;
                            line-height: 16px;
                            padding: 5px 10px;
                            border-radius: 13px;
                            animation: showTooltip .3s alternate;

                            &:after{
                                content: "";
                                display: block;
                                width: 0;
                                height: 0;
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                                border-bottom: 8px solid $color-pending;
                                position: absolute;
                                left: 10px;
                                top: -10px;
                            }

                            @keyframes showTooltip {
                                0%{margin-top: 10px; opacity: 0;}
                                100%{margin-top: 0; opacity: 1;}
                            }
                        }
                    }
                }
            }
        }

        .btn-center{
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn-primary{
                margin-bottom: 2rem;
            }

            .btn-cancel{
                background: #f5f5f5;
                border: 1px solid #ddd;
                color: $color-cancel;
            }
        }
    }
</style>