<template>
    <div class="share" v-if="showContent">
        <p class="lbl">
            {{getMyLanguage("Product","share.lbl")}}
        </p>

        <div class="links">
            <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :style="{backgroundColor: network.color}"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser">

                <!-- <i :class="network.icon"></i> -->
                <!-- <span>{{ network.name }}</span> -->
            </ShareNetwork>

            <!-- <div class="lnks lnks-product">
                <div class="whatsapp" @click="shareWhatsApp('product')"></div>
                <div class="facebook" @click="shareFacebook('product')"></div>
                <div class="instagram" @click="shareInstagram('product')"></div>
                <div class="twitter" @click="shareTwitter('product')"></div>
            </div>
            
            <div class="lnks lnks-store">
                <div class="whatsapp" @click="shareWhatsApp('store')"></div>
                <div class="facebook" @click="shareFacebook('store')"></div>
                <div class="instagram" @click="shareInstagram('store')"></div>
                <div class="twitter" @click="shareTwitter('store')"></div>
            </div> -->
        </div>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSourceProduct: Object,
            dataSourceStore: Object,
            dataSourceOrigin: String
        },

        async mounted(){
            setTimeout(() => {
                this.setSharing(this.dataSourceOrigin)
            }, 1000);
        },

        data () {
            return {
                showContent: false,

                sharing: {
                    url: '',
                    title: '',
                    description: '',
                    quote: '',
                    hashtags: '',
                    twitterUser: ''
                },

                networks: [
                    // { network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
                    // { network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
                    // { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                    // { network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
                    { network: 'facebook', name: 'Facebook', icon: 'facebook', color: '#1877f2' },
                    // { network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
                    // { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
                    // { network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
                    // { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
                    // { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                    // { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                    // { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
                    // { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
                    // { network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
                    // { network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
                    // { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
                    // { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
                    // { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                    // { network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
                    { network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1da1f2' },
                    { network: 'telegram', name: 'Telegram', icon: 'telegram', color: '#0088cc' },
                    // { network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
                    // { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
                    // { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
                    // { network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366' },
                    // { network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
                    // { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
                    // { network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' },
                    // { network: 'fakeblock', name: 'Custom Network', icon: 'fab fah fa-lg fa-vuejs', color: '#41b883' }
                ]
            }
        },
        
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            setSharing(value){
                if ( value == "ProductDetail" ){
                    this.sharing.url = this.dataSourceProduct.url
                    this.sharing.title = this.dataSourceProduct.title
                    this.sharing.description = this.dataSourceProduct.description
                    this.sharing.attachment = this.dataSourceProduct.attachment
                    this.sharing.quote = ""
                    this.sharing.hashtags = ""
                    this.sharing.twitterUser = "dnetoken"

                    this.showContent = true
                }

                if ( value == "HeaderStore" ){
                    this.sharing.url = this.dataSourceStore.url
                    this.sharing.title = this.dataSourceStore.title
                    this.sharing.description = ""
                    this.sharing.attachment = this.dataSourceStore.attachment
                    this.sharing.quote = ""
                    this.sharing.hashtags = ""
                    this.sharing.twitterUser = "dnetoken"

                    this.showContent = true
                }

                // sharing: {
                //     url: 'https://news.vuejs.org/issues/180',
                //     title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
                //     description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
                //     quote: 'The hot reload is so fast it\'s near instant. - Evan You',
                //     hashtags: 'vuejs,vite,javascript',
                //     twitterUser: 'youyuxi'
                // },
            }
        }
    }
</script>



<style lang="scss" scoped>
    .header-store .store-favorite + .share .lnks-product{display: none !important;}
    .product .share-wishlist .lnks-store{display: none !important;}

    .share{
        margin-right: 40px;

        .links{
            display: flex;

            a{
                width: 30px;
                height: 30px;
                margin-left: 2px;
                position: relative;
                background: none !important;
                cursor: pointer;

                &:before, &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    transition: .3s;
                }
                
                &:before{
                    background: url('~@/assets/images/icons/share.svg') no-repeat;
                    background-size: auto 24px;
                    z-index: 20;
                }

                &:after{
                    background: rgba($color: #000000, $alpha: 0.4);
                    border-radius: 50%;
                    transition: .3s;
                }

                &.share-network-whatsapp{
                    &:before{
                        background-position: 3px center !important;
                    }
                }

                &.share-network-facebook{
                    &:before{
                        background-position: -22px center !important;
                    }
                }
                
                &.share-network-telegram{
                    &:before{
                        background-position: -118px center !important;
                    }
                }

                &.share-network-twitter{
                    &:before{
                        background-position: -68px center !important;
                    }
                }

                @media (min-width: 992px){
                    &:hover{
                        &:after{
                            background: rgba($color: #000000, $alpha: 0.6);
                        }
                    }
                }
            }
        }
    }


    .header-store{
        .share{
            .lbl{
                display: none;
            }
        }
    }
</style>