<template>
    <section class="featured-theme">
        <div class="container-fluid">
            <h2 class="title-secondary" data-aos="fade-up" data-aos-offset="-100">{{dataSource.placeholder_name}}</h2>

            <div class="row">
                <div class="col-12 col-sm-6 featured-image" data-aos="fade-right" data-aos-offset="-100">
                    <figure>
                        <img :src="`/images/home/${dataSource.data[0].attachment}`" :alt="dataSource.data[0].name" />
                    </figure>
                </div>

                <div class="col-12 col-sm-6 cont" data-aos="fade-up" data-aos-offset="-100">
                    <h3>{{dataSource.data[0].name}}</h3>
                    <p>{{dataSource.data[0].description}}</p>
                    
                    <a :href="dataSource.data[0].slug" class="btn-primary">
                        {{getMyLanguage("FeaturedTheme","featured.bt-cta")}}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        data (){
            return {
                flagSelected: "en",
            }
        },

        props: {
            dataSource: {}
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_buttons.scss';

    .featured-theme{
        .container-fluid{
            .row{
                background: #f5f5f5;
                align-items: center;
                
                .col-12{
                    padding: 0;
                    
                    &.featured-image{
                        position: relative;
                        
                        &:before{
                            content: '';
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 20;
                            background: rgba($color: #000, $alpha: 0.4);
                        }

                        img{
                            width: 100%;
                        }
                    }
                    
                    &.cont{
                        @media (max-width: 576px){
                            padding: 2rem 1rem;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                        }
                        
                        @media (min-width: 576px){
                            padding: 3rem 0 3rem 3rem;
                        }

                        h3{
                            @media (max-width: 576px){
                                font-size: 26px;
                                line-height: 26px;
                                margin-bottom: 16px;
                            }
            
                            @media (min-width: 576px){
                                font-size: 40px;
                                line-height: 40px;
                                margin-bottom: 20px;
                            }
                        }

                        .btn-primary{
                            @media (max-width: 576px){
                                margin-top: 1rem;
                            }

                            @media (min-width: 576px){
                                margin-top: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
</style>