<template>
    <ul class="pagination" v-if="dataSource.length > 1">
        <li class="previous"
            :class="{disabled: pageActive <= pages.length}"
            @click='changePage(pageActive - 1,"previous")'>
            
            {{getMyLanguage("Pagination","btn.previous")}}
        </li>
        
        <li class="pages">
            <span :class="{disabled: pageActive <= pages.length}" @click='changePage(1,"first")' v-if="pages.length > 2">1</span>
            
            <span class="ellipsis"></span>
            
            <span :class="{active: pageActive == pagesList}" @click='changePage(pagesList, "change")' v-for="(pagesList, index) in pagesShow" :key="index">{{pagesList}}</span>
            
            <span class="ellipsis"></span>
            
            <span :class="{disabled: pageActive >= pages.length}" @click='changePage((pages.length), "last")' v-if="pages.length > 2">{{pages.length}}</span>
        </li>
        
        <li class="next"
            :class="{disabled: pageActive >= pages.length}"
            @click='changePage(pageActive + 1,"next")'>
            
            {{getMyLanguage("Pagination","btn.next")}}
        </li>
    </ul>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },

        data (){
            return {
                flagSelected: "en",

                pageActive: 1,
                pages: "",
                pagesShow: []
            }
        },

        async mounted() {
            this.languageSelected()

            this.startPages()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            startPages(){
                this.pages = this.dataSource
                // this.pages.shift()
                // this.pages.pop()
                
                if ( this.pages.length >= 5 ){
                    for (let i = 1; i < 6; i++) {
                        this.pagesShow.push(i)
                    }
                }
            },

            changePage(value,action){
                if ( action === "first" && this.pageActive != 1 ){
                    this.pageActive = value
                    console.log("first - " + this.pageActive)
                }

                if ( action === "last" && value != this.pageActive ){
                    this.pageActive = value
                    console.log("last - " + this.pageActive)
                }

                if ( action === "previous" && value > 2 ){
                    this.pageActive = value
                    console.log("pageActive: " + this.pageActive)
                    console.log("pagesShow: " + this.pagesShow)
                    console.log("pagesShow lenght: " + this.pagesShow.length)
                    console.log("pages: " + this.pages.length)

                    if ( this.pageActive > 2 ){
                        this.pagesShow.unshift(value - 2)
                        this.pagesShow.pop();
                    }
                }

                if ( action === "next" && value <= this.pages.length ){
                    this.pageActive = value
                    
                    if ( this.pageActive > 3 && this.pageActive <= (this.pages.length - 2) ){
                        this.pagesShow.shift()
                        this.pagesShow.push(value + 2);
                    }
                }

                if ( action === "change" && value != this.pageActive ){
                    this.pageActive = value
                    console.log("change - " + this.pageActive)
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';

    .pagination{
        margin: 20px 0 50px;
        display: flex;
        justify-content: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        li{
            display: block;
            text-align: center;
            font-size: 13px;
            font-weight: 600;
            color: #444;
            line-height: 30px;
            min-width: 30px;
            padding: 0 5px;

            &:last-child{
                border-right: 1px solid #eee;
            }

            &.pages{
                display: flex;
                
                span{
                    display: block;
                    padding: 0 4px;
                    min-width: 30px;
                    border: 1px solid #eee;
                    background: #f9f9f9;
                    transition: .3s;
                    cursor: pointer;

                    &.active{
                        background: $color-secondary;
                        color: $color-text-tertiary;
                    }

                    &.ellipsis{
                        min-width: 30px;
                        background: url('~@/assets/images/icons/ellipsis.svg') no-repeat center 20px transparent;
                        background-size: 14px auto;
                        border-color: transparent;
                    }

                    @media (min-width: 992px){
                        &:hover:not(&.active, &.ellipsis){
                            background: #e9e9e9;
                        }
                    }
                }
            }

            &.previous, &.next{
                transition: .3s;
                cursor: pointer;
            }

            &.previous{
                @media (min-width: 992px){
                    &:hover{
                        padding: 0 10px 0 0;
                    }
                }
            }

            &.next{
                @media (min-width: 992px){
                    &:hover{
                        padding: 0 0 0 10px;
                    }
                }
            }

        }
    }
</style>