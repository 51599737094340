<template>
    <div class="item"
        data-aos="fade-up"
        data-aos-offset="-100"
        v-for="(shelfProduct, index) in dataSource"
        :key="index">

        <div class="wishlist"
            :class="{selected: shelfProduct.wishlist}"
            @click="addWishlist(shelfProduct.Id,shelfProduct.wishlist)"></div>

        <a :href="`/${shelfProduct.slug}`">
            <div class="highlights">
                <div class="discount" v-if="shelfProduct.discount">
                    -{{shelfProduct.discount}}%
                </div>

                <div
                    v-for="(listHighlights, index) in shelfProduct.highlights"
                    :key="index"
                    :class="listHighlights">
                    
                    {{getMyLanguage("Result","shelf.highlights." + listHighlights)}}
                </div>
            </div>

            <figure class="photo">
                <img :src='shelfProduct.attachments[0].attachment' :alt="shelfProduct.name">
            </figure>

            <div class="details">
                <h3 class="name">
                    {{shelfProduct.name}}&nbsp;
                    <small>{{shelfProduct.brand.name}}</small>
                </h3>

                <div class="store">
                    <!-- <div class="brand" title="Apple Store">
                        <div><img src="~@/assets/images/brands/nike.svg"></div>
                    </div> -->

                    <small class="store-name">
                        {{shelfProduct.store.name}}
                    </small>

                    <!-- EXEMPLO DESCONTO POR PREÇO -->
                    <!-- <div class="prices">
                        <div class="price">
                            <span class="dne">
                                <span>
                                    {{(shelfProduct.amount / 100 * 90).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                </span>
                                <i></i>
                            </span>

                            <small class="current">
                                {{(shelfProduct.amount / 100 * 90 / 2).toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}
                            </small>
                        </div>
                        
                        <del><span>{{shelfProduct.amount.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span><i></i></del>
                    </div> -->
                    
                    <div class="prices">
                        <div class="price">
                            <span class="dne">
                                <span>
                                    {{(shelfProduct.amount).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                </span>
                                <i></i>
                            </span>

                            <small class="current">
                                {{(shelfProduct.price).toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import bus from '@/eventBus';

    export default{
        props: {
            dataSource: {}
        },

        data () {
            return {
                VUE_APP_IMAGE_URL: process.env.VUE_APP_IMAGE_URL,

                wishlist: {
                    active: "",
                    id: "",
                    action: ""
                },
            }
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            addWishlist(id,status){
                if ( status ){
                    this.wishlist.action = "remove"
                } else {
                    this.wishlist.action = "add"
                }

                this.wishlist.id = id

                bus.emit('add-to-wishlist', this.wishlist);
            },
        },

        created() {
            bus.on('updateShelfTemplate', (item) => {
                this.dataSource.filter(updWish => updWish.Id == item.idVariant)[0].wishlist = item.status

                if ( item.status ){
                    this.wishlist.action = "remove"
                } else {
                    this.wishlist.action = "add"
                }
            })
        }
    }
</script>


<style src="@/assets/scss/_shelf.scss" lang="scss" scoped />