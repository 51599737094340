<template>
    <div class="cart">
        <div :class="['open',{active: qtdItems > 0}]" @click="openMiniCart()">
            <span>{{qtdItems}}</span>
        </div>

        <div class="minicart" :class="{checkout: minicart.checkout}" v-if="minicart.show">
            <div class="close" @click="closeMiniCart()"></div>

            <div class="load load-cart" v-if="loadAdd"></div>

            <!-- EMPTY CART -->
            <div class="empty-cart" v-if="qtdItems == 0 && !loadAdd">
                <h5 class="title-cart">{{getMyLanguage("Minicart","empyt-cart.title")}}</h5>

                <div class="msg">
                    <p><strong>{{getMyLanguage("Minicart","empyt-cart.msg")}}</strong></p>
                </div>

                <div class="btn-primary" @click="closeMiniCart()">
                    {{getMyLanguage("Minicart","empyt-cart.start-buy")}}
                </div>
            </div>

            <!-- RESUME CART -->
            <div class="resume" v-if="minicart.resume && qtdItems > 0 && !loadAdd">
                <div class="load-update" v-if="loadUpd"></div>
                
                <h5 class="title-cart">
                    <span>
                        {{getMyLanguage("Minicart","resume-cart.title")}}
                        
                        <small v-if="dataCart.shipping.postal_code">
                            {{dataCart.shipping.postal_code}}
                        </small>
                    </span>
                </h5>
                
                <div class="load loadAdd" v-if="loadAdd"></div>
                
                <div class="items" v-if="!loadAdd">
                    <div class="item"
                        v-for="(item, index) in dataCart.items"
                        :key="index"
                        :class="{unavailable: item.product.stock == 0}">

                        <div class="form-group-check" v-if="item.product.stock > 0">
                            <input type="checkbox" class="form-check"
                                :id="item.product.id"
                                :checked="item.selected"
                                v-if="!loadUpd">

                            <label class="form-check-label"
                                :for="item.product.id"
                                @click="updateSelectedItems(item.product.id, item.status)"></label>
                        </div>
                        
                        <div class="form-group-check form-group-check-unavailable" v-if="item.product.stock == 0">
                            <input type="checkbox" class="form-check">
                            <label class="form-check-label"></label>
                        </div>

                        <div class="photo">
                            <img :src="item.product.attachment">
                        </div>
                        
                        <div class="details">
                            <a :href="`/${item.product.slug}`" class="name">
                                {{item.product.name}} - <span>{{item.store.name}}</span>
                            </a>
                            
                            <div class="price">
                                <span>{{item.product.amount}}</span>
                                
                                <small class="amount">
                                    {{item.product.price.toLocaleString(isoAlpha3, {style:"currency", currency:dataCart.instrument.asset_short})}}
                                </small>
                            </div>

                            <div class="qtd">
                                <div v-if="item.product.stock > 0 && item.selected">
                                    <small>{{getMyLanguage("Vouchers","vouchers.quantity")}}: </small>

                                    <select class="form"
                                        @change="updateSelectedItems(item.product.id, 'updQtd', item.product.quantity)"
                                        v-model="item.product.quantity">
                                        
                                        <option
                                            v-for="qty in getQuantityRange(item.product.min_qty, item.product.max_qty, item.product.stock, item.product.quantity, item.product.id, item.product.name, item.status)"
                                            :key="qty"
                                            :value="qty">
                                            
                                            {{ qty }} {{ qty > 1 ? getMyLanguage("Minicart","resume-cart.items") : getMyLanguage("Minicart","resume-cart.item") }}
                                        </option>
                                    </select>
                                </div>
                                
                                <div class="highlight" v-if="item.product.stock == 0">
                                    <div class="out-of-stock" v-if="item.product.stock == 0">
                                        {{getMyLanguage("Minicart","out-of-stock")}}
                                    </div>
                                </div>

                                <!-- <div class="delete" v-if="item.product.stock > 0 && item.selected" @click="removeItem(item.product.id)"></div> -->
                                <div class="delete" @click="removeItem(item.product.id)"></div>
                            </div>

                            
                            <!-- <p class="amount">{{item.product.amount.toLocaleString(tickerShort, {style:"currency", currency:dataCart.instrument.asset_short})}}</p> -->
                        </div>
                    </div>
                </div>


                <!-- SHIPPING -->
                <div class="shipping-address" v-if="!loadAdd">
                    <ShippingAddress
                        :statusLogin="statusLogin"
                        :dataSourceAddresses="dataCart.addresses"
                        :dataSourceShipping="dataCart.shipping" />
                </div>


                <!-- COUPON -->
                <Vouchers
                    :dataVouchers="dataCart.shipping"
                    v-if="statusLogin" />


                <!-- RESUME CART -->
                <div class="resume-cart" v-if="!loadAdd">
                    <h3>{{getMyLanguage("Minicart","resume-cart.resume")}}</h3>

                    <div class="resume-row">
                        <div class="resume-col">
                            {{getMyLanguage("Minicart","resume-cart.purchase-total")}}
                            <span v-if="qtdItems > 1">({{qtdItems}} {{getMyLanguage("Minicart","resume-cart.items")}})</span>
                            <span v-else>({{qtdItems}} {{getMyLanguage("Minicart","resume-cart.item")}})</span>
                        </div>
                        <div class="resume-col price"><span>{{dataCart.summary.items_amount}}</span></div>
                    </div>
                    <div class="resume-row">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.discount")}}</div>
                        <div class="resume-col price"><span>{{dataCart.summary.discount}}</span></div>
                    </div>
                    <div class="resume-row">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.subtotal")}}</div>
                        <div class="resume-col price"><span>{{dataCart.summary.subtotal}}</span></div>
                    </div>
                    <div class="resume-row">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.shipping-value")}}</div>
                        <div class="resume-col price"><span>{{dataCart.summary.shipping}}</span></div>
                    </div>
                    <!-- <div class="resume-row">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.fee")}}</div>
                        <div class="resume-col price"><span>{{dataCart.summary.fee}}</span></div>
                    </div>
                    <div class="resume-row">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.tax")}}</div>
                        <div class="resume-col price"><span>{{dataCart.summary.tax}}</span></div>
                    </div> -->
                    <div class="resume-row total">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.total-payable")}}</div>
                        <div class="resume-col price">
                            <span>{{dataCart.summary.total_amount}}</span>
                            <strong>{{(dataCart.summary.total_price).toLocaleString(isoAlpha3, {style:"currency", currency:dataCart.instrument.asset_short})}}</strong>
                        </div>
                    </div>
                    <div class="resume-row wallet">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.available-in-wallet")}}</div>
                        <div class="resume-col price"><span>{{dataCart.summary.wallet_balance.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span></div>
                    </div>
                    <div class="resume-row cashback">
                        <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.cashback")}}</div>
                        <div class="resume-col price"><span>{{dataCart.summary.cashback}}</span></div>
                    </div>
                </div>


                <!-- GO TO CHECKOUT -->
                <div class="btn-go-checkout">
                    <div class="total" v-if="statusLogin">
                        <div class="dne">{{dataCart.summary.total_amount}}</div>
                        <div class="currency">{{(dataCart.summary.total_price).toLocaleString(isoAlpha3, {style:"currency", currency:dataCart.instrument.asset_short})}}</div>
                    </div>

                    <div class="btn-primary btn-blue" v-if="statusLogin && !loadAdd && dataCart.shipping.account_address_id && dataCart.summary.total_amount > 0" @click="goToCheckout()">
                        {{getMyLanguage("Minicart","resume-cart.cta-purchase")}}
                    </div>

                    <div class="btn-primary btn-blue btn-disabled" v-if="statusLogin && !loadAdd && (!dataCart.shipping.account_address_id || dataCart.summary.total_amount <= 0)">
                        {{getMyLanguage("Minicart","resume-cart.cta-purchase")}}
                    </div>

                    <div class="btn-primary btn-blue" v-if="!statusLogin && !loadAdd" @click="openLogin()">
                        {{getMyLanguage("login","login.access-account")}}
                    </div>
                </div>


                <!-- <div class="btn-primary" v-if="!loadAdd" @click="goToCheckout()">{{getMyLanguage("Minicart","resume-cart.cta-purchase")}}</div> -->

                <!-- <div class="btn-primary checkout-express" @click="payWithBTC()">Checkout express BTC</div> -->
                
            </div>



            <!-- CHECKOUT -->
            <div class="checkout-resume" v-if="minicart.checkout">
                <h5 class="title-checkout">{{getMyLanguage("Minicart","checkout-resume.title")}}</h5>

                <div class="load" v-if="load"></div>

                <!-- PURCHASE TOKEN PENDING -->
                <!-- <div class="purchase-token" v-if="!tokenPending.status && !load">
                    <div class="wallets">
                        <h3>{{getMyLanguage("Minicart","token-pending.wallets.title")}}</h3>
                        <div class="wallet">
                            <div class="name dne">Spot</div>
                            <div class="value">{{wallets.spot.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</div>
                        </div>
                        
                        <div class="wallet">
                            <div class="name dne">Market</div>
                            <div class="value">{{wallets.market.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</div>
                        </div>

                        <p><span>{{getMyLanguage("Minicart","token-pending.wallets.total")}}&nbsp;</span><span class="value">{{dataCart.summary.wallet_balance.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span></p>
                    </div>

                    <div class="token-pending">
                        <div class="alert">
                            <p><strong>{{getMyLanguage("Minicart","token-pending.wallet-alert-pending")}}</strong></p>
                        </div>
                    </div>

                    <div class="buy-express">
                        <h4>{{getMyLanguage("Minicart","token-pending.buy-express.title")}}</h4>
                        <p class="instruction">{{getMyLanguage("Minicart","token-pending.buy-express.instruction")}}</p>

                        <table class="value-pending">
                            <thead>
                                <tr>
                                    <th>{{getMyLanguage("Minicart","token-pending.buy-express.lots")}}</th>
                                    <th>Tokens</th>
                                    <th>Euros</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>1 {{getMyLanguage("Minicart","token-pending.buy-express.lot")}} <small>({{getMyLanguage("Minicart","token-pending.buy-express.minimum-purchase")}})</small></td>
                                    <td>
                                        {{tokenPending.token.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                        <small class="value">(<span>18</span> {{getMyLanguage("Minicart","token-pending.buy-express.cashback")}})</small>
                                    </td>
                                    <td>{{tokenPending.fiat.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</td>
                                </tr>
                            </tbody>
                        </table>
                        

                        <ul class="select-method-payment">
                            <li>
                                <div class="form-group-radio">
                                    <input type="radio" id="method-creditCard" name="method-payment" class="form-radio" checked>
                                    <label for="method-creditCard" class="form-check-label">
                                        <div>
                                            <span class="type">Visa</span>
                                            <span class="lbl">**** **** **** 9153</span>
                                        </div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="form-group-radio">
                                    <input type="radio" id="methodmbway" name="method-payment" class="form-radio">
                                    <label for="methodmbway" class="form-check-label">
                                        <div>
                                            <span class="type">MBWay</span>
                                            <span class="lbl">+351 *** *** 515</span>
                                        </div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="form-group-radio">
                                    <input type="radio" id="method-usdt" name="method-payment" class="form-radio">
                                    <label for="method-usdt" class="form-check-label">
                                        <div>
                                            <span class="type">USDT</span>
                                            <span class="lbl">0xe94f...86ca0c</span>
                                        </div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="form-group-radio">
                                    <input type="radio" id="method-pix" name="method-payment" class="form-radio">
                                    <label for="method-pix" class="form-check-label">
                                        <div>
                                            <span class="type">PIX</span>
                                        </div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="btn-secondary">{{getMyLanguage("Minicart","token-pending.buy-express.more-options")}}</div>
                            </li>
                        </ul>

                        <div class="btn-primary btn-purchase-token" @click="buyTokenExpress()">
                            <span>{{getMyLanguage("Minicart","token-pending.buy-express.cta-buy")}}</span>
                            <small>
                                <span class="token">{{tokenPending.token.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span> >>> <span>{{tokenPending.fiat.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span>
                            </small>
                        </div>
                    </div>
                </div> -->


                <!-- FINISH PURCHASE -->
                <div class="finish-purchase" v-if="tokenPending.status && !load">
                    <div class="box resume-cart" v-if="!loadAdd">
                        <h3>{{getMyLanguage("Minicart","resume-cart.resume")}}</h3>

                        <div class="resume-row">
                            <div class="resume-col">
                                {{getMyLanguage("Minicart","resume-cart.purchase-total")}}
                                <span v-if="qtdItems > 1">({{qtdItems}} {{getMyLanguage("Minicart","resume-cart.items")}})</span>
                                <span v-else>({{qtdItems}} {{getMyLanguage("Minicart","resume-cart.item")}})</span>
                            </div>
                            <div class="resume-col price"><span>{{dataCart.summary.items_price}}</span></div>
                        </div>

                        <div class="resume-row">
                            <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.discount")}}</div>
                            <div class="resume-col price"><span>{{dataCart.summary.discount}}</span></div>
                        </div>

                        <div class="resume-row">
                            <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.subtotal")}}</div>
                            <div class="resume-col price"><span>{{dataCart.summary.subtotal}}</span></div>
                        </div>

                        <div class="resume-row">
                            <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.shipping-value")}}</div>
                            <div class="resume-col price"><span>{{dataCart.summary.shipping}}</span></div>
                        </div>

                        <div class="resume-row">
                            <!-- <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.fees-and-taxes")}}</div> -->
                            <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.fee")}}</div>
                            <div class="resume-col price"><span>{{dataCart.summary.fee}}</span></div>
                        </div>

                        <div class="resume-row">
                            <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.tax")}}</div>
                            <div class="resume-col price"><span>{{dataCart.summary.tax}}</span></div>
                        </div>

                        <div class="resume-row total">
                            <div class="resume-col">
                                {{getMyLanguage("Minicart","resume-cart.total-payable")}}
                            </div>
                            
                            <div class="resume-col price">
                                <span>{{dataCart.summary.total_amount}}</span>
                                <strong>{{(dataCart.summary.total_price).toLocaleString(isoAlpha3, {style:"currency", currency:dataCart.instrument.asset_short})}}</strong>
                            </div>
                        </div>

                        <!-- <div class="resume-row wallet">
                            <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.available-in-wallet")}}</div>
                            <div class="resume-col price">{{dataCart.summary.wallet_balance.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</div>
                        </div> -->

                        <div class="resume-row cashback">
                            <div class="resume-col">{{getMyLanguage("Minicart","resume-cart.cashback")}}</div>
                            <div class="resume-col price"><span>{{dataCart.summary.cashback}}</span></div>
                        </div>
                    </div>


                    <!-- WALLETS -->
                    <div class="wallets-account box">
                        <h3>{{getMyLanguage("Minicart","token-pending.wallets.title")}}</h3>
                        
                        <!-- <div class="form-group-check">
                            <input type="checkbox" :id="item.product.id" class="form-check" :checked="item.selected" v-if="!loadUpd">
                            <label :for="item.product.id" class="form-check-label" @click="updateSelectedItems(item.product.id, item.status)"></label>
                        </div> -->

                        <div class="form-group-check"
                            v-for="(wallet, indexWallet) in dataCart.wallets"
                            :key="indexWallet">

                            <input type="checkbox" class="form-check"
                                :id="wallet.id"
                                :checked="isSelected(wallet)"
                                @change="toggleSelection(wallet)">
                                
                            <label :for="wallet.id" class="form-check-label label-wallet">
                                <div class="wallet">
                                    <div>
                                        <i :class="wallet.asset_short"></i>
                                        <span>
                                            {{wallet.asset_short}}
                                            <small>{{wallet.name}}</small>
                                        </span>
                                    </div>

                                    <span class="balance-value DNE">
                                        <span>{{wallet.balance}}</span>
                                        <!-- <small>≈ 27,00 €</small> -->
                                    </span>
                                </div>
                            </label>
                        </div>

                        <div class="message-wallets insufficient" v-if="!isPaymentPossible && (dataCart.summary.wallet_balance >= dataCart.summary.total_amount)">
                            <span>{{ getMyLanguage("Minicart","token-pending.wallets.select-your-wallet") }}</span>
                        </div>

                        <div class="message-wallets added-value" v-if="isPaymentPossible">
                            <span>{{ getMyLanguage("Minicart","token-pending.wallets.selected-wallet") }}</span>
                        </div>

                        <div class="message-wallets cta-exchange" v-if="!isPaymentPossible && (dataCart.summary.wallet_balance < dataCart.summary.total_amount)">
                            <span>{{ getMyLanguage("Minicart","token-pending.wallets.wallet-insufficient") }}</span>
                            <div class="btn-primary btn-exchange" @click="purchaseDNEToken()">{{ getMyLanguage("Minicart","token-pending.wallets.purchase-token") }}</div>
                        </div>
                    </div>


                    <!-- CHECKOUT -->
                    <div class="btn-go-checkout go-pay">
                        <div class="btn-back" v-if="!load" @click="goMinicart()">
                            <span></span>
                        </div>

                        <div class="btn-primary" @click="openLogin()" v-if="!statusLogin">
                            <span>{{getMyLanguage("login","login.hello.msg")}}</span>
                        </div>
                        
                        <div class="pay" v-if="statusLogin">
                            <div class="total">
                                <div class="dne">{{dataCart.summary.total_amount}}</div>
                                <div class="currency">{{(dataCart.summary.total_price).toLocaleString(isoAlpha3, {style:"currency", currency:dataCart.instrument.asset_short})}}</div>
                            </div>

                            <div class="btn-primary btn-disabled" v-if="!isPaymentPossible">
                                <span>{{getMyLanguage("Minicart","finish-purchase.cta-purchase")}}</span>
                            </div>
                            
                            <div class="btn-primary" @click="finishPurchase()" v-if="isPaymentPossible">
                                <span>{{getMyLanguage("Minicart","finish-purchase.cta-purchase")}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- SUCCESS -->
            <div class="purchase-success" v-if="minicart.purchaseSuccess && !load">
                <h5 class="title-success">{{getMyLanguage("Minicart","purchase-success.title")}}</h5>

                <div class="box box-success">
                    <p><strong>{{getMyLanguage("Minicart","purchase-success.msg")}}</strong></p>

                    <!-- <div class="items">
                        <div class="item">
                            <router-link :to="{ name:'Product' }" class="photo">
                                <img :src="item.product.attachment">
                            </router-link>
                            
                            <div class="details">
                                <router-link :to="{ name:'Product' }" class="name">Casaco de inverno</router-link>
                                <router-link :to="{ name:'Brand' }" class="brand">Tommy Jeans</router-link>
                                <span class="price">298</span>
                            </div>
                        </div>
                        
                        <div class="item">
                            <router-link :to="{ name:'Product' }" class="photo">
                                <img src="~@/assets/images/items/mochila-couro-thumbs.jpg">
                            </router-link>
                            
                            <div class="details">
                                <router-link :to="{ name:'Product' }" class="name">Mochila em couro - Castanho-escuro - Firenze Artegiani</router-link>
                                <router-link :to="{ name:'Brand' }" class="brand">Winter Iconic Bags</router-link>
                                <span class="price">149</span>
                            </div>
                        </div>
                        
                        <div class="item">
                            <router-link :to="{ name:'Product' }" class="photo">
                                <img src="~@/assets/images/items/pendente-struve-thumbs.jpg">
                            </router-link>
                            
                            <div class="details">
                                <router-link :to="{ name:'Product' }" class="name">Pendente Struve - Preto e dourado</router-link>
                                <router-link :to="{ name:'Brand' }" class="brand">Police</router-link>
                                <span class="price">98</span>
                            </div>
                        </div>
                    </div> -->
                </div>

                <a :href="`/${flagSelected}/account/purchases`" class="btn-primary btn-historic">
                    {{getMyLanguage("Minicart","purchase-success.cta-my-purchases")}}
                </a>

                <div class="btn-primary btn-buy-more" @click="buyMore()">
                    {{getMyLanguage("Minicart","purchase-success.cta-buy-more")}}
                </div>
            </div>
        </div>

        <div class="overlay" v-if="minicart.show" @click="closeMiniCart()"></div>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import ShippingAddress from '@/components/Essentials/ShippingAddress.vue';
    import Vouchers from '@/components/Essentials/Vouchers.vue';

    import apiMarket from '@/config/apiMarket.js'

    import { onUnmounted } from 'vue';
    import bus from '@/eventBus';

    export default{
        setup() {
            const listener = (data) => {
                console.log(`${data}`);
            };

            bus.on('add-to-cart', listener);

            onUnmounted(() => {
                bus.off('add-to-cart', listener);
            });
        },

        components: {
            ShippingAddress,
            Vouchers
        },

        data (){
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                flagSelected: "en",

                dataCart: {
                    items: "",
                    instrument: ""
                },

                account_wallet_ids: [],
                selectedItems: [],

                // tickerShort: "EUR",
                isoAlpha3: "PRT",
                
                qtdItems: "",

                minicart: {
                    show: false,
                    resume: true,
                    checkout: false,
                    purchaseSuccess: false,
                },

                // wallets: {
                //     spot: 100,
                //     market: 342.5,
                //     total: 441.5
                // },

                tokenPending: {
                    status: true,
                    token: 180,
                    fiat: 90
                },

                load: false,
                loadAdd: false,
                loadUpd: false,

                statusLogin: null,

                newtk: "",
                linkInternacional: ""
            }
        },


        async mounted () {
            if ( localStorage.getItem("countryIsoAlpha3") ){
                this.isoAlpha3 = localStorage.getItem("countryIsoAlpha3")
            } else {
                this.isoAlpha3 = "PRT"
                localStorage.setItem("countryIsoAlpha3","PRT")
            }
            
            if ( !this.$cookies.get("idCart") ){
                this.$cookies.set("idCart","00000000-0000-0000-0000-000000000000")
            }

            if ( !this.$cookies.get("idAccount") ){
                this.$cookies.set("idAccount","00000000-0000-0000-0000-000000000000")
            }
            
            if ( this.$cookies.get("idCart") != "00000000-0000-0000-0000-000000000000" || this.$cookies.get("idAccount") != "00000000-0000-0000-0000-000000000000" ){
                await apiMarket.get(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`)
                .then(response => {
                    this.dataCart = response.data
                    console.log(this.dataCart)
                    this.qtdItems = this.dataCart.items.length
    
                    this.$cookies.set("idCart",this.dataCart.id)

                    // if ( !localStorage.getItem("CartList") ){
                    //     this.generateCartList()
                    // }
                })
                .catch(error => {
                    this.qtdItems = 0

                    if ( error.response.status == 404 ){
                        this.$cookies.set("idCart","00000000-0000-0000-0000-000000000000")
                    }
                })
            }

            if ( !this.qtdItems ){
                this.qtdItems = 0
            }

            if ( this.qtdItems > 0 ){
                this.selectWallets();
                this.updateAccountWalletId();
            }

            setTimeout(() => {
                if ( localStorage.getItem("instrumentTickerShort") ){
                    this.tickerShort = localStorage.getItem("instrumentTickerShort")
                } else {
                    this.tickerShort = "EUR"
                    localStorage.setItem("instrumentTickerShort","EUR")
                }
            }, 200);
        },


        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                if ( this.dataCart.items.length ){
                    this.dataCart.items.forEach(item => {
                        item.product.slug = item.product.slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                        item.store.slug = item.store.slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                    });
                }
            },

            openMiniCart(){
                this.minicart.show = true
                document.body.classList.add('body-cart-opened')
            },
            
            closeMiniCart(){
                this.minicart.show = false
                document.body.classList.remove('body-cart-opened')
            },

            getQuantityRange(minQty, maxQty, stock, selectedQuantity, idProduct, nameProduct, status) {
                const range = [];
                const maxAllowedQuantity = Math.min(maxQty, stock);
                
                for (let i = minQty; i <= maxAllowedQuantity; i++) {
                    range.push(i);
                }

                if ( maxAllowedQuantity == 0 && status == "checkout" ){
                    this.updateSelectedItems(idProduct, status)
                }

                if ( (selectedQuantity > maxAllowedQuantity && maxAllowedQuantity != 0) || (selectedQuantity > stock && maxAllowedQuantity != 0)) {
                    this.updateSelectedItems(idProduct, 'updQtd', maxAllowedQuantity);
                }

                return range;
            },


            updateSelectedItems(id, action, qtd){
                this.loadUpd = true
                let status = ""

                if ( action == "wishlist" ){
                    status = "checkout"
                    qtd = 1
                }
                
                else if ( action == "checkout" ){
                    status = "wishlist",
                    qtd = 1
                }
                
                else if ( action == "updQtd" ){
                    status = "checkout"
                }

                apiMarket.post(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`, {"product_variant_id": id, "quantity": qtd, "status": status})
                .then(response => {
                    this.dataCart = response.data
                    // this.qtdItems = this.dataCart.items.length

                    this.$cookies.set("idCart",this.dataCart.id)

                    setTimeout(() => {
                        this.loadUpd = false
                    }, 500);
                })
                .catch(error => {
                    setTimeout(() => {
                        this.loadUpd = false

                        if ( error.response.status == 404 ){
                            this.$cookies.set("idCart","00000000-0000-0000-0000-000000000000")
                        }

                        // this.qtdItems = this.dataCart.items.length
                    }, 1000);
                })
            },

            goToCheckout(){
                this.minicart.checkout = true
                this.minicart.resume = false
                this.minicart.purchaseSuccess = false

                // if ( this.dataCart.summary.total > this.dataCart.summary.wallet_balance ){
                //     this.tokenPending.status = false
                // } else{
                //     this.tokenPending.status = true
                // }

                this.selectWallets();
                this.updateAccountWalletId();

                // setTimeout(() => {
                //     const scroll = this.$refs.scrollBaseline;
                //     scroll.scrollTop = scroll.scrollHeight;0
                // }, 1100);
            },

            goMinicart(){
                this.minicart.resume = true
                this.minicart.checkout = false
                this.minicart.purchaseSuccess = false
            },

            purchaseDNEToken(){
                this.newtk = localStorage.getItem('accessToken')
                this.linkInternacional = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space/purchase`
                window.open(this.linkInternacional, '_blank');
            },

            // buyTokenExpress(){
            //     this.load = true

            //     setTimeout(() => {
            //         this.wallets.market = this.wallets.market + this.tokenPending.token
            //         this.tokenPending.status = false
                    
            //         this.load = false
            //     }, 1500);
            // },


            // SELECT WALLETS
            selectWallets() {
                let remainingAmount = this.dataCart.summary.total_amount;

                for (let i = 0; i < this.dataCart.wallets.length; i++) {
                    const wallet = this.dataCart.wallets[i];
                    if (wallet.balance >= remainingAmount) {
                        wallet.selected = true;
                        remainingAmount = 0;
                    break;
                    } else {
                        wallet.selected = true;
                        remainingAmount -= wallet.balance;
                    }
                }
            },

            isSelected(wallet) {
                return wallet.selected;
            },

            toggleSelection(wallet) {
                wallet.selected = !wallet.selected;
                this.updateAccountWalletId();
            },
            
            updateAccountWalletId() {
                this.account_wallet_ids = this.dataCart.wallets.filter(wallet => wallet.selected).map(wallet => wallet.id);
            },


            finishPurchase(){
                this.load = true

                apiMarket.post(`/api/v1/market/order/checkout`, {"checkout_id": this.dataCart.id, "account_wallet_ids": this.account_wallet_ids})
                .then(response => {
                    this.dataCart = response.data
                    this.qtdItems = this.dataCart.items.length

                    this.$cookies.set("idCart",this.dataCart.id)

                    setTimeout(() => {
                        this.minicart.resume = false
                        this.minicart.checkout = false
                        this.minicart.purchaseSuccess = true
    
                        this.load = false

                        this.$toast.success(this.getMyLanguage("Product","purchase-success"));
                        setTimeout(this.$toast.clear, 6000)
                    }, 1000);

                    setInterval(() => {
                        window.location.href = `/${this.flagSelected}/account/purchases`
                    }, 3000);
                })
                .catch(error => {
                    if ( error.response.status == 404 ){
                        this.$cookies.set("idCart","00000000-0000-0000-0000-000000000000")

                        this.minicart.resume = false
                        this.minicart.checkout = false
                        this.minicart.purchaseSuccess = true
    
                        this.load = false

                        this.$toast.success(this.getMyLanguage("Product","purchase-success"));
                        setTimeout(this.$toast.clear, 6000)
                    } else{
                        setTimeout(() => {
                            this.load = false
                            
                            this.$toast.error(this.getMyLanguage("Product","purchase-success-error"));
                            setTimeout(this.$toast.clear, 6000)
                        }, 1000);
                    }
                })
            },

            buyMore(){
                this.qtdItems = 0
                this.minicart.resume = false
                this.minicart.checkout = false
                this.minicart.purchaseSuccess = false
                this.minicart.show = false
            },

            removeItem(id){
                this.loadAdd = true

                apiMarket.post(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`, {"product_variant_id": id, "quantity": 0})
                .then(response => {
                    this.dataCart = response.data
                    this.qtdItems = this.dataCart.items.length

                    this.$cookies.set("idCart",this.dataCart.id)

                    setTimeout(() => {
                        this.$toast.warning(this.getMyLanguage("Product","remove-cart.tooltip"));
                        setTimeout(this.$toast.clear, 6000)
                        
                        this.loadAdd = false
                    }, 1000);
                })
                .catch(error => {
                    if ( error.response.status == 404 || error.response.status == 500 ){
                        this.$cookies.set("idCart","00000000-0000-0000-0000-000000000000")
                    }

                    setTimeout(() => {
                        this.qtdItems = 0

                        this.$toast.warning(this.getMyLanguage("Product","remove-cart.tooltip"));
                        setTimeout(this.$toast.clear, 6000)

                        this.loadAdd = false
                    }, 1000);
                    
                })
            },

            // payWithBTC(){
            //     this.minicart.show = false
            //     bus.emit('checkout-express', "show");
            // }

            
            openLogin(){
                bus.emit('open-login',"minicart-login");
                this.closeMiniCart()
            }
        },

        watch: {
            '$store.state.statusLogin': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.statusLogin = this.$store.state.statusLogin                        
                    }, 100);
                }
            },

            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.languageSelected();
                    }, 1500);
                }
            }
        },


        computed: {
            isPaymentPossible() {
                const totalSelected = this.dataCart.wallets.reduce((total, wallet) => {
                    return wallet.selected ? total + wallet.balance : total;
                }, 0);
                return totalSelected >= this.dataCart.summary.total_amount;
            }
        },


        created() {

            // ADD TO CART
            bus.on('add-to-cart', (item) => {
                this.minicart.show = true
                this.loadAdd = true

                document.body.classList.add('body-cart-opened')
                
                apiMarket.post(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`, {"product_variant_id": item.idVariant, "quantity": item.qtd, "status": "checkout"})
                .then(response => {
                    this.dataCart = response.data
                    this.qtdItems = this.dataCart.items.length

                    this.$cookies.set("idCart",this.dataCart.id)

                    setTimeout(() => {

                        this.$toast.success(this.getMyLanguage("Product","add-cart.tooltip"));
                        setTimeout(this.$toast.clear, 6000)
                        
                        this.loadAdd = false
                    }, 1000);
                })
                .catch(error => {
                    this.qtdItems = 0
                    console.log(error)
                    
                    this.$toast.error(this.getMyLanguage("Product","add-cart.tooltip-error"));
                    setTimeout(this.$toast.clear, 6000)
                })
            });


            // OPEN CART
            bus.on('open-minicart', () => {
                this.openMiniCart()
            })


            // UPDATE CART
            bus.on('updateMiniCart', (value) => {
                this.dataCart = value
            })
        }
    }
</script>

<style src="@/assets/scss/_minicart.scss" lang="scss" scoped />