// import apiMerchant from '@/config/apiMerchant.js'

import MultipleUpload from '@/components/Merchant/Products/MultipleUpload'

export default {
    components: {
        MultipleUpload
    },

    data(){
        return {
            dataAttachments: [],
        }
    },

    method: {
        
    }
}