<template>
    <div class="account">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-return">
            <div class="load" v-if="load"></div>

            <div class="row" v-if="errorsGet.dataPurchases != false">
                <div class="col-12">
                    <div class="box box-error">
                        <h3 class="title-secondary">
                            {{getMyLanguage("box-error","title-error")}}
                        </h3>

                        <p v-if='errorsGet.dataReturn != "401"'>
                            {{getMyLanguage("box-error","msg-error")}}
                        </p>

                        <p v-if='errorsGet.dataReturn == "401"'>
                            {{getMyLanguage("box-error","401")}}
                        </p>
                    </div>
                </div>
            </div>


            <div class="container" v-if="!load && !errorsGet.dataReturn">
                <h2 class="title-secondary">
                    {{getMyLanguage("Account","purchases-returns.title")}}
                </h2>

                <!-- RETURNS -->
                <div class="returns" v-if="!load && !errorsGet.dataPurchases">
                    <div class="products">
                        <!-- <a :href="`${flagSelected}/account/purchases/return/${purchase.Id}`" class="box product" v-for="(purchase, indexPurchase) in dataPurchases" :key="indexPurchase"> -->
                        
                        <div class="box product"
                        :class="purchase.status_extra"
                        v-for="(purchase, indexPurchase) in dataPurchases"
                        :key="indexPurchase">

                            <div class="itemReturn" v-for="(item, indexItem) in purchase.items" :key="indexItem">
                                <div class="anc" :id="item.reference"></div>

                                <div class="header-return" :class="item.return.status_extra">
                                    <strong>
                                        #{{item.return.reference}}
                                        <span>{{ getMyLanguage("Account","purchases.status." + item.return.status_extra) }}</span>
                                    </strong>

                                    <strong>
                                        {{dateFormat(item.return.created_at)}}
                                        <small>{{timeFormat(item.return.created_at)}}</small>
                                    </strong>
                                </div>

                                <div class="item item-return" :class="item.return.status_extra">
                                    <div class="photo">
                                        <img :src="item.product.attachment">
                                    </div>

                                    <div class="details">
                                        <h3 class="name">
                                            {{item.product.name}}&nbsp;/&nbsp;<small>{{purchase.store.name}}</small>
                                        </h3>

                                        <div class="price">
                                            <span>
                                                <!-- <strong>{{item.amount.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong> -->
                                                <strong>{{item.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                <i></i>
                                            </span>

                                            <!-- <small>{{(item.price * purchase.instrument.price).toLocaleString(purchase.instrument.asset_short, {style:"currency", currency:purchase.instrument.asset_short})}}</small> -->
                                        </div>

                                        <!-- <p class="status pending"><strong>Pendente</strong></p> -->
                                    </div>
                                </div>
                                
                                <div class="return">
                                    <h2 class="subtitle">
                                        <span>{{ getMyLanguage("Account","purchases.return.type-code." + item.return.type_code) }}</span>
                                    </h2>

                                    <div class="header-return" :class="item.return.status_extra">
                                        <strong>
                                            #{{item.return.reason_reference}}
                                            <span>{{item.return.status_extra}}</span>
                                        </strong>

                                        <strong>
                                            {{dateFormat(item.return.created_at)}}
                                            <small>{{timeFormat(item.return.created_at)}}</small>
                                        </strong>
                                    </div>

                                    <p class="reason">
                                        <strong>{{ getMyLanguage("Return","return.reason-type." + item.return.reason_name) }}</strong><br>
                                        <span v-if="item.return.description">{{item.return.description}}</span>
                                    </p>

                                    <p class="status-reason">
                                        <span v-html="item.return.status_reason"></span>
                                    </p>
                                </div>

                                <div class="refund" v-if="item.refund">
                                    <h3 class="subtitle">
                                    <span>Reembolso</span>
                                </h3>

                                <div class="price">
                                    <span>
                                        <strong>{{item.refund.value.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                        <i></i>
                                    </span>
                                </div>

                                <div class="header-return" :class="item.refund.status_extra">
                                    <strong>
                                        #{{item.refund.reference}}
                                        <span>{{item.refund.status_extra}}</span>
                                    </strong>

                                    <strong>
                                        {{dateFormat(item.refund.created_at)}}
                                        <small>{{timeFormat(item.refund.created_at)}}</small>
                                    </strong>
                                </div>

                                <p class="status-reason" v-if="item.refund.status_reason">
                                    <span v-html="item.refund.status_reason"></span>
                                </p>

                                <p>{{ getMyLanguage("Refund","refund.type-code." + item.refund.type_code) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <a :href="`/${flagSelected}/account/purchases`" class="box cta-new-return">
                        <p>
                            <strong>{{ getMyLanguage("Account","purchases-returns.cta-new-return") }}</strong>
                        </p>
                        
                        <div class="btn-primary">
                            {{ getMyLanguage("Account","purchases-returns.cta-new-return.btn") }}
                        </div>
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // const $ = require('jquery')
    // window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Account/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    import moment from 'moment';

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "account",
                load: true,
                
                breadcrumb: [],

                dataPurchases: {},

                tickerShort: "EUR",
                
                // dataReturn: [
                //     { name: 'iPhone 14 Pro Max', brand: "Apple", price: {dne: 10, currency: 20}, showReason: false, selected: false, selectedOption: "" },
                //     { name: 'Jogo Hogwarts Legacy', brand: "Warner Bros Games", price: {dne: 10, currency: 20}, showReason: false, selected: false, selectedOption: "" },
                // ],

                errorsGet: {
                    dataPurchases: false
                }
            }
        },

        async mounted() {
            this.languageSelected()

            if ( localStorage.getItem("instrumentTickerShort") ){
                this.tickerShort = localStorage.getItem("instrumentTickerShort")
            } else {
                this.tickerShort = "EUR"
                localStorage.setItem("instrumentTickerShort","EUR")
            }


            await apiMarket.get('/api/v1/market/order/filter?has_returns=true')
            .then(response => {
                this.dataPurchases = response.data
                console.log(this.dataPurchases)
                
                setTimeout(() => {
                    this.load = false
                }, 500);

                setTimeout(() => {
                    this.scrollToAnchor();
                }, 600);
            })
            .catch(error => {
                this.errorsGet.dataPurchases = error.response.status

                setTimeout(() => {
                    this.load = false

                    if (this.dataPurchases.length > 0) {
                        this.scrollToItem(this.dataPurchases[0].return.reference);
                    }
                }, 1000);

                if ( error.response.status == "401" ){
                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/`
                    }, 2000);
                }
            })


           // BREADCRUMB
            const newArray = [
                {
                    slug: `${this.flagSelected}/account/purchases`,
                    name: this.getMyLanguage("Account","purchases.title")
                },
                {
                    slug: null,
                    name: `${this.getMyLanguage("Account","purchases-returns.title")}`
                },
            ];

            this.breadcrumb = newArray;
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateFormat(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('DD/MM/YY');
            },

            timeFormat(value) {
                const formattedTime = moment.utc(value).format('HH:mm');
                return formattedTime;
            },

            scrollToAnchor() {
                const idProduto = window.location.hash.substring(1);
                console.log(idProduto)
                                
                if (idProduto) {
                    window.location.href=`#${idProduto}`;
                    
                    setTimeout(() => {
                        document.title = `${this.getMyLanguage("Account","purchases-returns.title")} | DNE Market`
                    }, 100);
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
                document.title = `${this.getMyLanguage("Account","purchases-returns.title")} | DNE Market`
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-return.scss" lang="scss" scoped />