const $ = require('jquery')
window.$ = $

$(document).on("click",".field", function(){
    $(".field").removeClass("active")
    $(this).addClass("active")
})

$(document).on("blur",".field", function(){
    $(".field").removeClass("active")
})


import getMyContentLanguage from "@/services/contentLanguage.js";
import apiMerchant from '@/config/apiMerchant.js'
import moment from 'moment';
import bus from '@/eventBus';

export default {
    methods: {
        languageSelected(){
            this.flagSelected = this.$store.state.SelectLanguage.code

            if ( this.$route.params.productID != undefined ){
                document.title = `Manager | DNE Market`
            } else {
                document.title = this.getMyLanguage("Merchant","products.title.add-product")
            }
        },

        getMyLanguage(group, code) {
            return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
        },

        dateTime(value) {
            const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
            return moment.utc(value).locale(format).format('ll');
        },

        goTop(){
            $('html, body').animate({scrollTop : 0},200);
            return false;
        },


        // STEPS
        checkStep(){
            this.flowRegister.load = true
            
            const urlParams = new URLSearchParams(window.location.search);
            if ( urlParams.size > 0 ){
                if ( urlParams.get("guide") == "description" ){
                    setTimeout(() => {
                        this.navStep("stp01")
                    }, 600);
                }
                
                if ( urlParams.get("guide") == "variants" ){
                    setTimeout(() => {
                        this.navStep("stp02")
                    }, 600);
                }
                
                if ( urlParams.get("guide") == "images" ){
                    setTimeout(() => {
                        this.navStep("stp03")
                    }, 600);
                }
            } else {
                setTimeout(() => {
                    this.flowRegister.load = false
                }, 1000);
            }
        },

        navStep(value){
            this.flowRegister.load = true

            const updateUrlParameter = (param, value) => {
                const url = new URL(window.location.href);
                url.searchParams.set(param, value);
                history.replaceState(null, '', url);
            };
        
            if (value == "stp01") {
                updateUrlParameter('guide', 'description');
                this.flowRegister.step = "stp01"
                this.goTop();
                
                setTimeout(() => {
                    this.flowRegister.load = false
                }, 1000);
            }
            
            if (value == "stp02") {
                updateUrlParameter('guide', 'variants');
                this.flowRegister.step = "stp02"
                this.goTop();
                
                setTimeout(() => {
                    this.flowRegister.load = false
                }, 1000);
            }

            if (value == "stp03") {
                updateUrlParameter('guide', 'images');
                this.flowRegister.step = "stp03"
                this.goTop();
                
                setTimeout(() => {
                    this.flowRegister.load = false
                }, 1000);
            }
        },


        // SLUG
        // generateSlugProduct(value, place, idVariant) {
        generateSlugProduct(value, place) {
            const name = value.toLowerCase();
            const slug = name.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w\s-]/g, "-")
            .replace(/\s+/g, "-")
            .replace(/--+/g, "-");

            if ( place == "product" ){
                return this.dataProduct.slug = slug;
            }

            if ( place == 'variant' ){
                return this.modalVariant.data.slug = slug
                // return this.dataProduct.variants.variant.filter(variant => variant.id == idVariant)[0].id
            }
        },

        
        // VARIANTS
        addVariant(){
            this.dataProduct.variables.push(this.flowRegister.variable)
            this.flowRegister.variable = []
            this.modal.variables = false
            this.imageSelection()
            this.$toast.success("Variante adicionada");

            setTimeout(() => {
                var lastVariable = $('.step-03 .variables .variable').last();
                var positionTop = lastVariable.offset().top;

                $(window).scrollTop(positionTop - 80);
            }, 500);

            if ( this.dataProduct.variables.length == 1 ){
                this.modal.addImages = true
            }
        },
        
        removeVariable(value){
            this.dataProduct.variables.splice(value, 1)
        },

        imageSelection() {
            if (this.allSelected) {
                this.flowRegister.selectedImages = Array(this.dataProduct.images.length).fill(false);
            } else {
                this.flowRegister.selectedImages = Array(this.dataProduct.images.length).fill(true);
            }
        },


        // SAVE STEP        
        save(step){

            // STEP 01
            if ( step == "stp01" ){
                this.flowRegister.load = true
                
                this.validateStep01()
                const validatedInputs = Object.values(this.flowRegister.validate).every(value => value === true)

                if (validatedInputs) {

                    console.log(this.productID)

                    if ( this.productID != undefined ){
                        setTimeout(() => {
                            this.flowRegister.load = false
                            this.flowRegister.step01Validated = true
                            this.flowRegister.step = "stp02"
                            this.$toast.success("Salvo com sucesso");
                        }, 1500);
                        
                        // apiMerchant.put(`/api/v2/market/product/${this.dataProduct.id}`, this.dataProduct)
                        // .then(response => {
                        //     this.dataProduct = response.data
                        //     console.log(this.dataProduct)

                        //     setTimeout(() => {
                        //         this.flowRegister.load = false
                        //         this.flowRegister.step01Validated = true
                        //         this.flowRegister.step = "stp02"
                        //         this.$toast.success("Salvo com sucesso");
                        //     }, 1500);
                        // })
                        // .catch(error => {
                        //     console.log(error)
                        //     this.$toast.error("Houve um erro ao tentar atualizar o produto")
                            
                        //     setTimeout(() => {
                        //         this.flowRegister.load = false
                        //     }, 500);
                        // })
                    } else {
                        apiMerchant.post(`/api/v2/market/product`, this.dataProduct)
                        .then(response => {
                            this.dataProduct = response.data
    
                            window.location.href = `/${this.flagSelected}/merchant/products/manager/${this.dataProduct.id}?new=true`
                        })
                        .catch(error => {
                            console.log(error)
                            this.$toast.error("Houve um erro ao tentar registar um novo produto")
                            
                            setTimeout(() => {
                                this.flowRegister.load = false
                            }, 500);
                        })
                    }
                    
                    
                } else {
                    console.log("função para localizar o primeiro erro");
                    this.$toast.error("Um ou mais campos obrigatórios estão em branco")
                    
                    setTimeout(() => {
                        this.flowRegister.load = false
                    }, 500);
                }
            }

            
            if ( step == "stp02" ){
                this.flowRegister.load = true

                // validar campos
                // post para api

                // then
                this.goTop()

                setTimeout(() => {
                    this.flowRegister.load = false
                    this.flowRegister.step = "stp03"
                    this.flowRegister.step02Validated = true                        
                    this.$toast.success("Salvo com sucesso");
                }, 1500);
            }
            
            if ( step == "stp03" ){
                this.flowRegister.load = true

                // validar campos
                // post para api

                // then
                this.goTop()

                setTimeout(() => {
                    this.flowRegister.load = false
                    this.flowRegister.step = "stp04"
                    this.flowRegister.step03Validated = true
                    this.$toast.success("Salvo com sucesso");
                }, 1500);
            }
        },

        uploadFile() {
            this.upload.File = this.$refs.file.files[0];
            console.log(this.upload)
        },

        delImage(){
            this.modal.removeImages = true
        }
    },

    async mounted() {
        this.languageSelected()
        this.checkStep()

        const urlParams = new URLSearchParams(window.location.search);
        const newValue = urlParams.get('new');
        
        if (newValue === 'true') {
            this.navStep("stp02")
        }

        this.productID = this.$route.params.productID
        
        if ( this.productID != undefined ){
            this.flowRegister.step01Validated = true
            // this.flowRegister.step02Validated = true
            // this.flowRegister.step03Validated = true
            
            await apiMerchant.get(`/api/v2/market/product/${this.productID}`)
            .then(response => {
                this.dataProduct = response.data
                console.log(this.dataProduct)

                this.flowAddVariant.variables = this.dataProduct.variants

                setTimeout(() => {
                    this.load = false
                }, 500)
            })
            .catch(error => {
                this.load = false
                this.errorsGet.dataProduct = JSON.stringify(error.response.status)

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }
            })
        } else{
            setTimeout(() => {
                    this.load = false
                }, 500);
        }

        this.breadcrumb = [
            {
                slug: `${this.flagSelected}/merchant/dashboard`,
                name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
            },
            {
                slug: `${this.flagSelected}/merchant/products`,
                name: this.getMyLanguage("Merchant","breadcrumb.products")
            }
        ]

        if ( this.productID == undefined ){
            this.breadcrumb.push({name: this.getMyLanguage("Merchant","breadcrumb.add-product")})
        } else{ 
            this.breadcrumb.push({name: this.getMyLanguage("Merchant","breadcrumb.manager-product")})
        }

        // then
        this.selectedType = "product"
    },

    watch: {
        '$store.state.SelectLanguage.code': {
            immediate: true,
            handler() {
                this.languageSelected();
            }
        }
    },

    computed: {
        allSelected() {
            return this.flowRegister.selectedImages.every(value => value === true);
        }
    },

    created() {
        if ( this.$store.state.statusLogin ){
            if ( this.$route.params.productID != undefined ){
                setTimeout(() => {
                    document.title = `${this.dataProduct.name} | DNE Market`
                }, 1000);
            } else {
                document.title = this.getMyLanguage("Merchant","products.title.add-product")
            }
        }


        const meta = document.querySelector('meta[name="robots"]')
        if (meta) {
            meta.content = 'noindex,nofollow'
        } else {
            const newMeta = document.createElement('meta')
            newMeta.name = 'robots'
            newMeta.content = 'noindex,nofollow'
            document.getElementsByTagName('head')[0].appendChild(newMeta)
        }
    },
}