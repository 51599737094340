<template>
    <div class="dne-market become-seller">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <section class="cont-become-seller">
            <h1 class="title-secondary">
                {{getMyLanguage("BecomeSeller","become-seller.title")}}
            </h1>

            <div class="container-fluid">

                <div class="row">
                    <div class="col-11 col-lg-6 image"></div>

                    <div class="col-12 col-lg-6 cta">
                        <div class="cont">
                            <h2>{{getMyLanguage("BecomeSeller","become-seller.cont.title")}}</h2>
                            <p>{{getMyLanguage("BecomeSeller","become-seller.cont.msg")}}</p>

                            <ul class="topics" v-html="getMyLanguage('BecomeSeller','become-seller.cont.topics')"></ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <div class="load load-register" v-if="load"></div>

        <!-- REGISTER -->
        <section class="form-become-seller" v-if='flowRegister == "Registering" && !load'>

            <div class="container" id="preregister">
                <div class="have-registration">
                    <div class="btn-primary btn-registered" @click="registerCompany()" v-if="!$store.state.statusLogin">
                        {{getMyLanguage("BecomeSeller","become-seller.register.btn-registered")}}
                    </div>
                </div>

                <h2 class="title-secondary">{{getMyLanguage("BecomeSeller","become-seller.register.title")}}</h2>

                <div class="row box-register">
                    <h3 class="title-box">{{getMyLanguage("BecomeSeller","become-seller.register.title-box.company")}}</h3>

                    <!-- COMPANY NAME -->
                    <div class="col-12 col-sm-6">
                        <label :class="{validated: errorForm.preRegister.company.fullName === false, error: errorForm.preRegister.company.fullName}">
                            <span>{{getMyLanguage("BecomeSeller","become-seller.register.company-name")}}</span>
                            
                            <!-- <input type="text" class="form" v-model="preRegister.company.fullName" :class='{error: errorForm.preRegister.company.fullName}' @keyup="errorForm.preRegister.company.fullName = false"> -->
                            
                            <input type="text" class="form"
                                v-model="preRegister.company.fullName"
                                @blur="validateForm('companyName')">

                            <p class="msg-error-form" v-if='errorForm.preRegister.company.fullName'>
                                {{getMyLanguage("BecomeSeller","become-seller.register.company-name.error")}}
                            </p>
                        </label>
                    </div>


                    <!-- INDUSTRY -->
                    <div class="col-12 col-sm-6">
                        <label :class="{validated: errorForm.preRegister.company.industryId === false, error: errorForm.preRegister.company.industryId}">
                            <span>{{getMyLanguage("BecomeSeller","become-seller.register.industry")}}</span>
                            
                            <select class="form"
                                v-model="preRegister.company.industryId"
                                :class='{error: errorForm.preRegister.company.industry}'
                                @change="errorForm.preRegister.company.industryId = false">

                                <option value="" disabled selected hidden>
                                    {{getMyLanguage("BecomeSeller","become-seller.register.industry.select")}}
                                </option>

                                <option v-for="(itemsCompanyIndustry,indexCompInd) in dataCompanyIndustry" :key="indexCompInd" :value="itemsCompanyIndustry.id">
                                    {{itemsCompanyIndustry.Value}}
                                </option>
                            </select>

                            <p class="msg-error-form" v-if='errorForm.preRegister.company.industryId'>
                                {{getMyLanguage("BecomeSeller","become-seller.register.industry.error")}}
                            </p>
                        </label>
                    </div>

                    <!-- COMPANY EMAIL -->
                    <div class="col-12 col-sm-6">
                        <label
                            :class="{validated: (validateEmail.company.regex && errorForm.preRegister.company.email === false),
                                error: (validateEmail.company.regex === false || errorForm.preRegister.company.email)}">
                            
                            <span>{{getMyLanguage("BecomeSeller","become-seller.register.email")}}</span>

                            <input type="email" class="form" maxlength="60"
                            v-model="preRegister.company.email"
                            @blur='regexEmail("company")'
                            @keyup='checkEmail("company"), validateForm("companyEmail")'>
                            
                            <p class="msg-error-form" v-if='validateEmail.company.regex === false || errorForm.preRegister.company.email'>
                                {{getMyLanguage("BecomeSeller","become-seller.register.email.error")}}
                            </p>

                            <!-- <p class="msg-error-form" v-if="errorsPost.company.email">
                                E-mail já registado
                            </p> -->
                        </label>
                    </div>

                    <!-- COMPANY TAXNUMBER -->
                    <div class="col-12 col-sm-6">
                        <label :class="{validated: errorForm.preRegister.company.taxNumber === false, error: errorForm.preRegister.company.taxNumber}">
                            <span>{{getMyLanguage("BecomeSeller","become-seller.register.taxnumber")}}</span>
                            
                            <input type="text" class="form" maxlength="50"
                                v-model="preRegister.company.taxNumber"
                                @keyup='validateForm("companyTaxNumber")'>

                            <p class="msg-error-form" v-if="errorForm.preRegister.company.taxNumber">
                                {{getMyLanguage("errors","errors.required")}}
                            </p>
                        </label>
                    </div>

                    <!-- COMPANY COUNTRY -->
                    <div class="col-12 col-sm-6">
                        <div class="autocomplete has-label no-icon formLanguage" :class="{opened: dropListOpened.countryCompany}">
                            <div class="openAutocomplete"
                                v-if="!listCountryCompanyStatus"
                                @click="dropListCountryCompany(true)"></div>

                            <div class="closeAutocomplete closeDropListCountry"
                                v-if="listCountryCompanyStatus"
                                @click="dropListCountryCompany(false)"></div>

                            <label
                                :class="{validated: (errorForm.preRegister.company.countryId === false && selectedCountryFlagCompany != false && !errorsPost.preRegister),
                                    error: (errorForm.preRegister.company.countryId || errorsPost.preRegister)}">

                                <span>{{getMyLanguage("BecomeSeller","become-seller.register.country")}}</span>

                                <img class="flag-selected"
                                    :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`"
                                    v-if="selectedCountryFlagCompany != false">

                                <input type="text" id="residenceCountryCompany" class="form"
                                    :class="{selected: selectedCountryFlagCompany != false}"
                                    @focus="dropListCountryCompany(true)"
                                    @blur="dropListCountryCompany(false)"
                                    @keydown.esc="dropListCountryCompany(false)"
                                    v-on:keyup="filterListCountryCompany()"
                                    :value="selectedCountryCompany.name">

                                <p class="msg-error-form" v-if='errorForm.preRegister.company.countryId'>
                                    {{getMyLanguage("BecomeSeller","become-seller.register.country.error")}}
                                </p>
                            </label>

                            <div class="cont-autocomplete" v-if="listCountryCompanyStatus">
                                <ul id="drop-list-company">
                                    <li
                                        v-for="(itemList,index) in $store.state.allCountries"
                                        :key="index"
                                        @click="preRegister.company.countryId = itemList.Id,
                                            preRegister.company.phoneCountryId = itemList.Id,
                                            changeMask('company',itemList.PhoneMask),
                                            selectedCountryFlagCompany = itemList.Flag,
                                            selectedCountryDDICompany.countrySelected = true,
                                            selectedCountryDDICompany.active = 'active',
                                            selectedCountryDDICompany.ddi = itemList.DDI,
                                            selectedCountryDDICompany.flag = itemList.Flag,
                                            selectedCountryCompany.name = itemList.Name,
                                            dropListCountryCompany(false),
                                            validateForm('companyCountry')">

                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                        <a>{{itemList.Name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- COMPANY PHONE -->
                    <div class="col-12 col-sm-6">
                        <div class="form-ddi-phone">
                            <label :class="{validated: validatePhone.company.regex, error: validatePhone.company.regex === false}">
                                <span>
                                    {{getMyLanguage("BecomeSeller","become-seller.register.phone")}}&nbsp;
                                    <small>({{getMyLanguage("BecomeSeller","become-seller.register.phone.preference")}})</small>
                                </span>

                                <div class="select-ddi"
                                    :class='{disabled: selectedCountryDDICompany.PhoneMask == "?"}'
                                    v-if="selectedCountryDDICompany.changeMask">
                                    
                                    <div class="selected-ddi">
                                        <div class="flag">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDICompany.flag}/flags`" v-if="selectedCountryDDICompany.flag != ''">
                                        </div>
                                        
                                        <span class="ddi" v-if="selectedCountryDDICompany.PhoneMask != '?'">
                                            +{{selectedCountryDDICompany.ddi}}
                                        </span>
                                    </div>
                                    
                                    <!-- <input v-if="selectedCountryDDICompany.PhoneMask != '?'" v-model="validatePhone.company.val" id="phoneNumberCompany" type="text" inputmode="numeric" class="form" v-mask="selectedCountryDDICompany.PhoneMask" @keydown="errorForm.preRegister.company.phoneNumber = false" @blur='regexPhone("company")' maxlength="25"> -->
                                    <input type="text" inputmode="numeric" class="form form-phone" id="phoneNumberCompany" maxlength="25"
                                        v-model="preRegister.company.phoneNumber"
                                        v-mask="selectedCountryDDICompany.PhoneMask"
                                        @blur="regexPhone('company'), validateForm('companyPhone')"
                                        :placeholder="selectedCountryDDICompany.PhoneMask"
                                        v-if='selectedCountryDDICompany.PhoneMask != "?" && selectedCountryDDICompany.PhoneMask != "load"'>
                                </div>

                                <div class="form load-phone" v-if="!selectedCountryDDICompany.changeMask"></div>
                                
                                <p class="msg-error-form" v-if='validatePhone.company.regex === false || errorForm.preRegister.company.phoneNumber'>
                                    {{getMyLanguage("BecomeSeller","become-seller.register.phone.error")}}
                                </p>

                                <!-- <p class="msg-error-form" v-if="errorsPost.company.phone">
                                    Telefone já registado
                                </p> -->
                            </label>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="box-pending" v-if="errorsPost.company.emailPhone">
                            <p>{{getMyLanguage("errors","errors.emailPhoneRegistered")}}</p>
                        </div>
                    </div>
                    
                    <!-- <div class="col-12 col-sm-6 field-phoneNumber">
                        <label :class="{validated: validatePhone.company.regex, error: validatePhone.company.regex === false}">
                            <span>Telefone <small>(preferencialmente telemóvel)</small></span>
                            <div class="flag-phone" v-if='selectedCountryDDICompany.PhoneMask != "?" && selectedCountryDDICompany.PhoneMask != "load"'>
                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`" class="flag-selected">
                            </div>

                            <div class="form disabled" v-if='selectedCountryDDICompany.PhoneMask == "?"'>Selecione primeiro o país</div>
                            <div class="load" v-if='selectedCountryDDICompany.PhoneMask == "load"'></div>
                            <input type="text" inputmode="numeric" class="form" id="phoneNumberCompany" v-model="preRegister.company.phoneNumber" v-mask="selectedCountryDDICompany.PhoneMask" @blur="regexPhone('company'), validateForm('companyPhone')" v-if='selectedCountryDDICompany.PhoneMask != "?" && selectedCountryDDICompany.PhoneMask != "load"' maxlength="25">

                            <p class="msg-error-form" v-if='validatePhone.company.regex === false || errorForm.preRegister.company.phoneNumber'>Número inválido</p>
                        </label>
                    </div> -->
                </div>


                <!-- - - - - - - - - - - - - - - - - - - - - - - -->


                <div class="row box-register">
                    <h3 class="title-box">
                        {{getMyLanguage("BecomeSeller","become-seller.register.title-box.person")}}
                    </h3>

                    <!-- PERSON NAME -->
                    <div class="col-12 col-sm-6">
                        <label :class="{validated: errorForm.preRegister.person.fullName === false, error: errorForm.preRegister.person.fullName}">
                            <span>{{getMyLanguage("BecomeSeller","become-seller.register.person-name")}}</span>
                            
                            <input type="text" class="form"
                                v-model="preRegister.person.fullName"
                                @blur="validateForm('personName')">
                            
                            <p class="msg-error-form" v-if='errorForm.preRegister.person.fullName'>
                                {{getMyLanguage("BecomeSeller","become-seller.register.person-name.error")}}
                            </p>
                        </label>
                    </div>

                    <!-- EMAIL -->
                    <div class="col-12 col-sm-6">
                        <label
                            :class="{validated: (validateEmail.person.regex && errorForm.preRegister.person.email === false),
                                error: (validateEmail.person.regex === false || errorForm.preRegister.person.email)}">

                            <span>{{getMyLanguage("BecomeSeller","become-seller.register.email")}}</span>
                            
                            <input type="email" class="form" maxlength="60"
                                v-model="preRegister.person.email"
                                @blur='regexEmail("person"), validateForm("personEmail")'>

                            <p class="msg-error-form" v-if='validateEmail.person.regex === false || errorForm.preRegister.person.email'>
                                {{getMyLanguage("BecomeSeller","become-seller.register.email.error")}}
                            </p>

                            <!-- <p class="msg-error-form" v-if="errorsPost.person.email">
                                E-mail já registado
                            </p> -->
                        </label>
                    </div>

                    <!-- COUNTRY -->
                    <div class="col-12 col-sm-6">
                        <div class="autocomplete has-label no-icon formLanguage" :class="{opened: dropListOpened.country}">
                            <div class="openAutocomplete"
                                v-if="!listCountryStatus"
                                @click="dropListCountry(true)"></div>

                            <div class="closeAutocomplete closeDropListCountry"
                                v-if="listCountryStatus"
                                v-on:click="dropListCountry(false)"></div>

                            <label
                                :class="{validated: (errorForm.preRegister.person.countryId === false && selectedCountryFlag != false && !errorsPost.preRegister),
                                    error: (errorForm.preRegister.person.countryId || errorsPost.preRegister)}">

                                <span>{{getMyLanguage("BecomeSeller","become-seller.register.country")}}</span>

                                <img class="flag-selected"
                                    :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`"
                                    v-if="selectedCountryFlag != false">

                                <input type="text" id="residenceCountry" class="form"
                                    :class="{selected: selectedCountryFlag != false}"
                                    @focus="dropListCountry(true)"
                                    @blur="dropListCountry(false)"
                                    @keydown.esc="dropListCountry(false)"
                                    @keyup="filterListCountry()"
                                    :value="selectedCountry.name">

                                <p class="msg-error-form" v-if='errorForm.preRegister.person.countryId'>
                                    {{getMyLanguage("BecomeSeller","become-seller.register.person-country.error")}}
                                </p>
                            </label>

                            <div class="cont-autocomplete" v-if="listCountryStatus">
                                <ul id="drop-list">
                                    <li
                                        v-for="(itemList,index) in $store.state.allCountries"
                                        :key="index"
                                        @click="preRegister.person.countryId = itemList.Id,
                                            preRegister.person.phoneCountryId = itemList.Id,
                                            changeMask('person',itemList.PhoneMask),
                                            selectedCountryFlag = itemList.Flag,
                                            selectedCountryDDI.countrySelected = true,
                                            selectedCountryDDI.active = 'active',
                                            selectedCountryDDI.ddi = itemList.DDI,
                                            selectedCountryDDI.flag = itemList.Flag,
                                            selectedCountry.name = itemList.Name,
                                            dropListCountry(false),
                                            validateForm('personCountry')">

                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                        <a>{{itemList.Name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- PHONE -->
                    <div class="col-12 col-sm-6">
                        <div class="form-ddi-phone">
                            <label :class="{validated: validatePhone.person.regex, error: validatePhone.person.regex === false}">
                                <span>
                                    {{getMyLanguage("BecomeSeller","become-seller.register.mobile-phone")}}&nbsp;
                                    <small>({{getMyLanguage("BecomeSeller","become-seller.register.mobile-phone.mandatorily")}})</small>
                                </span>
                                
                                <div class="select-ddi"
                                    :class='{disabled: selectedCountryDDI.PhoneMask == "?"}'
                                    v-if="selectedCountryDDI.changeMask">

                                    <div class="selected-ddi">
                                        <div class="flag">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDI.flag}/flags`" v-if="selectedCountryDDI.flag != ''">
                                        </div>

                                        <span class="ddi" v-if="selectedCountryDDI.PhoneMask != '?'">+{{selectedCountryDDI.ddi}}</span>
                                    </div>
                                    
                                    <input type="text" inputmode="numeric" class="form form-phone" id="phoneNumber" maxlength="25"
                                        v-model="preRegister.person.phoneNumber"
                                        v-mask="selectedCountryDDI.PhoneMask"
                                        @blur="regexPhone('person'), validateForm('personPhone')"
                                        :placeholder="selectedCountryDDI.PhoneMask"
                                        v-if='selectedCountryDDI.PhoneMask != "?" && selectedCountryDDI.PhoneMask != "load"'>
                                </div>

                                <div class="form load-phone" v-if="!selectedCountryDDI.changeMask"></div>
                                
                                <p class="msg-error-form" v-if='validatePhone.person.regex === false || errorForm.preRegister.person.phoneNumber'>
                                    {{getMyLanguage("BecomeSeller","become-seller.register.mobile-phone.error")}}
                                </p>

                                <!-- <p class="msg-error-form"  v-if="errorsPost.person.phone">
                                    Telefone já registado
                                </p> -->
                            </label>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="box-pending" v-if="errorsPost.person.emailPhone">
                            <p>{{getMyLanguage("errors","errors.emailPhoneRegistered")}}</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12" v-if="errorsPost.preRegister">
                        <div class="box box-error box-error-post">
                            <h3 class="title-secondary">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-html='getMyLanguage("BecomeSeller","error.not_allowed_restricted_country_pep-country")'></p>
                        </div>
                    </div>

                    <div class="col-12" v-if="!errorsPost.preRegister">
                        <div class="btn">
                            <div class="btn-primary" @click="validateForm('full')">
                                {{getMyLanguage("BecomeSeller","become-seller.register.btn-register")}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- SUCCESS -->
        <section class="success" v-if='flowRegister == "success" && !load'>
            <h2 class="title-secondary">
                {{getMyLanguage("BecomeSeller","become-seller.success.title")}}
            </h2>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p>{{getMyLanguage("BecomeSeller","become-seller.success.msg01")}}</p>
                        <p>{{getMyLanguage("BecomeSeller","become-seller.success.msg02")}}</p>
                    </div>
                </div>
            </div>
        </section>
        

        <!-- ERROR -->
        <section class="error-register" v-if='flowRegister == "error" && !load'>
            <h2 class="title-secondary">
                {{getMyLanguage("BecomeSeller","become-seller.error.title")}}
            </h2>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p>{{getMyLanguage("BecomeSeller","become-seller.error.msg01")}}</p>
                        <p>{{getMyLanguage("BecomeSeller","become-seller.error.msg02")}}</p>
                    </div>
                </div>
            </div>
        </section>
        
        
        <!-- REGISTRED -->
        <section class="success" v-if='flowRegister == "registred" && !load'>
            <h2 class="title-secondary">
                {{getMyLanguage("BecomeSeller","become-seller.success.title")}}
            </h2>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p>{{getMyLanguage("BecomeSeller","become-seller.success.msg01")}}</p>
                        <p>{{getMyLanguage("BecomeSeller","become-seller.success.msg02")}}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    $(document).on("click",".form-ddi-phone", function(){
        $(".form-ddi-phone").removeClass("active")
        $(this).addClass("active")
    })
    
    $(document).on("blur",".form-ddi-phone", function(){
        $(".form-ddi-phone").removeClass("active")
    })

    import Header from '@/components/Essentials/Header.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiPlatform from '@/config/apiPlatform.js'

    import bus from '@/eventBus';

    export default {
        components: {
            Header
        },

        data(){
            return {
                headerTemplate: "search",
                flowRegister: "Registering",
                load: true,

                dataOccupationArea: [
                    {
                        id: "1",
                        name: "Área aaa"
                    },
                    {
                        id: "2",
                        name: "Área bbb"
                    },
                    {
                        id: "3",
                        name: "Área ccc"
                    },
                ],
                
                dataCompanyIndustry: [],

                preRegister: {
                    person: {
                        pep: false,
                        fullName: "",
                        email: "",
                        countryId: "",
                        languageId: "cfeaba92-f219-4374-b5a4-3cd11f9ed34a",
                        phoneCountryId: "",
                        phoneNumber: "",
                        taxNumber: ""
                    },
                    company: {
                        fullName: "",
                        email: "",
                        industryId: "",
                        countryId: "",
                        languageId: "cfeaba92-f219-4374-b5a4-3cd11f9ed34a",
                        phoneCountryId: "",
                        phoneNumber: "",
                        taxNumber: ""
                    }
                },

                errorForm: {
                    preRegister: {
                        person: {
                            fullName: "",
                            email: "",
                            countryId: "",
                            languageId: "",
                            phoneCountryId: "",
                            phoneNumber: "",
                            taxNumber: ""
                        },
                        company: {
                            fullName: "",
                            email: "",
                            industryId: "",
                            countryId: "",
                            languageId: "",
                            phoneCountryId: "",
                            phoneNumber: "",
                            taxNumber: ""
                        }
                    }
                },

                errorsPost: {
                    preRegister: false,

                    company: {
                        email: false,
                        phone: false,
                        emailPhone: false,
                    },

                    person: {
                        email: false,
                        phone: false,
                        emailPhone: false
                    }
                },

                validateEmail: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },
                
                validatePhone: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },

                listCountryCompanyStatus: false,
                selectedCountryCompany: {},
                selectedCountryFlagCompany: false,

                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,
                
                selectedCountryDDICompany: {
                    changeMask: true,
                    PhoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },

                selectedCountryDDI: {
                    changeMask: true,
                    PhoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },

                dropListOpened: {
                    country: false,
                    ddiPerson: false
                },
            }
        },

        async mounted() {
            
            setTimeout(() => {
                this.load = false

                if ( localStorage.getItem("isMerchant") ){
                    // this.flowRegister = "registred"
                    window.location.href = `/${this.flagSelected}/merchant/dashboard`
                }
            }, 100);

            setTimeout(() => {
                if ( this.$store.state.statusLogin ){
                    window.location.href = `/${this.flagSelected}/merchant/company/0`

                    // if ( this.$store.state.profile.account_type == "Person" ){
                    //     this.preRegister.person.fullName = this.$store.state.profile.profile_full_name
                    //     this.preRegister.person.email = this.$store.state.profile.profile_email
                    //     this.preRegister.person.countryId = this.$store.state.profile.profile_phone_country_id
                    //     this.preRegister.person.languageId = this.$store.state.profile.profile_phone_country_id
                    //     this.preRegister.person.phoneCountryId = this.$store.state.profile.profile_phone_country_id
                        
                    //     this.selectedCountryDDI.PhoneMask = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.person.languageId)[0].PhoneMask
                    //     this.selectedCountryDDI.flag = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.person.languageId)[0].Flag
                    //     this.selectedCountryDDI.ddi = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.person.languageId)[0].DDI
                    //     this.preRegister.person.phoneNumber = this.$store.state.profile.profile_phone_number
                    //     this.selectedCountryFlag = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.person.languageId)[0].Flag
                    //     this.selectedCountry.name = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.person.languageId)[0].Name
                    // } else{
                    //     this.preRegister.company.fullName = this.$store.state.profile.profile_full_name
                    //     this.preRegister.company.email = this.$store.state.profile.profile_email
                    //     this.preRegister.company.countryId = this.$store.state.profile.profile_phone_country_id
                    //     this.preRegister.company.languageId = this.$store.state.profile.profile_phone_country_id
                    //     this.preRegister.company.phoneCountryId = this.$store.state.profile.profile_phone_country_id
                        
                    //     this.selectedCountryDDICompany.PhoneMask = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.company.languageId)[0].PhoneMask
                    //     this.selectedCountryDDICompany.flag = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.company.languageId)[0].Flag
                    //     this.selectedCountryDDICompany.ddi = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.company.languageId)[0].DDI
                    //     this.preRegister.company.phoneNumber = this.$store.state.profile.profile_phone_number
                    //     this.selectedCountryFlagCompany = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.company.languageId)[0].Flag
                    //     this.selectedCountryCompany.name = this.$store.state.allCountries.filter(lang => lang.Id == this.preRegister.company.languageId)[0].Name
                    // }
                }
            }, 1500);



            await apiPlatform.get('/api/v1/public/attributes/codes/company_industry')
            .then(response => {
                this.dataCompanyIndustry = response.data
            })
            .catch(error => {
                console.log(error)
            })
            
            this.goTop()
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                // this.preRegister.person.languageId = localStorage.getItem("flagLanguage")
                // this.preRegister.company.languageId = localStorage.getItem("flagLanguage")

                document.title = this.getMyLanguage("seo","title.BecomeSeller");
                document.head.querySelector('meta[name=description]').content = this.getMyLanguage("seo", "title.description.BecomeSeller");
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },


            registerCompany(){
                if ( this.$store.state.statusLogin ){
                    window.location.href = `/${this.flagSelected}/merchant/company/0`
                } else {
                    this.openLogin()
                }
            },
            
            openLogin(){
                this.show = false
                bus.emit('open-login',"become-seller");
            },

            regexEmail(emailCheck){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if ( emailCheck === "company"){
                    if (regex.test(this.preRegister.company.email)) {
                        this.validateEmail.company.regex = true
                    } else{
                        this.validateEmail.company.regex = false
                    }
                } else if ( emailCheck === "person"){
                    if (regex.test(this.preRegister.person.email)) {
                        this.validateEmail.person.regex = true
                    } else{
                        this.validateEmail.person.regex = false
                    }
                }
            },
            
            checkEmail(emailCheck){
                if ( emailCheck === "company" ){
                    if ( this.validateEmail.company.val === this.preRegister.company.email ){
                        this.validateEmail.company.status = true
                    } else{
                        this.validateEmail.company.status = false
                    }
                } else if ( emailCheck === "person" ){
                    if ( this.validateEmail.person.val === this.preRegister.person.email ){
                        this.validateEmail.person.status = true
                    } else{
                        this.validateEmail.person.status = false
                    }
                }
            },
            
            regexPhone(phoneCheck){
                const regex = /^\+[1-9]\d{8,14}$/;

                if ( phoneCheck === "company"){
                    let phoneE164Company = this.selectedCountryDDICompany.ddi + this.preRegister.company.phoneNumber
                    phoneE164Company = "+" + phoneE164Company.replace(/[^0-9]/g,'')
                    
                    if (regex.test(phoneE164Company)) {
                        this.validatePhone.company.regex = true
                    } else{
                        this.validatePhone.company.regex = false
                    }
                } else if ( phoneCheck === "person"){
                    let phoneE164Person = this.selectedCountryDDI.ddi + this.preRegister.person.phoneNumber
                    phoneE164Person = "+" + phoneE164Person.replace(/[^0-9]/g,'')
                    
                    if (regex.test(phoneE164Person)) {
                        this.validatePhone.person.regex = true
                    } else{
                        this.validatePhone.person.regex = false
                    }
                }
            },

            dropListCountryCompany(status){
                setTimeout(() => {
                    this.listCountryCompanyStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryCompany = true
                        document.getElementById('residenceCountryCompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryCompany = false
                }
            },

            dropListCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.country = true
                        document.getElementById('residenceCountry').focus()
                    }, 10);
                } else {
                    this.dropListOpened.country = false
                }
            },

            filterListCountryCompany() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("residenceCountryCompany");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list-company");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            filterListCountry() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("residenceCountry");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },


            changeMask(type,mask){
                if ( type == "company" ){
                    this.selectedCountryDDICompany.PhoneMask = "load"
    
                    setTimeout(() => {
                        this.selectedCountryDDICompany.PhoneMask = mask
                    }, 1000);
    
                    setTimeout(() => {
                        document.getElementById('phoneNumberCompany').focus()
                    }, 1050);
                } else if ( type == "person" ){
                    this.selectedCountryDDI.PhoneMask = "load"
    
                    setTimeout(() => {
                        this.selectedCountryDDI.PhoneMask = mask
                    }, 1000);
    
                    setTimeout(() => {
                        document.getElementById('phoneNumber').focus()
                    }, 1050);
                }

            },


            validateForm(value){
                this.regexEmail("company")
                this.regexPhone("company")
                
                this.regexEmail("person")
                this.regexPhone("person")


                // COMPANY NAME
                if ( value == "full" || value == "companyName" ){
                    if ( this.preRegister.company.fullName == "" ){
                        this.errorForm.preRegister.company.fullName = true
                    } else if ( this.preRegister.company.fullName != "" ){
                        var companyName = this.preRegister.company.fullName
                        companyName = companyName.trim().split(' ').length
    
                        if ( companyName < 2 ){
                            this.errorForm.preRegister.company.fullName = true
                        } else {
                            this.errorForm.preRegister.company.fullName = false
                        }
                    }
                }

                // OCCUPATION AREA
                if ( value == "full" || value == "occupationArea" ){
                    if ( this.preRegister.company.industryId == "" ){
                        this.errorForm.preRegister.company.industryId = true
                    } else {
                        this.errorForm.preRegister.company.industryId = false
                    }
                }

                // COMPANY EMAIL
                if ( value == "full" || value == "companyEmail" ){
                    if ( this.preRegister.company.email == "" ){
                        this.errorForm.preRegister.company.email = true
                    } else {
                        this.errorForm.preRegister.company.email = false
                    }
                }

                // COMPANY TAXNUMBER
                if ( value == "full" || value == "companyTaxNumber" ){
                    if ( this.preRegister.company.taxNumber == "" ){
                        this.errorForm.preRegister.company.taxNumber = true
                    } else {
                        this.errorForm.preRegister.company.taxNumber = false
                    }
                }

                // COMPANY COUNTRY
                if ( value == "full" || value == "companyCountry" ){
                    if ( this.preRegister.company.countryId == "" ){
                        this.errorForm.preRegister.company.countryId = true
                    } else {
                        this.errorForm.preRegister.company.countryId = false
                    }
                }

                // COMPANY PHONE
                if ( value == "full" || value == "companyPhone" ){
                    if ( this.preRegister.company.phoneNumber == "" ){
                        this.errorForm.preRegister.company.phoneNumber = true
                    } else {
                        this.errorForm.preRegister.company.phoneNumber = false
                    }
                }


                // - - - - - - - - - - - - - - - - - - - - -


                // PERSON NAME
                if ( value == "full" || value == "personName" ){
                    if ( this.preRegister.person.fullName == "" ){
                        this.errorForm.preRegister.person.fullName = true
                    } else if ( this.preRegister.person.fullName != "" ){
                        var personName = this.preRegister.person.fullName
                        personName = personName.trim().split(' ').length

                        if ( personName < 2 ){
                            this.errorForm.preRegister.person.fullName = true
                        } else {
                            this.errorForm.preRegister.person.fullName = false
                        }
                    }
                }

                // PERSON EMAIL
                if ( value == "full" || value == "personEmail" ){
                    if ( this.preRegister.person.email == "" ){
                        this.errorForm.preRegister.person.email = true
                    } else {
                        this.errorForm.preRegister.person.email = false
                    }
                }

                // PERSON COUNTRY
                if ( value == "full" || value == "personCountry" ){
                    if ( this.preRegister.person.countryId == "" ){
                        this.errorForm.preRegister.person.countryId = true
                    } else {
                        this.errorForm.preRegister.person.countryId = false
                    }
                }

                // PERSON PHONE
                if ( value == "full" || value == "personPhone" ){
                    if ( this.preRegister.person.phoneNumber == "" ){
                        this.errorForm.preRegister.person.phoneNumber = true
                    } else {
                        this.errorForm.preRegister.person.phoneNumber = false
                    }
                }


                // - - - - - - - - - - - - - - - - - - - - -

                if ( value == "full" ){
                    if ( 
                        !this.errorForm.preRegister.company.fullName && 
                        !this.errorForm.preRegister.company.industryId && 
                        !this.errorForm.preRegister.company.email && 
                        !this.errorForm.preRegister.company.countryId && 
                        !this.errorForm.preRegister.company.phoneNumber && 
    
                        !this.errorForm.preRegister.person.fullName && 
                        !this.errorForm.preRegister.person.email && 
                        !this.errorForm.preRegister.person.countryId && 
                        !this.errorForm.preRegister.person.phoneNumber && 
    
                        this.validateEmail.company.regex && 
                        this.validatePhone.company.regex && 
                        this.validateEmail.person.regex && 
                        this.validatePhone.person.regex
                     ){
                        this.register()

                     } else{
                        window.location.href = "#preregister"
                     }
                }
            },

            register(){
                this.load = true

                apiPlatform.post('api/v2/platform/account/register', this.preRegister)
                .then(response => {
                    console.log(response)
                    this.flowRegister = "success"

                    setTimeout(() => {
                        this.load = false
                    }, 2000);
                })
                .catch(error => {
                    if ( error.response.data == "not_allowed_restricted_country_pep"){
                        this.errorsPost.preRegister = true
                        setTimeout(() => {
                            var boxError = $('.box.box-error.box-error-post');
                            var positionBoxError = boxError.offset().top;
            
                            $(window).scrollTop(positionBoxError - 120);

                            this.load = false
                        }, 200);
                    } else{
                        if ( error.response.data == "account_activate_email_already_exists" ){
                            this.errorsPost.person.emailPhone = true
                            
                            // this.errorsPost.company.email = true
                            // this.errorsPost.person.email = true

                        } else if ( error.response.data == "account_activate_company_email_already_exists" ){
                            this.errorsPost.person.emailPhone = true

                            // this.errorsPost.company.phone = true
                            // this.errorsPost.person.phone = true

                        } else {
                            this.flowRegister = "error"
                        }

                        
                        setTimeout(() => {
                            this.load = false
                        }, 200);
                    }
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.BecomeSeller");
            document.head.querySelector('meta[name=description]').content = this.getMyLanguage("seo", "title.description.BecomeSeller");
        },
   }
</script>

<style src="./custom-become-seller.scss" lang="scss" scoped />

<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';

    .cont-become-seller{
        .topics{
            margin-top: 2rem;
            
            li{
                list-style: circle inside;
                font-family: $font-primary-semibold;
                color: $color-secondary;
                
    
                &:not(:last-child){
                    margin-bottom: 5px;
                }
            }
        }
    }
</style>