<template>
    <section class="featured-products">
        <h2 class="title-secondary" data-aos="fade-up" data-aos-offset="-100">{{getMyLanguage("FeaturedProducts","featured-products.title")}}</h2>

        <div class="container-fluid">
            <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="3000" class="carousel-products">
                <Slide v-for="(shelfProduct, index) in dataSource" :key="index">
                    <div class="item" data-aos="fade-up" data-aos-offset="-100">
                        <!-- <div class="wishlist" :class="{selected: shelfProduct.wishlist}" @click="addWishlist(shelfProduct.Id,shelfProduct.wishlist)"></div> -->

                        <a :href="`/${shelfProduct.product_variants[0].slug}`">
                            <div class="highlights">
                                <div class="discount" v-if="shelfProduct.discount">-{{shelfProduct.discount}}%</div>
                                <div v-for="(listHighlights, index) in shelfProduct.highlights" :key="index" :class="listHighlights">{{getMyLanguage("Result","shelf.highlights." + listHighlights)}}</div>
                            </div>

                            <figure class="photo">
                                <img :src="shelfProduct.product_variants[0].attachment" :alt="shelfProduct.name">
                            </figure>

                            <div class="details">
                                <h3 class="name">
                                    {{shelfProduct.name}}&nbsp;<small>{{shelfProduct.brand.name}}</small>
                                </h3>

                                <div class="store">
                                    <!-- <div class="brand" title="Apple Store">
                                        <div><img src="~@/assets/images/brands/nike.svg"></div>
                                    </div> -->

                                    <small class="store-name">{{shelfProduct.product_variants[0].store.name}}</small>

                                    <div class="prices">
                                        <div class="price">
                                            <span class="dne"><span>{{(shelfProduct.product_variants[0].amount).toLocaleString(isoAlpha3, {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span><i></i></span>
                                            <small class="current">{{(shelfProduct.product_variants[0].price * shelfProduct.instrument.price.price).toLocaleString(isoAlpha3, {style:"currency", currency:shelfProduct.instrument.quote_asset.short})}}</small> 
                                        </div>
                                        
                                        <!-- <del><span>{{shelfProduct.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span><i></i></del> -->
                                    </div>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </Slide>
                
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </section>
</template>


<script>
    import { defineComponent } from 'vue'
    import { Carousel, Navigation, Slide } from 'vue3-carousel'

    import getMyContentLanguage from "@/services/contentLanguage.js";

    // import bus from '@/eventBus';

    export default defineComponent({
        props: {
            dataSource: {}
        },

        components: {
            Carousel,
            Slide,
            Navigation,
        },

        data (){
            return {
                flagSelected: "en",
                isoAlpha3: "PRT",

                wishlist: {
                    active: "",
                    id: "",
                    action: ""
                },

                settings: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                    wrapAround: true,
                },

                breakpoints: {
                    576: {
                        itemsToShow: 3,
                    },
                    
                    992: {
                        itemsToShow: 4,
                    },
                    
                    1280: {
                        itemsToShow: 5,
                    },
                },
            }
        },

        async mounted(){
            if ( localStorage.getItem("countryIsoAlpha3") ){
                this.isoAlpha3 = localStorage.getItem("countryIsoAlpha3")
            } else {
                this.isoAlpha3 = "PRT"
                localStorage.setItem("countryIsoAlpha3","PRT")
            }
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                // setTimeout(() => {
                //     this.dataSource.data.forEach(item => {
                //         item.slug = item.slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                //     });
                // }, 500);
            },

            // addWishlist(id,status){
            //     if ( status ){
            //         this.wishlist.action = "remove"
            //     } else {
            //         this.wishlist.action = "add"
            //     }

            //     this.wishlist.id = id

            //     bus.emit('add-to-wishlist', this.wishlist);
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        // created() {
        //     bus.on('updateShelfTemplate', (item) => {
        //         this.dataSource.filter(updWish => updWish.Id == item.idVariant)[0].wishlist = item.status

        //         if ( item.status ){
        //             this.wishlist.action = "remove"
        //         } else {
        //             this.wishlist.action = "add"
        //         }
        //     })
        // }
    })
</script>

<style src="@/assets/scss/_shelf.scss" lang="scss" scoped />
<style src="@/assets/scss/_carousel.scss" lang="scss" />

// <style lang="scss" scoped>
//     @import '@/assets/scss/_colors.scss';
//     @import '@/assets/scss/_fonts.scss';
//     @import '@/assets/scss/_dneElements.scss';
//     @import '@/assets/scss/_inputs.scss';

//     .item{
//         position: relative;
//         margin: 0 5px 30px 5px;
//         width: calc(100% - 10px);
//         text-align: left;

//         @media (min-width: 992px){
//             &:hover{
//                 .photo{
//                     img{
//                         transform: translate(-50%, -50%) scale(1.02);
//                     }
//                 }
//             }
//         }

//         .wishlist{
//             position: absolute;
//             z-index: 20;
//             top: 5px;
//             right: 5px;
//             width: 32px;
//             height: 32px;
//             background: url('~@/assets/images/icons/wishlist.svg') no-repeat center;
//             background-size: 22px auto !important;
//             cursor: pointer;

//             &.selected{
//                 background: url('~@/assets/images/icons/wishlist-selected.svg') no-repeat center;

//                 @media (min-width: 992px){
//                     &:hover{
//                         background-size: 24px auto !important;
//                     }
//                 }
//             }

//             @media (min-width: 992px){
//                 &:not(.selected):hover{
//                     background: url('~@/assets/images/icons/wishlist-active.svg') no-repeat center;
//                     background-size: 24px auto !important;
//                 }
//             }
//         }

//         .highlights{
//             position: absolute;
//             z-index: 15;
//             left: 5px;
//             top: 10px;
//             display: flex;
//             flex-wrap: wrap;
//             width: 0;

//             div{
//                 margin-bottom: 3px;
//                 font-size: 11px;
//                 line-height: 16px;
//                 padding: 3px 8px;
//                 border-radius: 4px;
//                 white-space: nowrap;

//                 &.discount{
//                     background: $color-cancel;
//                     color: $color-text-tertiary;
//                 }
                
//                 &.free-shipping{
//                     background: #ffee00;
//                     color: $color-text-secondary;
//                 }
                
//                 &.unavailable{
//                     background: #999;
//                     color: $color-text-tertiary;
//                 }
                
//                 &.to-order{
//                     background: $color-secondary;
//                     color: $color-text-tertiary;
//                 }
//             }
//         }
        
//         .photo{
//             border: 1px solid #ddd;
//             margin-bottom: 10px;
//             overflow: hidden;
//             width: 100%;
//             padding-top: 100%;
//             position: relative;

//             img{
//                 position: absolute;
//                 top: 50%;
//                 left: 50%;
//                 width: auto;
//                 height: auto;
//                 transform: translate(-50%, -50%);
//                 transition: .5s ease-in-out;
//             }
//         }

//         .details{
//             padding: 0 10px;

//             .store-name{
//                 display: block;
//                 margin-bottom: 5px;
//                 color: $color-text-secondary;
//                 font-size: 12px;
//                 line-height: 15px;
//             }

//             .name{
//                 font-family: $font-primary-semibold;
//                 margin-bottom: 5px;
                
//                 @media (max-width: 576px){
//                     font-size: 12px;
//                     line-height: 16px;
//                 }
                
//                 @media (min-width: 576px){
//                     font-size: 14px;
//                     line-height: 18px;
//                 }
                
//                 small{
//                     display: inline-block;
//                     color: $color-secondary;
//                 }
//             }
            
//             .store{
//                 display: flex;
//                 align-items: center;
                
//                 .brand{
//                     div{
//                         display: flex;
//                         align-items: center;
//                         width: 60px;
//                         height: 60px;
//                         margin-right: 15px;
                        
//                         img{
//                             max-height: 100%;
//                         }

//                     }
                    
//                     @media (max-width: 576px){
//                         display: none;
//                     }
//                 }
                
                
//                 .price{
//                     small{
//                         display: block;
//                         color: $color-text-secondary;
//                         font-size: 12px;
//                         line-height: 15px;
//                     }

//                     span{
//                         padding-right: 18px;
//                         background: url("~@/assets/images/icons/dne-symbol.svg") no-repeat right 5px;
//                         background-size: 15px auto;
//                         font-family: $font-secondary-bold;
//                         color: $color-primary;
//                         font-size: 18px;
//                         line-height: 20px;
//                         height: 20px;
    
//                         del{
//                             color: #999;
//                             margin-right: 7px;
//                         }
//                     }
//                 }
//             }

//         }
        
//         .ad{
//             position: absolute;
//             right: 0;
//             bottom: 0;
//             background: #f5f5f5;
//             font-size: 11px;
//             line-height: 12px;
//             color: #bbb;
//             padding: 5px 8px;
//         }

//         &.ad{
//             background-color: #f5f5f5;
//         }

//         &.unavailable{
//             .photo{
//                 img{
//                     filter: grayscale(80%);
//                     opacity: 0.7;
//                 }
//             }
//         }
//     }
// </style>