<template>
    <div class="new-variant">
        <div class="load" v-if="load"></div>

        <div class="variant" v-if="!load">
            <label>
                <span class="info">
                    {{ getMyLanguage("SetCategories","info-variant-types") }}
                    <i><span>{{ getMyLanguage("SetCategories","info-variant-types-text") }}</span></i>
                </span>
            </label>

            <p class="instructions">
                {{ getMyLanguage("SetCategories","instructions-01") }}<br>
                {{ getMyLanguage("SetCategories","instructions-02") }}
            </p>
            
            
            <!-- VARIANT 01 -->
            <div class="var var-01" :class="{validated: add.variant_1.variant_id && !noApplyVariant.var01, noapply: noApplyVariant.var01}">
                <h2>{{ getMyLanguage("SetCategories","variant-01") }}</h2>

                <div class="no-variant">
                    <div class="form-group-check">
                        <input
                            type="checkbox"
                            class="form-check"
                            id="applyVar01"
                            v-model="noApplyVariant.var01"
                            @change="handleApplyVariant('var01')" />

                        <label class="form-check-label" for="applyVar01">
                            {{ getMyLanguage("SetCategories","no-variants") }}
                        </label>
                    </div>
                </div>

                <div class="fields disabled" v-if="noApplyVariant.var01">
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-type") }}</h3>
                        <div class="form form-disabled"></div>
                    </div>
                    
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-name") }}</h3>
                        <div class="form form-disabled"></div>
                    </div>
                </div>

                <div class="fields" v-else>

                    <!-- TYPE 01 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-type") }}</h3>

                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'type01'}"
                            v-if="!addNew.type.type_01">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'type01'"
                                @click="dropListVariants(true, 'type01')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'type01'"
                                @click="dropListVariants(false, 'type01')"></div>

                            <label>
                                <input type="text" id="list_type01" class="form"
                                    @focus="dropListVariants(true, 'type01')"
                                    @keyup="filterListVariants('type01')"
                                    v-model="autocomplete.fields.variant_type_1">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'type01'">
                                <ul id="drop_list_type01">
                                    <li
                                        v-for="(itemList, index) in filteredVariantsType01"
                                        :key="index"
                                        @click="addListVariant(itemList, 'type01')">
                                        
                                        <a>{{itemList.type}}</a>
                                    </li>
                                </ul>

                                <div class="add"
                                    @click="addManuallyVariant('type01')">
                                    
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span>&nbsp;
                                    <strong>{{autocomplete.fields.variant_type_1}}</strong>
                                </div>
                            </div>
                        </div>


                        <!-- ADD TYPE 01 -->
                        <div class="field-add" v-if="addNew.type.type_01">
                            <input type="text" class="form"
                                id="add_type01_type"
                                placeholder="Novo tipo de variante"
                                v-model="addNew.post.variant_1.variant_type"
                                @keyup="addListManually('type01')">
                        </div>
                    </div>


                    <!-- VAR 01 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-name") }}</h3>

                        <div class="form form-disabled" v-if="(!add.variant_1.variant_type_id || autocomplete.type == 'type01') && !addNew.type.type_01"></div>
                        
                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'var01'}"
                            v-if="add.variant_1.variant_type_id && autocomplete.type != 'type01' && !addNew.type.type_01 && !addNew.type.var_01">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'var01'"
                                @click="dropListVariants(true, 'var01')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'var01'"
                                @click="dropListVariants(false, 'var01')"></div>

                            <label>
                                <input type="text" id="list_var01" class="form"
                                    @focus="dropListVariants(true, 'var01')"
                                    @keyup="filterListVariants('var01')"
                                    v-model="autocomplete.fields.variant_1">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'var01'">
                                <ul id="drop_list_var01">
                                    <li
                                        v-for="(itemList, index) in variantsFilter.var01"
                                        :key="index"
                                        @click="addListVariant(itemList, 'var01')">

                                        <a>{{itemList.name}}</a>
                                    </li>
                                </ul>
                                
                                <div class="add"
                                    @click="addManuallyVariant('var01')">
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span> <strong>{{autocomplete.fields.variant_1}}</strong>
                                </div>
                            </div>
                        </div>

                        <!-- ADD VAR 01 -->
                        <div class="field-add" v-if="addNew.type.type_01 || addNew.type.var_01">
                            <input type="text" class="form"
                                placeholder="Nova variante"
                                id="add_var01_var"
                                v-model="addNew.post.variant_1.variant"
                                @keyup="addListManually('var01')">

                            <div class="tooltip" v-if="addNew.tooltip">
                                <span>{{ getMyLanguage("SetCategories","enter-variant-name") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- VARIANT 02 -->
            <div class="var var-02" :class="{validated: add.variant_2.variant_id && !noApplyVariant.var01, noapply: noApplyVariant.var02}">
                <h2>{{ getMyLanguage("SetCategories","variant-02") }}</h2>

                <div class="no-variant" v-if="!noApplyVariant.var01">
                    <div class="form-group-check">
                        <input
                            type="checkbox"
                            class="form-check"
                            id="applyVar02"
                            v-model="noApplyVariant.var02"
                            @change="handleApplyVariant('var02')" />

                        <label class="form-check-label" for="applyVar02">
                            {{ getMyLanguage("SetCategories","no-apply") }}
                        </label>
                    </div>
                </div>

                <div class="fields disabled" v-if="noApplyVariant.var02">
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-type") }}</h3>
                        <div class="form form-disabled"></div>
                    </div>
                    
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-name") }}</h3>
                        <div class="form form-disabled"></div>
                    </div>
                </div>

                <div class="fields" v-else>

                    <!-- TYPE 02 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-type") }}</h3>

                        <div class="form form-disabled" v-if="autocomplete.type == 'type01' || autocomplete.type == 'var01' || !add.variant_1.variant_id"></div>
                        
                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'type02'}"
                            v-if="!addNew.type.type_02 && autocomplete.type != 'type01' && autocomplete.type != 'var01' && add.variant_1.variant_id">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'type02'"
                                @click="dropListVariants(true, 'type02')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'type02'"
                                @click="dropListVariants(false, 'type02')"></div>

                            <label>
                                <input type="text" id="list_type02" class="form"
                                    @focus="dropListVariants(true, 'type02')"
                                    @keyup="filterListVariants('type02')"
                                    v-model="autocomplete.fields.variant_type_2">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'type02'">
                                <ul id="drop_list_type02">
                                    <li
                                        v-for="(itemList, index) in filteredVariantsType02"
                                        :key="index"
                                        @click="addListVariant(itemList, 'type02')">
                                        
                                        <a>{{itemList.type}}</a>
                                    </li>
                                </ul>

                                <div class="add" @click="addManuallyVariant('type02')">
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span>&nbsp;
                                    <strong>{{autocomplete.fields.variant_type_2}}</strong>
                                </div>
                            </div>
                        </div>
                        

                        <!-- ADD TYPE 02 -->
                        <div class="field-add" v-if="addNew.type.type_02">
                            <input type="text" class="form"
                                id="add_type02_type"
                                placeholder="Novo tipo de variante"
                                v-model="addNew.post.variant_2.variant_type"
                                @keyup="addListManually('type02')">
                        </div>
                    </div>


                    <!-- VAR 02 -->
                    <div class="field">
                        <h3>{{ getMyLanguage("SetCategories","variant-name") }}</h3>

                        <div class="form form-disabled" v-if="(!add.variant_2.variant_type_id || autocomplete.type == 'type01' || autocomplete.type == 'type02') && !addNew.type.type_02"></div>
                        
                        <div class="autocomplete"
                            :class="{opened: autocomplete.type == 'var02'}"
                            v-if="add.variant_2.variant_type_id && autocomplete.type != 'type01' && autocomplete.type != 'type02' && !addNew.type.type_02 && !addNew.type.var_02">

                            <div class="openAutocomplete"
                                v-if="autocomplete.type != 'var02'"
                                @click="dropListVariants(true, 'var02')"></div>

                            <div class="closeAutocomplete"
                                v-if="autocomplete.type == 'var02'"
                                @click="dropListVariants(false, 'var02')"></div>

                            <label>
                                <input type="text" id="list_var02" class="form"
                                    @focus="dropListVariants(true, 'var02')"
                                    @keyup="filterListVariants('var02')"
                                    v-model="autocomplete.fields.variant_2">
                            </label>

                            <div class="cont-autocomplete" v-if="autocomplete.type == 'var02'">
                                <ul id="drop_list_var02">
                                    <li
                                        v-for="(itemList, index) in variantsFilter.var02"
                                        :key="index"
                                        @click="addListVariant(itemList, 'var02')">
                                        
                                        <a>{{itemList.name}}</a>
                                    </li>
                                </ul>

                                <div class="add"
                                    @click="addManuallyVariant('var02')">
                                    <span>{{ getMyLanguage("SetCategories","add") }}</span> <strong>{{autocomplete.fields.variant_2}}</strong>
                                </div>
                            </div>
                        </div>

                        <!-- ADD VAR 02 -->
                        <div class="field-add" v-if="addNew.type.type_02 || addNew.type.var_02">
                            <input type="text" class="form"
                                placeholder="Nova variante"
                                id="add_var02_var"
                                v-model="addNew.post.variant_2.variant"
                                @keyup="addListManually('var02')">

                            <div class="tooltip" v-if="addNew.tooltip">
                                <span>{{ getMyLanguage("SetCategories","enter-variant-name") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <pre>{{add}}</pre> -->
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiMerchant from '@/config/apiMerchant.js'

    export default {
        data(){
            return {
                dataVariants: [],
                filteredVariantsType01: [],
                filteredVariantsType02: [],

                noApplyVariant: {
                    var01: false,
                    var02: false
                },
        
                variantsFilter: {
                    var01: [],
                    var02: []
                },

                load: true,
                alert: false,

                autocomplete: {
                    type: "",
                    showAdd: false,

                    fields: {
                        variant_type_1: "",
                        variant_1: "",
                        variant_type_2: "",
                        variant_2: ""
                    }
                },

                add: {
                    variant_1: {
                        variant_type_id: "",
                        variant_type: "",
                        variant_id: "",
                        variant: ""
                    },
                    variant_2: {
                        variant_type_id: "",
                        variant_type: "",
                        variant_id: "",
                        variant: ""
                    }
                },
                
                addNew: {
                    type: {
                        type_01: false,
                        var_01: false,
                        type_02: false,
                        var_02: false
                    },

                    tooltip: false,

                    post: {
                        variant_1: {
                            variant_type_id: "",
                            variant_type:"",
                            variant_id: "",
                            variant: ""
                        },
                        variant_2: {
                            variant_type_id: "",
                            variant_type:"",
                            variant_id: "",
                            variant: ""
                        }
                    }
                },

                flowAddVariant: {
                    load: false,
                    showSave: false,
                }
            }
        },

        async mounted() {
            this.languageSelected()
            this.listVariants()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            handleApplyVariant(variantKey) {
                if ( this.noApplyVariant[variantKey] ) {
                    if ( variantKey == "var01" ){
                        this.add.variant_1.variant_type_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_1.variant_type = "Empty"
                        this.add.variant_1.variant_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_1.variant = "Empty"

                        this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_2.variant_type = "Empty"
                        this.add.variant_2.variant_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_2.variant = "Empty"

                        this.noApplyVariant.var02 = true
                    }
                    
                    if ( variantKey == "var02" ){
                        this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_2.variant_type = "Empty"
                        this.add.variant_2.variant_id = "00000000-0000-0000-0000-000000000000"
                        this.add.variant_2.variant = "Empty"
                    }
                } else {
                    if ( variantKey == "var01" ){
                        this.add.variant_1.variant_type_id = ""
                        this.add.variant_1.variant_type = ""
                        this.add.variant_1.variant_id = ""
                        this.add.variant_1.variant = ""

                        this.add.variant_2.variant_type_id = ""
                        this.add.variant_2.variant_type = ""
                        this.add.variant_2.variant_id = ""
                        this.add.variant_2.variant = ""

                        this.autocomplete.fields.variant_type_1 = ""
                        this.autocomplete.fields.variant_1 = ""
                        this.autocomplete.fields.variant_type_2 = ""
                        this.autocomplete.fields.variant_2 = ""

                        this.addNew.type.type_01 = false
                        this.addNew.type.var_01 = false
                        this.addNew.type.type_02 = false
                        this.addNew.type.var_02 = false

                        this.addNew.post.variant_1.variant_type = ""
                        this.addNew.post.variant_1.variant = ""
                        this.addNew.post.variant_2.variant_type = ""
                        this.addNew.post.variant_2.variant = ""

                        this.noApplyVariant.var02 = false
                    }
                    
                    if ( variantKey == "var02" ){
                        this.add.variant_2.variant_type_id = ""
                        this.add.variant_2.variant_type = ""
                        this.add.variant_2.variant_id = ""
                        this.add.variant_2.variant = ""

                        this.autocomplete.fields.variant_type_2 = ""
                        this.autocomplete.fields.variant_2 = ""

                        this.addNew.type.type_02 = false
                        this.addNew.type.var_02 = false

                        this.addNew.post.variant_2.variant_type = ""
                        this.addNew.post.variant_2.variant = ""
                    }
                }
            },
            
            listVariants(){
                apiMerchant.get(`api/v2/market/variant/types`)
                .then(response => {
                    this.dataVariants = response.data
                    this.filteredVariantsType01 = response.data;
                    this.filteredVariantsType02 = response.data;

                    setTimeout(() => {
                        this.load = false
                    }, 1700);
                })
                .catch(error => {
                    console.log(error)
                })
            },

            dropListVariants(status, type){
                if ( status ){
                    this.autocomplete.type = type
                } else{
                    this.autocomplete.type = null
                }

                if ( type == 'type01' ){
                    this.addNew.type.var_01 = false
                }
                
                if ( type == 'type02' ){
                    this.addNew.type.var_02 = false
                }
                
                setTimeout(() => {
                    if ( type == 'type01' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_type01').focus()
                            }, 10);
                        }
                    }
                    
                    if ( type == 'var01' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_var01').focus()
                            }, 10);
                        }
                    }
                    
                    if ( type == 'type02' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_type02').focus()
                            }, 10);
                        }
                    }
                    
                    if ( type == 'var02' ){
                        if ( status ){
                            setTimeout(() => {
                                document.getElementById('list_var02').focus()
                            }, 10);
                        }
                    }
                }, 250)
            },

            filterListVariants(type) {
                let input, filter, ul, li, a, i, txtValue;
                input = document.getElementById(`list_${type}`);
                filter = input.value.toUpperCase();
                ul = document.getElementById(`drop_list_${type}`);
                li = ul.getElementsByTagName("li");

                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },

            addListVariant(value, type){
                if ( type == 'type01' ){
                    this.add.variant_1.variant_type_id = value.id
                    this.add.variant_1.variant_type = value.type
                    this.variantsFilter.var01 = value.variants
                    this.autocomplete.fields.variant_type_1 = value.type

                    this.filteredVariantsType02 = this.dataVariants.filter(v => v.id !== value.id)
                    
                    this.add.variant_1.variant_id = ""
                    this.add.variant_1.variant = ""
                    this.autocomplete.fields.variant_1 = ""

                    this.dropListVariants(false, 'type01')
                    this.dropListVariants(true, 'var01')
                }

                if ( type == 'var01' ){
                    this.add.variant_1.variant_id = value.id
                    this.add.variant_1.variant = value.name
                    this.autocomplete.fields.variant_1 = value.name

                    this.add.variant_2.variant_id = ""
                    this.add.variant_2.variant = ""
                    this.autocomplete.fields.variant_2 = ""

                    this.dropListVariants(false, 'var01')
                    this.dropListVariants(true, 'type02')
                }

                if ( type == 'type02' ){
                    this.add.variant_2.variant_type_id = value.id
                    this.add.variant_2.variant_type = value.type
                    this.variantsFilter.var02 = value.variants

                    this.autocomplete.fields.variant_type_2 = value.type

                    this.filteredVariantsType01 = this.dataVariants.filter(v => v.id !== value.id);

                    this.add.variant_2.variant_id = ""
                    this.add.variant_2.variant = ""
                    this.autocomplete.fields.variant_2 = ""

                    this.dropListVariants(false, 'type02')
                    this.dropListVariants(true, 'var02')
                }

                if ( type == 'var02' ){
                    this.add.variant_2.variant_id = value.id
                    this.add.variant_2.variant = value.name
                    this.autocomplete.fields.variant_2 = value.name

                    this.dropListVariants(false, 'var02')
                }

                this.$emit('set-categories', this.add);
            },


            // ADD MANUALLY NEW VARIANT
            addManuallyVariant(type){
                const typeVar = type

                this.autocomplete.type = ""

                if ( typeVar == 'type01' ){
                    this.addNew.type.type_01 = true
                    this.addNew.post.variant_1.variant_type = this.autocomplete.fields.variant_type_1
                    this.addNew.post.variant_1.variant = ""

                    this.add.variant_1.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_1.variant_type = this.addNew.post.variant_1.variant_type
                }
                
                if ( typeVar == 'var01' ){
                    this.addNew.type.var_01 = true
                    this.addNew.post.variant_1.variant = this.autocomplete.fields.variant_1

                    this.add.variant_1.variant_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_1.variant = this.addNew.post.variant_1.variant
                }
                
                if ( typeVar == 'type02' ){
                    this.addNew.type.type_02 = true
                    this.addNew.post.variant_2.variant_type = this.autocomplete.fields.variant_type_2
                    this.addNew.post.variant_2.variant = ""

                    this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_2.variant_type = this.addNew.post.variant_2.variant_type
                }

                if ( typeVar == 'var02' ){
                    this.addNew.type.var_02 = true
                    this.addNew.post.variant_2.variant = this.autocomplete.fields.variant_2

                    this.add.variant_2.variant_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_2.variant = this.addNew.post.variant_2.variant
                }

                this.$emit('set-categories', this.add);
            },

            addListManually(type){
                this.autocomplete.type = ""
                
                if ( type == 'type01' ){
                    this.add.variant_1.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_1.variant_type = this.addNew.post.variant_1.variant_type
                }
                
                if ( type == 'var01' ){
                    this.add.variant_1.variant_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_1.variant = this.addNew.post.variant_1.variant
                }

                if ( type == 'type02' ){
                    this.add.variant_2.variant_type_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_2.variant_type = this.addNew.post.variant_2.variant_type
                }
                
                if ( type == 'var02' ){
                    this.add.variant_2.variant_id = "00000000-0000-0000-0000-000000000000"
                    this.add.variant_2.variant = this.addNew.post.variant_2.variant
                }

                this.$emit('set-categories', this.add);
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .new-variant{
        .title-secondary{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 2rem;

            span{
                display: block;
                margin-left: .5rem;
                color: $color-secondary
            }
        }

        .variant{
            padding: 1rem;
            background: #f5f5f5;
            border-radius: 12px;
            margin-bottom: 30px;
            transition: .3s;

            .instructions{
                margin-bottom: 2rem;
                font-size: 75%;
                color: $color-pending;
            }

            > label{
                color: $color-secondary;
                font-family: $font-primary-semibold;
                border-bottom: 2px solid #ddd;
                padding-bottom: 10px;
                margin-bottom: 20px;
            }            

            .var{
                position: relative;
                padding: 1rem;
                margin-bottom: 1rem;
                border-radius: 12px;
                background: #e9e9e9;
                transition: .3s;
                
                &.var-01{
                    z-index: 100;
                    margin-block: 1.5rem;
                    
                    &.noapply{
                        + .var-02{
                            display: none;
                        }
                    }
                }

                &.var-02{
                    z-index: 95;
                }

                &:focus-within{
                    background: #ddd;
                }

                &.validated{
                    background: rgba($color: $color-secondary, $alpha: 0.15);
                }
                
                &.noapply{
                    background: #ddd;

                    h2{
                        filter: blur(2px);
                    }
                }

                .no-variant{
                    .form-check:checked{
                        +label{
                            transform: scale(1.2);
                        }
                    }

                    .form-check-label{
                        background: #f2f2f2;
                        padding: 3px 10px 3px 33px;
                        border-radius: 6px;
                        line-height: 22px;
                        transition: .3s;

                        &:before{
                            top: 3px;
                            left: 3px;
                        }

                        @media(max-width: 576px){
                            transform-origin: top left;
                        }
                        
                        @media(min-width: 576px){
                            transform-origin: top right;
                        }
                    }
                    
                    @media (max-width: 576px){
                        margin-bottom: 1.5rem;

                        .form-check-label{
                            font-size: 11px;
                        }
                    }

                    @media (min-width: 576px){
                        position: absolute;
                        right: 10px;
                        top: 10px;

                        .form-check-label{
                            font-size: 13px;
                        }
                    }
                }
            }
            

            h2{
                font-size: 22px;
                line-height: 26px;

                @media (max-width: 576px){
                    margin-bottom: .5rem;
                }

                @media (min-width: 576px){
                    margin-bottom: 1rem;
                }
            }

            h3{
                font-size: 16px;
                color: $color-secondary;
                margin-bottom: .25rem;
            }

            .fields{
                display: flex;
                align-items: center;

                @media (max-width: 576px){
                    flex-direction: column;
                }

                &.disabled{
                    .field{
                        filter: blur(2px);
                        opacity: .7;
                    }
                }

                .field{
                    position: relative;
                    width: 100%;

                    &:first-child{
                        z-index: 10;
                    }

                    @media (max-width: 576px){
                        margin-bottom: 1rem;
                    }

                    @media (min-width: 576px){
                        &:first-child{
                            margin-right: 1.5rem;
                        }
                    }

                    .autocomplete{
                        .cont-autocomplete{
                            ul{
                                padding: 0 !important;
                                margin-bottom: 1.5rem;

                                li{
                                    background: #f5f5f5;
                                    border-radius: 8px;
                                }
                            }

                            .add{
                                background: $color-secondary;
                                color: $color-text-tertiary;
                                padding: .65rem .5rem;
                                border-radius: 12px;
                                text-align: center;

                                @media (min-width: 992px){
                                    transition: .3s;
                                    cursor: pointer;

                                    &:hover{
                                        padding: 0.65rem 0.1rem 0.65rem 0.4rem;
                                    }
                                }
                            }
                        }
                    }

                    .form{
                        margin: 0;
                        background-color: $color-text-tertiary;
                    }

                    .field-add{
                        position: relative;
                        display: flex;
                        align-items: center;

                        .form_add{
                            width: calc(100% - 44px);
                        }

                        .add{
                            background: url(~@/assets/images/icons/check.svg) no-repeat center $color-secondary;
                            background-size: 65% auto;
                            border-radius: 50%;
                            width: 34px;
                            height: 34px;
                            margin-left: 10px;
                            animation: showAddVar .5s alternate;

                            &.disabled{
                                background-color: #ccc;
                                filter: blur(1px);
                            }

                            @media (min-width: 992px){
                                border: 3px solid transparent;
                                cursor: pointer;
                            }
                        }

                        @keyframes showAddVar {
                            0% {transform: scale(0);}
                            20% {transform: scale(0);}
                            70% {transform: scale(1.2);}
                            100% {transform: scale(1);}
                        }

                        .tooltip{
                            position: absolute;
                            left: .5rem;
                            top: calc(100% + 4px);
                            background: $color-pending;
                            color: $color-text-tertiary;
                            font-size: 12px;
                            line-height: 16px;
                            padding: 5px 10px;
                            border-radius: 13px;
                            animation: showTooltip .3s alternate;

                            &:after{
                                content: "";
                                display: block;
                                width: 0;
                                height: 0;
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                                border-bottom: 8px solid $color-pending;
                                position: absolute;
                                left: 10px;
                                top: -10px;
                            }

                            @keyframes showTooltip {
                                0%{margin-top: 10px; opacity: 0;}
                                100%{margin-top: 0; opacity: 1;}
                            }
                        }
                    }
                }
            }
        }

        .btn-center{
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn-primary{
                margin-bottom: 2rem;
            }

            .btn-cancel{
                background: #f5f5f5;
                border: 1px solid #ddd;
                color: $color-cancel;
            }
        }
    }
</style>