<template>
    <section class="dne-exchange">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-5 col-lg-4 cont">
                    <figure>
                        <img src="~@/assets/images/home/dne-exchange.svg" alt="DNE Exchange" class="logo-dne-exchange" data-aos="fade-right" data-aos-offset="-100" />
                    </figure>

                    <ul>
                        <li data-aos="fade-right" data-aos-offset="-100">
                            {{getMyLanguage("DneExchange","exchange.topic-01")}}
                        </li>

                        <li data-aos="fade-right" data-aos-offset="-100">
                            {{getMyLanguage("DneExchange","exchange.topic-02")}}
                        </li>

                        <li data-aos="fade-right" data-aos-offset="-100">
                            {{getMyLanguage("DneExchange","exchange.topic-03")}}
                        </li>
                    </ul>

                    <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/exchange`" target="_blank" class="btn-primary" data-aos="fade-right" data-aos-offset="-100">
                        <span>{{getMyLanguage("DneExchange","exchange.bt-cta")}}</span>
                    </a>
                </div>

                <div class="col-12 col-sm-7 col-lg-8 img-dne-exchange">
                    <figure>
                        <picture>
                            <source srcset="~@/assets/images/home/dne-exchange-sm.jpg" media="(max-width: 576px)" data-aos="fade-up" data-aos-offset="-100">
                            <source srcset="~@/assets/images/home/dne-exchange-mb.jpg" media="(max-width: 991px)" data-aos="fade-up" data-aos-offset="-100">
                            <source srcset="~@/assets/images/home/dne-exchange-lg.jpg" media="(min-width: 992px)" data-aos="fade-up" data-aos-offset="-100">
                            <img src="~@/assets/images/home/dne-exchange-lg.jpg" alt="DNE Exchange">
                        </picture>
                    </figure>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        data (){
            return {
                flagSelected: "en",

                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL
            }
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_buttons.scss';

    .dne-exchange{
        .row{
            background: #f5f5f5;
            align-items: center;

            .cont{
                @media (max-width: 576px){
                    padding: 2rem 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                @media (min-width: 576px) and (max-width: 992px){
                    padding: 2rem 1rem 2rem 1rem;
                }
                
                @media (min-width: 992px){
                    padding: 2rem 1rem 2rem 3rem;
                }


                .logo-dne-exchange{
                    @media (max-width: 992px){
                        max-width: 200px;
                    }
                    
                    @media (min-width: 992px){
                        max-width: 350px;
                    }
                }

                ul{
                    margin: 30px 0;
                    
                    li{
                        position: relative;
                        padding-left: 26px;
                        margin-bottom: 6px;
                        font-family: $font-primary-semibold;

                        @media (min-width: 576px) and (max-width: 992px){
                            font-size: 80%;
                        }
            
                        &:last-child{
                            margin-bottom: 0;
                        }
            
                        &:before{
                            content: '';
                            display: block;
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            left: 0;
                            top: 1px;
                            background: url('~@/assets/images/icons/bull.svg') no-repeat center;
                            background-size: 100% auto;
                        }
                    }
                }

                .btn-primary{
                    span{
                        margin-left: 0;
                        transition: .3s;
                    }

                    @media (min-width: 992px){
                        &:hover{
                            padding: 0 40px;

                            span{
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        .img-dne-exchange{
            padding: 0;

            img{
                width: 100%;
            }
        }
    }
</style>