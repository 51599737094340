<template>
    <div class="merchant page-returns">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- HEADER STORE -->
        <div class="load" v-if="loadMerchantStore"></div>
        <HeaderStore :dataSource="dataMerchantStore" v-if="!loadMerchantStore" />


        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-returns">
            
            <div class="load" v-if="load"></div>

            <div class="row" v-if="errorsGet.dataReturns != false">
                <div class="col-12">
                    <div class="box box-error">
                        <h3 class="title-secondary">
                            {{getMyLanguage("box-error","title-error")}}
                        </h3>

                        <p v-if='errorsGet.dataReturns != "401"'>
                            {{getMyLanguage("box-error","msg-error")}}
                        </p>

                        <p v-if='errorsGet.dataReturns == "401"'>
                            {{getMyLanguage("box-error","401")}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="container" v-if="!load && !errorsGet.dataReturns">
                <h2 class="title-secondary subtitle">
                    <!-- <small>{{storeName}}</small> -->
                    {{getMyLanguage("Account","purchases-returns.title")}}
                </h2>


                <div class="search-bar" data-aos="fade-up" data-aos-offset="-100" v-if="dataReturns.length > 0">
                    <input type="search" inputmode="search" class="form form-search" id="searchSale"
                    @keyup="searchFilter"
                    :placeholder='getMyLanguage("Merchant", "products.placeholder.search")'>
                    
                    <div class="filter">
                        <div class="btn-filter" @click="advancedSearch.modal = true"></div>
                    </div>
                </div>

                <div class="cont-returns" data-aos="fade-up" data-aos-offset="-100" v-if="!load && !errorsGet.dataReturns && dataReturns.length > 0">
                    <div class="returns" >
                        <div class="return" :class="purchase.status" v-for="(purchase, indexPurchase) in dataReturns" :key="indexPurchase" :id="`purchase${indexPurchase}`">
                            <!-- <div class="header-print">
                                <div class="logo-company">
                                    <div class="logo-dne-market"></div>
                                    <div class="partner">
                                        <h4><strong>{{purchase.store.name}}</strong></h4>
                                        <p>+351 222 222 222</p>
                                    </div>
                                </div>

                                <div class="content">
                                    <div class="buyer">
                                        <div class="name">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-name")}}</small></p>
                                            <p><strong>{{purchase.customer.full_name}}</strong></p>
                                        </div>

                                        <div class="email">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-email")}}</small></p>
                                            <p><strong>{{purchase.customer.email}}</strong></p>
                                        </div>

                                        <div class="phone-number">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-phone-number")}}</small></p>
                                            <p><strong>{{purchase.customer.phone_number}}</strong></p>
                                        </div>
                                    </div>

                                    <div class="shipping">
                                        <div class="address">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-shipping-address")}}</small></p>
                                            <p>
                                                <strong>
                                                    {{purchase.customer.address.address1}},&nbsp;
                                                    {{purchase.customer.address.number}}
                                                    <span v-if="purchase.customer.address.address2">
                                                        ,&nbsp;{{purchase.customer.address.address2}}
                                                    </span>
                                                    ,&nbsp;{{purchase.customer.address.postal_code}}
                                                    &nbsp;|&nbsp;{{purchase.customer.address.city}}
                                                    &nbsp;|&nbsp;{{purchase.customer.address.state}}
                                                    &nbsp;|&nbsp;{{purchase.customer.address.country}}
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="order">
                                <div class="resume-return">
                                    <div class="header-return">
                                        <div class="buyer">
                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.buyers-name")}}</small>
                                                <strong>{{purchase.customer.full_name}}</strong>
                                            </div>

                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.buyers-email")}}</small>
                                                
                                                <a :href="`mailto:${purchase.customer.email}`">
                                                    <strong>{{purchase.customer.email}}</strong>
                                                </a>
                                            </div>
                                            
                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.buyers-phone-number")}}</small>
                                                
                                                <a :href="`tel:${purchase.customer.phone_number}`">
                                                    <strong>{{purchase.customer.phone_number}}</strong>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="date-status">
                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.reference")}}</small>
                                                <strong>{{purchase.reference}}</strong>
                                            </div>

                                            <div class="date">
                                                <small>{{getMyLanguage("Account","purchases.purchase.purchase-date")}}</small>
                                                <strong>{{dateTime(purchase.created_at)}}</strong>
                                            </div>
                                            <div class="status" :class="purchase.status_extra">
                                                <small>{{getMyLanguage("Account","purchases.purchase.status")}}</small>
                                                <strong>{{purchase.status}}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="products">
                                        <div class="items"
                                        v-for="(item, indexItem) in purchase.items"
                                        :key="indexItem">

                                            <div class="item" @click="openModalReturn(purchase, item)">
                                                <div class="photo">
                                                    <img :src="item.product.attachment">
                                                </div>

                                                <div class="details">
                                                    <p class="name">{{item.product.name}}</p>
                                                    <p class="reference"><small>Ref.:</small> {{item.reference}}</p>

                                                    <div class="price">
                                                        <span>
                                                            {{item.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                            <i></i>
                                                        </span>

                                                        <small v-if="(item.quantity = 1)">
                                                            ({{item.quantity}} {{getMyLanguage("Account","purchases.purchase.item")}})
                                                        </small>

                                                        <small v-if="(item.quantity > 1)">
                                                            ({{item.quantity}} {{getMyLanguage("Account","purchases.purchase.items")}})
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="box-empty" v-if="dataReturns.length == 0">
                    <h2 class="title-secondary">
                        {{ getMyLanguage("box-empty","title-empty.sales") }}
                    </h2>

                    <p>{{ getMyLanguage("box-empty","msg-empty.sales") }}</p>
                </div>
            </div>
        </section>


        <!-- MODAL ADVANCED SEARCH -->
        <div class="modal" v-if="advancedSearch.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="advancedSearch.modal = false"></div>
                <div class="modal-scroll">
                    <h2 class="title-secondary">Busca avançada</h2>
                    <div class="filters">
                        filtros....
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL RETURN -->
        <div class="modal modal-return" v-if="modalReturn.show">
            <div class="modal-cont">
                <div class="modal-close" @click="modalReturn.show = false"></div>

                <div class="modal-scroll">
                    <h2 class="title-secondary">
                        {{modalReturn.item.product.name}}
                        <small>{{modalReturn.purchase.customer.full_name}}</small>
                    </h2>

                    <div class="load" v-if="modalReturn.load"></div>

                    <div class="itemReturn" v-if="!modalReturn.load">

                        <div class="header-return" :class="modalReturn.item.status_extra">
                            <strong>
                                #{{modalReturn.purchase.reference}}
                                <span>{{ getMyLanguage("Account","purchases.status." + modalReturn.item.status_extra) }}</span>
                            </strong>

                            <strong>
                                {{dateFormat(modalReturn.purchase.created_at)}}
                                <small>{{timeFormat(modalReturn.purchase.created_at)}}</small>
                            </strong>
                        </div>

                        <div class="item item-return" :class="modalReturn.item.status_extra">
                            <div class="photo">
                                <img :src="modalReturn.item.product.attachment">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    {{modalReturn.item.product.name}}
                                </h3>

                                <div class="price">
                                    <span>
                                        <strong>{{modalReturn.item.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                        <i></i>
                                    </span>

                                    <!-- <small>{{(modalReturn.item.price * modalReturn.purchase.instrument.price).toLocaleString(modalReturn.purchase.instrument.asset_short, {style:"currency", currency:modalReturn.purchase.instrument.asset_short})}}</small> -->
                                </div>
                            </div>
                        </div>
                        
                        <div class="return">
                            <h3 class="subtitle">
                                <span>{{ getMyLanguage("Account","purchases.return.type-code." + modalReturn.item.return.type_code) }}</span>
                            </h3>

                            <div class="header-return" :class="modalReturn.item.return.status_extra">
                                <strong>
                                    #{{modalReturn.item.return.reason_reference}}
                                    <span>{{modalReturn.item.return.status_extra}}</span>
                                </strong>

                                <strong>
                                    {{dateFormat(modalReturn.item.return.created_at)}}
                                    <small>{{timeFormat(modalReturn.item.return.created_at)}}</small>
                                </strong>
                            </div>

                            <p class="reason">
                                <strong>{{ getMyLanguage("Return","return.reason-type." + modalReturn.item.return.reason_name) }}</strong><br>
                                <span v-if="modalReturn.item.return.description">{{modalReturn.item.return.description}}</span>
                            </p>

                            <p class="status-reason">
                                <span v-html="modalReturn.item.return.status_reason"></span>
                            </p>

                            <div class="reply" v-if="!modalReturn.error.listStatusReturn && modalReturn.listStatusReturn.length > 0">
                                <label>
                                    <strong>Novo status</strong>

                                    <select class="form" v-model="modalReturn.item.return.status_extra">
                                        <option
                                            v-for="(listStatus, indexReturn) in modalReturn.listStatusReturn"
                                            :key="indexReturn"
                                            :value="listStatus.Extra">
                                            
                                                {{ listStatus.Value }}
                                            </option>
                                    </select>
                                </label>

                                <label class="message">
                                    <span class="characters">{{modalReturn.updateStatus.return.reason.length}}/50</span>

                                    <textarea class="form" maxlength="50"
                                        v-model="modalReturn.updateStatus.return.reason"
                                        placeholder="Descreva sua resposta"></textarea>
                                </label>

                                <div class="btn-reply">
                                    <div class="btn-primary btn-disabled"
                                        v-if="!modalReturn.item.return.status_extra || modalReturn.item.return.status_extra == modalReturn.updateStatus.return.status">
                                        
                                        Atualizar
                                    </div>

                                    <div class="btn-primary"
                                        v-else
                                        @click="updateStatus(modalReturn.purchase.id, 'return', modalReturn.purchase.items[0].return.id, modalReturn.item.return.status_extra, modalReturn.updateStatus.return.reason)">
                                        
                                        Atualizar
                                    </div>
                                </div>

                                <!-- modalReturn.purchase.id: {{modalReturn.purchase.id}}<br>
                                modalReturn.item.Id: <pre>{{modalReturn.purchase.items[0].return.id}}</pre><br>
                                type: "return",<br>
                                modalReturn.item.return.status_extra: {{modalReturn.item.return.status_extra}} -->

                            </div>

                            <p class="error-reply" v-else>
                                <strong v-if="modalReturn.error.listStatusReturn">Erro ao carregar status, tente novamente em alguns instantes</strong>
                                <strong v-else>Não há mais opções de status</strong>
                            </p>
                        </div>

                        <div class="refund" v-if="modalReturn.item.refund">
                            <h3 class="subtitle">
                                <span>Reembolso</span>
                            </h3>

                            <div class="price">
                                <span>
                                    <strong>{{modalReturn.item.refund.value.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                    <i></i>
                                </span>
                            </div>

                            <div class="header-return" :class="modalReturn.item.refund.status_extra">
                                <strong>
                                    #{{modalReturn.item.refund.reference}}
                                    <span>{{modalReturn.item.refund.status_extra}}</span>
                                </strong>

                                <strong>
                                    {{dateFormat(modalReturn.item.refund.created_at)}}
                                    <small>{{timeFormat(modalReturn.item.refund.created_at)}}</small>
                                </strong>
                            </div>

                            <p class="status-reason" v-if="modalReturn.item.refund.status_reason">
                                <span v-html="modalReturn.item.refund.status_reason"></span>
                            </p>

                            <p>{{ getMyLanguage("Refund","refund.type-code." + modalReturn.item.refund.type_code) }}</p>

                            <div class="reply" v-if="!modalReturn.error.listStatusRefund && modalReturn.listStatusRefund.length > 0">
                                <label>
                                    <strong>Novo status</strong>

                                    <select class="form" v-model="modalReturn.item.refund.status_extra">
                                        <option
                                            v-for="(listStatus, indexRefund) in modalReturn.listStatusRefund"
                                            :key="indexRefund"
                                            :value="listStatus.Extra">
                                            
                                                {{ listStatus.Value }}
                                            </option>
                                    </select>
                                </label>

                                <label class="message">
                                    <span class="characters">{{modalReturn.updateStatus.refund.reason.length}}/50</span>

                                    <textarea class="form" maxlength="50"
                                        v-model="modalReturn.updateStatus.refund.reason"
                                        placeholder="Descreva sua resposta"></textarea>
                                </label>
                                
                                <div class="btn-reply">
                                    <div class="btn-primary btn-disabled"
                                        v-if="!modalReturn.item.refund.status_extra || modalReturn.item.refund.status_extra == modalReturn.updateStatus.refund.status">
                                        
                                        Atualizar
                                    </div>

                                    <div class="btn-primary"
                                        v-else
                                        @click="updateStatus(modalReturn.purchase.id, 'refund', modalReturn.purchase.items[0].refund.id, modalReturn.item.refund.status_extra, modalReturn.updateStatus.refund.reason)">
                                        
                                        Atualizar
                                    </div>
                                </div>
                            </div>
                            
                            <p class="error-reply" v-else>
                                <strong v-if="modalReturn.error.listStatusRefund">Erro ao carregar status, tente novamente em alguns instantes</strong>
                                <strong v-else>Não há mais opções de status</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Merchant/HeaderStore.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import bus from '@/eventBus';

    import moment from 'moment';

    const html2pdf = require('html2pdf.js')

    export default {
        components: {
            Header,
            HeaderStore,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "merchant",
                breadcrumb: "",
                load: true,
                dataReturns: {},

                dataMerchantStore: {},
                loadMerchantStore: true,

                storeName: "",

                errorsGet: {
                    dataReturns: false
                },

                advancedSearch: {
                    modal: false,
                    aaa: ""
                },


                modalReturn: {
                    show: false,
                    load: false,
                    purchase: "",
                    item: "",

                    updateStatus: {
                        return: {
                            status: "",
                            reason: ""
                        },

                        refund: {
                            status: "",
                            reason: ""
                        }
                    },

                    listStatusReturn: [],
                    listStatusRefund: [],
                    
                    error: {
                        listStatusReturn: false,
                        listStatusRefund: false
                    }
                }
            }
        },

        async mounted() {
            this.languageSelected()

            // HEADER STORE
            await apiMerchant('/api/v1/market/store')
            .then(response => {
                setTimeout(() => {
                    this.dataMerchantStore = response.data.store
                    this.loadMerchantStore = false
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errorsGet.dataMerchantStore = error.response.status
                    this.loadMerchantStore = false
                    localStorage.removeItem("storeReference")
                }, 500);
            })

            this.storeName = localStorage.getItem("storeName")

            this.loadReturns()

            this.breadcrumb = [
                {
                    slug: `${this.flagSelected}/merchant/dashboard`,
                    name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
                },
                {
                    name: this.getMyLanguage("Merchant","breadcrumb.sales")
                }
            ]
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            dateFormat(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('DD/MM/YY');
            },

            timeFormat(value) {
                const formattedTime = moment.utc(value).format('HH:mm');
                return formattedTime;
            },

            async loadReturns(){
                await apiMerchant.get('/api/v1/market/order/filter?order_status=cancelled&order_status=refunded&order_status=disputed&order_status=partially_refunded&order_item_status=cancelled&order_item_status=declined&order_item_status=refunded&order_item_status=disputed&has_returns=true&has_refunds=true')
                .then(response => {
                    this.dataReturns = response.data
                    
                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
                .catch(error => {
                    this.load = false
                    this.errorsGet.dataReturns = JSON.stringify(error.response.status)

                    if ( error.response.status == 401 ){
                        bus.emit('open-login',"401");
                    }
                })
            },

            pdfPurchaseSingle(value, indexPurchase) {
                html2pdf(document.getElementById(`purchase${indexPurchase}`), {
                    filename: `Receipt ${value} DNE Market`,
                    margin: 15
                });
            },

            openModalReturn(purchase, item){
                this.modalReturn.purchase = purchase
                this.modalReturn.item = item
                this.modalReturn.show = true

                this.modalReturn.updateStatus.return.status = this.modalReturn.item.return.status_extra
                this.modalReturn.updateStatus.refund.status = this.modalReturn.item.refund.status_extra
                
                this.modalReturn.updateStatus.return.reason = ""
                this.modalReturn.updateStatus.refund.reason = ""

                console.log(this.modalReturn)

                this.statusReturnRefund(this.modalReturn.item.return.status_extra, this.modalReturn.item.refund.status_extra)
            },

            statusReturnRefund(statusReturn, statusRefund){
                this.modalReturn.load = true

                // RETURN
                apiMerchant.get(`api/v2/market/order/item/return/status/${statusReturn}`)
                .then(response => {
                    this.modalReturn.listStatusReturn = response.data

                    setTimeout(() => {
                        this.modalReturn.load = false
                    }, 500);
                })
                .catch(error => {
                    console.log(error)
                    this.modalReturn.error.listStatusReturn = true

                    setTimeout(() => {
                        this.modalReturn.load = false
                    }, 500);
                })

                // REFUND
                apiMerchant.get(`api/v2/market/order/item/refund/status/${statusRefund}`)
                .then(response => {
                    this.modalReturn.listStatusRefund = response.data

                    setTimeout(() => {
                        this.modalReturn.load = false
                    }, 500);
                })
                .catch(error => {
                    console.log(error)
                    this.modalReturn.error.listStatusRefund = true

                    setTimeout(() => {
                        this.modalReturn.load = false
                    }, 500);
                })
            },

            updateStatus(order_id, type, order_item_id, order_item_status, reason){
                // console.log(order_id, type, order_item_id, order_item_status)

                this.modalReturn.load = true

                apiMerchant.post(`/api/v2/market/order/${order_id}/${type}/${order_item_id}/status/${order_item_status}`, {"status_reason": reason, "shipping_provider_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6", "tracking_number": "string"})
                .then(response => {
                    console.log(response.data)
                    
                    
                    this.modalReturn.item.return.status_reason = this.modalReturn.updateStatus.return.reason
                    this.modalReturn.item.refund.status_reason = this.modalReturn.updateStatus.refund.reason

                    this.modalReturn.updateStatus.return.reason = ""
                    this.modalReturn.updateStatus.refund.reason = ""

                    this.modalReturn.updateStatus.return.status = this.modalReturn.item.return.status_extra
                    this.modalReturn.updateStatus.refund.status = this.modalReturn.item.refund.status_extra
                    
                    this.loadReturns()

                    this.statusReturnRefund(this.modalReturn.item.return.status_extra, this.modalReturn.item.refund.status_extra)

                    setTimeout(() => {
                        this.$toast.success("Status atualizado!")
                        this.modalReturn.load = false
                    }, 500);
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("Houve um erro, tente novamente mais tarde")

                    setTimeout(() => {
                        this.modalReturn.load = false
                    }, 500);
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-returns.scss" lang="scss" scoped />

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';

    .header-print{
        // margin-bottom: 20px;

        .logo-company{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .logo-dne-market{
                width: 30%;
                height: 70px;
                background: url('~@/assets/images/header/logo-dne-market.svg') no-repeat left center;
                background-size: auto 100%;
            }

            .partner{
                h4{
                    font-size: 20px;
                    line-height: 20px;
                    margin-bottom: 2px;
                }

                p{
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        
        .content{    
            border: 1px solid #ccc;
            padding: 5px 15px 22px 15px;

            small{
                display: block;
                line-height: 12px;
                margin-bottom: 5px;
            }
            
            p{
                margin-bottom: 0;
                font-size: 12px;
                line-height: 18px;
            }

            .buyer{
                display: flex;
                width: 100%;
                margin-bottom: 20px;

                .email, .phone-number{
                    margin-left: 20px;
                }
            }
        }
    }

    .header-sale{
        display: none;
    }


    .products{
        margin-top: 40px;
        border: 1px solid #ccc;
        padding: 0 15px 22px 15px;

        .items{
            &:not(&:last-child){
                .item{
                    padding-bottom: 20px;
                    border-bottom: 1px solid #ccc;
                }
            }

            .item{
                position: relative;
                z-index: 5;
                display: flex;

    
                .photo{
                    display: none;
                }
    
                .details{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
    
                    .name{
                        color: $color-text-primary;
                        font-family: $font-primary-semibold;
                        font-size: 13px;
                        margin-bottom: 3px;
                    }
    
                    .price{
                        display: flex;
                        width: 100%;
    
                        span{
                            display: block;
                            font-family: $font-primary-semibold;
                            color: $color-text-primary;
                            align-items: center;
                            height: 20px;
                            border-right: none;
                            font-size: 13px;
                            line-height: 20px;
                            margin-right: 15px;
                            padding-right: 21px;
                            background: url('~@/assets/images/icons/dne-symbol-pdf.png') no-repeat right right;
                            background-size: auto 12px;
                        }
    
                        small{
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }

    .box-summary{
        display: flex;
        justify-content: space-between;
        
        .resume-purchase{
            display: block;
            width: calc(50% - 10px);
            padding: 0 16px 6px;
            background: $color-text-tertiary;
            border: 1px solid #ccc;
            margin-top: 40px;

            div{
                margin-bottom: 15px;;

                p{
                    font-size: 16px;
                    margin: 0;

                    strong{
                        color: $color-primary;
                    }
                }
            }
        }
        
        table{
            width: calc(50% - 10px);
            padding: 0 16px 6px;
            background: $color-text-tertiary;
            border: 1px solid #ccc;
            margin-top: 40px;
            
            tr{
                &.cashback{
                    td{
                        border-bottom: none;
                    }
                }
    
                td{
                    border-bottom: 1px solid #ddd;
                    padding: 0;
    
                    &:nth-of-type(2){
                        text-align: right;
                    }
    
                    strong{
                        position: relative;
                        top: -5px;
                        display: block;
                        font-family: $font-primary-semibold;
                        font-size: 12px;
                        line-height: 12px;
                        height: 22px;
                        color: $color-text-primary;
                    }
                    
                    span{
                        position: relative;
                        top: -5px;
                        display: inline-flex;
                        font-family: $font-primary-semibold;
                        color: $color-text-primary;
                        font-size: 12px;
                        line-height: 12px;
                        height: 22px;
                        padding-right: 21px;
                        background: url('~@/assets/images/icons/dne-symbol-pdf.png') no-repeat right 10px;
                        background-size: auto 12px;
                    }
                }
            }
        }

        .bts{
            display: none;
        }
    }
</style>

<style>
    .download-pdf .btn-primary small{display: block; margin-left: 10px; font-size: 12px;}
</style>