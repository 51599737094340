<template>
    <div class="dne-market products">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <article>
            <!-- HEADER STORE -->
            <HeaderStore
            :dataSource="createTemplate.headerStore.store"
            v-if="createTemplate.headerStore.store" />
            
            <div class="load load-product" v-if="!dataProduct.name && !errorsGet.dataProduct"></div>

            <!-- BREADCRUMB -->
            <Breadcrumb :dataSource="breadcrumbs" />

            <!-- CONTENT PRODUCT -->
            <section class="content-product" v-if="dataProduct.name && !errorsGet.dataProduct">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-8 col-lg-6 col-xl-5 product">
                            
                            <!-- GALLERY SLIDER -->
                            <div class="gallery-slider" data-aos="fade-up" data-aos-offset="-100" v-if="dataProductVariant.attachments">
                                <!-- <div class="nav" v-if="navModal.qtd > 1">
                                    <div class="left" @click='zoomNav("left")'></div>
                                    <div class="right" @click='zoomNav("right")'></div>
                                </div> -->

                                <Carousel id="gallery"
                                :items-to-show="1"
                                :wrap-around="false"
                                v-model="currentSlide"
                                :class="{aaa: (navModal.qtd > 1 && navModal.qtd < 4)}">

                                    <Slide v-for="(slider, slide) in dataProductVariant.attachments" :key="slide">
                                        <div class="carousel__item">
                                            <figure class="photo" @click="zoom(slider.attachment,slide)">
                                                <img :src='slider.attachment' :alt="slider.caption">
                                            </figure>
                                        </div>
                                    </Slide>
                                </Carousel>

                                <Carousel id="thumbnails"
                                :items-to-show="4"
                                :wrap-around="true"
                                v-model="currentSlide" ref="carousel">

                                    <Slide v-for="(slider, slide) in dataProductVariant.attachments" :key="slide">
                                        <figure class="carousel__item" @click="slideTo(slide)" v-if="slider.length > 1">
                                            <img :src='slider.attachment' :alt="slider.caption">
                                        </figure>
                                    </Slide>
                                </Carousel>
                            </div>


                            <!-- PRODUCT -->
                            <div class="cont-product">
                                
                                <!-- BRAND -->
                                <!-- <div class="row brand">
                                    <router-link :to="{ name:'Brand', params: { id: '123', pageTitle: dataProductVariant.store.name } }" class="logo-brand" style="background: #000">
                                        <img :src="`${VUE_APP_IMAGE_URL}${dataProduct.brand.attachment}`" :alt="dataProduct.brand.name">
                                    </router-link>
                                    
                                    <div class="inf-brand">
                                        <div>
                                            <p class="lbl">{{getMyLanguage("Product","store.lbl")}}</p>
                                            <p><router-link :to="{ name:'StoreTemp', params: { id: '123', pageTitle: dataProductVariant.store.name } }"><strong>{{dataProductVariant.store.name}}</strong></router-link></p>
                                            <p><small>1258&nbsp;{{getMyLanguage("Product","store.products")}}</small></p>
                                        </div>
                                    </div>
                                </div> -->


                                <h1>{{dataProductVariant.name}}</h1>
                                
                                <div>
                                    <a :href="`/${dataProductVariant.store.slug}`" class="category">
                                        {{dataProductVariant.store.name}}
                                    </a>

                                    <a :href="`/${dataProductVariant.breadcrumbs[2].value}`" class="category">
                                        {{dataProductVariant.breadcrumbs[2].key}}
                                    </a>

                                    <a :href="`/${dataProduct.brand.slug}`" class="category">
                                        {{dataProduct.brand.name}}
                                    </a>
                                </div>
                                
                                <div class="short-description" v-if="dataProductVariant.short_description">
                                    <div v-html="dataProductVariant.short_description"></div>
                                </div>

                                <div class="add-cart">
                                    <div class="price-variables" v-if="dataProduct.variants_1.length > 1 || dataProduct.variants_2.length > 1">
                                        
                                        <!-- VARIABLES -->
                                        <div class="variables">

                                            <!-- Variantes 1 -->
                                            <div class="variable" v-if="dataProduct.variants_1.length > 1">
                                                <div class="options">
                                                    <div
                                                        v-for="(listVars1, index) in dataProduct.variants_1"
                                                        :key="index"
                                                        class="option-var"
                                                        :class="{
                                                            active: listVars1.reference === dataProductVariant.variant1.reference,
                                                            unavailable: !isCombinationAvailable(listVars1.reference, dataProductVariant.variant2.reference)
                                                        }"
                                                        @click="handleOptionClick(listVars1.reference, dataProductVariant.variant2.reference, 'variant1')">

                                                        <img :src="`/images/items/${listVars1.thumbs}`" v-if="listVars1.thumbs">
                                                        <span v-if="listVars1.name">{{ listVars1.name }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Variantes 2 -->
                                            <div class="variable" v-if="dataProduct.variants_2.length > 1">
                                                <div class="options">
                                                    <div
                                                        v-for="(listVars2, index) in dataProduct.variants_2"
                                                        :key="index"
                                                        class="option-var"
                                                        :class="{
                                                            active: listVars2.reference === dataProductVariant.variant2.reference,
                                                            unavailable: !isCombinationAvailable(dataProductVariant.variant1.reference, listVars2.reference)
                                                        }"
                                                        @click="handleOptionClick(dataProductVariant.variant1.reference, listVars2.reference, 'variant2')">

                                                        <img :src="`/images/items/${listVars2.thumbs}`" v-if="listVars2.thumbs">
                                                        <span v-if="listVars2.name">{{ listVars2.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="add-to-cart">

                                        <!-- PRICE -->
                                        <!-- <div class="values" v-if='dataProductVariant.availability === "available" || dataProductVariant.availability === "order"'> -->
                                        <div class="values">
                                            <div class="price" v-if='dataProduct.type_code != "dne_token"'>
                                                <!-- <span>{{price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}<i></i></span> -->
                                                <!-- <small>{{price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small> -->

                                                <span>
                                                    {{(dataProductVariant.amount).toLocaleString(isoAlpha3, {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                    <i></i>
                                                </span>

                                                <small>
                                                    {{(dataProductVariant.price).toLocaleString(isoAlpha3, {style:"currency", currency:dataProduct.instrument.quote_asset.short})}}
                                                </small> 
                                            </div>

                                            <div class="price" v-else>
                                                <span>
                                                    {{(dataProductVariant.price).toLocaleString(isoAlpha3, {style:"currency", currency:dataProduct.instrument.quote_asset.short})}}
                                                </span>
                                            </div>


                                            <!-- AVAILABILITY -->
                                            <div class="availability" v-if="dataProduct.type_code != 'dne_token'">
                                                <div class="available" v-if='dataProductVariant.availability === "available"'>
                                                    <p>{{getMyLanguage("Product","availability.available.lbl")}}</p>
                                                    <!-- <p>{{getMyLanguage("Product","availability.available.txt")}}&nbsp;<strong>{{dataProduct.dateShipping}}</strong></p> -->
                                                </div>

                                                <div class="order" v-if='dataProductVariant.availability === "order"'>
                                                    <p><strong>{{getMyLanguage("Product","availability.order.lbl")}}</strong></p>
                                                    <p>{{getMyLanguage("Product","availability.order.txt")}}&nbsp;<strong>25/03/2023</strong></p>
                                                </div>
                                                
                                                <div class="unavailable" v-if='dataProductVariant.availability === "unavailable"'>
                                                    <p>{{getMyLanguage("Product","availability.unavailable.lbl")}}</p>
                                                </div>

                                                <div class="alert-stock" :class="{active: alertStock.includes(dataProduct.sku.skuId)}" v-if='dataProductVariant.availability === "out-of-stock"'>
                                                    <p>{{getMyLanguage("Product","availability.out-of-stock.lbl")}}</p>
                                                    
                                                    <div class="btn-alert" v-if="!alertStock.includes(dataProduct.sku.skuId)" @click='itemAlertStock(dataProduct.sku.skuId,"add")'>
                                                        {{getMyLanguage("Product","availability.out-of-stock.bt-alert")}}
                                                    </div>

                                                    <div class="active-alert" v-if="alertStock.includes(dataProduct.sku.skuId)">
                                                        <strong>{{getMyLanguage("Product","availability.out-of-stock.active-alert")}}</strong>
                                                        
                                                        <span @click='itemAlertStock(dataProduct.sku.skuId,"remove")'>
                                                            {{getMyLanguage("Product","availability.out-of-stock.bt-alert-remove")}}
                                                        </span>
                                                    </div>
                                                </div>

                                                <!-- <div class="shipping" v-if="dataProductVariant.priceShipping">
                                                    <span>{{dataProductVariant.priceShipping.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span> /&nbsp;{{getMyLanguage("Product","add-cart.values.shipping")}}
                                                </div> -->
                                                <div class="shipping">
                                                    <span>{{getMyLanguage("Product","add-cart.values.shipping")}}</span>
                                                    
                                                    <div>
                                                        <strong>24,40</strong>
                                                        <small>12,20 €</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- ADD CART -->
                                        <div class="addCart" v-if='(dataProductVariant.availability === "available" || dataProductVariant.availability === "order") && dataProduct.type_code != "dne_token"'>
                                            <!-- <div class="quantity">
                                                <div class="qtd">
                                                    <div class="less" @click='qtdPurchase("less")'></div>
                                                    <div class="items">{{addCart.qtd}}</div>
                                                    <div class="more" @click='qtdPurchase("more")'></div>
                                                    <div class="tooltip" v-if="tooltipStock">{{getMyLanguage("Product","add-cart.tooltip.max")}}&nbsp;{{dataProductVariant.quantity}}</div>
                                                </div>
                                            </div> -->

                                            <div class="btn-primary btn-add-cart" @click="addToCart(dataProductVariant.Id)">
                                                <span>{{getMyLanguage("Product","add-cart.bt-add-cart")}}</span>
                                            </div>
                                        </div>
                                        
                                        <div class="addCart" v-if="dataProduct.type_code == 'dne_token'">
                                            <div class="btn-primary btn-add-cart" @click="purchaseDNEToken()">
                                                <span>{{getMyLanguage("Product","add-cart.bt-purchase-token")}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <!-- SHARE / WISHLIST -->
                                <div class="share-wishlist">
                                    <Share
                                    :dataSourceProduct="productShare"
                                    :dataSourceOrigin="shareOrigin" />

                                    <div class="wishlist">
                                        <p class="lbl">
                                            {{getMyLanguage("Product","wishlist.lbl")}}
                                        </p>

                                        <div class="icon"
                                        :class="{selected: dataProductVariant.wishlist}"
                                        @click="addWishlist(dataProductVariant.Id,dataProductVariant.wishlist)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-12 col-sm-4 col-lg-6 col-xl-7" data-aos="fade-up" data-aos-offset="-100">

                            <!-- GALLERY ZOOM -->
                            <div class="gallery-zoom"
                            :data-gallery="dataProductVariant.attachments.length"
                            v-if="dataProductVariant.attachments">

                                <figure class="photo"
                                v-for="(galleryZoom, index) in dataProductVariant.attachments"
                                :key="index"
                                @click="zoom(galleryZoom.attachment, index, dataProductVariant.attachments.length)">

                                    <img :src='galleryZoom.attachment' :alt="dataProductVariant.name" v-if="!loadSKU">
                                </figure>
                            </div>
                        </div>
                    </div>


                    <!-- DETAILS -->
                    <!-- <div class="row details" data-aos="fade-up" data-aos-offset="-100">
                        <div class="col-12">
                            <h3 class="title-details"><strong>{{getMyLanguage("Product","details.title")}}</strong></h3>
                            <div class="topics">
                                <p>Número do produto: DO2408-739</p>
                                <p>Sexo: Unisexo</p>
                                <p>Tecnologia: ZoomX</p>
                                <p>Tipo de corrida: Corrida Rápida</p>
                                <p>Desporto: Corrida</p>
                                <p>Drop (mm): 8</p>
                                <p>Conforto e amortecimento: Alto</p>
                                <p>Coleção: Nike Ekiden Pack</p>
                                <p>Fabricante: Nike</p>
                                <p>Tipo de calçado: Neutro, Supinação</p>
                                <p>Superfície: Estrada</p>
                                <p>Modelo: Nike ZoomX Vaporfly Next%</p>
                                <p>Função: Respirável, Carbono</p>
                                <p>Largura da sapatilha: Padrão</p>
                                <p>Cor: Amarelo</p>
                                <p>Garantia: 2 anos</p>
                            </div>
                        </div>
                    </div> -->


                    <!-- DESCRIPTION -->
                    <div class="row description" data-aos="fade-up" data-aos-offset="-100">
                        <div v-html="dataProductVariant.description"></div>
                    </div>
                </div>
            </section>
        </article>


        <!-- RELATED PRODUCTS -->
        <section class="featured-products related-products" v-if="relatedProducts.length && !errorsGet.dataProduct">
            <h2 class="title-secondary" data-aos="fade-up" data-aos-offset="-100">
                {{getMyLanguage("FeaturedProducts","related-products.title")}}
            </h2>

            <div class="container-fluid">
                <ShelfTemplateProductSlider :dataSource="relatedProducts" />
            </div>
        </section>


        <!-- CONTENT SERVICE -->
        <section class="content-service" v-if="dataProduct.sku && !errorsGet.dataProduct">
            
        </section>


        <!-- ERROR PRODUCT -->
        <section class="error-product" v-if="errorsGet.dataProduct">
            <img src="~@/assets/images/icons/not-found.svg" alt="Not found">
        </section>

        <LegalPolicies />
    </div>


    <!-- MODAL ZOOM -->
    <div class="modal modal-zoom" v-if="modal.zoom === true">
        <div class="modal-cont">
            <div class="modal-close" @click='modal.zoom = false,modal.image = ""'></div>
            
            <div class="nav" v-if="navModal.qtd > 1">
                <div class="left" @click='zoomNav("left")'></div>
                <div class="right" @click='zoomNav("right")'></div>
            </div>
            
            <inner-image-zoom :src='modal.image' v-if='modal.image != ""' />
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Filter/HeaderStore.vue";
    import Breadcrumb from "@/components/Essentials/Breadcrumb.vue";
    import ShelfTemplateProductSlider from "@/components/Shelves/ShelfTemplateProductSlider.vue";
    import Share from "@/components/Essentials/Share.vue";
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import 'vue3-carousel/dist/carousel.css'
    import { defineComponent } from 'vue'
    import { Carousel, Slide } from 'vue3-carousel'

    import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
    import InnerImageZoom from 'vue-inner-image-zoom'

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    import bus from '@/eventBus';

    export default defineComponent({
        components: {
            Header,
            HeaderStore,
            Breadcrumb,
            ShelfTemplateProductSlider,
            Share,
            LegalPolicies,
            
            Carousel,
            Slide,

            InnerImageZoom
        },

        data() {
            return {
                VUE_APP_IMAGE_URL: process.env.VUE_APP_IMAGE_URL,
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                
                flagSelected: "en",
                headerTemplate: "search",
                referrerUrl: "",
                isoAlpha3: "PRT",

                currentSlide: 0,

                productShow: {
                    shortDescription: "",
                    name: "",
                    category: "",
                    price: "",
                    variant1: {
                        gallery: "",
                    },
                    size: "",
                    dateShipping: "15/09/2022",
                    priceShipping: 6.25,
                },

                dataRoute: {
                    storeReference: 0
                },

                dataProduct: [],
                dataProductVariant: {},

                breadcrumbs: [],
                loadSKU: false,

                createTemplate: {
                    headerStore: {
                        store: {}
                    }
                },

                navModal: {
                    position: 0,
                    qtd: 0
                },

                errorsGet: {
                    dataProduct: false
                },
                
                addCart: {
                    qtd: 1,
                    idVariant: {}
                },

                stock: 8,
                tooltipStock: false,
                alertStock: [""],

                wishlist: {
                    active: "",
                    id: "",
                    action: ""
                },

                modal: {
                    zoom: false,
                    image: ""
                },

                relatedProducts: [],

                paramsProduct: {
                    store: "",
                    product: "",
                    filter: ""
                },

                productShare: {
                    url: "",
                    title: "",
                    price: "",
                    attachment: "",
                    description: "",
                },

                shareOrigin: "ProductDetail",

                newtk: "",
                linkInternacional: ""
            };
        },

        async mounted() {
            if ( localStorage.getItem("countryIsoAlpha3") ){
                this.isoAlpha3 = localStorage.getItem("countryIsoAlpha3")
            } else {
                this.isoAlpha3 = "PRT"
                localStorage.setItem("countryIsoAlpha3","PRT")
            }

            this.dataRoute.storeReference = this.$route.params.store_reference

            sessionStorage.setItem('referrerUrl', document.referrer || "/");
            this.referrerUrl = sessionStorage.getItem("referrerUrl")
            window.addEventListener("popstate", this.handlePopState);
            
            await apiMarket.get(`/api/v1/market/product/store/${this.$route.params.store_reference}/product/${this.$route.params.product}`)
            .then(response => {
                this.dataProduct = response.data

                // this.createTemplate.headerStore.store = this.dataProduct.brand
                // console.log(this.createTemplate.headerStore)
                
                if ( !this.dataProduct.product_variants.filter(ref => ref.reference == this.$route.params.variant)[0] ){
                    this.dataProductVariant = this.dataProduct.product_variants[0]
                } else {
                    this.dataProductVariant = this.dataProduct.product_variants.filter(ref => ref.reference == this.$route.params.variant)[0]
                }

                this.breadcrumbs = this.dataProductVariant.breadcrumbs

                this.wishlist.active = this.dataProductVariant.wishlist

                this.navModal.qtd = this.dataProductVariant.attachments.length
                this.price = this.dataProductVariant.price
                document.title = `${this.dataProductVariant.name} | DNE Market`

                this.setMetaTags()

                // temp
                this.dataProductVariant.availability = "available"
            })
            .catch(error => {
                this.errorsGet.dataProduct = error.response.status
                // console.log(this.errorsGet.dataProduct)
            })


            // HEADER STORE
            await apiMarket.get(`/api/v1/layout/template/store/${this.dataRoute.storeReference}/page/store?theme_slug=default_0`)
            .then(response => {
                this.createTemplate.headerStore = response.data
            })
            .catch(error => {
                console.log(error)
            })
            
            
            this.goTop()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                setTimeout(() => {                    
                    // SLUGS
                    this.dataProductVariant.slug = this.dataProductVariant.slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                    this.dataProductVariant.store.slug = this.dataProductVariant.store.slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                    this.dataProduct.brand.slug = this.dataProduct.brand.slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
    
                    // SHARE
                    this.productShare.url = `${this.VUE_APP_MARKET_URL}${this.dataProductVariant.slug}`
                }, 1000);
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            setMetaTags(){
                this.productShare.url = `${this.VUE_APP_MARKET_URL}${this.dataProductVariant.slug}`
                this.productShare.title = this.dataProductVariant.name
                this.productShare.price = this.dataProductVariant.price
                this.productShare.attachment = this.dataProductVariant.attachment
                this.productShare.description = this.dataProductVariant.description
                // console.log(this.productShare)

                this.removeExistingMetaTags();

                // Add new metatags
                this.addMetaTag('og:title', this.productShare.title);
                this.addMetaTag('og:description', this.productShare.description);
                this.addMetaTag('og:image', this.productShare.attachment);
                this.addMetaTag('og:price:price', this.productShare.price.toString());
                this.addMetaTag('og:price:currency', "DNE");
                this.addMetaTag('og:url', this.productShare.url);
            },

            removeExistingMetaTags() {
                const existingMetaTags = document.querySelectorAll('head meta[property^="og:"]');
                existingMetaTags.forEach(tag => tag.remove());
            },

            addMetaTag(property, content) {
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', property);
                metaTag.setAttribute('content', content);
                document.head.appendChild(metaTag);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},0);
                return false;
            },

            slideTo(val) {
                this.currentSlide = val
                // console.log(this.currentSlide)
            },

            zoom(value,position) {
                this.modal.zoom = true
                this.navModal.qtd = this.dataProductVariant.attachments.length
                this.navModal.position = position

                    // console.log("qtd: " + this.navModal.qtd)
                    // console.log("pos: " + this.navModal.position)
                setTimeout(() => {
                    this.modal.image = value
                }, 500);
            },

            zoomNav(direction){
                if ( direction == "left" && this.navModal.position > 0 ){
                    this.modal.image = ""
                    setTimeout(() => {
                        this.modal.image = this.dataProductVariant.attachments[this.navModal.position - 1].attachment
                        this.navModal.position = this.navModal.position - 1
                    }, 1);
                } else if ( direction == "left" && this.navModal.position == 0 ){
                    this.modal.image = ""
                    setTimeout(() => {
                        this.modal.image = this.dataProductVariant.attachments[this.navModal.qtd - 1].attachment
                        this.navModal.position = this.navModal.qtd - 1
                    }, 1);
                }
                
                if ( direction == "right" && (this.navModal.position + 1) < this.navModal.qtd ){
                    // console.log("qtd: " + this.navModal.qtd)
                    // console.log("pos: " + this.navModal.position)
                    this.modal.image = ""
                    setTimeout(() => {
                        this.modal.image = this.dataProductVariant.attachments[this.navModal.position + 1].attachment
                        this.navModal.position = this.navModal.position + 1
                    }, 1);
                } else if ( direction == "right" && (this.navModal.position + 1) == this.navModal.qtd ){
                    this.modal.image = ""
                    setTimeout(() => {
                        this.modal.image = this.dataProductVariant.attachments[0].attachment
                        this.navModal.position = 0
                    }, 1);
                }
            },

            handlePopState() {
                window.location.href = this.referrerUrl;
            },

            isCombinationAvailable(variant1Reference, variant2Reference) {
                const productReference = this.dataProduct.products_references.find(
                    (ref) =>
                        ref.variant_reference_1 === variant1Reference &&
                        ref.variant_reference_2 === variant2Reference
                );
                return productReference && productReference.product_variant_reference !== null;
            },

            handleOptionClick(variant1Reference, variant2Reference, variantType) {
                // Garante que a combinação está disponível antes de trocar.
                if (!this.isCombinationAvailable(variant1Reference, variant2Reference)) {
                    this.$toast.error(this.getMyLanguage("Product","availability.unavailable.lbl"));
                    return;
                }

                // Atualiza as variantes com base no tipo clicado.
                if (variantType === 'variant1') {
                    this.changeSKU(variant1Reference, variant2Reference, this.dataProductVariant.variant1.reference, this.dataProductVariant.variant2.reference);
                } else if (variantType === 'variant2') {
                    this.changeSKU(variant1Reference, variant2Reference, this.dataProductVariant.variant1.reference, this.dataProductVariant.variant2.reference);
                }
            },

            changeSKU(var1, var2, currentVar1, currentVar2) {
                // A lógica existente da função
                this.loadSKU = true;

                if (
                    this.dataProduct.product_variants.find(
                        (SKU) => SKU.variant1.reference === var1 && SKU.variant2.reference === var2
                    )
                ) {
                    this.dataProductVariant = this.dataProduct.product_variants.find(
                        (SKU) => SKU.variant1.reference === var1 && SKU.variant2.reference === var2
                    );
                    document.title = `${this.dataProductVariant.name} | DNE Market`;

                    this.breadcrumbs = this.dataProductVariant.breadcrumbs;

                    this.dataProductVariant.slug = this.dataProductVariant.slug.replace(
                        /^([a-z]{2})/,
                        this.$store.state.SelectLanguage.code
                    );

                    const url = window.location.href;
                    const currentUrl = window.location.pathname;
                    const newSlug = `/${this.dataProductVariant.slug}`;
                    const newUrl = url.replace(currentUrl, newSlug);
                    window.history.pushState({}, '', newUrl);

                    this.setMetaTags();

                    this.navModal.qtd = this.dataProductVariant.attachments.length;

                    if (this.addCart.qtd > this.dataProductVariant.quantity) {
                        this.addCart.qtd = this.dataProductVariant.quantity;
                    }

                    this.price = this.dataProductVariant.price * this.addCart.qtd;

                    setTimeout(() => {
                        this.loadSKU = false;
                    }, 5);

                    this.dataProductVariant.availability = 'available';
                } else {
                    this.dataProductVariant = this.dataProduct.product_variants.find(
                        (SKU) =>
                            SKU.variant1.reference === currentVar1 &&
                            SKU.variant2.reference === currentVar2
                    );
                    this.dataProductVariant.availability = 'unavailable';

                    this.$toast.error(
                        `O produto está indisponível para: ${this.dataProduct.variants_1.find(
                            (SKU01) => SKU01.reference === var1
                        ).name} / ${this.dataProduct.variants_2.find(
                            (SKU01) => SKU01.reference === var2
                        ).name}`
                    );
                    setTimeout(this.$toast.clear, 12000);

                    setTimeout(() => {
                        this.loadSKU = false;
                    }, 5);
                }
            },
            

            qtdPurchase(operation){
                if ( operation === "more" && this.addCart.qtd < this.dataProductVariant.quantity ){
                    this.addCart.qtd = this.addCart.qtd + 1
                    this.price = this.dataProductVariant.price * this.addCart.qtd
                }

                if ( operation === "less" && this.addCart.qtd > 1 ){
                    this.addCart.qtd = this.addCart.qtd - 1
                    this.price = this.dataProductVariant.price * this.addCart.qtd
                }

                if ( operation === "more" && this.addCart.qtd == this.dataProductVariant.quantity ){
                    this.tooltipStock = true
                    
                    setTimeout(() => {
                        this.tooltipStock = false
                    }, 2000);
                }
            },

            itemAlertStock(value,action){
                if ( action === "add" ){
                    this.alertStock.push(value)
                } else if ( action === "remove" ){
                    this.alertStock.splice(this.alertStock.indexOf(value), 1)
                } else{
                    console.log("Alert unavailable")
                }
            },

            addToCart(id){
                this.addCart.idVariant = id
                bus.emit('add-to-cart', this.addCart);
            },

            purchaseDNEToken(){
                this.newtk = localStorage.getItem('accessToken')
                this.linkInternacional = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space/purchase`
                window.open(this.linkInternacional, '_blank');
            },

            addWishlist(id,status){
                if ( status ){
                    this.wishlist.action = "remove"
                } else {
                    this.wishlist.action = "add"
                }

                this.wishlist.id = id

                bus.emit('add-to-wishlist', this.wishlist);
            },
            
            // addWishlist(id){
            //     if ( this.wishlist.active ){
            //         this.wishlist.active = false
            //         this.wishlist.action = "remove"
            //     } else {
            //         this.wishlist.active = true
            //         this.wishlist.action = "add"
            //     }

            //     this.wishlist.id = id

            //     bus.emit('add-to-wishlist', this.wishlist);
            // },


            
        },

        beforeUnmount() {
            window.removeEventListener("popstate", this.handlePopState);
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.languageSelected();
                    }, 1000);
                }
            }
        },

        created() {
            bus.on('updateShelfTemplate', (item) => {
                // console.log(item)
                this.dataProductVariant.wishlist = item.status

                if ( item.status ){
                    this.wishlist.action = "remove"
                } else {
                    this.wishlist.action = "add"
                }
            })
        },
    });
</script>

<style src="./custom-product.scss" lang="scss" scoped />

<style>
    .modal-zoom .modal-cont .iiz{max-height: calc(90vh - 10px);}
    .modal-zoom .modal-cont .iiz__close{top: auto; bottom: 10px;}
    .modal-zoom .modal-cont .iiz__img{max-height: calc(90vh - 10px);}

    .content-product .product .gallery-slider .aaa .carousel__slide{flex-shrink: 1 !important;}

    .content-product .description p:last-child{margin-bottom: 0;}
</style>