<template>
  <div class="dne-market filter">
    <div id="dne"></div>
    <Header :dataSource="headerTemplate" />

    <article>
        <!-- HEADER STORE -->
        <HeaderStore
        :dataSource="createTemplate.headerStore.store"
        v-if="createTemplate.headerStore.store" />

        <BannerFull
        :dataSource="components.PhBannerFull"
        v-if="components.PhBannerFull" />

        <BannerSlider
        :dataSource="components.PhBannerSlider"
        v-if="components.PhBannerSlider" />

        <Breadcrumb :dataSource="dataFilter.breadcrumbs" />


        <!-- CONTENT RESULTS -->
        <section class="content-results">
            <div class="container-fluid">
                <div class="row">
                    <Sidebar :dataSource="components.sidebar" />

                    <div class="results">
                        <BannerTop :topbanner="components.PhBannerTop" v-if="components.PhBannerTop" />
                        <BannerTopSlider :dataSource="components.PhBannerTopSlider" v-if="components.PhBannerTopSlider" />

                        <Region :dataSource="components.regions" />

                        <h1 class="title-result">
                            <a :href="categoryTitle.slug" v-if="categoryTitle"><strong>{{categoryTitle.name}}</strong></a>
                            <strong v-else>{{getMyLanguage("Result","products-found.title")}}</strong>
                        </h1>

                        <div class="load" v-if="load"></div>
                        
                        <div class="box box-error" v-if="!load && errorsGet.listProducts != false">
                            <h3 class="title-secondary">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-if='errorsGet.dataPurchases != "401"'>
                                {{getMyLanguage("box-error","msg-error")}}
                            </p>

                            <p v-if='errorsGet.dataPurchases == "401"'>
                                {{getMyLanguage("box-error","401")}}
                            </p>
                        </div>

                        <!-- PRODUCTS -->
                        <div class="products" v-if="!load && !errorsGet.listProducts && listProducts.length > 0">
                            <ShelfTemplateProduct :dataSource="listProducts" />
                        </div>

                        <Pagination
                        :dataSource="paginationProducts"
                        v-if="!load && !errorsGet.listProducts && listProducts.length > 0" />

                        <div class="not-found" v-if="!load && !errorsGet.listProducts && listProducts.length == 0">
                            <div class="cont">
                                <p>{{getMyLanguage("Result","box.no-result")}}</p>
                                
                                <p class="insufficient-value" v-if='insufficient_value == true'>
                                    {{getMyLanguage("box-error","search.insufficient_value")}}
                                </p>
                            </div>

                            <div class="products">
                                <h2 class="title-secondary">Sugestões para você</h2>
                                <ShelfTemplateProduct :dataSource="listNoResult" />
                            </div>
                        </div>


                        <!-- SERVICES -->
                        <!-- <h2 class="title-result"><strong>{{getMyLanguage("Result","services-found.title")}}</strong></h2>

                        <div class="services">
                            <ShelfTemplateService :dataSource="createListServices" />
                        </div>
                        <Pagination :dataSource="paginationServices" /> -->


                        <!-- CLASSIFIED -->
                        <!-- <h2 class="title-result"><strong>{{getMyLanguage("Result","classifieds-found.title")}}</strong></h2>

                        <div class="classified">
                            <ShelfTemplateClassified :dataSource="createListClassifieds" />
                        </div>
                        <Pagination :dataSource="paginationClassifieds" /> -->
                    </div>
                </div>
            </div>
        </section>
    </article>


    <FeaturedProductSingle
    :dataSource="components.PhFeaturedProduct"
    v-if="components.PhFeaturedProduct" />

    <BannerHalf
    :dataSource="components.PhBannerHalf"
    v-if="components.PhBannerHalf" />

    <LegalPolicies />

  </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Filter/HeaderStore.vue";
    import BannerFull from "@/components/Banners/BannerFull.vue";
    import BannerSlider from "@/components/Banners/BannerSlider.vue";
    import Breadcrumb from "@/components/Essentials/Breadcrumb.vue";
    import Sidebar from "@/components/Filter/Sidebar.vue";
    import BannerTop from "@/components/Banners/BannerTop.vue";
    import BannerTopSlider from "@/components/Banners/BannerTopSlider.vue";
    import Region from "@/components/Filter/Region.vue";
    import ShelfTemplateProduct from "@/components/Shelves/ShelfTemplateProduct.vue";
    // import ShelfTemplateService from "@/components/Shelves/ShelfTemplateService.vue";
    // import ShelfTemplateClassified from "@/components/Shelves/ShelfTemplateClassified.vue";
    import Pagination from "@/components/Essentials/Pagination.vue";
    import FeaturedProductSingle from "@/components/Shelves/FeaturedProductSingle.vue";
    import BannerHalf from "@/components/Banners/BannerHalf.vue";
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    export default {
        components: {
            Header,
            HeaderStore,
            BannerFull,
            BannerSlider,
            Breadcrumb,
            Sidebar,
            BannerTop,
            BannerTopSlider,
            Region,
            ShelfTemplateProduct,
            // ShelfTemplateService,
            // ShelfTemplateClassified,
            Pagination,
            FeaturedProductSingle,
            BannerHalf,
            LegalPolicies,
        },

        data() {
            return {
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,

                flagSelected: "en",
                load: true,

                headerTemplate: "search",

                dataRoute: {
                    brandReference: 0,
                    storeReference: 0,
                    categoryReference: 0,
                    search: 0
                },

                dataFilter: {},
                listProducts: [],
                paginationProducts: ["1","2","3","4","5","6","7","8","9"],


                createTemplate: {
                    headerStore: {
                        store: {}
                    },

                    // TEMP
                    store_details: {
                        address: "Alameda da Republica, 724, Gulpilhares",
                        region: "Porto/Portugal",
                        phone_number: "+351 912 253 838",
                        lat_long: "-6.806900, -35.077759",
                        business_hours: ["Segunda a Sexta 9h as 18h","Sabados 9h as 14h"]
                    },

                    // TEMP
                    // fullBanner: {
                    //     link: "https://www.globo.com/",
                    //     target: "_blank",
                    //     image: "sport-zone-full.jpg"
                    // },
                    // sliderBanners: [
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" }
                    // ],
                    // topbanner: {
                    //     link: "https://www.globo.com/",
                    //     target: "_blank",
                    //     image: "sport-zone-full.jpg"
                    // },
                    // topSliderBanners: [
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-full.jpg" }
                    // ],
                    // halfbanners: [
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-banner01.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-banner02.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-banner03.jpg" },
                    //     { link: "https://www.globo.com/", target: "_blank", image: "sport-zone-banner04.jpg" }
                    // ]
                },

                createListServices: [
                    { image: "servico-formatacao.jpg", name: "Formatação", shortDescription: "Computador, notebook, tablet android e ios e manutenção em geral.", wishlist: true, price: "80" },
                    { image: "servico-comtabilista.jpg", name: "Contabilista", shortDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", wishlist: false, price: "228" },
                    { image: "servico-formatacao.jpg", name: "Formatação", shortDescription: "Computador, notebook, tablet android e ios e manutenção em geral.", wishlist: true, price: "80" },
                    { image: "servico-comtabilista.jpg", name: "Contabilista", shortDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", wishlist: false, price: "228" }
                ],

                paginationServices: ["1","2"],


                createListClassifieds: [
                    { type: "full", url: "/pt/service", name: "Manutenção de computadores", short: "Computador, notebook, tablet e telemóvel.", image: "informatica-main.jpg" },
                    { type: "half", url: "/pt/service", name: "", short: "", image: "informatica-01.jpg" },
                    { type: "full", url: "/pt/service", name: "Manutenção de computadores", short: "Computador, notebook, tablet e telemóvel.", image: "informatica-main.jpg" },
                    { type: "half", url: "/pt/service", name: "", short: "", image: "informatica-02.jpg" },
                    { type: "half", url: "/pt/service", name: "", short: "", image: "informatica-03.jpg" },
                ],

                paginationClassifieds: ["1"],

                errorsGet: {
                    listProducts: false,
                    dataPurchases: false
                },

                insufficient_value: false,
                listNoResult: [],

                categoryTitle: "",


                components: {
                    // PhBannerFull: [],
                    // PhBannerSlider: [],
                    // PhBannerTop: [],
                    // PhBannerTopSlider: [],
                    // PhFeaturedProduct: [],
                    // PhBannerHalf: [],

                    // TEMP
                    breadcrumb: [
                        {
                            slug: "#",
                            name: "Category"
                        },
                        {
                            slug: null,
                            name: "Subcategory"
                        }
                    ],


                    // TEMP
                    regions: [
                        { slug: "/en/store/1000047381/dnemarket", name: "Portugal", IsoCode: "PT", quantity: 345 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Alemanha", IsoCode: "DE", quantity: 235 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Espanha", IsoCode: "ES", quantity: 213 },
                        { slug: "/en/store/1000047381/dnemarket", name: "França", IsoCode: "FR", quantity: 198 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Suíça", IsoCode: "CH", quantity: 175 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Itália", IsoCode: "IT", quantity: 149 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Luxemburgo", IsoCode: "LU", quantity: 126 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Holanda", IsoCode: "NL", quantity: 120 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Irlanda", IsoCode: "IE", quantity: 112 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Bélgica", IsoCode: "BE", quantity: 98 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Suécia", IsoCode: "SE", quantity: 78 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Noruega", IsoCode: "NO", quantity: 65 },
                        { slug: "/en/store/1000047381/dnemarket", name: "Polónia", IsoCode: "PL", quantity: 46 }
                    ],


                    sidebar: {
                        categories: [
                            // { name: "Categoria 01", slug: "aaa", subcategories: [
                            //         { name: "Subcateg 01", slug: "aaa" }, { name: "Subcateg 02", slug: "aaa" },
                            //         { name: "Subcateg 03", slug: "aaa" },
                            //     ]
                            // },
                            // { name: "Caregoria 02", slug: "bbb", subcategories: [
                            //         { name: "Subcateg 01", slug: "aaa" }, { name: "Subcateg 02", slug: "aaa" },
                            //         { name: "Subcateg 03", slug: "aaa" },
                            //     ]
                            // },
                            // { name: "Categoria 03", slug: "ccc" },
                            // { name: "Categoria 04", slug: "ddd" }
                        ],
                        
                        // TEMP
                        brands: [
                            { id: "111", name: "All brands", quantity: 1415 },
                            { id: "222", name: "Nike", quantity: 698 },
                            { id: "333", name: "Adidas", quantity: 126 },
                            { id: "444", name: "Eko", quantity: 460 },
                            { id: "555", name: "Hugo Boss", quantity: 56 },
                            { id: "666", name: "Saint Lourent", quantity: 39 },
                            { id: "777", name: "Victor Hugo", quantity: 26 }
                        ],
                        types: [
                            { id: "aaa", name: "all", quantity: 123 },
                            { id: "bbb", name: "products", quantity: 113 },
                            { id: "ccc", name: "services", quantity: 7 },
                            { id: "ddd", name: "classifieds", quantity: 3 }
                        ]
                    }
                },
            };
        },


        async mounted() {
            this.languageSelected()

            // SIDEBAR
            // const resumeCreateSidebar = await apiMarket.get(`/api/v1/market/product/categories?parent_category_reference=0`)
            // this.components.sidebar.categories = resumeCreateSidebar.data.categories

            try {
                const resumeCreateSidebar = await apiMarket.get(`/api/v1/market/product/categories?parent_category_reference=0`);
                this.components.sidebar.categories = resumeCreateSidebar.data.categories;

                const reference = this.$route.params.category_reference;
                this.categoryTitle = this.findCategoryByReference(this.components.sidebar.categories, reference);
                
                // if (this.categoryTitle) {
                //     console.log('Categoria encontrada:', this.categoryTitle);
                // } else {
                //     console.log('Categoria não encontrada.');
                // }
            }
            catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }


            // CATEGORY
            if ( this.$route.params.category_reference ){
                this.dataRoute.categoryReference = this.$route.params.category_reference

                await apiMarket.get(`/api/v1/market/product/filter?include_variants=false&include_attachments=true&category_reference=${this.dataRoute.categoryReference}`)
                .then(response => {
                    this.dataFilter = response.data

                    let listProduct = []
                    response.data.records.forEach(product => {
                        product.product_variants.forEach(pv => {
                            pv.brand = product.brand
                            listProduct.push(pv)
                        })
                    });
                    
                    this.listProducts = listProduct

                    this.updateMetaTags();

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                    
                    this.goTop()
                })
                .catch(error => {
                    this.errorsGet.listProducts = error.response.status
                    
                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            }


            // BRAND
            if ( this.$route.params.brand_reference ){
                this.dataRoute.brandReference = this.$route.params.brand_reference

                await apiMarket.get(`/api/v1/layout/template/store/dnemarket/page/brand?brand_reference=${this.dataRoute.brandReference}&theme_slug=default_0`)
                .then(response => {
                    this.createTemplate.headerStore = response.data
                })
                .catch(error => {
                    console.log(error)
                })

                // console.log(this.createTemplate.headerStore)
                
                await apiMarket.get(`/api/v1/market/product/filter?include_variants=false&?include_attachments=true&brand_reference=${this.dataRoute.brandReference}`)
                .then(response => {
                    this.dataFilter = response.data

                    console.log(this.dataFilter.breadcrumbs)

                    let listProduct = []
                    response.data.records.forEach(product => {
                        product.product_variants.forEach(pv => {
                            pv.brand = product.brand
                            listProduct.push(pv)
                        })
                    });
                    
                    this.listProducts = listProduct

                    this.updateMetaTags();

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                    
                    this.goTop()
                })
                .catch(error => {
                    this.errorsGet.listProducts = error.response.status
                    
                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            }


            // STORE
            if ( this.$route.params.store_reference ){
                this.dataRoute.storeReference = this.$route.params.store_reference
                console.log(this.dataRoute.storeReference)

                await apiMarket.get(`/api/v1/layout/template/store/${this.dataRoute.storeReference}/page/store?theme_slug=default_0`)
                .then(response => {
                    this.createTemplate.headerStore = response.data
                    document.title = `${this.createTemplate.headerStore.title} | DNE Market`
                })
                .catch(error => {
                    console.log(error)
                })

                // console.log(this.createTemplate.headerStore)

                await apiMarket.get(`/api/v1/market/product/filter?include_variants=false&?include_attachments=true&store_reference=${this.dataRoute.storeReference}`)
                .then(response => {
                    this.dataFilter = response.data

                    console.log(this.dataFilter.breadcrumbs)

                    let listProduct = []
                    response.data.records.forEach(product => {
                        product.product_variants.forEach(pv => {
                            pv.brand = product.brand
                            listProduct.push(pv)
                        })
                    });
                    
                    this.listProducts = listProduct

                    this.updateMetaTags();

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                    
                    this.goTop()
                })
                .catch(error => {
                    console.log(error)
                    this.errorsGet.listProducts = error.response.status
                    
                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            }
            
            // SEARCH
            if ( this.$route.params.query ){
                this.dataRoute.search = this.$route.params.query

                if ( this.dataRoute.search.length >= 3 ){
                    await apiMarket.get(`api/v1/market/product/filter?query=${this.dataRoute.search}&include_variants=false&include_attachments=false&compressed_response=false`)
                    .then(response => {
                        this.dataFilter = response.data

                        console.log(this.dataFilter.breadcrumbs)
    
                        let listProduct = []
                        response.data.records.forEach(product => {
                            product.product_variants.forEach(pv => {
                                pv.brand = product.brand
                                listProduct.push(pv)
                            })
                        });

                        this.listProducts = listProduct

                        this.updateMetaTags();

                        if ( this.listProducts.length == 0 ){
                            this.noResults()
                        }
    
                        setTimeout(() => {
                            this.load = false
                        }, 500);
                        
                        this.goTop()
                    })
                    .catch(error => {
                        this.errorsGet.listProducts = error.response.status
                        
                        setTimeout(() => {
                            this.load = false
                        }, 500);
                    })
                } else{
                    this.insufficient_value = true
                    this.noResults()
                        
                    setTimeout(() => {
                        this.load = false
                    }, 500);
                }
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            findCategoryByReference(categories, reference) {
                for (let category of categories) {
                    if (category.reference == reference) {
                        return category;
                    }
                    if (category.categories.length) {
                        const subCategory = this.findCategoryByReference(category.categories, reference);
                        if (subCategory) {
                            return subCategory;
                        }
                    }
                }
                return null;
            },

            async noResults(){
                const noResults = await apiMarket.get("/api/v1/market/product/filter?include_variants=false&include_attachments=false&store_reference=1000095778&store_reference=1000053841&store_reference=1000049406")

                let listProduct = []
                noResults.data.records.forEach(product => {
                    product.product_variants.forEach(pv => {
                        pv.brand = product.brand
                        listProduct.push(pv)
                    })
                });

                this.listNoResult = listProduct
            },

            updateMetaTags() {
                // document.title = `${this.createTemplate.headerStore.title} | DNE Market`

                document.querySelector('meta[property="og:url"]').setAttribute('content', `${this.VUE_APP_MARKET_URL}${this.createTemplate.headerStore.store.slug}`);
                document.querySelector('meta[property="og:site_name"]').setAttribute('content', `${this.createTemplate.headerStore.title} | DNE Market`);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', `${this.createTemplate.headerStore.title} | DNE Market`);

                document.querySelector('meta[property="og:url"]').setAttribute('content', `${this.VUE_APP_MARKET_URL}${this.createTemplate.headerStore.store.slug}`);
                document.querySelector('meta[property="og:site_name"]').setAttribute('content', `${this.createTemplate.headerStore.title} | DNE Market`);
            }
          

            // categories(action){
            //     var element = document.getElementById("brandCategories");

            //     if ( action === "open" ){
            //         this.openCategories = true

            //         element.classList.add("active");
            //     }
                
            //     if ( action === "close" ){
            //         this.openCategories = false

            //         element.classList.remove("active");
            //     }
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                this.languageSelected();
                }
            }
        },

        created() {
            // document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.home");
        }
    };
</script>

<style src="@/assets/scss/_filter.scss" lang="scss" scoped />