<template>
    <div class="merchant page-products">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- HEADER STORE -->
        <div class="load" v-if="loadMerchantStore"></div>
        <HeaderStore :dataSource="dataMerchantStore" v-if="!loadMerchantStore" />


        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-products">
            <div class="load" v-if="load"></div>

            <div class="row" v-if="errorsGet.dataProducts != false">
                <div class="col-12">
                    <div class="box box-error">
                        <h3 class="title-secondary">
                            {{getMyLanguage("box-error","title-error")}}
                        </h3>

                        <p v-if='errorsGet.dataProducts != "401"'>
                            {{getMyLanguage("box-error","msg-error")}}
                        </p>

                        <p v-if='errorsGet.dataProducts == "401"'>
                            {{getMyLanguage("box-error","401")}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="container" v-if="!load && !errorsGet.dataProducts">
                <h2 class="title-secondary subtitle">
                    <!-- <small>{{storeName}}</small> -->
                    {{getMyLanguage("Merchant","products.title")}}
                </h2>

                <div class="search-bar" v-if="dataFilter.length > 0">
                    <input type="search" inputmode="search" class="form form-search" id="searchProduct"
                        @keyup="searchFilter"
                        :placeholder='getMyLanguage("Merchant", "products.placeholder.search")'>
                    
                    <div class="filter">
                        <div class="btn-filter" @click="advancedSearch.modal = true"></div>
                    </div>
                </div>

                <div class="products" id="products-list" v-if="dataFilter.length > 0">
                    <div class="item add" @click="openAddProduct()">
                        <h3>ADD</h3>
                    </div>

                    <ShelfTemplateAdminProduct :dataSource="dataFilter" />
                </div>

                <div class="box-empty" v-if="dataFilter.length == 0">
                    <h2 class="title-secondary">{{ getMyLanguage("box-empty","title-empty.products") }}</h2>
                    <p>{{ getMyLanguage("box-empty","msg-empty.products") }}</p>

                    <div class="btn" @click="openAddProduct()">
                        <div class="add">
                            <h3>ADD</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- MODAL ADVANCED SEARCH -->
        <div class="modal" v-if="advancedSearch.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="advancedSearch.modal = false"></div>
                <div class="modal-scroll">
                    <h2 class="title-secondary">Busca avançada</h2>
                    <div class="filters">
                        filtros....
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL NEW PRODUCT -->
        <div class="modal modal-new-product" v-if="flowAddProduct.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="flowAddProduct.modal = false"></div>


                <!-- SEARCH -->
                <div class="box box-error" v-if="errorsGet.dataResults">
                    <h3 class="title-secondary">{{getMyLanguage("box-error","title-error")}}</h3>
                    <p>{{getMyLanguage("box-error","msg-error")}}</p>
                </div>

                <h2 class="title-secondary" v-if="!flowAddProduct.mincharacters && flowAddProduct.step == 'search'">
                    Novo produto
                </h2>
                
                <div class="box-search" :class="{active: flowAddProduct.mincharacters}" v-if="flowAddProduct.step == 'search'">
                    <label>
                        <span>Busque pelo nome ou EAN do produto para trazer as informações iniciais.</span>
                        <input type="search" id="searchProductAdd"
                            :class="['form',{error: flowAddProduct.alert}]"
                            v-model="flowAddProduct.input"
                            @input="handleInput()"
                            placeholder="Nome do produto ou EAN">
                    </label>

                    <p class="alert" v-if="flowAddProduct.alert">
                        * {{getMyLanguage("Search","form.tooltip-empty")}}
                    </p>
                </div>


                <!-- RESULTS -->
                <div class="modal-scroll"
                    :class="{active: flowAddProduct.mincharacters}"
                    v-if="flowAddProduct.step == 'search' && !errorsGet.dataResults">

                    <div class="load" v-if="flowAddProduct.load"></div>

                    <div class="results" v-if="flowAddProduct.mincharacters && !flowAddProduct.load && !flowAddProduct.alert">
                        <div class="result"
                            v-for="(list, index) in dataResults"
                            :key="index"
                            @click="searchVariants(list.id, list.name)">

                            <h4>
                                <span>{{list.name}}</span>

                                <div class="clone">
                                    <div class="btn-clone">
                                        <img src="~@/assets/images/icons/clone.svg">
                                        <span>
                                            Clonar
                                        </span>
                                    </div>
                                </div>
                            </h4>
                            
                            <div class="topics">
                                <span v-if="list.brand">Marca: <strong>{{list.brand}}</strong></span>
                                <span v-if="list.ean">EAN: <strong>{{list.ean}}</strong></span>
                                <span v-if="list.sku">SKU: <strong>{{list.sku}}</strong></span>
                            </div>

                        </div>

                        <div class="no-results" v-if="flowAddProduct.empty && !this.flowAddProduct.alert">
                            <strong>
                                Nenhum resultado
                            </strong>
                        </div>

                        <div class="actions" v-if="flowAddProduct.addManually">
                            <a :href="`/${flagSelected}/merchant/products/add`" class="btn-primary">
                                Inserir manualmente
                            </a>
                        </div>
                    </div>
                </div>


                <!-- VARIANTS -->
                <div class="box box-error" v-if="errorsGet.dataVariantsProduct">
                    <h3 class="title-secondary">{{getMyLanguage("box-error","title-error")}}</h3>
                    <p>{{getMyLanguage("box-error","msg-error")}}</p>
                </div>

                <div class="modal-scroll" v-if="flowAddProduct.step == 'variants' && !errorsGet.dataVariantsProduct">
                    <div class="cont-variants">
                        <div v-if="!flowAddVariant.show">
                            <h2 class="title-secondary">{{nameProduct}}</h2>
                            <p class="lbl">
                                <span>
                                    Selecione as variantes disponíveis na sua loja.
                                </span>
                            </p>

                            <div class="load" v-if="flowAddProduct.load"></div>

                            <div class="variants" v-if="!flowAddProduct.load">
                                <div class="form-group-check" v-for="(variant, index) in dataVariantsProduct" :key="index">
                                    <input type="checkbox" class="form-check"
                                        :id="variant.id"
                                        :value="variant.id"
                                        @change="toggleVariant(variant.id)"
                                        :checked="selectedVariants.includes(variant.id)">

                                    <label :for="variant.id" class="form-check-label">
                                        <h3>{{ variant.name }}</h3>

                                        <div class="variant">
                                            <span>
                                                {{ variant.variant_type_1 }}:&nbsp;
                                                <small>{{ variant.variant_1 }}</small>
                                            </span>

                                            <span v-if="variant.variant_type_2 !== 'empty'">
                                                {{ variant.variant_type_2 }}:&nbsp;
                                                <small>{{ variant.variant_2 }}</small>
                                            </span>
                                        </div>
                                    </label>
                                </div>

                                <div class="form-group-check add-variant" @click="addVariant()" v-if="!dataVariantsAdded.show">
                                    <span>ADD Variante</span>
                                </div>
                            </div>


                            <div class="load" v-if="dataVariantsAdded.load && dataVariantsAdded.show"></div>

                            <div class="variants-added" v-if="!dataVariantsAdded.load && dataVariantsAdded.show">
                                <h2 class="title-secondary">Suas variantes</h2>

                                <div class="variants">
                                    <div class="form-group-check" v-for="(variant, index) in dataVariantsAdded.variants" :key="index">
                                        <input type="checkbox" class="form-check"
                                            :id="variant.id"
                                            :value="variant.id"
                                            @change="toggleVariant(variant.id)"
                                            :checked="selectedVariants.includes(variant.id)">

                                        <label :for="variant.id" class="form-check-label">
                                            <h3>{{ variant.name }}</h3>

                                            <div class="variant">
                                                <span>
                                                    {{ variant.variant_type_1 }}:&nbsp;
                                                    <small>{{ variant.variant_1 }}</small>
                                                </span>

                                                <span v-if="variant.variant_type_2 !== 'empty'">
                                                    {{ variant.variant_type_2 }}:&nbsp;
                                                    <small>{{ variant.variant_2 }}</small>
                                                </span>
                                            </div>
                                        </label>
                                    </div>

                                    <div class="form-group-check add-variant" @click="addVariant()">
                                        <span>ADD Variante</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- NEW VARIANT -->
                        <AddVariant
                            :nameProduct="nameProduct"
                            :idProduct="idProduct"
                            :page="page"
                            @variantAdded="addedArrayVariant"
                            @closeAdd="closeAddVariant"
                            v-if="flowAddVariant.show" />


                        <!-- RESUME -->
                        <div class="resume" v-if="!flowAddVariant.show">
                            <h3 class="title-secondary" v-if="!flowAddProduct.load">Resumo</h3>

                            <div class="box" v-if="!flowAddProduct.load">
                                <p>
                                    <strong>{{nameProduct}}</strong>
                                </p>
                                
                                <p>
                                    <strong>{{selectedVariants.length}}</strong>
                                    <span v-if="selectedVariants.length > 1">&nbsp; Variantes</span>
                                    <span v-else>&nbsp; Variante</span>
                                </p>

                                <div class="btn">
                                    <div class="btn-primary btn-disabled" v-if="!selectedVariants.length">
                                        Adicionar produto
                                    </div>

                                    <div class="btn-primary" v-else>
                                        Adicionar produto
                                    </div>
                                </div>
                            </div>

                            <div class="actions">
                                <div class="btn-back" @click="flowAddProduct.step = 'search'">
                                    <span>Voltar</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header";
    import HeaderStore from "@/components/Merchant/HeaderStore.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb";
    import ShelfTemplateAdminProduct from "@/components/Merchant/ShelfTemplateAdminProduct";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import bus from '@/eventBus';

    import moment from 'moment';

    import AddVariant from '@/components/Merchant/Products/AddVariant'

    export default {
        components: {
            Header,
            HeaderStore,
            Breadcrumb,
            ShelfTemplateAdminProduct,
            AddVariant
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "merchant",
                page: "products",
                breadcrumb: "",
                load: true,
                querySearch: "",
                dataFilter: {},

                dataMerchantStore: {},
                loadMerchantStore: true,

                storeName: "",

                errorsGet: {
                    dataProducts: false,
                    dataResults: false,
                    dataVariantsProduct: false
                },
                
                errorsPost: {
                    addVar: false
                },

                advancedSearch: {
                    modal: false,
                    aaa: ""
                },

                flowAddProduct: {
                    modal: false,
                    load: false,
                    input: "",
                    alert: false,
                    empty: false,
                    mincharacters: false,
                    addManually: false,
                    step: "search"
                },

                dataResults: [],
                debouncedSearch: null,

                productName: "",
                dataVariantsProduct: [],
                selectedVariants: [],

                flowAddVariant: {
                    show: false
                },

                dataVariantsAdded: {
                    load: false,
                    show: false,
                    variants: []
                }
            }
        },

        async mounted() {
            this.languageSelected()

            // HEADER STORE
            await apiMerchant('/api/v1/market/store')
            .then(response => {
                setTimeout(() => {
                    this.dataMerchantStore = response.data.store
                    this.loadMerchantStore = false
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errorsGet.dataMerchantStore = error.response.status
                    this.loadMerchantStore = false
                    localStorage.removeItem("storeReference")
                }, 500);
            })

            this.storeName = localStorage.getItem("storeName")

            // await api.get(`api/v1/market/product/filter?query=${this.querySearch}&include_variants=false&include_attachments=false&compressed_response=false`)
            await apiMerchant.get('/api/v2/market/product/filter?include_variants=false&include_attachments=true&compressed_response=false')
            .then(response => {
                this.dataFilter = response.data
                
                // let listProduct = []
                // response.data.forEach(product => {
                //     product.product_variants.forEach(pv => {
                //         pv.brand = product.brand
                //         listProduct.push(pv)
                //     })
                // });
                
                // this.listProducts = listProduct

                setTimeout(() => {
                    this.load = false
                }, 500);
            })
            .catch(error => {
                this.load = false
                this.errorsGet.dataProducts = JSON.stringify(error.response.status)

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }
            })

            this.breadcrumb = [
                {
                    slug: `${this.flagSelected}/merchant/dashboard`,
                    name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
                },
                {
                    name: this.getMyLanguage("Merchant","breadcrumb.products")
                }
            ]
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            searchFilter() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchProduct");
                const filter = inputList.value.toUpperCase();
                const group = document.getElementById("products-list");
                const item = group.getElementsByClassName("item");
                for (i = 0; i < item.length; i++) {
                    a = item[i].getElementsByTagName("h3")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            item[i].style.display = "";
                    } else {
                            item[i].style.display = "none";
                    }
                }
            },


            // NEW PRODUCT
            openAddProduct(){
                // TEMP
                window.location.href = `/${this.flagSelected}/merchant/products/add`

                // SEARCH PRODUCT
                // this.flowAddProduct.modal = true

                // setTimeout(() => {
                //     document.getElementById("searchProductAdd").focus()
                // }, 100);
            },

            async searchProducts() {
                this.errorsGet.dataResults = false

                if (this.flowAddProduct.input.length >= 3) {
                    this.flowAddProduct.load = true
                    this.flowAddProduct.addManually = true

                    await apiMerchant.get(`api/v2/market/product/search?search=${this.flowAddProduct.input}`)
                    .then(response => {
                        this.flowAddProduct.alert = false
                        this.dataResults = response.data;
                        
                        if ( this.dataResults.length == 0 ){
                            this.flowAddProduct.empty = true
                        } else {
                            this.flowAddProduct.empty = false
                        }

                        setTimeout(() => {
                            this.flowAddProduct.load = false
                        }, 500);
                    })
                    .catch(error => {
                        console.log(error)
                        this.errorsGet.dataResults = true
                    })
                } else {
                    this.dataResults = [];
                    this.flowAddProduct.alert = true
                    this.flowAddProduct.addManually = false

                    setTimeout(() => {
                        this.flowAddProduct.load = false
                    }, 500);
                }
            },

            handleInput() {
                if (this.flowAddProduct.input.length >= 3) {
                    this.flowAddProduct.mincharacters = true
                } else {
                    this.flowAddProduct.mincharacters = false
                }

                this.debouncedSearch();
            },

            debounce(func, wait) {
                let timeout;
                return function(...args) {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        func.apply(this, args);
                    }, wait);
                };
            },


            // VARIANTS
            searchVariants(idProduct, nameProduct) {
                this.errorsGet.dataVariantsProduct = false
                this.nameProduct = nameProduct
                this.idProduct = idProduct
                this.flowAddProduct.load = true
                this.flowAddProduct.step = "variants"

                apiMerchant.get(`api/v2/market/product/${idProduct}/variants`)
                .then(response => {
                    this.dataVariantsProduct = response.data
                    this.selectedVariants = response.data.map(variant => variant.id);

                    setTimeout(() => {
                        this.flowAddProduct.load = false
                    }, 500);
                })
                .catch(error => {
                    console.log(error)
                    this.errorsGet.dataVariantsProduct = true
                })
            },

            toggleVariant(id) {
                const index = this.selectedVariants.indexOf(id);

                if (index === -1) {
                    this.selectedVariants.push(id);
                } else {
                    this.selectedVariants.splice(index, 1);
                }
            },



            // NEW VARIANT
            addVariant(){
                this.flowAddVariant.show = true
            },

            closeAddVariant(){
                this.flowAddVariant.show = false
            },

            addedArrayVariant(item) {
                this.dataVariantsAdded.load = true
                this.dataVariantsAdded.show = true
                this.dataVariantsAdded.variants.push(item);
                this.toggleVariant(item.id)

                setTimeout(() => {
                    this.dataVariantsAdded.load = false
                }, 1000);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }


            // NEW PRODUCT
            this.debouncedSearch = this.debounce(this.searchProducts, 500);
        },
    };
</script>

<style src="./custom-products.scss" lang="scss" scoped />