<template>
    <div class="merchant page-campaigns">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- HEADER STORE -->
        <div class="load" v-if="loadMerchantStore"></div>
        <HeaderStore :dataSource="dataMerchantStore" v-if="!loadMerchantStore" />


        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-campaigns">
            
            <div class="load" v-if="load"></div>

            <!-- <div class="row" v-if="errorsGet.dataCampaigns != false">
                <div class="col-12">
                    <div class="box box-error">
                        <h3 class="title-secondary">{{getMyLanguage("box-error","title-error")}}</h3>
                        <p v-if='errorsGet.dataCampaigns != "401"'>{{getMyLanguage("box-error","msg-error")}}</p>
                        <p v-if='errorsGet.dataCampaigns == "401"'>{{getMyLanguage("box-error","401")}}</p>
                    </div>
                </div>
            </div> -->

            <!-- <div class="container" v-if="!load && !errorsGet.dataCampaigns"> -->
            <div class="container" v-if="!load">
                <h2 class="title-secondary subtitle">
                    <!-- <small>{{storeName}}</small> -->
                    {{getMyLanguage("Merchant","campaigns.title")}}
                </h2>

                <div class="cont-campaigns">
                    <div class="campaigns">
                        <div class="add-campaign" @click="openModal('new')">
                            <h3>{{ getMyLanguage("Campaigns","add") }}</h3>
                        </div>

                        <div
                            :class="['campaign', campaign.type]"
                            v-for="(campaign, index) in dataCampaigns"
                            :key="index"
                            @click="openModal('upd', campaign)">
                            
                            <figure>
                                <img :src="require(`@/assets/images/icons/campaign-${campaign.type}.svg`)">
                            </figure>

                            <div class="cont-campaign">
                                <h3>
                                    {{campaign.type_extra}}
                                    <span v-if="campaign.value">{{campaign.value}}</span>
                                </h3>

                                <div class="resume">
                                    <span>
                                        Status:
                                        <strong>{{campaign.status}}</strong>
                                    </span>

                                    <span>
                                        Cluster:
                                        <strong v-for="(cluster, indexCluster) in campaign.clusters" :key="indexCluster">
                                            {{ cluster.name }}
                                        </strong>
                                    </span>

                                    <span>
                                        Área:
                                        <strong v-for="(area, indexArea) in campaign.area" :key="indexArea">
                                            {{ area.name }}
                                        </strong>
                                    </span>

                                    <span>
                                        Cumulativo:
                                        <img src="~@/assets/images/icons/checked.svg" v-if="campaign.cumulative">
                                        <img src="~@/assets/images/icons/unchecked.svg" v-else>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <!-- MODAL -->
    <div class="modal" v-if="modalCampaign.show">
        <div class="modal-cont">
            <div class="modal-close" @click="closeModal()"></div>

            <div class="modal-scroll">
                <h2 class="title-secondary" v-if="modalCampaign.action == 'new' && !modalCampaign.data.type_id">
                    {{ getMyLanguage("Campaigns","add") }}
                </h2>
                
                <h2 class="title-secondary" v-if="modalCampaign.action != 'new'">
                    {{modalCampaign.data.type_extra}}
                    <span v-if="modalCampaign.data.value">{{modalCampaign.data.value}}</span>
                </h2>


                <!-- TYPE CAMPAIGN -->
                <div class="select-campaign" v-if="modalCampaign.action == 'new' && !modalCampaign.data.type_id">
                    <h3>{{ getMyLanguage("Campaigns","modal.select-campaign-type") }}</h3>

                    <div class="options">
                        <div
                            @click="selectTypeCampaign(type)"
                            v-for="(type, indexType) in dataTypesCampaigns"
                            :key="indexType">
                            
                            <figure>
                                <img :src="require(`@/assets/images/icons/campaign-${type.type}.svg`)">
                            </figure>
                            
                            <h3>{{type.type_extra}}</h3>
                        </div>
                    </div>
                </div>

                <div class="selected-campaign" v-if="modalCampaign.action == 'new' && modalCampaign.data.type_id">
                    <figure>
                        <img :src="require(`@/assets/images/icons/campaign-${modalCampaign.data.type}.svg`)">
                    </figure>
                    
                    <div>
                        <h3>{{ getMyLanguage("Campaigns","add") }}</h3>
                        <h2>{{modalCampaign.data.type_extra}}</h2>
                    </div>
                </div>


                <div class="fields" v-if="modalCampaign.data.type_id">

                    <!-- VALUE -->
                    <div class="field cols">
                        <label>
                            <span v-if="modalCampaign.data.type == 'discount'">
                                {{ getMyLanguage("Campaigns","modal.campaign-type.discount-amount") }}
                            </span>

                            <span v-if="modalCampaign.data.type == 'free-shipping'">
                                {{ getMyLanguage("Campaigns","modal.campaign-type.applicable-from") }}
                            </span>

                            <span v-if="modalCampaign.data.type == 'cashback'">
                                {{ getMyLanguage("Campaigns","modal.campaign-type.cashback-amount") }}
                            </span>

                            <input type="text" class="form" v-model="modalCampaign.data.value">
                        </label>

                        <label>
                            <span>{{ getMyLanguage("Campaigns","modal.campaign.type") }}</span>
                            <select name="" id="" class="form">
                                <option value=""
                                    v-if="modalCampaign.data.type != 'free-shipping' && modalCampaign.data.type != 'cashback'">

                                    {{ getMyLanguage("Campaigns","modal.campaign.type-percent") }}
                                </option>

                                <option value="">
                                    {{ getMyLanguage("Campaigns","modal.campaign.type-currency") }}
                                </option>
                            </select>
                        </label>
                    </div>


                    <!-- APPLICATIONS -->
                    <div class="field applications">
                        <h3>{{ getMyLanguage("Campaigns","modal.applications.where-apply") }}</h3>

                        <div class="cols">
                            <div class="form-group-radio">
                                <input type="radio" id="applyAllProducts" name="select-application" class="form-radio" value="all" :checked='modalCampaign.data.application == "full"'>
                                
                                <label for="applyAllProducts" class="form-check-label">
                                    {{ getMyLanguage("Campaigns","modal.applications.all-products") }}
                                </label>
                            </div>
                            
                            <div class="form-group-radio">
                                <input type="radio" id="applyClusters" name="select-application" class="form-radio" value="all" :checked='modalCampaign.data.application == "clusters"'>
                                
                                <label for="applyClusters" class="form-check-label">
                                    {{ getMyLanguage("Campaigns","modal.applications.clusters") }}
                                </label>
                            </div>
                            
                            <div class="form-group-radio">
                                <input type="radio" id="applyCategory" name="select-application" class="form-radio" value="all" :checked='modalCampaign.data.application == "categories"'>
                                
                                <label for="applyCategory" class="form-check-label">
                                    {{ getMyLanguage("Campaigns","modal.applications.categories") }}
                                </label>
                            </div>

                            <div class="form-group-radio">
                                <input type="radio" id="applyBrand" name="select-application" class="form-radio" value="all" :checked='modalCampaign.data.application == "brands"'>
                                
                                <label for="applyBrand" class="form-check-label">
                                    {{ getMyLanguage("Campaigns","modal.applications.brands") }}
                                </label>
                            </div>
                        </div>

                        <!-- <h3>Clusters</h3>

                        <label>
                            <span class="info">
                                * Selecione um ou mais clusters
                                <i><span>Clusters (grupo de produtos) que deseja aplicar essa campanha</span></i>
                            </span>
                        </label> -->
                    </div>

                    <!-- <pre>{{modalCampaign.data}}</pre> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Merchant/HeaderStore.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import moment from 'moment';

    export default {
        components: {
            Header,
            HeaderStore,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "merchant",
                breadcrumb: "",
                load: true,

                dataMerchantStore: {},
                loadMerchantStore: true,

                dataCampaigns: [
                    {
                        type: "free-shipping",
                        type_extra: "Free Shipping",
                        type_id: 1111111111,
                        value: "",
                        value_type: "",
                        status: "Active",
                        clusters: [
                            { id: 111, name: "Pants" },
                            { id: 222, name: "Selection 19-10-2024" },
                            { id: 333, name: "Autumn Collection" }
                        ],
                        area: [
                            { id: 111, name: "Europa" },
                            { id: 222, name: "Asia" },
                            { id: 333, name: "Brasil" }
                        ],
                        cumulative: true
                    },
                    {
                        type: "discount",
                        type_extra: "Discount",
                        type_id: 2222222222,
                        value: 30,
                        value_type: "percent",
                        status: "Pause",
                        clusters: [
                            { id: 111, name: "BlackFriday 2024" }
                        ],
                        area: [
                            { id: 111, name: "Europa" },
                            { id: 222, name: "Asia" },
                            { id: 333, name: "Brasil" }
                        ],
                        cumulative: false
                    }
                ],

                dataTypesCampaigns: [
                    { id: 1111111111, type: "free-shipping", type_extra: "Free Shipping" },
                    { id: 2222222222, type: "discount", type_extra: "Discount" },
                    { id: 3333333333, type: "cashback", type_extra: "CashBack" }
                ],

                storeName: "",

                errorsGet: {
                    dataCampaigns: false
                },

                modalCampaign: {
                    show: false,
                    load: false,
                    action: "",
                    data: {}
                }
            }
        },

        async mounted() {
            this.languageSelected()

            // HEADER STORE
            await apiMerchant('/api/v1/market/store')
            .then(response => {
                setTimeout(() => {
                    this.dataMerchantStore = response.data.store
                    this.loadMerchantStore = false
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errorsGet.dataMerchantStore = error.response.status
                    this.loadMerchantStore = false
                    localStorage.removeItem("storeReference")
                }, 500);
            })

            this.storeName = localStorage.getItem("storeName")

            // await apiMerchant.get('/api/v1/market/order/filter')
            // .then(response => {
            //     this.dataCampaigns = response.data
            //     console.log(this.dataCampaigns)
                
            //     setTimeout(() => {
            //         this.load = false
            //     }, 500);
            // })
            // .catch(error => {
            //     this.load = false
            //     this.errorsGet.dataCampaigns = JSON.stringify(error.response.status)

            //     if ( error.response.status == 401 ){
            //         bus.emit('open-login',"401");
            //     }
            // })

            this.breadcrumb = [
                {
                    slug: `${this.flagSelected}/merchant/dashboard`,
                    name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
                },
                {
                    name: this.getMyLanguage("Merchant","campaigns.title")
                }
            ]

            // TEMP
            setTimeout(() => {
                this.load = false
            }, 1500);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            openModal(action, value){
                this.modalCampaign.show = true
                this.modalCampaign.load = true
                this.modalCampaign.action = action

                if ( action == "new" ){
                    this.modalCampaign.data.type_id = ""

                } else{
                    this.modalCampaign.data = value
                }


                setTimeout(() => {
                    this.modalCampaign.load = false
                }, 500);
            },

            closeModal(){
                this.modalCampaign.show = false
            },

            selectTypeCampaign(type){
                this.modalCampaign.data.type_id = type.id
                this.modalCampaign.data.type = type.type
                this.modalCampaign.data.type_extra = type.type_extra
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-campaigns.scss" lang="scss" scoped />