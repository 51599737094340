<template>
    <div class="search-bar" :class="{active: search.suggestions}">
        <div class="search">
            <input type="search" inputmode="search" class="form form-search" id="searchMarket"
                @keyup="suggestionsFound()"
                v-model="search.input"
                @keyup.enter="sendSearch('full')"
                @click="openSearch()"
                :placeholder='getMyLanguage("Search","form.placeholder")'
                :disabled="search.disable" />
            
            <div class="btn-search" v-if="search.input.length < 3" @click="alertSearch()"></div>
            <div class="btn-search" v-if="search.input.length >= 3" @click='sendSearch("full")'></div>

            <div class="tooltip-search" v-if="emptySearch">
                {{getMyLanguage("Search","form.tooltip-empty")}}
            </div>
        </div>

        <div class="select-region" @click="openModalRegion()">
            <div class="selected" v-if='regionSelected == "Region"'>
                {{ getMyLanguage("Search","region.name." + regionSelected )}}
            </div>

            <div class="selected" v-else>
                {{ getMyLanguage("Search","region.name." + regionSelected )}}
            </div>
        </div>
    </div>


    <!-- SUGGESTIONS -->
    <div class="search-suggestions" v-if="search.suggestions">
        <div class="container cont" :class="{noResults: !dataSuggestions.length}">
            <div class="close" @click="search.suggestions = false"></div>
            
            <div class="row">
                <div class="col-12 col-lg-6 suggestions" id="suggestions">
                    <h4>{{getMyLanguage("Search","autocomplete.title")}}</h4>
                    
                    <div class="resume">
                        <p><strong v-if="search.input">"{{search.input}}"</strong></p>

                        <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="lnk" v-if='search.input != "iphone"'><span>{{ getMyLanguage("SearchSuggestions","mobile-phones") }}</span></a>
                        <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="lnk" v-if='search.input != "iphone"'><span>{{ getMyLanguage("SearchSuggestions","cars") }} > {{ getMyLanguage("SearchSuggestions","parts-and-accessories") }}</span></a>
                        <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="lnk" v-if='search.input != "iphone"'><span>{{ getMyLanguage("SearchSuggestions","maintenance") }} > {{ getMyLanguage("SearchSuggestions","painters") }}</span></a>
                        
                        <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="lnk" v-if='search.input == "iphone"'><span>{{ getMyLanguage("SearchSuggestions","mobile-phones") }}</span></a>
                        <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="lnk" v-if='search.input == "iphone"'><span>{{ getMyLanguage("SearchSuggestions","mobile-phones") }} > {{ getMyLanguage("SearchSuggestions","parts-and-accessories") }}</span></a>
                    </div>

                    <ul class="lnks" v-if='search.input != "iphone"'>
                        <li v-for="(listLnks, index) in lnks" :key="index">
                            <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="lnk">
                                <span>{{listLnks}}</span>
                            </a>
                        </li>
                    </ul>

                    <ul class="lnks" v-if='search.input == "iphone"'>
                        <li v-for="(listLnksIphone, index) in lnksIphone" :key="index">
                            <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="lnk">
                                <span>{{listLnksIphone}}</span>
                            </a>
                        </li>
                    </ul>

                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="all-results" v-if="search.input">
                        {{getMyLanguage("Search","autocomplete.see-all")}}&nbsp;
                        <strong v-if="search.input">"{{search.input}}"</strong>
                    </a>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="load" v-if="loadSuggestions"></div>
                    <div class="preview" v-if="!loadSuggestions">
                        <a :href="`/${items.product_variant_slug}`"
                            :class="['item',items.attribute]" v-for="(items, index) in dataSuggestions.slice(0, 5)"
                            :key="index">

                            <div class="photo">
                                <img :src="items.product_variant_attachment" :alt="items.product_variant_name">
                            </div>
                            
                            <p class="name">
                                {{items.product_variant_name}}
                                <span class="price">
                                    <!-- <small>{{getMyLanguage("Search","autocomplete.since")}}&nbsp;</small> -->
                                    <strong>
                                        <del v-if="items.price != items.price_offer">
                                            <small>{{items.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</small>
                                        </del>

                                        <span>{{items.price_offer.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span>

                                        <!-- <pre>{{items}}</pre> -->
                                    </strong>
                                    <!-- <em>({{getMyLanguage("Search","autocomplete.available-in")}}&nbsp;4&nbsp;{{getMyLanguage("Search","autocomplete.available-stores")}})</em> -->
                                </span>
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="close-suggestions" @click="search.suggestions = false"></div>
    </div>


    <!-- MODAL REGION SELECTION -->
    <div class="modal modal-region-selection" v-if="modal.regionSelection">
        <div class="modal-cont">
            <div class="modal-close" @click="closeModalRegion()"></div>
            <div class="modal-scroll">
                <h3>{{getMyLanguage("Search","region-selection.title")}}</h3>

                <div class="regions">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 248.75" style="enable-background:new 0 0 500 248.75;" xml:space="preserve">
                        <g @click='setRegion("Africa")' :class='{selected: regionSelected == "Africa"}' class="region africa">
                            <path class="st0" d="M295.236,190.42c0,0,0.983-2.114,0.983-2.208c0-0.542-0.742-1.77-0.742-2.556c0-2.408,1.771-2.259,3.195-3.095
                                c1.132-0.64,2.654-2.509,3.443-3.295c0.243-0.244,0.096-1.08,0.293-1.472c1.671,0.197,1.525,3.389,2.016,4.668v0.737
                                c-0.247-0.099-0.739-0.246-0.835-0.246c0,3.584-1.129,6.042-2.212,8.845c-0.884,2.213-1.326,5.502-4.325,5.502
                                c-1.23,0-2.309-1.421-2.309-2.699c0-0.639,0.149-0.888,0.096-1.231c-0.587-0.443-0.587-1.225-0.587-1.966
                                C294.252,191.208,295.136,190.42,295.236,190.42z"/>
                            <path class="st0" d="M264.769,112.534h1.076c0,0.688,0.545,0.836,0.742,1.13c0.442,0.59,1.572,0.196,2.357,0.491
                                c1.571,0.592,3.785,1.328,5.502,1.328c1.573,0,2.064-0.736,3.585-0.736c0.937,0,1.479,0.639,2.56,0.639
                                c0.734,0,0.734-0.249,1.377-0.396c0.341,0.886,0.982,1.525,0.982,2.46c0,0.439-0.788,2.26-0.982,2.849
                                c-1.034-0.148-1.868-2.358-2.706-3.197v0.839l4.672,8.256c-0.247,1.027,1.227,3.192,1.966,3.782v2.604
                                c0.489,1.325,1.472,1.325,2.21,2.309c0.883,1.179,0.346,2.459,1.229,3.345c0.786,0.782,1.323,0.831,2.21,1.47
                                c0.788,0.591,1.208,2.631,2.826,2.631c0,0.836,0.464,0.957-0.024,1.546c0.637,0.492,1.276,2.067,2.11,2.067
                                c0.789,0,0.839-0.641,1.476-0.835c0.736-0.248,1.228,0.099,1.967,0c1.866-0.297,3.637-1.031,5.408-1.476
                                c0.146,0.295,0.341,0.445,0.341,0.736c0,2.799-2.209,6.44-3.44,8.108c-1.475,1.967-2.11,3.737-3.929,5.162
                                c-3.243,2.556-6.734,3.342-7.961,7.716c-0.342,1.224-1.623,1.569-1.623,2.951c0,1.518,0.391,2.206,0.391,3.682
                                c0,1.67,1.823,2.502,1.823,3.436c0,0.296-0.299,0.641-0.349,0.738v1.228c0,0.788-0.145,1.72-0.145,3.099
                                c0.297,0.289,0.396,0.586,0.396,0.982c0,3.834-6.49,3.392-7.03,6.632c-0.683,0.1-1.475,0.935-1.475,1.477
                                c0,1.082,1.378,1.815,1.378,3.29c0,0.787-0.097,2.405-0.397,2.847c-0.294,0.393-1.128,0.2-1.472,0.345
                                c-1.179,0.593-1.867,1.081-2.456,2.311c0.442,0.242,0.738,0.591,0.738,1.033c0,1.374-1.378,2.452-1.964,3.047
                                c-1.086,1.076-1.184,2.211-1.969,3.195c-1.227,1.57-1.77,2.947-3.782,3.932c-0.836,0.391-2.065,0-2.214,0.884
                                c-0.637,0.047-1.03,0.34-1.473,0.34c-0.539,0-0.835-0.491-1.374-0.491c-2.063,0-3.292,1.474-5.158,1.474
                                c-0.838,0-1.278-0.443-1.574-0.983c-0.297,0.048-0.392,0.198-0.494,0.247c0-0.982-0.49-2.063-0.882-2.456
                                c0.392-0.393,0.635-0.785,0.635-1.328c0-0.787-1.372-2.261-1.616-2.702c-1.771-3.001-4.032-5.899-4.032-10.222
                                c0-0.784,0-0.933,0-1.327c0-0.689-0.588-0.932-0.981-1.622c-1.376-2.31-2.852-4.177-2.852-7.372c0-3.439,3.097-4.274,3.097-6.878
                                c0-1.23-0.489-1.722-0.641-2.802c-0.344-2.112-0.735-2.849-1.227-4.076c-0.394-0.983-0.196-2.115-0.835-2.949
                                c-1.277-1.623-4.079-3.34-4.079-5.652c0-0.541,0.785-1.769,1.13-1.967c-0.196-0.442,0-0.54,0-0.735c0-0.591,0-2.065,0.491-2.458
                                c-0.54-1.083-1.426-2.065-3.095-2.065c-0.886,0-1.625,0-2.064,0c-1.474,0-1.817-2.852-3.834-2.852
                                c-2.456,0-4.273,1.133-6.141,1.721c-0.737,0.247-0.983,0.887-1.72,0.887c-0.738,0-1.966-0.741-2.949-0.741
                                c-1.916,0-2.754,0.984-4.668,0.984c-0.591,0-1.279-0.244-1.473-0.396c-1.378-1.029-4.227-2.701-5.26-4.273
                                c-0.932-1.423-1.226-2.607-2.456-3.586c-0.345-0.245-2.949-2.556-2.949-2.754c-0.245-0.293-0.393-0.389-0.637-0.686h0.149
                                c0-0.739-0.248-1.13-0.248-1.575c0-0.685-0.392-0.933-0.736-1.618c1.079-0.543,2.061-3.195,2.061-4.422
                                c0-2.162-0.982-3.541-0.982-5.502c0-0.987,1.133-1.575,1.378-2.362c0.638-1.918,2.112-4.518,3.294-6.143
                                c0.687-0.931,1.963-0.835,2.847-1.328c0.738-0.442,1.966-1.424,2.213-2.211c0.588-1.816-0.151-2.605,0.834-3.832
                                c1.277-1.623,2.949-3.147,4.177-4.668h-0.1c0.396-0.49,0.494-0.935,0.984-1.328c1.227,0.885,2.507,1.229,4.176,1.229
                                c1.032,0,1.23-0.59,1.819-0.883c1.918-0.937,5.554-2.311,7.764-2.311c0.688,0,1.326,0.491,1.721,0.491
                                c0.539,0,1.325-0.736,2.063-0.736c0.639,0,0.933,0.343,1.475,0.343c0.982,0,1.03-0.737,1.965-0.737
                                c0.934,0,0.687,0.884,1.472,0.884c0.297,0,0.445-0.245,0.738-0.293c-0.049,0.737-0.738,0.635-0.738,1.276
                                c0,0.443,0.64,0.639,0.64,1.083c0,0.734-0.64,1.127-0.64,1.865c0,1.226,1.279,2.308,2.213,2.308c0.588,0,0.98,0,1.378,0
                                c3.19,0,4.323,3.932,7.957,3.932c0.442,0,0.983-0.491,0.983-0.736c0-0.346,0-0.639,0-0.984c0-1.571,1.178-1.571,2.704-2.061
                                L264.769,112.534z"/>
                        </g>


                        <g @click='setRegion("Europe")' :class='{selected: regionSelected == "Europe"}' class="region europe">
                            <path class="st1" d="M274.412,92.822c-0.185,0.8-0.162,0.401-0.308,0.696v1.276c-0.491,0.492-1.375,1.473-1.375,2.164
                                c0,0.983,1.321,1.668,1.622,2.111c-0.199,0.197-0.394,0.147-0.641,0.197c-0.442,0.148-0.785-0.05-1.229,0.148
                                c-0.636,0.294-0.787,0.934-1.423,0.934c-0.738,0-1.181-0.983-2.062-0.983c-0.792,0-0.985,0.444-1.626,0.638
                                c0.1,0.393,0.1,0.687,0,1.08c-0.737,0-0.831-0.734-1.274-0.734c-0.252,0-0.539,0.295-0.539,0.541l0.93,1.08
                                c-0.048,0.295-0.198,0.444-0.198,0.59c0,0.149,1.328,1.376,1.722,1.376c0,0.148,0.096,0.346,0.193,0.444
                                c-0.244,0.146-0.734-0.05-0.834,0.295h-0.098c-0.539-0.099-1.031-0.491-1.275-0.739v0.393c0.146,0.299,0.292,0.443,0.292,0.837
                                c0,0.294-0.49,0.884,0,1.13c-0.242,0-0.639,0-0.685,0c-0.79,0-1.475-0.789-1.475-1.621c0-0.345,0.491-0.491,0.639-0.739
                                c-0.296,0-0.542,0-0.839,0c-0.194,0-0.292-0.44-0.292-0.54c-0.198-0.687-0.59-1.129-1.031-1.57
                                c-0.594-0.593-1.375-1.035-1.375-2.065c0-0.59,0-0.884,0-1.279c0-0.69-1.081-1.72-1.772-2.062
                                c-0.686-0.347-1.473-0.395-1.473-1.082c-0.295,0-0.445,0-0.686,0c-1.083,0-2.31-1.672-2.31-2.8c-0.538,0-0.934,0-1.474,0
                                c-0.641,0-0.347-1.034-0.641-1.328c-0.541,0.343-1.62,0.343-1.62,0.885c0,0.095,0.148,0.292,0.245,0.292
                                c-0.097,0.294-0.195,0.592-0.195,0.883c0,0.742,0.441,1.032,1.029,1.232c0.739,0.242,1.132,1.081,1.279,1.521
                                c0.197,0.688,0.538,1.474,0.934,1.67c0.735,0.347,1.228,0.097,1.965,0.097c0,0.151,0,0.295-0.096,0.394
                                c-0.199,0.59,0.981,0.838,1.275,0.985c0.984,0.489,2.159,0.881,2.016,1.914c-0.492-0.096-0.785-0.734-1.574-0.734
                                c-0.592,0-1.08,0.295-1.08,0.785c0,0.641,0.886,0.787,0.886,1.425c0,0.246-0.639,0.246-0.639,0.246
                                c-0.44,0.443-0.344,1.475-1.034,1.475c-0.342,0-0.54-0.148-0.54-0.346c0.591,0,0.833-0.735,0.833-1.328
                                c0-0.736-0.391-0.932-0.492-1.375c-0.142,0-1.322-1.167-1.375-1.466c-0.193,0-0.931-0.206-0.931-0.498
                                c-1.523-0.344-1.866-0.787-2.7-1.623c-0.347-0.344-1.083-0.541-1.183-0.883c-0.882-1.131-1.178-3.145-3.046-3.145
                                c-0.883,0-0.934,0.638-1.374,0.836c-0.936,0.441-1.575,1.327-2.655,1.327c-1.082,0-1.768-0.543-2.752-0.543
                                c-0.737,0-1.52,0.492-1.52,1.232l0.293,1.079c-0.293,0.984-1.374,1.328-2.409,1.574c-0.885,0.197-2.308,2.213-2.308,3.293
                                c0,0.392,0.245,0.538,0.491,0.636c-0.638,0.983-0.932,1.576-1.964,2.065c-0.591,0.293-0.688,1.523-1.671,1.227v0.049
                                c-0.443-0.145-1.277,0.101-1.523,0.101c-1.179,0-1.82,0.983-2.899,0.983c-0.739,0-0.789-1.818-1.917-1.818
                                c-0.835,0-1.378,0.342-2.113,0.342c-0.297,0-0.391-0.243-0.391-0.393c0-1.032,0-1.372,0-1.964c-0.394,0-0.787-0.297-0.787-0.69
                                c0-0.639,0.539-0.784,0.539-1.127c0-1.233,0.441-2.507,0.441-3.784c0-0.74-0.54-1.426-0.54-1.87c0-0.292,1.671-1.327,1.965-1.327
                                c0.198,0,0.443,0.295,0.737,0.196h2.21c0.198,0.198,0.592,0.248,0.836,0.298h2.36c0.59-0.15,0.884,0.195,1.376,0.195
                                c0.341,0,1.082-0.246,1.228-0.246c0-0.984,0.639-1.275,0.639-2.21c0-0.198-0.053-1.061-0.053-1.356l-0.097,0.076
                                c0.099-0.193,0.199-0.539,0.199-0.735c0-0.442-0.298-0.346-0.298-0.742c-0.982,0-1.278-0.881-1.278-1.666
                                c-0.293-0.05-0.341-0.298-0.588-0.345c-0.786-0.244-2.946-0.639-2.946-1.378c-0.05,0-0.05-0.146-0.05-0.194
                                c0-0.444,1.374-0.738,1.866-0.885c0.147,0.294,0.934,0.491,1.326,0.491c0.391,0,0.933-0.197,1.131-0.246v-0.341
                                c-0.345-0.099-0.198-0.835-0.246-0.983v-0.396h0.492c0.39,0.396,0.884,0.639,1.471,0.639c0.395,0,0.739-0.343,0.837-0.736
                                c1.477,0,1.868-1.082,2.165-2.26c0.292,0,0.44-0.049,0.687-0.101c0.293-0.096,2.259-0.882,2.455-1.078
                                c0.394-0.392,0.247-0.984,0.247-1.573c0-0.197,0.441-0.491,0.491-0.541c0.688-0.687,1.378-1.476,2.505-1.476
                                c0.638,0,0.344,0.151,0.688,0c0.148,0.151,0.295-0.241,0.393-0.341c0.347-0.347,0.787-0.049,1.229-0.049
                                c0.296,0,1.082-0.442,1.082-0.835c0-0.248-0.343-1.182-0.343-1.232c-0.197-1.325-0.984-1.719-0.984-2.899
                                c0-2.062,1.915-2.209,3.145-3.093v0.59c-0.248,0.491-0.295,0.786-0.295,1.277c0,0.494,0.541,0.541,0.933,0.541
                                c0,0.145,0,0.245,0,0.394c0,0.241-0.44,0.342-0.588,0.492c-0.491,0.49-1.081,0.933-1.081,1.818c0,0.489,0.247,0.832,0.537,0.882
                                c0,0.297,0.052,0.396,0,0.54c0.543,0,0.74,0.198,1.183,0.198c0.096,0.246,0.197,0.591,0.344,0.591c1.326,0,2.259-1.129,3.391-0.984
                                c-0.052,0.246-0.297,0.393-0.297,0.54c0,0.099,0.837,0.688,0.837,0.688c0.538,0,0.588-0.341,0.838-0.44
                                c0.831-0.396,1.472-0.249,2.208-0.594c0.443-0.195,1.226-0.932,1.818-0.932c0.638,0,1.328,0.05,1.672,0.198
                                c-0.102,0.195-0.2,0.443-0.2,0.54c1.281,0,2.594-0.25,3.527-0.25c0.936,0,1.536-0.066,2.521-0.066c1.032,0,1.524,1.838,1.524,2.919
                                c0,0.642-0.692,0.984-0.692,1.622c0,1.034,0.934,1.673,0.934,2.606c0,0.587-0.934,0.786-1.129,1.078
                                c-0.587,0.888-0.789,1.674-0.789,2.853c0,1.375,0.985,1.08,2.115,1.08c1.574,0,2.312-0.638,3.493-0.638
                                c1.324,0,1.029,2.555,2.257,2.555v2.014c0,0.15,0.148,0.247,0.247,0.345l0.244,0.295c0.244,0,0.834-0.248,1.179-0.295
                                c0.051,0.246,0,0.491,0.051,0.738C274.94,92.094,274.412,92.332,274.412,92.822z"/>
                            <path class="st1" d="M249.877,65.604c-0.637-0.292-0.737-0.734-1.08-1.227c-1.031,1.476-2.603,3.342-4.669,3.342
                                c-0.295,0-2.212-1.182-2.212-1.374c0-0.394,0.642-0.641,0.838-0.74V64.87c-0.688,0-0.688,0-1.278,0.243
                                c0-0.343,0.538-0.935,0.787-1.276c-0.74-0.883-0.837-2.116-0.837-3.489c0-0.737,0.784-0.688,1.079-1.131
                                c0.592-0.835,1.133-1.427,2.213-1.719c0.888-0.245,1.079-1.428,2.85-1.23c-0.541-0.146-0.737-0.246-1.179-0.344
                                c2.161-0.54,2.997-2.259,4.472-3.442c0.59-0.441,0.492-1.965,0.885-1.965c0,0,0.491-1.078,0.491-1.128
                                c0.588-0.983,1.279-1.67,2.31-2.063c1.03-0.391,2.408-2.066,2.652-3.099c-1.226,0.249-1.72,0.889-2.899,0.889
                                c0.247-0.347,0.788-0.593,0.982-0.69c-0.244,0-0.735-0.149-0.735-0.149c0.096-0.491,0.345-0.784,0.735-0.784
                                c0.151,0,0.248,0.145,0.394,0.145c0.149-0.294,0.297-0.49,0.442-0.638c0.249,0.293,0.297,0.543,0.147,0.884
                                c0.787,0,0.985,0.491,1.62,0.491c0.247,0,0.789-1.181,0.887-1.227c-0.345-0.098-0.345-0.148-0.787-0.098
                                c0.248-0.393,0.637-0.787,1.131-0.787c0.343,0,0.64,0.097,0.981-0.099c-0.247-0.246-0.191-0.195-0.39-0.395
                                c0.492-0.489,1.376-0.441,2.111-0.588v0.588c0.638-0.145,0.788-1.276,1.819-1.324v0.835c0.638-1.177,1.474-0.884,2.36-1.376
                                c-0.392-0.146-0.737-0.247-0.737-0.592c0-0.241,0.882-0.441,1.029-0.733c0.297,0.341,0.589,0.733,1.034,0.733
                                c0.737,0,2.605-1.227,2.605-0.587c0,0.687-0.838,0.885-1.035,1.571c0.984,0.1,1.278-1.03,2.113-1.474
                                c0.197,0.344,0.197,0.59,0.247,0.886c0.689-0.688,1.083-0.886,1.866-1.375c0.051,0.638-0.05,1.08,0.494,1.131
                                c0.146-0.392,0.344-0.738,0.738-0.738c0.245,0,1.325,0.587,1.72,0.788c-0.445,1.178-0.839,0.783-1.967,1.178
                                c0.492,0.394,0.835,0.442,0.835,0.737c0.441,0.05,1.473,0.197,1.62,0.197c-0.688,0.491-2.456,1.376-2.456,2.508
                                c0,0.933,1.72,0.884,1.72,1.964c0,0.838-0.885,1.229-0.885,2.065c0,0.932,0.983,1.473,0.983,2.454c0,0.594-0.491,0.886-0.491,1.476
                                c0,1.082,1.473,1.375,1.473,2.458c0,0.293-0.444,0.542-0.59,0.64c0.441,0.786,1.573,0.981,1.573,2.064
                                c0,1.62-3.392,3.437-4.425,4.813c-0.49,0.985-2.752,1.131-3.686,0.985c-0.982,0.294-2.111,0.736-2.948,0.736
                                c-0.635,0-1.078-0.1-1.078-0.641c-1.033,0-2.114-0.684-2.114-1.716c0-0.493,0.541-0.838,0.688-1.082
                                c-0.491-0.196-0.934-1.328-0.934-1.866c0-0.345,0.096-0.642,0-0.983c2.065,0,3.149-2.703,4.422-3.441
                                c0.689-0.392,1.865-0.641,1.865-1.473c0-1.228-1.617-1.476-2.848-1.476c-1.622,0-2.947,0.592-2.947,1.965
                                c0,0.592,0.148,0.834,0,1.133c0,1.127-2.261,2.111-3.292,2.702c-0.835,0.491-1.72,0.688-2.212,1.573
                                c-0.394,0.686,0.098,1.619-0.639,1.964v0.984c0.246,0.195,0.393,0.538,0.393,0.882c0.884,0.15,2.212,1.034,2.212,1.967
                                c0,0.737-1.473,1.968-1.965,1.968c-0.983,0-1.374,1.423-1.374,2.456c0,0.586,0.44,0.783,0.735,0.833
                                c-0.147,1.328-1.325,1.426-1.474,2.358c-0.689,0-0.931,0-1.324,0c-0.838,0-1.132,1.574-2.364,1.574
                                c-0.392,0-1.078-2.064-1.078-2.307c0-0.153,0.099-0.347,0.099-0.494c-0.835-0.835-0.738-1.525-1.082-2.606
                                c-0.243-0.735-0.981-0.932-0.981-1.815c0-0.347,0.295-0.789,0.341-0.886V65.604z"/>
                            <path class="st1" d="M265.356,21.282c-0.096-0.398-0.096-0.636,0.048-0.887c-0.59-0.096-1.622-0.047-2.257-0.096
                                c0.096-0.887,0.636-0.639,0.736-1.379c-0.342-0.095-0.788-0.982-0.983-1.374c0.248-0.051,0.441-0.097,0.688-0.097
                                c-0.786-0.099-1.18-0.738-1.18-0.738c-1.669,0-2.209,2.163-2.701,3.439c-0.1,0.294-0.492,0.198-0.738,0.393
                                c-0.836,0.641-0.735,2.016-1.428,2.703c-1.174-0.785-3.831-1.425-3.831-3.097c0.688-0.097,1.82,0.052,1.82,0.052
                                c0.491-0.394,1.028-0.25,1.62-0.394c-0.291-0.197-1.869-0.248-2.209-0.248c-0.594,0-1.869,0.542-1.869-0.64
                                c0-0.44,1.128-0.44,1.378-0.489c0.637-0.196,1.964-0.786,2.504-1.227c-0.689,0-1.081,0-1.279,0c-0.1,0-0.541-0.295-0.734-0.444
                                c-0.151,0-0.251,0-0.395,0c-0.443,0.246-0.443,1.326-1.474,1.326c-0.786,0-1.327-0.291-1.573-0.783
                                c0.149-0.049,0.246-0.099,0.394-0.099c-0.834-0.444-1.573-0.837-1.378-1.623c-0.147-0.049-0.243-0.195-0.393-0.195
                                c-0.345,0-0.883-0.837-1.228-1.181c0.196-0.246,1.228-1.178,1.72-1.178c0.787,0,1.326,0,2.457,0.199
                                c-0.248,0.293-0.443,0.439-0.592,0.687c0.495,0.395,0.984,0.395,1.328,0.737c-0.197-0.1-0.344-0.342-0.344-0.641
                                c0-0.441,0.393-0.835,0.834-0.835c1.326,0,0.982,1.721,2.212,2.164c-0.295-0.789-0.588-1.231-0.588-2.164
                                c0-0.735,0.342-0.735,0.982-0.735c0.636,0,1.966,0.491,1.966,0.983c0,0.343-0.146,0.638-0.146,0.98
                                c0.391-0.197,0.49-0.637,0.882-0.637c0.787,0,0.687,0.785,0.592,1.178c0.145,0,0.343,0.051,0.49,0.051
                                c0.835,0,3.441,1.475,3.931,2.061c-0.147,0.051-0.345,0.051-0.491,0.051c0.392,0.099,0.538,0.05,0.882,0
                                c0.099,0.246,0.148,0.541,0,0.738c0.739,0,0.985,0,1.574,0c0,1.524,1.425,1.131,1.867,1.769
                                C267.128,20.052,266.587,20.884,265.356,21.282z"/>
                            <path class="st1" d="M271.892,12.878c-1.18,0.785-3.637,2.506-5.063,2.506c-0.883,0-1.373-0.295-1.718-0.885h-2.801
                                c-0.441-0.146-0.589-0.394-0.639-0.787c0.341-0.049,0.886-0.049,0.985-0.049c0.439-0.146,0.981-0.146,1.226-0.243
                                c-0.197,0-2.212-0.149-2.309-0.149c-0.835,0-1.819,0.589-1.725-0.541c-0.391-0.293-0.685-0.686-0.734-1.178h0.734
                                c0.3,0.195,0.692,0.294,0.987,0.146v-0.243h0.835l-0.246-0.491h1.376c0.293,0.292,1.72,0.982,1.967,0.982
                                c0.587,0,0.983-0.935,1.228-1.133h0.589c0,0.641-0.099,0.884,0,1.231c0.442,0,0.737-0.492,1.474-0.492c1.177,0,0.883,0,1.617,0
                                C270.661,11.551,271.597,12.288,271.892,12.878z"/>
                            <path class="st1" d="M248.895,15.972c0.64,0.84,1.327,1.032,1.67,2.113c-0.982-0.244-2.505-1.719-1.522-2.113v0.148L248.895,15.972
                                z"/>
                            <path class="st1" d="M221.377,75.777c0.295,0,1.131-0.246,1.424-0.542c-0.195-0.295-0.293-0.392-0.293-0.637
                                c0-0.344,0.981-0.934,1.474-0.934c0.295,0,0.54,0,0.835,0c1.128,0,1.672,1.179,1.672,2.014c0,0.395-0.886,0.347-0.886,0.739
                                c0,0.34,0,1.621,0,1.669c0,0.393,0.343,0.983-0.05,1.378c-0.294,0.291-0.784,0.244-1.276,0.244c-0.788,0-1.377,0.836-1.769,1.082
                                c-0.248,0.145-0.639,0.194-0.935,0.194c-0.59,0-1.226-0.536-1.226-1.129c0-1.227,1.226-1.327,1.423-2.506
                                c-0.491-0.245-0.935-0.542-0.935-1.034C220.835,75.678,221.231,75.777,221.377,75.777z"/>
                            <path class="st1" d="M224.768,68.553c-0.147,0-0.296-0.393-0.296-0.588c0-0.295,0.835-0.541,1.229-0.541
                                C225.702,67.965,225.308,68.553,224.768,68.553z"/>
                            <path class="st1" d="M235.185,78.331c0.342-0.245,0.245-0.195,0.639-0.195c0.588,0,1.032,0.442,1.032,0.933
                                c0,0.886-1.18,1.328-1.576,1.966c0.496,0.147,0.692,0.197,0.985,0.197v0.341c-0.44,0.444-0.786,0.74-1.376,0.74
                                c-0.59,0-1.671,0-1.965,0c-0.491,0-1.819,0.441-2.554,0.441c-0.345,0-0.542-0.295-0.886-0.295c-0.494,0-0.784,0.44-0.784,0.838
                                h-0.689c-0.348,0-0.494,0.194-0.69,0.391h-0.343v-0.295c0.096-0.196,1.572-1.771,1.722-1.819c0.685-0.193,1.669,0.048,1.669-0.638
                                c0-0.096-0.097-0.246-0.097-0.343c-0.198,0.296-0.541,0.491-0.888,0.491c-0.638,0-0.834-0.588-1.374-0.588
                                c-0.296,0-0.246,0.244-0.494,0.244c-0.097,0-0.244-0.147-0.244-0.244c0-0.739,1.377-0.787,1.377-1.523
                                c0-0.641-0.394-0.886-0.394-1.428c0.882,0,1.964-0.44,2.21-0.933v-0.737c-0.494,0.052-1.179-0.05-1.179-0.543
                                c0-0.392,0.246-0.49,0.343-0.687c-0.146,0-0.293-0.047-0.441-0.047c-0.489,0-0.737,0.244-1.128,0.244c-0.445,0-0.788,0-0.788-0.393
                                c0-0.391,0.492-0.641,0.492-1.032c0-0.442-0.149-0.541,0-0.934c-0.394,0-0.69-0.146-0.837-0.246c-0.248,0.396-0.098,0.542,0,0.738
                                h-0.392c0-0.393,0-0.54,0-0.784c0-0.15,0.049-0.249,0.196-0.349v-0.44c-0.294-0.05-0.739-0.195-0.739-0.395
                                c0-0.243,0.347-0.588,0.347-0.636v-0.394c-0.738-0.146-1.131-0.293-1.131-0.933h0.784c0,0.295,0.248,0.44,0.347,0.44
                                c0.098,0,0.197-0.197,0.197-0.292c-0.098-0.051-0.244-0.2-0.244-0.294c0-0.396,0.343-0.59,0.687-0.59
                                c0-0.396,0.196-0.59,0.196-0.837c0-0.244,0.489-0.636,0.788-0.636c0.538,0,1.08,0,1.422,0c0.196,0,0.493,0.343,0.493,0.442
                                c0,0.687-1.083,0.589-1.227,1.276c0,0.149,0.05,0.247,0.145,0.392h2.901c0.194,1.379-1.327,1.965-1.327,2.999
                                c-0.197,0.051-0.345,0.198-0.397,0.394c0.494,0.146,0.74-0.049,1.132,0.048c0.934,0.296,0.786,1.474,1.377,2.064
                                c0.787,0.785,2.064,1.133,2.064,2.307c0,0.396,0.538,0.643,0.538,1.034c0,0.149-0.095,0.295-0.242,0.346v0.343
                                c0.343-0.098,0.392-0.247,0.588-0.343L235.185,78.331z"/>
                            <path class="st1" d="M228.012,75.531c-0.296,0.147-0.348,0.344-0.348,0.591c0.299,0,0.446,0,0.544,0c0-0.247,0.05-0.444,0.05-0.591
                                C228.208,75.531,228.062,75.531,228.012,75.531z"/>
                            <path class="st1" d="M251.598,104.424v0.346c0.147,0.246,0.54,0.195,0.688,0.246c0.786,0.245,1.423,1.082,2.26,1.277
                                c0,0.148,0.244,0.198,0.395,0.198c0.146,0,0.292-0.198,0.292-0.343c0-0.394-0.244-0.444-0.244-0.787
                                c0-0.198,0.244-0.542,0.244-0.542l0.199-0.57c-0.742-0.147-1.034,0.324-1.673,0.324
                                C253.073,104.573,252.629,103.933,251.598,104.424z"/>
                            <path class="st1" d="M246.585,96.761c0.345,0,0.541-0.443,0.787-0.493c0,0.247,0.047,0.493,0.197,0.543
                                c-0.15,0.591-0.294,1.67-0.443,1.964c-0.491-0.097-0.638-1.523-0.883-1.916C246.339,96.859,246.488,96.761,246.585,96.761z"/>
                            <path class="st1" d="M246.389,100.789c0-0.393-0.441-0.639-0.492-1.03c0.541-0.149,0.591-0.639,1.033-0.639
                                c0.344,0,0.489,0.295,0.638,0.295c0.429,0.781,0.307,1.223,0.307,2.011c0,0.685-0.897,1.379-1.29,1.379
                                c-0.491,0-0.491-0.638-0.491-1.031C246.143,101.378,246.389,101.133,246.389,100.789z"/>
                            <path class="st1" d="M239.017,101.87c0,0.149-0.245,0.246-0.393,0.246c-0.195,0-0.59-0.392-0.638-0.442
                                c0.294-0.151,0.493-0.296,0.787-0.341C238.772,101.427,239.017,101.724,239.017,101.87z"/>
                            <path class="st1" d="M249.287,74.204c-0.588,0-1.132-0.146-1.132-0.638c0-0.148,0-0.344,0-0.492h0.936
                                c0,0.59,0.343,0.492,0.343,0.985c0,0.391-0.096,0.29-0.147,0.539C249.287,74.45,249.287,74.349,249.287,74.204z"/>
                            <path class="st1" d="M250.025,74.551c0.343-0.051,0.687-0.396,0.687-0.64c-0.49-0.102-1.03-0.493-1.03-0.786
                                c0-0.2,0.591-1.476,0.934-1.476c0.982,0,1.032,0.444,1.278,1.277c-0.834,0.246-0.443,1.965-1.525,1.965
                                c-0.343,0-0.639-0.246-0.687-0.293c0.048-0.098,0.195-0.198,0.247-0.249L250.025,74.551z"/>
                            <path class="st1" d="M260.199,68.702c0,0.54-0.394,0.783-0.74,1.227c-0.146-0.196,0-0.394,0-0.641c0-0.295,0.248-1.176,1.132-1.228
                                C260.49,68.26,260.199,68.453,260.199,68.702z"/>
                            <path class="st1" d="M265.845,66.735c0,0.197,0,0.393,0,0.591c0,0.146-0.637,0.493-0.832,0.493c-0.443,0-0.49-0.444-0.49-0.738
                                c0.392-0.05,0.686-0.345,1.083-0.394c-0.149-0.196-0.344-0.342-0.344-0.59c0-0.243,0.441-0.492,0.441-0.492
                                c0.246,0.295,0.346,0.394,0.54,0.492C266.194,66.344,265.947,66.686,265.845,66.735z"/>
                            <path class="st1" d="M270.661,108.996c-0.637,0.344-0.883,0.099-1.224,0.099c-0.397,0-1.33-0.099-1.869-0.394
                                C268.65,108.505,269.828,108.75,270.661,108.996z"/>
                        </g>

                        <g @click='setRegion("Oceania")' :class='{selected: regionSelected == "Oceania"}' class="region oceania">
                            <path class="st2" d="M443.393,168.948c-0.394-0.492-1.28-0.347-1.427-0.983h1.472c0.098,0.045,0.543-0.148,0.543-0.148
                                c0.737-0.248,1.475-0.197,1.916-0.394c0.687-0.345,0.395-1.426,1.08-1.426c0.297,0,0.443,0.444,0.443,0.737
                                c0,1.034-1.031,0.834-1.031,1.721c-0.886,0.198-1.328,0.738-2.359,0.738c-0.196,0-0.835-0.342-0.886-0.492L443.393,168.948z"/>
                            <path class="st2" d="M448.355,166.541c-0.588-0.099-0.588-0.447-0.491-0.937c-0.639-0.292-0.542-0.835-1.178-1.227
                                c-0.394-0.249-0.739-0.199-0.886-0.59c0.932,0.293,1.374,0.883,1.915,1.425c0.396,0.392,0.935,0.295,0.935,0.982
                                c0,0.101-0.048,0.248,0,0.348C448.55,166.541,448.449,166.636,448.355,166.541z"/>
                            <path class="st2" d="M451.599,168.896c-0.592-0.093-0.788-0.583-0.689-1.079c0.295,0.194,1.326,1.275,1.326,1.424
                                c0,0.147-0.196,0.393-0.343,0.393c-0.147,0-0.293-0.195-0.293-0.342C451.599,169.144,451.599,169.044,451.599,168.896z"/>
                            <path class="st2" d="M461.18,175.14c0,0.097-0.049,0.193,0,0.291c-0.492,0-0.833-0.737-0.784-1.032
                                C460.787,174.65,461.18,174.747,461.18,175.14z"/>
                            <path class="st2" d="M459.852,174.303c-0.047,0-0.197,0.097-0.245,0.097c-0.59,0-1.378-0.196-1.378-0.782
                                C459.017,173.618,459.51,173.811,459.852,174.303z"/>
                            <path class="st2" d="M459.904,173.269c-0.492,0-1.376-1.179-1.424-1.568C459.165,172.09,459.656,172.534,459.904,173.269z"/>
                            <path class="st2" d="M455.674,170.52c0.444,0.248,1.428,0.54,1.723,0.983c-0.099,0.049-0.248,0.099-0.396,0.099
                                c-0.39,0-1.521-0.394-1.521-0.935h0.099L455.674,170.52z"/>
                            <path class="st2" d="M453.267,169.292c0.54,0.392,0.886,0.242,1.181,0.685h-0.44l-0.741-0.491c0.047,0,0.247,0,0.247-0.047
                                L453.267,169.292z"/>
                            <path class="st2" d="M439.068,221.134c0.637,0,1.471-0.295,2.159-0.489c0.151,0.59,0.542,0.883,0.542,1.47
                                c0,1.085-1.326,3.683-2.309,3.683c-1.472,0-3.098-3.631-3.098-4.664C436.363,220.345,439.02,221.134,439.068,221.134z"/>
                            <path class="st2" d="M441.671,219.318c0,0.491-0.097,0.587-0.146,0.834c-0.442-0.148-0.491-0.442-0.491-0.834
                                C441.278,219.367,441.525,219.367,441.671,219.318z"/>
                            <path class="st2" d="M426.783,212.928c-0.541,0.343-1.279,0.444-1.721,0C425.995,212.484,426.092,212.583,426.783,212.928z"/>
                            <path class="st2" d="M447.668,196.17c0.782,1.03,1.274,3.686,1.274,5.162c0,1.08-0.291,2.602-0.733,3.048v1.377
                                c-0.248,0.392-0.098,0.587-0.345,0.982c-0.886,1.374-1.771,1.866-2.26,3.434c-0.392,1.182-1.427,2.708-1.427,4.426v-0.097
                                c-0.54,2.261-4.568,1.228-4.964,3.587c-0.39-0.052-2.407-1.177-2.456-1.476c-1.126,0.1-0.735,1.08-1.967,1.08
                                c-1.917,0-5.06-1.274-5.06-3.291c0-0.737-0.639-1.326-0.69-1.965c0,0-0.05-0.198-0.05-0.243c-0.341,0.193-0.587,0.243-0.981,0.342
                                h-0.59c0.146-0.297,0.344-0.589,0.344-1.08c0-0.595-0.295-0.789-0.49-0.983c0,0.146-0.1,0.34-0.1,0.489
                                c-0.441,0.196-0.49,1.082-1.227,1.082c-0.248,0-0.494-0.248-0.494-0.489c1.23-0.25,1.475-2.804,1.429-3.541
                                c-0.888,0.886-2.117,3.442-3.15,3.442c-0.588,0-0.392-0.884-0.392-0.884c-0.34,0-1.423-2.409-1.816-2.951
                                c-0.789-0.983-3.689-1.473-5.311-1.473c-1.768,0-2.11,0.783-3.191,1.129c-2.51,0.837-5.602-0.049-5.995,2.457
                                c-1.031,0.098-1.87,0.345-2.952,0.345c-0.786-0.394-0.786,0-1.374,0c-1.719,0-2.457,1.62-4.667,1.62
                                c-0.834,0-3.192-1.03-3.192-1.718c0-0.735,1.126-1.08,1.126-2.209c0-1.428-0.733-2.214-0.982-3.097
                                c-0.587-2.164-0.734-2.85-1.376-4.765c-0.242-0.742-1.225-0.987-0.983-1.724c0.101-0.193,0.15-0.392,0.296-0.637
                                c0.097,0.244,0.297,0.492,0.446,0.637c0.143-0.193,0-0.392,0-0.637c0-0.739-0.592-1.33-0.592-2.557c0-1.473,0-1.572,0-2.846
                                c0-0.396,0.341-0.496,0.541-0.595c0.051,0.15,0.051,0.344,0.051,0.492c0.93-0.1,2.061-1.325,2.599-1.865
                                c0.591-0.59,2.361-0.69,3.295-0.837c1.771-0.295,5.798-1.671,5.798-3.343c0-0.296,0-0.536,0-0.834c0-0.495,0.147-0.984,0.638-1.229
                                c0.248,0.443,0.538,0.635,0.737,0.984c0.544-0.349,0.295-1.082,0.544-1.622c0.391,0.196,0.636,0.393,1.029,0.393
                                c0-1.376,0.982-1.574,1.08-2.701c1.328,0,1.574-0.983,2.605-0.983c0.884,0,1.376,0.983,1.474,1.867
                                c0.247-0.345,0.638-0.54,1.082-0.54c0.246,0,0.637,0.343,0.735,0.39c0.296-0.44,0.147-0.782,0.147-1.224
                                c0-0.688,0.737-1.033,0.737-1.723c0-0.686,1.966-1.129,2.804-1.129c0.344,0,0.734-0.298,0.885-0.345
                                c-0.299-0.44-0.788-0.59-0.885-1.13c1.67,0.69,2.654,1.376,4.422,1.376c0.637,0,0.838-0.393,1.377-0.393
                                c0.293,0,0.981,0.492,0.981,0.886c0,0.638-0.738,0.685-1.132,0.982l-0.59,2.458c0,0.392,0.693,0.294,0.983,0.489
                                c0.297,0.195,0.297,0.937,0.492,0.983c1.327,0.489,2.066,0.737,3.291,1.227c0.69,0.246,0.935,1.325,1.87,1.325
                                c1.521,0,1.82-2.899,2.062-4.025v-4.424c0.784-0.59,0.541-1.229,1.377-1.673c0.15,1.327,0.786,2.21,1.079,3.149
                                c0.197,0.683-0.048,1.568,0.493,1.966c0.491,0.142,1.424,0.441,1.621,0.541c0.592,0.39,0.592,1.96,0.837,2.748
                                c0.44,1.329,0.835,1.967,1.229,3.192c0.392,1.181,2.505,1.624,3.342,2.46c0.589,0.59,0.686,1.522,0.885,2.21
                                c0.195,0.643,0.981,0.643,1.622,0.643c0,1.961,1.865,2.798,2.799,3.732L447.668,196.17z"/>
                            <path class="st2" d="M473.414,223.59c0,0,0-0.341,0.05-0.491c0.195-0.392,0.493-0.49,0.886-0.543c0-0.096,0.049-0.195,0.049-0.291
                                c0-0.737,0.735-1.426,1.178-1.62c0,0.338,0.198,0.489,0.344,0.489c0,0.343-0.049,0.687,0.247,0.687
                                c0.344,0,0.588-0.343,0.834-0.443c0.05,0.15,0.197,0.196,0.348,0.196c0,0.392,0.094,0.641,0.293,0.74
                                c-0.152,0.636-1.131,2.455-1.919,2.455c-0.147,0.738,0.1,0.934,0,1.522c-0.244,0-1.424,0.249-1.817,0.249
                                c-0.148,0.594-1.524,3.437-1.524,3.437c-0.442,0.443-1.323,1.18-1.865,1.18c-0.198,0-0.787-0.247-1.081-0.247
                                c-0.298,0-2.215-0.738-2.215-0.981c-0.097,0-0.244-0.098-0.244-0.197c0-1.276,1.474-1.968,1.965-2.704
                                c0.195-0.344,0.442-0.638,0.788-0.638c1.178,0,1.917-1.226,2.752-1.622C472.728,224.672,473.414,223.59,473.414,223.59z"/>
                            <path class="st2" d="M478.181,214.941c-0.148-0.393-0.538-0.54-0.538-0.933c0-0.147,0.096-0.343,0.147-0.489
                                c-1.132-0.197-1.817-1.821-2.114-2.999c0.54,0.833,1.377,0.883,1.866,1.376c0.297,0.298,0.297,0.639,0.59,0.787
                                c-0.048,0.147-0.196,0.197-0.196,0.347c0,0.241,0.588,1.323,0.837,1.568h0.293c0-0.295,0.197-0.494,0.441-0.494
                                c0.099,0,0.199,0,0.297,0c0,1.279,0.638,2.017,1.817,2.017c0.934,0,0.883-0.493,1.67-0.493c0.147,0,0.298,0.148,0.395,0.298
                                c-0.69,0.686-0.489,1.718-0.981,2.459l-0.201-0.249c-0.049,0-0.099,0.147-0.146,0.147c-0.491,0.101-0.984,0.247-0.984,0.639
                                c0,0.148,0.196,0.346,0.196,0.491c0,0.345-0.591,0.835-0.688,0.982c-0.49,0.739-0.784,1.719-1.817,1.719
                                c-0.442,0-0.735-0.241-0.735-0.637c0-0.591,0.587-0.833,0.587-1.423c0-0.984-1.82-0.592-1.82-1.475
                                c0-0.491,0.443-0.544,0.691-0.785c0.491-0.496,0.591-1.182,0.591-1.918c0-0.391-0.2-0.934-0.249-1.132h0.049V214.941z"/>
                            <path class="st2" d="M482.851,184.723c0.146,0.244,0.347,0.393,0.588,0.441v0.589c-0.293,0.293-0.293,0.198-0.684,0.198
                                c-0.395,0-1.035-0.097-1.035-0.296C481.72,185.013,482.407,184.871,482.851,184.723z"/>
                            <path class="st2" d="M485.206,183.296c0,0.146,0,0.292,0,0.442c-0.586,0.15-0.733,0.64-1.224,0.64
                                c-0.149,0-0.295-0.298-0.295-0.443C483.687,183.346,484.914,183.296,485.206,183.296z"/>
                            <path class="st3" d="M432.459,164.352c0.716,0.273,1.557,0.468,2.234,0.468c0.593,0,0.888,0.688,1.473,0.688
                                c0,0.396,0.396,0.442,0.639,0.491c1.573,0.493,1.523,1.819,2.707,2.409c0.49,0.247,1.522-0.295,1.522,0.787
                                c0,0.54-0.934,0.341-0.934,0.784c0,0.247,0.838,1.082,0.934,1.18c0.491,0.492,0.393,0.838,0.785,1.426
                                c0.147,0.245,0.737,0.493,1.082,0.493c0.147,0,0.294,0.343,0.394,0.543c0.098,0.242,0.392,0.047,0.587,0.096
                                c0.294,0.097,0.394,0.392,0.495,0.588c0.096,0.246,0.834,0.147,0.982,0.195c-0.299,0.197-0.641,0.443-0.641,0.788h-0.295
                                c-0.096,0-0.835-0.49-0.932-0.592h-1.28c-0.735-0.145-1.866-0.686-1.866-1.226c0-0.199-0.392-0.294-0.491-0.393
                                c-0.983-0.985-1.866-2.358-3.49-2.358c-1.228,0-1.129,0.883-1.815,1.225c0.194,0.64-0.248,0.838-0.248,1.181h-1.868
                                c-0.854,0-1.29-0.68-1.866-1.131L432.459,164.352z"/>
                        </g>

                        <g @click='setRegion("America")' :class='{selected: regionSelected == "America"}' class="region america">
                            <path class="st4" d="M148.353,146.833c0,0.15,0.297,0,0.444,0c0.294,0,0.491-0.44,0.589-0.638v-0.345c-0.148,0-0.295,0-0.443,0
                                C148.65,145.851,148.353,146.343,148.353,146.833z"/>
                            <path class="st4" d="M164.62,160.692c-0.59,0-0.984,0.491-0.984,1.229c0,0.443,1.279,1.324,2.016,1.324
                                c0.589,0,1.327-1.129,1.327-1.571c0-0.098-0.147-0.445-0.343-0.445c-0.247,0-0.687,0.543-0.787-0.096
                                c-0.393,0-0.736-0.245-1.032-0.441C164.817,160.739,164.667,160.692,164.62,160.692z"/>
                            <path class="st4" d="M131.453,226.735h0.439v-1.472c0.05-0.15,0.197-0.247,0.197-0.543c0-0.391-0.147-0.785-0.59-0.785
                                c-0.293,0-0.588,1.472-0.588,1.472s0,0.391,0,0.644C130.91,226.245,131.155,226.685,131.453,226.735z"/>
                            <path class="st4" d="M184.914,168.01c-0.688-0.194-1.13,0.101-1.819-0.095c-0.783-0.247-2.309-1.621-2.75-2.359
                                c-0.54-0.244-1.377-0.588-2.309-0.787l-1.867,0.249c-0.984,0-1.179-0.789-2.211-0.789c-0.444,0-0.64,0.345-1.084,0.541h-0.344
                                c0-0.441,0.051-0.782-0.097-1.227c-0.098-0.396-0.539-0.297-0.933-0.542c-0.639-0.393-2.408-1.326-3.292-1.326
                                c-0.789,0-1.376,1.031-1.868,1.822c-0.149,0.24-0.344,0.584-0.54,0.584c-0.148,0-0.245-0.392-0.245-0.538h-1.181
                                c-0.098-0.493-0.344-0.884-0.835-1.133c-0.247-0.098-0.689-0.045-0.689-0.539c0-1.474,1.426-1.966,2.212-2.948
                                c0-0.493-0.637-0.493-0.883-0.982c-0.247-0.541-0.886-2.113-0.886-2.701c-0.835-0.2-1.864-1.821-2.651-1.821
                                c-0.098,0-0.199,0-0.295,0v-0.098c-0.496,0-1.28-0.392-1.623-0.539h-1.278c-1.328-0.25-3.392-0.098-3.736-1.474
                                c-0.246,0-0.637-0.102-0.737-0.3c-0.589-1.226-1.029-1.078-1.963-2.01h-1.378c-0.197-0.639-0.05-1.381-0.689-1.572
                                c-0.734-0.25-1.769-0.394-2.111-1.082v-0.393c0.146,0.097,0.537,0.05,0.735-0.1c0,0-0.884-0.15-1.376-0.15
                                c-0.148,0.099-1.177,0.099-1.228,0.25c-0.296,0.588-0.785,0.588-1.522,0.686c-0.393,0-0.983-0.098-0.983-0.541
                                c-0.442,0-1.868,0-2.655,0c-0.292-0.048-0.59-0.395-0.59-0.881c-0.737-0.152-1.841-0.429-2.038-1.219l-0.86-0.009
                                c0,0.586,0.736,0.343,0.932,0.932h-1.178c-0.444,0.297-1.229,0.198-1.229,0.933c0,0.592,0.591,0.933,0.591,1.525
                                c0,0.196-0.27,0.799-0.467,0.799c-0.542,0-0.812-0.949-0.812-1.488c0-0.442,0.295-1.278,0.443-2.013
                                c0.195,0,0.491-0.197,0.491-0.588c0-0.049-0.197-0.59-0.444-0.59c-0.637,0-0.781,0.733-1.421,0.93
                                c-1.229,0.394-2.016,0.595-3.197,0.983c-0.638,0.197-1.081,0.343-1.326,1.131c-0.098,0.394-0.149,1.032-0.444,1.23
                                c-0.292,0.195-0.787,0.341-0.787,0.833c-0.098,0-0.293,0.147-0.293,0.245c0,0.1,0,0.198,0,0.293c-0.344,0.102-0.196,0.102-0.591,0
                                c-0.072,0.116-0.209,0.224-0.365,0.336c0.172-0.183,0.308-0.339,0.329-0.381c-0.786-0.587-1.39-1.574-2.764-1.574
                                c-1.179,0-1.818,0.983-3.095,0.983c-0.839,0-0.839-0.933-1.378-1.179c-0.784-0.392-1.817-0.834-1.817-1.918v-2.996
                                c0.294-0.494,0.343-1.718,0.343-2.164v-0.734c-0.885-0.196-1.18-1.034-2.36-1.034c-1.721,0-3.047,0.294-4.666,0.294
                                c-0.296,0-0.591-0.195-0.591-0.536v-0.25c0.295-0.293,0.885-0.492,0.885-0.982v-2.358h0.392v0.343
                                c0.297-0.494,0.59-0.835,0.59-1.618c0-0.937,0.835-1.82,0.835-2.507v-0.444c-0.292,0-0.933-0.047-1.128-0.1
                                c-0.199-0.096-0.541-0.147-0.984-0.147c-1.328,0-2.947,0.491-2.947,1.917c0,0.737-0.837,1.378-1.034,1.967
                                c-0.049,0.196-0.293,0.786-0.689,0.786c-0.195,0-0.394-0.299-0.932-0.299c-0.983,0-1.769,0.693-2.703,0.693
                                c-0.393,0-0.393-0.443-0.835-0.591c-1.278-0.394-1.278-1.228-2.161-2.113c-0.343-0.345-0.982-1.031-1.18-1.428
                                c-0.196-0.441,0.049-0.731-0.146-1.175c-0.1-0.199-0.493-0.592-0.395-0.986v-2.748c0.149-0.736,0.492-1.082,0.492-1.969
                                c0-0.292-0.096-0.491-0.197-0.786l-0.028-0.115c0.044,0.006,0.086,0.011,0.128,0.016c0-0.119-0.168-0.275-0.226-0.408l-0.02-0.082
                                c0-1.473,0.637-2.802,1.72-3.195c0.489-0.196,0.931,0.148,1.326-0.244c0.59-0.588,1.276-1.819,2.702-1.819
                                c0.541,0,0.833,0.491,1.376,0.491c0.297,0,0.441-0.391,0.737-0.391c0.983,0,2.165,1.668,3.539,0.982
                                c-0.59-0.393-0.148-0.834-0.098-1.576c-0.294,0.15-0.196,0.15-0.687,0c0.833-0.291,1.867-0.193,2.65-0.392
                                c0,0.15,0.199,0.392,0.348,0.392c0.392,0,0.538-0.392,1.226-0.392c1.182,0,1.72,1.133,2.607,1.133c0.441,0,0.639-0.641,1.081-0.641
                                c2.162,0,1.572,2.999,2.358,4.569c0.393,0.787,0.883,1.032,1.474,1.821c0.295,0.44,0.246,1.226,0.983,1.226
                                c0.833,0,1.08-0.934,1.08-1.966c0-0.885-0.441-1.378-0.59-1.965v-0.244c0-0.035,0-0.069-0.002-0.102
                                c-0.058-1.496-1.371-2-1.371-3.584c0-3.573,2.943-4.505,5.244-5.807l-0.131-0.092c0.291-0.296,0.242-0.443,0.833-0.639
                                c0.391-0.098,0.649-0.479,1.226-0.589c0.272-0.054,0.429-0.348,0.578-0.543l-0.578-0.342v-0.392c0.443,0,0.665,0.046,0.766-0.495
                                c-0.248-0.048-0.483-0.086-0.678-0.429c0.246,0,0.65-0.208,0.65-0.599v-0.492h-0.341c-0.297-0.294,0-0.246,0-0.737
                                c0-0.493-0.249-1.378-0.249-1.721c0,0-0.048-0.39,0.049-0.639c0.102,0.491,0.542,1.281,0.542,1.625v0.538
                                c0,0.1,0.1,0.296,0.197,0.296c0.148,0,1.132-1.719,1.132-2.013c0-0.394-0.343-0.543-0.445-0.936l0.246-0.244
                                c0.096,0.098,0.199,0.392,0.344,0.392c0.345,0,1.278-1.721,1.376-2.112c0,0-0.441-0.343-0.441-0.59
                                c0-0.147,0.145-0.295,0.292-0.346c0.148,0,0.299,0.052,0.447,0.052c0.685,0,1.176,0,1.865-0.245c-0.344-0.247-0.787,0-1.229,0
                                h-0.491c0.983-0.64,2.652-0.344,3.638-1.082c0.197,0.197,1.032,0,1.621-0.149v-0.342c0,0-0.443,0.342-0.54,0.342
                                c-0.295,0-0.492-0.638-0.492-0.884c-0.146-0.048-0.197-0.294-0.197-0.442c0-1.864,1.669-2.407,2.458-3.194
                                c0.492-0.245,0.541,0,1.129,0c0.689,0,1.23-0.296,1.575-0.836c-0.083-0.105-0.153-0.215-0.214-0.331l-0.032-0.161v-0.146
                                c0.099,0.048,0.344,0,0.492,0c0.295,0,1.522,0.197,1.965-0.096c0.441-0.297,0.441-0.934,1.179-0.985
                                c0,0.688-0.049,0.735,0.737,0.735c-1.133,0.1-2.898,0.887-2.898,1.967c0,0.441,0.687,1.23,0.982,1.23
                                c0.392,0,1.303-1.32,2.286-1.616c0.247-0.047,3.611-1.332,3.611-1.727c0-0.539-1.278-0.59-1.572-0.59
                                c-1.425,0-3.686-1.376-3.686-2.605c0-0.293,0.392-0.687,0.492-1.18c-0.248,0-0.787-0.145-1.23-0.145
                                c0.295-0.393,2.066-0.641,2.066-1.623c0-0.935-1.524-0.735-2.556-0.735c-2.161,0-3.146,1.52-4.616,1.717
                                c0.638-1.325,1.669-0.884,2.75-1.966c0.688-0.687,0.197-1.129,1.228-1.475c1.868-0.588,2.949-0.241,4.815-0.241
                                c2.46,0,3.801,0.803,5.259-1.083c0.709-0.917,4.666-1.132,4.666-2.702c0-1.13-0.392-1.623-0.833-2.359
                                c-0.395,0,0.173-0.484-0.395-0.736v-0.593c-1.129,0.641-3.488,1.475-4.667,1.475c-0.148,0-0.44-0.099-0.49-0.195
                                c0.983-0.543,3.711-0.915,4.005-1.849c0.05-0.246-0.369-0.315-0.42-0.559c-1.179,0.146-3.684-0.396-3.684-2.063v-0.149
                                c-1.083,0-1.721-0.688-2.359-1.179c0.246-0.145,0.737-0.297,0.737-0.886c0-0.49-0.441-0.884-0.344-1.374
                                c-0.441-0.147-2.037-1.162-1.621-2.063c0.511-1.116-1.574-1.378-1.72-2.703c-0.147,0-0.638-0.442-0.837-0.64
                                c-0.343,0.248-1.127,0.688-1.127,1.376c0,0.738-1.574,2.556-2.804,2.556c-0.881,0-1.031-1.081-1.622-1.081
                                c-0.833,0-1.176-2.41-1.322-3.438c-0.199-1.38-2.853-0.396-2.853-2.213c-1.377-0.196-1.671-1.866-3.047-1.866
                                c-0.639,0-0.639,0.491-1.374,0.491c-1.427,0-2.311-0.836-3.689-0.836c-0.735,0-1.472,0.345-1.472,1.226
                                c0,0.494,1.735,1.21,0.146,2.213c-0.622,0.395,0.896,1.479,0.985,2.212c0.173,1.453-1.217,1.821-1.858,2.07
                                c1.132,1.918,2.841,2.55,2.841,5.794c0,0.885-2.95,3.093-3.933,3.291c1.707,0.866,1.033,3.539,1.721,4.374
                                c-0.197,0.296-0.837,0.641-0.887,1.178c-0.588,0-1.013,0.963-1.474,0.589c-1.215-0.974-2.946-2.505-2.946-3.93
                                c0-1.571,0.292-2.802-1.082-3.685h-3.195c-1.816-1.325-3.881-2.115-6.043-2.951c-0.394-0.144-1.526-0.981-2.211-0.981
                                c-0.739,0-1.08,0.737-1.819,0.737c0-1.178-0.787-3.929-1.868-3.929c-0.492,0-0.738,0.392-1.227,0.491c0.196-0.591,0-0.789,0-2.213
                                c0-1.967,0.588-3.144,1.719-4.274c0.393-0.393,1.229-2.31,1.622-2.456c0.834-0.295,2.309,0.197,2.309-0.983
                                c0-0.738-1.881-0.795-2.569-1.188c0.443,0.146-1.045-0.86,2.95,0.367c1.033,0.318,0.407-1.048,0.995-1.048h1.084
                                c1.177,0,1.817-1.227,2.601-1.816v-0.739c-1.67-0.391-3.341-0.147-4.077-1.62h0.884c0.636,0.392,1.277,1.126,2.21,1.126
                                c0.788,0,2.215-1.029,2.215-1.618c0-0.196-0.74-1.08-0.349-1.08c1.083,0,1.476,0.982,2.163,0.982c0.443,0,0.739-0.394,1.623-0.394
                                c-0.244-0.295-0.01-1.164,0.591-0.244c0.322,0.491,2.85-1.673,2.85-2.309c0-0.443-1.13-0.933-1.13-1.23
                                c0-0.342-0.665-1.124,0.884-1.869c0.197-0.442,0-0.39,0-1.225c-1.573,0-1.18-1.229-3.195-1.229
                                c-0.442-0.052-0.788-0.345-1.229-0.345c-0.59,0-1.228,0.394-1.228,0.986c0,0.639,0.738,0.881,0.738,1.323
                                c0,0.491-1.133,0.491-1.378,0.983c-0.392,0.838-0.26,1.494-0.97,2.402c-1.107,1.411-2.566-0.879-2.566-1.42
                                c0-0.636,0.834-0.491,0.834-1.227c0-0.343-1.427-1.722-1.819-1.722c-1.426,0-0.44,2.116-1.963,2.116
                                c0-0.838-0.099-1.425-1.132-1.918c0.15-0.248,0.247-0.393,0.491-0.589c-0.784-0.493-1.867,0-2.458-0.739
                                c0.344-0.196,1.231-0.539,1.231-1.178c0-0.934-1.452-0.897-1.475-1.625c-0.05-1.531-1.13-2.509-3.046-2.554
                                c-0.935-0.023-0.542,0.589-0.493,1.082c-0.587,0.147-1.373,0-1.373,0.739c0,0.492,0.393,0.733,0.393,1.226
                                c0,0.491-0.639,0.444-0.639,1.132c0,1.623,3.338,0.639,3.338,2.307c0,0.299-0.391,1.474-0.391,1.474
                                c0.343-0.196,0.637-0.292,1.032-0.491c0.638,2.018-2.605,1.032-2.605,3.441c-1.613,0.436-0.342-1.179-0.735-1.719
                                c-0.148-0.247-1.474-0.588-2.212-0.588c-0.884,0-1.229,0.541-1.229,1.574c-0.836-0.1-1.377,0-4.176,0
                                c-2.654,0-5.751-0.936-6.389-2.947c-0.886,0.24-3.833,0.29-3.833,1.374c0,0.342,0.228,0.53,0.621,0.53
                                c0.789,0,2.081-0.386,2.672-0.774c0.196,1.618-2.31,0.293-2.31,1.965c0,0.341,0.541,1.47,0.295,1.47
                                c-0.983,0-1.178-1.227-2.017-1.617l-0.735-0.347H77.2c-0.983,0-1.622,0.395-2.703,0.441c-1.947,0.092-1.947-0.62-1.947-0.769
                                c0-0.442,1.507-0.26,1.8-0.556c-1.369-1.901-1.123-1.593-3.933-1.374c-2.989,0.232-4.473-2.213-7.467-2.213
                                c-0.935,0-1.329,0.638-2.114,0.638c-0.54,0-1.083-0.934-1.182-1.326c-0.933,0.197-0.933,1.278-1.916,1.278
                                c-0.934,0-2.259-2.212-2.553-2.212c-0.984,0-0.639,1.425-1.671,1.425c-0.393,0-0.739-0.295-0.888-0.688
                                c-1.52,0.345-2.061,0.443-3.437,0.981c-0.541,0.101-1.828,1.043-2.258,0.642c-1.287-1.203-1.062,0.327-1.524,0.341
                                c-3.202,0.115-0.986,1.281-1.427,1.281c-1.228,0-3.434-0.244-5.454-1.475c-0.083-0.05-0.688-0.49-0.688-0.64
                                c-0.342,0.2-0.098,0.102-0.589,0v0.051c-1.229-0.393-1.669-0.984-3.095-0.984c-0.886,0-1.428,0.342-2.313,0.342
                                c-1.523,0-4.568-0.241-5.549-1.225h-3.786c-0.689-0.344-0.884-0.592-1.473-0.984h-2.456c-0.443-0.148-0.594-0.293-1.132-0.489
                                c-0.247,0.392-0.736,0.734-1.229,0.636c0.15-0.244,0.199-0.344,0.441-0.636c-0.636-0.199-0.882-0.739-1.52-0.739
                                c-0.839,0-1.328,1.08-2.117,1.375c-0.686,0.245-1.131-0.096-1.815,0.098c-2.605,0.835-4.816,2.705-6.288,4.671H2.212
                                c-0.05,0.147-0.737,1.375-0.737,1.375c0,0.342,2.014,0.736,2.602,1.083c0.492,0.291,1.082,1.768,1.474,1.961
                                c1.427,0.687,2.459,0.789,2.95,2.361c-0.588,0.149-0.688,0-1.375,0c-1.033,0-1.82,0.443-1.82-1.129H4.423
                                C3.245,50.912,1.229,51.551,0,52.288c0.246,0.491,0.687,0.786,1.475,0.786c-0.051,0.149-0.147,0.342-0.147,0.491
                                c0,0.442,0.59,0.641,0.639,1.377h2.603c0.737-0.146,1.082,0.345,2.066,0.345c1.031,0,1.324-0.836,2.112-0.836
                                c0.294,0,0.294-0.052,0.735,0v0.738c-0.341-0.199-0.245-0.248-0.735,0.047c0.048,0.148,0.835,0.835,0.835,1.181
                                c0,0.639-3.344,1.965-3.784,1.965c-1.033,0-3.685,1.228-3.685,2.701c0,0.787,1.573,1.032,1.964,2.113
                                c-0.246,0-0.49,0.15-0.737,0.15c0.346,0.441,2.655,2.258,3.295,2.258c0.196,0,0.589-0.833,0.983-0.833
                                c0.442,0,0.392,0.59,0.392,0.737v0.983c0,0.392,0.194,1.228,0.491,1.228c0.196,0,0.442-0.392,0.737-0.392H9.83
                                c0-0.197-0.049-0.443,0.047-0.739c0.836,0.64,1.18,0.934,2.36,1.378c0.047-0.346,0.195-0.493,0.344-0.885
                                c0.489,0.147,0.735,0.392,1.327,0.392c0.735,0,1.08-0.343,1.815-0.637h0.739c-1.179,0.736-1.179,2.21-2.31,2.947
                                c-1.179,0.786-3.095,1.622-3.929,2.702c-0.589,0.738-1.526,0.246-2.358,0.738c-0.543,0.294-0.984,1.278-1.578,1.62
                                c-0.831,0.49-1.766,0.098-2.356,1.033c2.064,0.591,3.047-1.475,4.816-1.767c1.424-0.197,3.193-0.74,3.93-1.475
                                c0.786-0.79,2.459-1.28,3.196-2.214c0.389-0.49,0.588-0.982,1.229-1.227c1.126-0.443,2.21-0.837,2.21-1.966V66.49
                                c0-1.62,3.243-4.325,4.422-4.325h0.59c0,0.147-0.049,0.345-0.049,0.491c-1.426,0.441-2.164,1.282-2.164,2.36
                                c0,0.246-0.193,0.246,0.05,0.588c-0.1,0.049-0.395,0.099-0.395,0.395c0,0.197,0.295,0.346,0.493,0.346
                                c1.131,0,4.274-1.773,4.914-2.752c-0.246-0.197-0.345-0.347-0.489-0.688c0.439-0.248,1.424-0.492,2.013-0.739
                                c0.834,1.722,2.996,1.916,4.62,2.457h2.701c1.476,0.835,3.145,0.786,4.424,1.722c0.587,0.443,1.277,1.866,2.309,1.866
                                c0.392,0,0.588-0.492,0.738-0.884c0.393,0.392,0.736,0.735,1.474,0.735c0.197-0.343,0.639,0.099,0.884,0.344
                                c-0.147,0.248-0.492,0.443-0.492,1.033c0,0.296,0.54,0.738,0.736,0.738c0.642,0,0.394-0.591,1.329-0.641v0.641
                                c0,0.392-0.737,0.44-0.737,0.832c0,0.297,0.197,0.739,0.883,0.739c0.884,0,1.476,0,1.476,0.737v0.738
                                c0,0.541,1.228,1.474,2.064,1.474c0.247,0,0.247-0.05,0.493-0.05c0,0.246,0.293,0.396,0.293,0.687c0,0.641-0.198,1.967,0.493,2.167
                                c0.391,0.095,0.786,0.095,1.079,0.391c0.441,0.441-0.096,1.082,0.492,1.473c0,0,1.278,0.786,1.326,0.936
                                c0.344,1.129,0.443,2.456,2.113,2.456c0.247,0.985,1.966,0.343,2.358,1.621c0.151,0.492,1.378,0.587,1.868,1.08
                                c0.096,0.098,0.342,0.202,0.538,0.299c0.199,0.492,0.641,0.638,0.641,1.128c0,0.442-0.443,1.031-0.687,1.329
                                c-0.248-0.446-0.689-0.837-1.425-0.837c-0.198,0-0.737,0.047-0.737,0.344c0,1.08,1.226,1.523,1.226,2.6
                                c0,1.525-1.291,4.313-0.835,5.506c0.474,1.237-0.047,3.049,0.493,4.129c-0.294,0.196-0.737,0.507-0.493,0.935
                                c0.842,1.457,1.919,3.633,2.95,3.783c-0.248,2.013,1.129,2.604,1.817,4.323c0.147,0.392,0,0.934,0.394,1.084
                                c1.768,0.685,4.273,1.717,5.011,3.191h-0.1v0.342c0.346,1.134,0.787,1.43,1.23,2.312c0.292,0.591,0.259,1.959,0.841,2.329
                                c0.864,0.559,1.67,1.608,2.397,2.645c0.43,0.612-0.731,0.432-0.977,0.432c0.492,0.737,1.571,0.587,2.26,1.278
                                c0.49,0.493,0.884,1.033,1.422,1.57v1.282c0.15,0.242,0.2,0.242,0.495,0.441c0.539,0.344,1.016,1.508,1.898,1.508
                                c0.051,0.199,0.705,1.049,0.802,1.049c0.148,0,0.345-0.494,0.345-0.742c0-0.389-0.588-1.225-0.983-0.831
                                c-0.882-0.592-1.178-1.867-1.571-3.05c-0.343-1.03-1.329-1.571-1.67-2.701c-0.247-0.836-0.344-1.328-1.033-2.013
                                c-0.49-0.494-1.135-0.495-1.478-1.235c-0.395-0.831-0.881-1.469-0.881-2.5c0-0.149-0.049-0.444,0.098-0.639
                                c0.641,0.639,1.426,0.588,2.261,0.982c0.393,0.195,0.296,1.279,0.74,1.72c0.441,0.439,0.293,1.277,0.733,1.72
                                c0.543,0.541,1.032,1.083,1.673,1.719c0.145-0.146,0.097-0.098,0.539,0c0,1.18,1.522,1.28,1.965,2.162
                                c0.248,0.543-0.097,1.033,0.296,1.425c0.491,0.49,1.278,0.69,1.77,1.181c1.078,1.075,2.751,2.506,3.337,4.275
                                c0.101,0.343,0.495,0.49,0.495,1.132c0,0.535-0.495,0.833-0.495,1.373c0,0.441,0.3,0.983,0.74,0.983
                                c0.148,0.639,1.671,0.98,2.26,1.572c0.151,0.149,0.247,0.197,0.542,0.343c0.638,0.295,0.983,0.197,1.622,0.493
                                c0.687,0.344,0.833,0.935,1.522,1.129c0.541,0.147,0.884-0.147,1.428,0.099c0.489,0.246,2.163,1.424,2.75,1.424
                                c0.395,0,0.739-0.099,1.13,0.099c0.196,0.098,0.591,0.441,0.983,0.441c0.835,0,0.981-0.983,1.868-0.983
                                c0.392,0,0.687,0.151,1.08,0.395h0.295c1.329,0.637,1.525,1.767,3.048,2.506c0.984,0.493,1.673,0.243,2.604,0.689
                                c0.637,0.291,1.132,0.835,2.065,0.835c0.341,0,0.588-0.247,0.934-0.247h0.293c0.297,1.18,1.131,1.622,1.916,2.411
                                c0.245,0.242,0.491,0.343,0.639,0.833c0.245,0.739-0.246,1.428,0.492,1.918h0.493c-0.1-0.1-0.1-0.294,0-0.442
                                c0.046,0,1.717,1.031,1.866,1.325c0.197,0.393,0.197,0.883,0.884,1.033c0,0.1,0.149,0.294,0.245,0.294
                                c0.341,0,1.13-0.294,1.474,0.052c0.394,0.39,0.884,1.326,1.669,1.326c0.394,0,0.639-0.249,0.739-0.739
                                c-0.1-0.05-0.396-0.247-0.396-0.442c0-0.296,1.379-1.278,1.724-1.278c0.541,0,0.687,0.636,1.225,0.886
                                c-0.097,0.194-0.293,0.293-0.293,0.491c0,0.145,0.454,0.284,0.551,0.379c0.019,0.01,0.066-0.015,0.122-0.054
                                c-0.048,0.096-0.083,0.198-0.083,0.314c0,0.881,0.885,0.736,0.885,1.769c0,0.491-0.246,0.685-0.246,1.276
                                c0,0.391,0.294,0.69,0.294,1.08c0,0.084,0,0.166,0,0.249l-0.195,0.39c0,0.147,0.246,0.396,0.49,0.494
                                c-0.294,1.327-1.57,0.982-2.113,2.062c-0.394,0.789-0.738,2.113-2.065,2.113c0.209,2.165-1.031,1.969-1.031,3.096
                                c0,0.687,0.096,1.67,0.785,1.67c0.346,0,0.298-0.246,0.738,0.05c-0.196,0.787-1.083,0.984-1.523,1.72
                                c-0.199,0.197-0.542,0.886-0.542,1.475c0,0.886,0.69,1.866,1.229,2.407c0.197,0.196,0.592,0.097,0.74,0.395
                                c0.146,0.341,0.245,1.029,0.587,1.374c0.147,0.146,0.491,0.049,0.638,0.344c0.443,0.933,0.738,1.574,1.081,2.701
                                c0.344,1.08,1.328,1.968,1.722,3.145c0.196,0.69,0.931,1.082,0.931,1.867c0,0.588-0.196,1.133,0.2,1.523
                                c0.884,0.884,1.568,1.575,2.947,2.016c0.687,0.197,0.982,0.391,1.572,0.985c0.54,0.539,1.082,0.441,1.819,0.782
                                c0.882,0.447,1.669,1.183,2.112,2.36c0.148,0.539,0.394,3.439,0.394,3.933c0,0.788-0.444,2.113-0.444,2.803
                                c0,0.634,0,0.634,0,2.158c-0.097,0.491-0.342,1.378-0.342,1.721c0,0.144,0,0.295,0,0.441c0,1.672-1.131,2.901-1.131,4.769
                                c0,0.786-0.541,1.475-0.247,2.358l0.049,3.684c-0.292,1.18-0.292,2.012-0.635,3.147c-0.051,0.196-0.298,0.15-0.447,0.341
                                c-0.244,0.342-0.34,1.035-0.539,1.377c-0.441,0.687-1.129,1.821-1.129,2.949c0,0.346,0.05,1.722,0.246,1.722
                                c0,0,0.295,0.242,0.295,0.541c0,0.146-0.149,0.293-0.49,0.293c-0.148,0.881-0.494,1.277-0.494,2.213
                                c0,0.539,0.295,1.669,0.689,1.669c0.343,0,0.393-0.394,0.737-0.542c0.146-0.047,0.297,0,0.441,0v0.743l-1.62,7.271
                                c-0.739,0-0.393-0.889-1.279-0.889c-0.245,0-0.442,0.249-0.442,0.543h-0.048v0.492c0.099,0.54,0.688,1.329,0.688,1.72
                                c0,0.786-1.133,1.129-1.133,2.015c0,0.833,0.739,2.555,0.739,3.635c0,0.197,0,0.345,0,0.687c0,1.033,1.327,0.837,1.327,2.017
                                c0,0.293-0.197,0.588-0.343,0.836c0.146,0.099,0.244,0.197,0.394,0.247c-0.051,0.44-0.934,0.393-0.934,0.835
                                c0,0.098,0.292,0.34,0.44,0.493c0.639,0.637,1.033,1.323,2.41,1.323c0.045,0.245,0.147,0.641,0.539,0.641c0.345,0,0.539,0,0.935,0
                                c0,0.884,1.08,0.884,1.817,1.129c0.787,0.248,0.392,1.572,1.525,1.572c0.49,0,0.881,0,1.374,0c0.147,0,0.343-0.199,0.491-0.199
                                c0.244,0,0.198,0,0.542,0c0.441,0,1.671-0.93,2.406-0.93c0.295,0,0.588,0.142,0.787-0.149c-1.326-0.637-2.652-1.082-3.734-2.162
                                c-0.639-0.637-0.59-2.898-1.473-3.392c-0.296-0.05-0.737-0.637-0.737-1.13c0-0.932,0.247-1.62,0.836-2.213
                                c0.443-0.442,1.227-0.142,1.573-0.831c0.294-0.642,0.147-1.279,0.541-1.673c0.687-0.684,1.914-0.834,1.914-2.208
                                c0-1.525-2.209-0.84-2.209-2.755c0-0.835,0.687-1.525,1.471-1.72c0.347-0.049,1.231-0.098,1.329-0.638
                                c0.293-1.229,0.293-2.113,1.179-2.998c-0.244-0.098-0.539-0.197-0.539-0.736c0.492,0,0.687,0.441,1.278,0.441
                                c0.1,0,0.492-0.344,0.492-0.637c0-0.246-0.244-0.297-0.443-0.491c-0.15,0.046-0.44,0.343-0.689,0.343
                                c-0.439,0-1.03-1.278-1.03-1.966c0-0.246,0.145-0.592,0.491-0.592c0.98,0,1.179,0.688,2.21,0.688c0.343,0,1.13-0.343,1.13-0.588
                                c0-0.122,0-0.335,0-0.491c0.344-0.69-0.148-2.165,0.492-2.802c0.443-0.444,2.065,0.096,2.848-0.146
                                c1.182-0.392,2.214-0.151,3.048-0.983c0.493-0.496,0.839-0.806,1.28-1.494c0.246-0.393,0.245-0.716,0.245-1.31
                                c0-0.538-0.787-0.538-0.937-1.081c-0.197-0.588-0.197-1.131-0.588-1.522c-0.146-0.148-0.882-0.391-0.882-0.831
                                c0-0.153,0.244-0.25,0.391-0.25c0.737,0,1.377,0.591,2.063,0.591c0.345,0,1.441,0.536,2.23,0.536c2.26,0,2.637-2.452,3.619-3.928
                                c0.834-1.277,1.817-1.422,2.705-2.748c0.392-0.641,0.344-0.983,0.686-1.623v0.048c0.491-1.033,0.984-1.868,1.966-2.358v-2.31
                                c0-0.932-0.096-1.62,0.542-2.263c0.392-0.391,1.031-0.391,1.426-1.032c0.884-1.373,2.113-0.684,3.292-1.866
                                c0.149-0.147,0.198-0.39,0.492-0.541c0.737-0.342,1.474-0.293,2.408-0.293c0.833,0,1.867-0.687,2.16-1.278
                                c0.099-0.197,0.05-0.787,0.247-1.08c0.738-0.982,0.983-1.475,1.717-2.606c0.1-0.195,0.251-1.177,0.251-1.673
                                c0-0.391,0.44-0.883,0.636-1.127c0.047-0.149,0.246-0.344,0.246-0.589c0-0.639,0.335-1.46,0.335-2.342
                                c0-1.181-0.726-2.77,0.008-3.507c0.492-0.491,1.033-0.59,1.526-1.324c0.294-0.444,0.508-0.563,0.802-1.007
                                c1.326-2.011,3.323-2.976,3.323-6.612C186.143,169.342,185.899,168.313,184.914,168.01z M123.295,95.531
                                c1.082-0.345,3.39-0.933,4.619-0.933c-0.014,1.848-3.146,1.374-4.573,1.374c-0.245,0-0.245,0.05-0.589,0.05l0.146,0.274
                                C122.64,95.922,122.345,95.845,123.295,95.531z M122.446,97.603c-1.152,0.564-2.756,1.319-3.773,1.319
                                c-0.491,0-0.688-0.738-0.688-1.229c0.747,1.264,1.388,0.137,1.427,0.097C120.255,96.949,122.301,97.686,122.446,97.603z
                                M109.24,87.375h-0.09l1.367-0.246c0.492-0.246,0.296-1.033,0.787-1.033c0.59,0,0.981,0.884,1.181,1.082
                                c0.097-0.197,0.048-0.59,0.048-0.688h0.393c0.295,0,2.358,1.67,2.458,1.865c0.293,0.591,0,1.083,0.392,1.474
                                c1.132,1.132,2.656,0.887,3.882,1.674h1.279c0.929,0.28,1.957,2.208,0.833,2.208c-0.59,0-1.178-0.636-1.373-0.834
                                c-0.198,0.788-0.639,3.145-1.327,3.145c-0.492-0.393-0.492-0.737-0.688-1.521c-0.493,0.146-0.395,0.341-0.983,0.341
                                c0.147-0.685,0.538-1.132,0.538-1.817c0-0.592-1.817-1.475-2.209-1.475c-0.811,0,0.012,1.192-1.778,1.303
                                c-1.148,0.067,0.227,5.823-1.517,5.823c-2.407,0-0.359-5.254,0.1-5.848c-0.492,0-0.687,0.394-1.181,0.689
                                c0.099-0.982,2.065-2.359,3.145-2.359c0.393,0,1.475,0.243,2.21,0v-0.59c-1.031,0-1.618-0.64-2.798-0.64
                                c-0.542,0-0.737,0.492-1.376,0.492c-0.344,0-0.787-0.245-0.836-0.592h-0.984c0.199-0.391,0.543-0.538,0.543-1.226
                                c-0.739,0.147-1.623,1.082-2.164,1.082c-0.252,0-1.516,1.388-2.062,0.143c-0.895,0.134-0.132,0.347-1.623,0.347
                                C106.538,88.75,107.912,88.259,109.24,87.375z"/>
                            <path class="st4" d="M213.562,50.472c0-0.148,0.149-0.345,0.296-0.446c-0.441,0-0.738,0.251-1.178,0.251
                                c-0.491,0-0.887-0.053-0.887-0.642c-0.195,0.149-0.195,0.098-0.54,0c-0.099,0.198-0.196,0.344-0.196,0.738
                                c-0.442-0.443-1.032,0.145-1.67,0.145c-0.444,0-0.835-0.145-1.575-0.145c0,0.145-0.05,0.345,0,0.491
                                c-0.196-0.098-0.294-0.248-0.687-0.347l-1.179,0.299c-0.294,0-0.443-0.248-0.641-0.443c-0.146,0.195-0.096,0.295,0,0.637
                                c-0.243,0.05-0.539,0.3-0.88,0.3c-0.346,0-0.791-0.641-0.739-0.985c-0.053-0.298-0.248-0.443-0.295-0.741c-0.148,0-0.246,0-0.394,0
                                c-0.393,0-0.494,0.544-0.885,0.544c0,0.149,0,0.245,0,0.39c-0.293-0.145-0.539-0.24-0.981-0.097
                                c0.097,0.538,1.917,1.081,2.503,1.081c0,0.149,0.053,0.295,0.198,0.395c-0.537,0.195-1.817,0.44-2.555,0.44
                                c0.492,0.493,1.327,0,2.114,0.199c-0.096,0.539-0.246,0.835-0.246,1.473c-0.148,0-0.295,0.149-0.441,0.246
                                c1.08,0,1.472,0.688,2.161,0.886c1.326,0.441,2.604,0.882,4.423,0.882c1.67,0,2.602-0.833,3.785-1.227
                                c0.637-0.199,1.817-1.08,2.209-1.77l0.097-0.101C215.38,51.55,213.562,51.896,213.562,50.472z"/>
                            <path class="st4" d="M130.516,132.189c-0.344-0.146-0.589-0.835-1.13-0.983l-0.736-0.049c-0.049-0.094,0-0.242,0-0.343
                                c-1.131,0-2.359-0.54-3.048-1.229c-0.095-0.097-0.492-0.588-0.835-0.588c-1.622,0-2.655-1.231-4.817-1.231
                                c-2.212,0-3.245,0.839-4.03,2.166h0.444c0.492-0.491,1.817-1.181,2.751-1.181c0.246,0,0.492-0.049,0.688,0.151
                                c-0.148,0.145-0.196,0.34,0.096,0.54h1.427c0.69,0,1.031,0.733,1.722,0.733c0.391,0,0.638-0.146,1.029,0.051
                                c0.198,0.096,0.148,0.44,0.544,0.44c0.146,0.742,0.932,0.69,1.669,1.034c-0.048,0.247-0.248,0.441-0.492,0.441
                                c0,0.1,0,0.199,0,0.296c0.934,0,1.72,0.343,2.852,0.343C129.236,132.779,130.172,132.779,130.516,132.189z"/>
                            <path class="st4" d="M118.379,129.638v0.341c-0.147,0-0.444,0.097-0.444,0.442c0,0.098,0.639,0,0.689,0
                                c0.098-0.248,0.048-0.49,0-0.739l-0.147-0.045H118.379z"/>
                            <path class="st4" d="M125.259,134.892c0,0.397,0.396,0.737,0.784,0.737c0.593,0,1.035-0.196,1.623-0.295
                                c-0.047-0.195-0.147-0.343-0.442-0.442l0.101,0.099c-0.296-0.099-0.983-0.395-1.574-0.395
                                C125.554,134.596,125.259,134.697,125.259,134.892z"/>
                            <path class="st4" d="M140.738,134.795c0,0.495,0.638,0.539,1.229,0.539c0.293,0,0.639,0,0.787-0.297
                                c-0.147-0.291-1.327-0.54-1.475-0.54C141.132,134.497,140.738,134.545,140.738,134.795z"/>
                            <path class="st4" d="M130.615,134.596c0,0.296,0.295,0.738,0.59,0.788c0-0.146,0.049-0.244,0.049-0.393
                                c0.244,0.149,0.244,0,0.589,0c0.59,0,1.765-0.146,2.213,0.149c0.245,0.15,0.145,0.638,0.586,0.638c0.541,0,0.496-0.885,1.132-0.885
                                c0.246,0,0.196,0.248,0.492,0.248c0.342,0,0.54-0.197,0.885-0.394h1.816c-0.343-0.492-0.834-0.687-1.325-1.083l0.244,0.199
                                c-0.88-0.443-1.179-1.279-2.747-1.279c-0.543,0-0.838,0.197-1.475,0.197c-0.442,0-0.739-0.343-1.181-0.343
                                c-0.149,0-0.394,0.197-0.394,0.343c0,0.293,0.345,0.545,0.638,0.545c0,0.389,0.05,0.735,0.344,1.03
                                c-0.588,0.294-1.078,0.046-2.212,0.046C130.812,134.451,130.714,134.596,130.615,134.596z"/>
                            <path class="st4" d="M124.817,125.066c0,0.394,0,0.932,0.49,0.932c0.099,0,0.247-0.149,0.247-0.247
                                c0-0.341-0.147-0.636-0.539-0.685C124.963,125.066,124.817,125.018,124.817,125.066z"/>
                            <path class="st4" d="M124.767,122.263c-0.246,0-0.588,0.199-0.689,0.395c0.298,0,0.787,0.048,1.279,0
                                C125.259,122.509,125.11,122.263,124.767,122.263z"/>
                            <path class="st4" d="M126.291,122.853c-0.048,0.147,0.097,0.443,0.247,0.393L126.291,122.853z"/>
                            <path class="st4" d="M132.238,130.665v-0.44c-0.198,0.294-0.59,0.541-0.984,0.44c0,0.101,0,0.2,0,0.298c0,0.047,0.146,0,0.199,0
                                C131.697,130.963,132.04,130.865,132.238,130.665z"/>
                            <path class="st4" d="M49.14,75.189c0.247,0,0.395-0.147,0.395-0.493c0-1.081-1.33-1.228-1.182-2.554
                                c-1.031-0.198-1.277-0.198-1.277,0.984c0.59-0.395,0.294-0.15,1.081,0c-0.146,0.242,0,0.637,0,0.983
                                C48.157,74.154,48.7,75.189,49.14,75.189z"/>
                            <path class="st4" d="M45.603,70.273c-0.097,0.05-0.493,0.147-0.493,0c-0.247-0.788,0-0.788,0-2.063c-0.689,0.394-0.247,0-1.131,0
                                c-0.196,0-0.588,0.099-0.588,0.493c0,0.736,0.588,1.375,1.326,1.474c0,0.785,0.196,1.374,0.788,1.965
                                C45.998,71.649,45.506,70.961,45.603,70.273z"/>
                            <path class="st4" d="M91.057,42.018c0.885,0,1.082,0.194,1.965-0.394c-0.932-0.737-5.06-1.819-5.06-3.39
                                c0-1.278-1.325-2.411-1.325-4.177c0-0.442,1.08-0.886,1.08-1.623c0-0.737-0.834-1.079-1.72-1.079c-0.787,0-1.475,0.342-1.475,0.835
                                c0,0.15,0,0.342,0.051,0.49c-0.788,0.392-0.493,0-1.38,0c-0.39,0-0.882,0.244-0.882,0.643c0,0.441,0.738,2.748,1.13,3.39
                                c-0.248,0.193-0.248,0.391-0.638,0.391c-0.883,0-0.539-1.228-0.834-1.818c-0.246-0.539-0.935-1.179-1.623-1.376h-1.08
                                c0.098,0.295,0.195,0.441,0.246,0.884c-0.542,0.147-0.935,0.244-1.474,0.344c-0.348-0.882-0.837-1.475-2.213-1.475
                                c-0.787,0-1.13,0.641-1.523,0.884c-0.198-0.292-0.146-1.717-0.837-1.717c-1.769,0-6.241,1.766-6.241,4.176
                                c0,0.244,0.935,0.981,1.477,0.981c0.638,0,0.931-0.147,1.718,0.05c-0.538,0.344-1.965-0.05-1.965,1.034
                                c0,1.375,2.703,0.882,3.787,0.882c0.489,0,1.669,0,2.603,0c0.489,0,0.882,0.296,1.229,0.493c-0.592,0.048-0.886,0.099-1.622,0.099
                                c-2.063,0-3.59,0.243-4.768,1.129c0.343,0.59,1.228,1.719,2.309,1.719c0.688,0,2.163-0.148,2.702,0.246
                                c0.59,0.443,0.049,1.23,0.885,1.23c1.328,0,2.213,0,4.42,0c1.035-0.736,2.265-0.345,3.54-0.983
                                c0.738-0.342,0.393-0.884,1.278-0.884c1.425,0,2.112,1.475,4.029,1.475c1.232,0,2.359,0.047,2.802-0.885
                                c-0.196-0.148-0.443-0.297-0.492-0.687c-0.196,0-0.737,0-0.737-0.394C90.418,42.165,90.665,42.018,91.057,42.018z"/>
                            <path class="st4" d="M60.1,37.25c0.146,0.246,0.491,0.984,0.737,0.984s0.391,0.491,0.735,0.491c0.394,0,3.786-1.229,3.786-1.621
                                c0-1.133,2.161-3.194,3.687-3.686c0.489-0.147,3.092-0.542,3.092-1.078c0-1.134-2.406-2.117-3.437-2.117
                                c-0.638,0-1.231,0.593-1.675,0.149c-1.081-0.098-1.57-0.689-2.651-0.984c-0.884-0.247-1.375,0.344-1.967,0.344
                                c-1.179,0-1.718-0.491-2.947,0c0.048,0.541,0.734,1.325,0.734,1.719c0,1.771-2.456,3.145-2.456,4.815
                                C57.739,36.859,59.706,36.713,60.1,37.25z"/>
                            <path class="st4" d="M72.119,24.336c-0.045-0.002-0.076-0.01-0.127-0.01c-0.394,0-0.788,0.249-0.983,0.542
                                c0.163,0.105,0.335,0.176,0.514,0.231c-0.127-0.019-0.229-0.033-0.268-0.033c0,0-0.445-0.049-0.837,0v0.736
                                c0.174,0.067,0.355,0.136,0.543,0.205c-0.168-0.03-0.34-0.058-0.543-0.058c-0.491,0-0.735,0.392-1.229,0.494
                                c0.198,0.341,0,0.241,0,0.733c0,0.394,0.346,0.739,0.739,0.739c0.347,0,1.082,0.342,1.721,0.342c0.592,0,1.229-0.242,2.063-0.342
                                c0-0.295,0.246-0.591,0.542-0.642c0.097,0.492,0.539,0.741,0.834,1.133c-0.637,0-1.376-0.149-1.376,0.588
                                c0,0.296,0.787,0.739,1.376,0.739c1.228,0,2.21-0.344,3.195-0.739c0.147-0.046,3.289-0.737,3.289-0.836
                                c0-0.144,2.902,0.198,3.195,0.099c0.443-0.145,1.623-1.62,1.623-2.308c0-0.591-0.542-1.227-1.129-1.227
                                c-0.639,0-0.886,0.588-1.328,0.588c-0.935,0-1.866-1.081-1.866-1.818c0-0.098-0.049-0.246,0-0.639
                                c-0.343,0-0.639-0.149-0.935-0.344c-0.491,0.392-1.767,0.786-1.767,1.575c0,0.44,0.883,0.683,1.226,0.734v0.64
                                c-0.147,0-0.442,0.099-0.49,0.197c0.196,0.096,0.443,0.147,0.981,0.147c-0.197,0.441-0.441,0.641-1.082,0.641
                                c-2.161,0-3.191-1.869-4.667-1.869c-0.098,0-0.54,0-1.127,0c-0.049-0.59-0.64-0.741-0.838-0.741c-0.44,0-0.738-0.145-1.62,0
                                C71.746,24.078,71.9,24.235,72.119,24.336z M73.464,25.409c-0.166-0.017-0.453-0.063-0.764-0.115
                                C72.947,25.322,73.2,25.354,73.464,25.409z"/>
                            <path class="st4" d="M66.733,26.195c0,0.343,0.443,0.589,0.836,0.589c0.639,0,1.375-1.034,1.476-1.718
                                C68.552,25.017,66.733,25.409,66.733,26.195z"/>
                            <path class="st4" d="M73.612,23.246c0,0,0.885,0,1.081,0v-0.638h-1.72C72.974,23.051,73.366,23.246,73.612,23.246z"/>
                            <path class="st4" d="M63.147,25.066c0.49,0,0.784,0.292,1.375,0c0.096,0.193,0.44,0.736,0.835,0.736
                                c0.539,0,0.931-0.543,1.032-1.08c0.833,0.096,1.571-0.689,1.671-1.525c0.44,0.244,0.391,1.378,1.228,1.378l2.111-1.576
                                c0-0.39-0.342-0.489-0.392-0.981c0.343-0.099,0.639-0.442,0.639-0.787c0-0.883-2.948-0.095-2.948-0.095
                                c-0.442-0.051-0.492-0.102-0.885-0.102c-1.376,0-1.623,1.276-2.556,1.82c-0.835,0.489-1.571,0.588-2.359,0.98
                                c-0.197,0.102-1.081,0.789-1.081,0.889c0,0.145,0.393,0.392,0.493,0.736C62.703,25.409,62.848,25.066,63.147,25.066z"/>
                            <path class="st4" d="M77.148,18.576c1.084,0,0.79,0,2.117,0c0.59,0,0.933-0.295,1.324-0.492c-0.196-0.636-1.031-1.227-2.062-1.227
                                c-0.638,0-3.588,1.08-3.588,1.719c0.391-0.05,0.589-0.391,0.983-0.391C76.417,18.185,76.562,18.576,77.148,18.576z"/>
                            <path class="st4" d="M79.116,18.824c-1.031,0-4.029,0.095-4.029,1.228c0,0.443,0.836,1.325,1.572,1.325
                                c1.082,0,2.704-0.442,2.606-1.473h-1.376c0.735,0,1.571-0.148,2.109-0.59C79.854,19.119,79.659,18.824,79.116,18.824z"/>
                            <path class="st4" d="M73.71,20.395c0.346,0,0.494-0.294,0.69-0.587c-0.298-0.148-1.672-1.378-1.672-0.148
                                C72.728,19.904,73.267,20.395,73.71,20.395z"/>
                            <path class="st4" d="M98.134,34.548c0-0.491,0.146-0.049,0.146-0.734c0-0.542-1.425-0.1-1.819-0.05
                                c0.346-0.441,1.573-0.839,1.573-1.819c0-0.247-0.343-0.493-0.489-0.493c0,0-1.084,0-1.966,0c-1.084,0-2.163-0.194-2.95,0.59
                                c-0.049,0.148-0.345,0.246-0.345,0.392c0,0.638,0.935,0.543,1.575,0.638v1.329c-0.396,0-0.247,0-0.738,0
                                c-0.837,0-1.573-0.588-2.212-0.588v0.835c1.426,1.422,2.95,1.228,4.327,2.602c0.293,0.297,0.393,0.835,1.081,0.835
                                c0.441,0,0.539-0.59,0.98-0.739c1.574-0.589,1.731,0.128,1.969-2.552C98.967,34.698,98.331,34.597,98.134,34.548z"/>
                            <path class="st4" d="M88.451,23.342c-0.196,0-0.391,0-0.587,0c0,0.249,0.077,0.436,0.177,0.592l-0.08-0.1v0.889
                                c0.392,0.295,0.738,0.636,1.229,0.49l0.147,0.487h0.74c0,0.298,0.241,0.592,0.588,0.592c0.982,0,2.7-0.491,3.587-0.491
                                c0.196,0,0.441,0.049,0.588,0.147c-0.295,0.294-1.229,0.294-1.229,0.735c-0.048,0.147-0.048,0.346,0,0.492
                                c-0.195,0.098-0.342,0.298-0.342,0.491c0,0.445,1.276,0.248,1.571,0.248c0.147,0,1.228,0,1.967-0.248
                                c-0.198-1.375,0.394-1.967,0.394-3.092c0-0.741-0.104-1.233-0.985-1.233c-0.393,0-0.588,0.396-0.981,0.396
                                c-0.936,0-1.424-0.491-2.214-0.491c-0.341,0-0.541-0.099-0.883,0.048c0.197,0.69,0.883,0.69,1.72,0.69v1.082
                                c-1.229,0-1.622-1.082-2.704-1.082v0.59c0.196,0.244,0.592,0.588,0.983,0.835C90.614,25.851,89.878,23.342,88.451,23.342z"/>
                            <path class="st4" d="M96.068,43.493c-0.196,0.05-1.081-0.149-1.081,0.243c0,0.149,3.636,1.474,4.278,1.474
                                c0.54,0,0.736-0.492,1.227-0.784c-0.296-0.69-2.309-2.507-3.046-2.507C96.461,41.919,96.657,43.294,96.068,43.493z"/>
                            <path class="st4" d="M102.701,30.372c-1.03,0-1.328,0.685-1.328,0.737c0,0.149-0.096,0.441,0,0.489c-0.194,0-0.736,0-1.125,0v0.837
                                c0.341,0.984,0,1.571,0.783,2.36c-0.047,0.244-0.047,0.835-0.047,1.472h0.982c0.591-0.587,0.982-0.738,1.13-1.618h-0.641
                                c0.297-0.985,0.297-0.344,1.378-0.344c1.476,0,2.161-1.082,2.801-1.964c0,0,0.886-0.643,0.886-0.742c0-0.242-0.05-0.391,0-0.636
                                C105.749,30.962,104.372,30.372,102.701,30.372z"/>
                            <path class="st4" d="M87.226,21.868c0.342,0,0.638-0.242,0.685-0.586c-1.029-0.346-1.128-1.378-2.408-1.378
                                C85.503,20.543,86.683,21.868,87.226,21.868z"/>
                            <path class="st4" d="M86.978,27.865c0.393-0.049,0.494,0.05,0.983,0.05c0.294,0,0.738-0.05,0.738-0.642
                                c0-0.342-0.249-0.49-0.346-0.735C87.667,26.784,87.172,27.03,86.978,27.865z"/>
                            <path class="st4" d="M86.978,16.12c-0.05,0.199-0.195,0.393-0.342,0.492c0.392,0.297,0.637,0,1.717,0
                                c-0.098,0.147-0.098,0.441,0,0.591c-0.59,0.195-1.031,0.147-1.472,0.591c0.441,0.441,0.638,0.392,1.326,0.392
                                c0.737,0,1.377-0.248,1.622,0.197c0.883-0.1,1.424,0.195,2.308,0.195c1.131,0,1.031,1.231,2.212,1.231
                                c0.393,0,0.886-0.148,0.886-0.641c0-0.54-0.984-0.933-1.377-1.326c0.097-0.147,0.295-0.346,0.393-0.492
                                c-0.246-0.246-0.789-1.129-1.475-1.129c-0.295,0-0.442,0.392-0.735,0.392c-0.542,0-0.494-1.13-1.477-1.13
                                c-0.391,0-0.588,0.393-0.981,0.393c-0.593,0-0.836-0.883-1.869-0.883c-0.59,0-0.981,0.295-1.572,0.393
                                C86.144,15.972,86.29,16.12,86.978,16.12z"/>
                            <path class="st4" d="M92.188,19.559c-0.441-0.148-0.589-0.393-1.131-0.393c-0.196,0-1.327,0.737-0.442,0.737
                                C91.35,19.904,91.941,19.904,92.188,19.559z"/>
                            <path class="st4" d="M109.485,28.651h0.589c-0.099,0.837,1.425,0.982,2.458,0.738h3.932c0.443-0.297,0.542-0.539,1.229-0.638
                                c0.098,0.341,0.343,0.737,0.981,0.737c1.722,0,3.933,0,4.078-1.722c0,0-0.291,0.05-0.639-0.047
                                c0.057-0.193,0.145-0.334,0.245-0.451c0.143-0.014,0.317-0.051,0.394-0.091c-0.687-1.276-1.967-1.374-3.93-1.374
                                c-2.556,0-3.439,1.228-6.143,1.228c-1.229,0-2.21-0.297-3.393-0.587c-0.195,0.145-0.783,0.44-0.783-0.248
                                c0-0.148,0.245-0.148,0.343-0.246c-0.687-0.54-1.916,0.196-2.211-1.131h1.968c-0.444-0.638-2.706-0.344-2.314-1.477
                                c0,0-0.54,0-0.884,0c-0.195,0.298-0.491,0.396-0.984,0.396c-1.669,0-2.064-1.72-4.273-1.72c-0.588,0-1.474,0-1.474,0.736
                                c0,0.443,2.997,1.72,3.536,1.72c0.394,0,0.69-0.2,0.985-0.49c0.834,0.834,1.079,1.621,1.964,2.506
                                c-0.195,0.098-0.586,0.441-0.586,0.783c0,0.983,2.455,2.214,3.537,2.214C108.553,29.488,109.286,28.9,109.485,28.651z"/>
                            <path class="st4" d="M103.195,27.667c0-0.637-1.182-1.62-2.063-1.62c-1.13,0-0.935,1.129-1.473,1.129
                                c-0.099,0-0.638,0.248-0.638,0.739c0,0,2.847,1.327,3.19,1.327C102.901,29.242,103.195,28.504,103.195,27.667z"/>
                            <path class="st4" d="M103.342,20.543c0-0.099,0-0.295,0-0.639c-0.493,0.098-0.096,0-0.64,0c-0.932,0-2.7,0.098-3.191,0.591
                                c0,0,2.847,0.539,3.094,0.539C103.049,21.033,103.342,20.788,103.342,20.543z"/>
                            <path class="st4" d="M98.184,18.824c-0.149,0.049-0.493,0.246-0.493,0.49c0,0.59,1.722,0.394,3.44,0c-0.147-0.54,0-0.885,0-1.23
                                c0-1.033-1.718,0.052-1.718-1.131c-0.543,0-1.575-0.343-1.722-0.734c-0.489,0.049-1.129,0.246-1.129,0.734
                                C96.562,17.937,97.592,18.234,98.184,18.824z"/>
                            <path class="st4" d="M95.726,13.91h0.836v-0.983c-0.345,0-1.082-0.049-1.722,0C94.84,13.516,95.431,13.615,95.726,13.91z"/>
                            <path class="st4" d="M101.967,12.927c-0.443,0-1.23-0.295-1.474-0.491h-0.737c0.147,1.423,1.181,1.569,2.848,1.569
                                c-0.294,0.394-0.637,0.445-1.13,0.641c0,0.293,0.05,0.489,0.394,0.489c0.393,0,0.541-0.196,1.033-0.39
                                c0.149,0.291,0.196,0.637,0.687,0.637c0.59,0,0.738-0.393,1.475-0.393c0.737,0,1.275,0.196,2.014,0.393
                                c-0.688,0.146-3.735,0.049-3.735,0.982c0,0.737,1.375,1.228,2.213,1.228c-0.15,0.151-0.298,0.296-0.641,0.343
                                c0.098,0.69,1.182,0.888,1.966,0.888c0.737,0,1.179-0.248,1.179-1.131c0.444,0.293,1.328,0.933,1.772,0.882v-0.639h0.881
                                c-0.146-0.391-0.343-0.489-0.639-0.983c0.247-0.243,0.347-0.488,0.59-0.734c0.148,0.539,0.148,0.983,0.786,0.983
                                c0.639,0,1.327-1.475,2.308-1.573c0.396-0.05,0.935,0.049,1.132-0.245c0.151-0.197,0.05-0.393,0.05-0.737
                                c-0.64-0.05-1.919-0.641-2.408-0.641c-0.1,0-0.837,0.445-0.837,0c0-0.294,0.345-0.391,0.345-0.735c0-0.343-0.59-0.393-0.59-0.737
                                c0-0.588,0.049-0.837-0.638-0.837c-0.148,0-0.343,0-0.491,0l0.246,0.346v0.636h-0.591c-0.44-0.442-0.144-1.078-0.884-1.374
                                c-0.735-0.296-1.226,0.193-1.966-0.1c-0.788-0.294-0.587-1.717-1.57-2.112c-0.885-0.346-1.869-0.444-3.099-0.539
                                c0.051,0.684,0.886,0.734,1.475,1.031h-0.835c-0.886-0.197-1.278,0.099-1.867,0.441c0,0.05,0.441,0.786,0.637,0.786
                                c0.295,0,0.885-0.293,1.379,0c-0.149,0.151-0.248,0.493-0.545,0.493c-0.392,0-0.637-0.343-1.328-0.343
                                c-0.438,0-1.125-0.051-1.125,0.491c0,0.539,1.521,0.884,2.16,1.08C102.408,12.533,102.162,12.927,101.967,12.927z"/>
                            <path class="st4" d="M119.9,55.189c-0.244-0.199-0.096-0.542-0.343-0.886c-0.884-1.181-2.456-0.687-3.487-1.968
                                c-0.149,0.098-0.296,0.199-0.441,0.396c-0.247-0.491-0.493-0.641-0.74-1.376c-1.375,0.145-1.621,2.11-1.621,2.948
                                c0,0.441,0,0.293,0,0.736c0,0.294-0.247,0.442-0.492,0.642c-0.097,0.242-0.687,0.144-0.736,0.98c0.392-0.047,1.326-0.198,2.113,0
                                c-0.099,0.491-0.246,0.983,0.343,0.983c0.246,0,0.246-0.146,0.393-0.391c0.689,0.294,2.311-0.739,2.311-1.822
                                c0.295,0.1,1.867,1.23,1.867,1.23c0.489,0,0.737,0,1.327,0c0.638,0,1.078-0.198,1.622-0.539
                                C121.574,55.531,120.69,55.779,119.9,55.189z"/>
                            <path class="st4" d="M129.236,47.081c0-1.621-3.044-1.965-3.044,0c0,0.587,0.492,1.08,1.229,1.08
                                C128.01,48.161,129.236,47.718,129.236,47.081z"/>
                            <path class="st4" d="M118.182,57.989c-0.05,0.294-0.687,0.735-1.081,1.228v0.637c0.786-0.098,2.015-1.079,2.456-1.669
                                C118.966,57.939,119.165,57.939,118.182,57.989z"/>
                            <path class="st4" d="M123.491,34.305c0.835,0,1.277-0.491,1.817-0.491c0.543,0,0.834,0.342,1.473,0.342
                                c0.443,0,0.738-0.342,0.885-0.788c-0.885-0.442-2.011-1.521-3.34-1.521c-1.032,0-1.376-0.394-2.458-0.394
                                c-0.395,0-0.835,0.246-0.835,0.738c0,0.541,0.54,0.787,0.984,1.033C121.67,34.057,122.508,34.305,123.491,34.305z"/>
                            <path class="st4" d="M122.358,60.839c0.492,0,0.592-0.688,0.886-1.083c-0.493-0.589-1.623-0.539-1.623,0.344
                                C121.621,60.1,122.26,60.839,122.358,60.839z"/>
                            <path class="st4" d="M131.353,46.735c-0.148-0.193-0.541-0.786-0.982-0.786c-0.1,0-0.64,0.345-0.64,0.491
                                c0,0.294,0.293,0.393,0.64,0.393C130.859,46.834,130.714,46.884,131.353,46.735z"/>
                            <path class="st4" d="M124.472,43.098c-0.59,0-1.721,1.129-1.131,1.129C123.934,44.227,124.326,43.688,124.472,43.098z"/>
                            <path class="st4" d="M135.039,38.579c0.145-0.838-1.083-0.984-1.621-0.984c-0.347,0-0.493,0-1.23,0
                                c-1.623,0-1.965-1.672-3.047-2.457c-0.639-0.441-2.753-0.737-3.686-0.737c-0.884,0-1.128,1.228-1.963,1.228
                                c-0.442,0-0.59-0.491-1.133-0.491c-0.49,0-0.688,0.491-0.981,0.491c-0.246,0,0-0.786,0-0.981c0-1.083-0.687-2.802-1.965-2.802
                                c-1.967,0-5.259,0.588-5.259,2.458c0,0.49,0.491,0.834,0.491,1.325c0,0.392-0.687,0.983-0.98,0.983
                                c-0.592,0-0.495-0.443-0.495-0.835c0,0-0.097-0.54,0-0.886c-0.246,0-0.394-0.195-0.394-0.491c0-1.424,1.623-1.525,2.113-2.556
                                c-0.049,0-1.621-0.491-1.621-0.491c-1.672,0-2.948,0.835-3.931,1.816c-0.198,0.196,0.048,1.085-0.345,1.376
                                c-0.736,0.54-0.983,0.837-0.983,2.21c0,0.444,0.737,1.328,0.737,1.328s2.26,0.199,2.702,0.494v0.638
                                c-0.835-0.098-1.574-0.396-2.21-0.147c0.098,0.687,0.834,0.782,1.081,1.474h2.358c0.293,0.392,1.079,0.983,1.474,0.983
                                c2.212,0,5.946-0.148,7.468,0c-0.048,0.34-0.244,1.13,0.395,1.13c0.884,0,1.67-1.87,2.309-1.87c0.538,0,3.192,2.263,3.637,2.705
                                c-0.297,0.05-1.182,0.145-1.182,0.639c0,0.095,0.199,0.586,0.395,0.586c0.64,0,0.932-0.586,1.571-0.586
                                c1.033,0,4.178,2.409,4.178,3.686c0,0.687-1.033,1.765-1.471,2.208c-0.348,0.346-1.231-0.097-1.231,0.74
                                c0,0.393,0.637,0.884,0.883,1.031c-0.589,0.394-1.769,0.686-2.208,0.686c-0.445,0-1.232,0-2.117,0
                                c-0.685,0-1.963,0.838-1.963,1.328c0,0.688,1.571,1.379,2.113,1.379c0.588,0,0.885-0.738,1.475-0.738
                                c0.442,0,0.341,0.344,0.831,0.344c0.443,0,0.741-0.396,0.986-0.738c1.426,0.835,2.211,1.769,3.834,2.457
                                c-0.835,1.573,2.11,1.525,3.291,2.209c1.229,0.74,2.164,0.444,3.586,1.13c0.147,0.052,0.246,0.592,0.493,0.592
                                c0.194,0,0.491-0.244,0.491-0.592c0-1.372-2.752-2.406-3.589-3.437h0.74c0.539,0.688,3.686,1.866,4.568,1.966
                                c0.197-0.543,0.737-1.474,0.737-1.966c0-0.542-0.884-0.785-0.884-1.129c-0.049-0.196-0.049-0.196,0-0.983c-0.345,0-0.345,0-0.835,0
                                c-0.099-0.837-0.442-0.246-1.376-0.589c-0.393-0.15-0.985-1.233-1.079-1.87h-0.64v-0.737c0.196,0.148,0.441,0.101,0.734,0v-0.592
                                c-0.441,0.098-0.539,0.151-0.98-0.097c0.047,0,0.686-0.54,0.98-0.54c2.361,0,2.46,3.343,4.914,3.343
                                c0.541,0,0.639-0.836,0.639-1.378c0.934,0,1.083-0.44,1.083-0.588c0-0.248,0.05-0.098,0-0.641c0.639,0,1.475-0.195,1.475-0.831
                                c0-0.788-0.393-1.132-1.328-1.132c-0.243,0-0.638,0-0.883-0.246c-0.393-0.933-0.638-0.245-1.72-0.245
                                c-0.735,0-0.982-0.836-1.081-1.72c-1.031-0.098-2.357-0.59-2.457-1.722c-0.64,0-0.934-0.147-1.374-0.39
                                c0.441-0.737,0.836-0.737,1.621-1.134c-0.146-0.29-0.491-0.441-0.736-0.441c-0.444,0-0.641,0-1.476-0.096
                                c0.393-0.591,1.032-0.543,1.722-0.688c-0.294-0.69-0.689-1.182-1.376-1.182c0,0-0.541,0-0.837,0v-0.835
                                C137.103,39.805,136.363,39.02,135.039,38.579z"/>
                            <path class="st4" d="M124.57,57.104c0.49,0.394,0.64,0.394,1.131,0C125.11,56.809,125.06,56.464,124.57,57.104z"/>
                            <path class="st4" d="M116.365,51.108c-0.052,0.344,0.341,0.737,0.982,0.788C117.005,51.452,117.005,50.815,116.365,51.108z"/>
                            <path class="st4" d="M107.372,7.521h1.474c-0.588,0.198-0.881,0.343-1.376,0.836c0.096,0.05,0.641,0.391,0.885,0.391
                                c0.93,0,0.982-0.884,1.965-0.884c0.391,0,0.542,0,0.982,0.099c-1.278,0.393-2.506,0.248-3.046,1.525c0.392-0.05,1.375,0,2.309,0
                                c-0.638,0.049-0.98,0.293-1.327,0.537c0.346,0.444,1.72,1.033,2.703,1.033c0.443,0,0.737-0.247,1.079-0.491h0.643
                                c-0.299,0.637,0.98,0.983,1.965,0.983c0.392,0,0.588-0.492,0.979-0.492c0.492,0,0.788,0.394,1.722,0.394
                                c1.57,0,2.703-0.345,3.686-1.132h0.734c-0.589,0.738-1.424,0.984-2.356,1.375h-2.459c-0.097,0.148-0.343,0.295-0.343,0.493
                                c0,0.297,1.573,1.278,1.82,1.522c-1.378,0.148-1.82-1.275-3.048-1.669c-0.982-0.297-2.065-0.098-2.607-0.098
                                c-0.243,0-0.489-0.1-0.737,0c0.102,0.393,0.198,0.588,0.737,0.588c-0.489,0-1.226-0.097-1.226,0.394
                                c0,0.884,3.341,0.884,3.341,2.457c0,1.375-1.473,0.935-2.702,1.327c-0.738,0.243-1.081,0.784-1.475,1.621v0.587h0.737
                                c0.2-0.193,0.2-0.193,0.493-0.343h0.74c0,1.18,1.521,1.771,2.207,2.457h-0.882c-0.935-0.539-1.475-1.375-3.048-1.375
                                c-0.493,0-1.23-0.197-1.23,0.491c0,0.884,1.033,1.034,1.624,1.477c-1.033,0.736-3.589,0-3.589,1.967
                                c0,0.145,0.147,0.392,0.492,0.392c0.245,0,0.44-0.247,0.591-0.492c1.72,0.541,3.044,0.982,4.666,0.982
                                c0.737,0,1.032-0.49,1.475-0.49c1.23,0,2.261,0,3.686,0c0.984,0,0.491,0.738,1.622,0.738c1.129,0,3.932-0.889,3.932-1.724
                                c0-0.44-0.588-0.39-0.738-0.39c-0.344,0-0.493,0-1.228,0c0-1.673,2.063-0.74,2.063-2.31c0-0.296-0.247-0.394-0.344-0.739
                                c0.394,0,0.344,0,2.458,0c0.834-0.588,1.179-1.766,2.213-2.258c-0.298-0.69-0.837-0.886-1.427-1.182
                                c0.934,0.1,1.524,0.199,1.427-0.882h-1.379c0.787-0.64,2.015-0.739,3.098-1.082c0.786-0.246,1.869,0.343,2.555-0.15
                                c1.473-1.079,2.604-3.043,4.568-3.684c1.769-0.589,4.523-1.275,5.652-2.406c-1.23-0.051-3.195,0.342-3.685-0.15
                                c2.604-1.275,5.011-0.391,6.976-1.866c0.346-0.246,1.231-0.1,1.231-0.735c0-0.886-2.606-0.641-3.195-1.232
                                c-0.294-0.292-0.294-0.687-0.834-0.832c-0.344-0.1-0.54-0.2-0.884-0.392l-3.933,1.129c0.396-0.099,0.543-0.197,0.886-0.541
                                c-0.982-0.984-5.211-0.837-6.388-0.837c-0.491,0-0.786,0-1.377,0c-0.442,0-0.737-0.342-1.471-0.342
                                c-0.691,0-0.889,0.833-1.577,0.833c-0.735,0-0.979-0.588-1.866-0.588c-0.247,0-0.491,0.148-0.588,0.346c-2.557,0-3.095,0-5.653,0
                                c-0.49,0-0.881,0.146-1.128,0.637c-0.641,0.539-0.147,0.096-0.984,0.096c-1.425,0-2.016,1.035-2.456,2.213
                                c-0.442-0.146-2.36-1.08-3.293-1.08c-1.033,0-0.393,1.08-1.965,1.08c0,0.197-0.197,0.396-0.392,0.396c-0.101,0-0.494,0-1.082,0
                                c-1.425,0-4.621,0.586-4.914,1.815c0.292,0,0.882,0,1.08,0C106.782,7.765,107.077,7.619,107.372,7.521z"/>
                            <path class="st4" d="M213.907,5.654c-0.491,0-0.834,0.048-0.984,0.638h-2.356c-0.788,0.393-0.936,0.639-1.82,0.984
                                c-0.195,0.048-1.13-0.148-1.13-0.148s-2.016,0-3.293,0c-0.786,0.591-3.273,3.834-4.548,4.177c0.146-0.493,3.321-3.833,3.321-4.275
                                c0-0.739-0.346-1.473-1.229-1.473c-1.623,0-4.551,2.799-5.777,2.799c-0.396,0,1.747-2.065,1.845-2.458
                                c-0.884-0.341-9.122,0.638-9.957,0l10.791-0.589c1.329-0.494,4.669-0.244,4.917-1.965c-1.232-0.295-2.949,0.047-4.278-0.491
                                C197.102,1.871,194.94,0,192.042,0c-2.458,0-4.376,0-7.128,0c-2.505,0-4.422,1.378-6.783,1.378c-0.735,0-1.228-0.098-1.963,0v0.59
                                h-0.984c-1.083-0.395-1.867-0.344-3.044-0.344c-1.624,0-2.853,0.344-4.18,0.984c0.248,0.342,0.64,0.392,0.985,0.737
                                c-0.444,0.096-1.966-0.145-1.966,0.587c0,0.935,2.016,1.575,2.8,1.967h-2.212c-1.227-0.589-2.407-1.719-4.421-1.719
                                c-0.493,0-1.227,0.05-1.227,0.636c0,0.395,0.245,0.691,0.393,1.083c0,0-0.497,0-0.738,0c-0.982-0.392-1.819-0.293-2.359-1.226
                                l-0.492,0.491c0,0.343,0.246,0.635,0.246,0.983c-0.932,0-1.179-1.474-2.064-1.474c-1.327,0-2.31,0.784-3.686,1.126
                                c-1.867,0-2.604,0.493-4.568,0.738c-1.575,0.197-0.247,2.459-1.967,2.459c-0.491,0-0.884-0.099-1.277-0.393
                                c-0.982,0.982-3.194,2.161-4.864,2.702c-0.249,0.048-0.983,0.296-0.983,0.738c0,0.932,2.801,0.884,3.686,0.981
                                c0,1.23-0.542,2.112-1.328,2.604c-1.08,0.639-2.849-0.099-4.176,0.343c-0.935,0.296-1.475,0.84-2.36,1.131
                                c-1.081,0.345-2.801-0.195-2.801,1.327c0,0.789,4.716,2.113,4.914,2.113c0.639,0,0.935,0,1.719,0c0.247,0,0.393-0.244,0.494-0.491
                                h1.964c-0.147,0.54-0.147,0.736-0.638,1.083h-2.801c-0.247,0.341-0.395,0.392-0.885,0.392c-0.342,0-0.69,0-1.229,0
                                c-0.391,0-1.375,0.294-1.476,0.982c0.935,0.344,0.64,0,1.72,0c0.591,0,1.181,0.542,1.966,0.833
                                c-0.195,0.443-0.537,0.492-0.981,0.787c0.587,0.739,2.164,1.182,3.194,1.182c0.392,0,0.343,0.148,0.638-0.147
                                c-0.146-0.048-0.342-0.246-0.491-0.396c0.244-0.095,0.443-0.193,0.983-0.193c0.588,0,1.032,0.392,1.472,0.392
                                c0.638,0,0.936-0.641,1.574-0.641c0.443,0,0.639,0,1.474,0c5.505,0,8.943,4.276,10.318,8.505v0.735
                                c0.445,0.05,1.229,0.098,1.229,0.836c0,1.127-1.078,1.03-1.078,2.113c0,0.736,0.442,0.832,1.325,0.832
                                c0.639,0,0.786-0.832,1.23-0.832c1.622,0,2.064,2.307,3.585,2.798c-0.047,0.298,0,0.741-0.489,0.741
                                c-1.183,0-1.918-0.741-2.949-0.741c-0.444,0-0.394-0.193-0.886,0c0.199,1.379,3.784,1.625,5.308,2.115
                                c-0.442,1.376-1.179,1.866-1.472,3.342c-0.691-0.294-0.691-0.247-1.377,0.099v0.098c-0.984,0.786-1.279,1.769-1.966,2.702v1.133
                                c0.295-0.249,0.64-0.199,0.982,0c-0.148,0.491-0.492,0.734-0.492,1.225c0,1.33,1.329,1.87,1.623,2.803
                                c0.146,0.492,0.295,1.425,0.493,1.623c0.836,0.44,1.079-0.786,1.818,0.145c-0.346,0.345-0.739,0.295-0.739,0.934
                                c0,1.424,1.868,3.636,2.852,4.423c0.146,0.098,0.05,0.784,0.343,0.983c0.787,0.59,1.277,0.244,1.623,1.375
                                c0.588-0.05,1.376-0.64,2.062-0.64c1.23,0,1.132,2.215,2.361,2.215c0.296,0,0.391-0.295,0.98-0.345
                                c0.101,0.296,0.247,0.491,0.594,0.491c1.325,0,0.736-2.551,1.865-3.094c-0.099-0.54,0-1.82,0-2.703
                                c0-1.426,2.307-0.885,2.307-2.552c0-0.542-0.588-0.79-0.588-1.133c0-0.246,0.198-0.393,0.345-0.59c0-0.149-0.098-0.342-0.098-0.493
                                c0-1.621,2.652-1.178,3.686-2.21c0.442-0.441,0.247-0.933,0.985-1.231v0.983h0.735c1.03-0.732,2.7-0.492,3.686-1.474
                                c0.736-0.734,0.785-1.717,1.718-2.453l1.229-0.888c0-0.391,0.492-0.54,0.982-0.832h2.31c1.868-0.789,3.294-0.642,5.062-1.228
                                c1.423-0.444,1.917-1.819,3.538-2.362c0.884-0.291,1.376-0.342,2.114-0.933c-1.034-0.343-5.406-0.146-5.406-1.619
                                c0.243-0.051,1.081-0.198,1.081-0.638c0-0.393-0.491-0.543-0.491-1.232c0.244,0.149,0.491,0,0.738,0
                                c2.014,0,1.527,2.458,3.833,2.458c0.537,0,1.329-0.047,1.329-0.588c0-2.806-3.148-3.637-4.916-5.404v-0.741
                                c0.098,0,0.246,0.05,0.637,0c0.297,0.933,1.819,2.213,2.704,2.213c0.588,0,0.738-0.787,0.738-1.376c0-1.329-2.21-0.888-2.702-2.212
                                h0.737c0.393,0.54,0.932,0.787,1.474,1.133c0.393-1.034,2.36-0.396,2.701-1.869c-0.247-0.099-0.492-0.396-0.492-0.739
                                c0.936-0.099,1.917-0.54,2.213-1.323c-0.588-0.2-1.967-0.149-1.967-0.984c0-0.642,0.786-0.642,1.574-0.642
                                c-0.245-0.291,0-0.686,0-1.078c0-0.737-0.735-1.032-0.735-2.111c-0.691,0-1.573,0-1.573-0.838c0-0.346,0.443-0.737,0.981-0.737
                                c0.886,0,3.441,0.442,3.441-0.737c0-1.18-1.916-0.934-2.113-1.72c0.294,0,0.637-0.148,0.885-0.296
                                c-0.493-0.391-1.18-0.932-1.969-0.932c-0.539,0-0.931,0.246-1.47,0c0.293-0.493,0.244-1.672,0.833-2.114
                                c0.932-0.689,1.967-1.032,1.967-2.064c0-0.196,0.047-0.196,0-0.737c0.93-0.248,2.211-0.443,2.211-1.23c0-0.49,0.341,0.05,0-0.636
                                c0.885-0.198,2.21-0.198,2.21-1.573c-0.393,0.294-0.44,0.294-1.031,0c2.212,0,3.242-1.424,4.866-1.964
                                c0.294-0.099,1.226-0.542,1.226-1.132C216.855,6.735,214.547,5.654,213.907,5.654z"/>
                            <path class="st4" d="M158.625,43.001h1.081c0.837,0,1.869-0.147,1.967-0.982c-1.278-0.394-1.674-1.722-2.949-1.722
                                c-0.442,0-1.081,0.249-1.081,0.984C157.642,41.869,158.182,42.953,158.625,43.001z"/>
                            <path class="st4" d="M208.355,26.539c-0.149,0.146-0.493,0.345-0.493,0.637c0,0.248,0.295,0.739,0.64,0.739
                                c0.492,0,1.326-0.591,1.572-0.739h-0.835c0-0.194,0-0.293,0-0.637H208.355z"/>
                            <path class="st4" d="M156.562,81.428c0-0.195-0.148-0.493-0.343-0.493c-0.788,0-1.084,0.838-1.475,1.476
                                c-0.736,1.128-1.28,3.343-2.654,4.028c0.047,0.148,0.245,0.248,0.392,0.248v0.293c0,0.049-0.883,0.739-0.883,0.933
                                c0,0.15,0.245,0.491,0.491,0.491c0.587,0,0.786-0.291,1.523-0.291c0.54,0,0.835,0.342,1.474,0.342c0.344,0,0.639-0.1,0.982-0.243
                                c0.246,0.491,0.886,0.193,1.377,0.193c-0.147,0.298-0.541,0.298-0.541,0.787c0,0.1,0.197,0,0.295,0
                                c0.589,0,1.034-0.736,1.424-1.328c0.394,0.396,0.099,1.328,0.492,1.328c0.148,0,0.247,0.1,0.39,0.1
                                c0,0.194,0.102,0.589,0.445,0.589c0.491,0,0.787-0.589,0.787-1.177c0-0.491-0.344-0.443-0.344-1.033
                                c-0.294,0.193-0.148,0.293-0.639,0.293c0-0.341-0.345-0.54-0.345-0.785c0-0.197,0.44-0.295,0.494-0.738
                                c-0.198,0-0.445,0.05-0.541,0.148c0-0.736,0.047-1.179,0.047-1.624c-0.247,0.15-0.881,0.15-1.425,0l-0.59,0.199
                                c0,0,0-0.199,0-0.244h-0.886v-0.447l0.249-0.242c-0.197-0.2-0.492-0.251-0.788-0.251c-0.341,0-0.341,0.299-0.736,0.396v-0.44
                                C155.527,83.047,156.562,82.51,156.562,81.428z"/>
                            <path class="st4" d="M121.131,78.972c0.046,0,0.246,0.047,0.246,0c0-0.69-0.835-0.835-1.672-0.835c0.05,0.096,0.05,0.246,0,0.343
                                C119.999,78.775,120.69,78.972,121.131,78.972z"/>
                            <path class="st4" d="M57.299,85.264c0.787,0.491,2.504,1.816,3.635,1.816c0.149,0,0.294,0,0.444,0v-0.442
                                c-1.277-1.279-2.456-2.458-4.573-3.146c-0.343-0.099-0.784-0.687-1.325-0.687c-0.244,0-0.491,0.147-0.688,0.242h0.197
                                c0,0,0.491,0.15,0.737,0.2c-0.048,0.146-0.246,0.343-0.49,0.442c0.193,0.59,1.569,1.083,2.555,1.083
                                C57.643,84.919,57.396,84.919,57.299,85.264z"/>
                            <path class="st4" d="M49.928,78.579c0-0.248-0.247-0.346-0.247-0.588c0-0.491,0.099-0.789,0-1.082
                                c-0.098,0.245-0.294,0.636-0.638,0.636c-0.296,0,0.295-0.536,0.295-0.588c-0.441-0.048-0.689-0.344-1.133-0.344v0.396
                                c0.198,0.292,0.396,0.882,0.591,0.882c0.298,0,0.394,0.54,0.787,0.639c0,0.099-0.195,0.247-0.195,0.54
                                c0,0.395,0.294,0.934,0.688,0.934c0.49,0-0.394-0.737-0.394-0.934C49.681,78.873,49.928,78.775,49.928,78.579z"/>
                            <path class="st4" d="M18.182,69.929v0.588c0.345,0.247,0.591,0.295,1.032,0.643c0.737-0.593,1.031-0.935,1.916-1.379v-0.589
                                c-0.246,0-0.246,0-0.591,0C19.657,69.193,19.019,69.684,18.182,69.929z"/>
                            <path class="st4" d="M147.912,85.408c-1.031-0.588-1.965-1.522-3.488-1.178c0.59,0.442,2.013,1.474,2.798,1.474
                                C147.618,85.704,147.766,85.655,147.912,85.408z"/>
                            <path class="st4" d="M144.863,89.536c-0.097,0.05-0.195,0.196-0.195,0.343c0,0.344,1.819,1.279,2.164,1.279
                                c0.096,0,0.587-0.247,0.587-0.54c0-0.099,0-0.199,0-0.299h-1.473C145.455,90.224,145.11,90.026,144.863,89.536z"/>
                            <path class="st4" d="M150.517,91.159c0-0.097,0-0.247,0-0.345c-1.131,0-0.393-0.294-0.393-0.737c0-0.197-0.246-0.294-0.345-0.49
                                c-0.443,0.146-1.129,1.374-1.129,1.67c0,0.295,0.293,0.539,0.785,0.539C149.975,91.796,150.517,91.502,150.517,91.159z"/>
                            <path class="st4" d="M2.113,64.031c-0.394,0-0.542-0.094-0.737,0.345H0.641c0.099,0.195,0.39,0.345,0.588,0.493h1.623
                                C2.75,64.377,2.705,64.031,2.113,64.031z"/>
                            <!-- <path class="st4" d="M16.905,134.254c0,0.146,0.145,0.342,0.293,0.342c0.345,0,0.494-0.145,0.887-0.242v-0.344
                                c-0.294-0.297-0.639-0.445-1.18-0.445C16.954,133.812,16.905,133.958,16.905,134.254z"/>
                            <path class="st4" d="M15.971,131.896c0.149,0.147,0.197,0.492,0.443,0.492c0.049,0,0.197-0.1,0.197-0.1
                                C16.462,132.09,16.216,131.896,15.971,131.896z"/>
                            <path class="st4" d="M14.988,131.798c0.246,0,0.492-0.2,0.64-0.391h-0.887C14.741,131.553,14.842,131.798,14.988,131.798z"/>
                            <path class="st4" d="M13.908,130.915c-0.1,0-0.248,0-0.344,0.048c0,0,0.244,0.344,0.442,0.344c0.097,0,0.246-0.101,0.246-0.201
                                c0-0.096,0-0.191,0-0.292c-0.149,0-0.295,0.052-0.443,0.101H13.908z"/>
                            <path class="st4" d="M11.841,129.733c-0.046,0.099-0.195,0.199-0.294,0.246c0,0.144,0.248,0.342,0.394,0.342
                                c0.098,0,0.248,0,0.343,0c-0.047-0.148-0.047-0.245-0.047-0.39C12.088,129.931,11.941,129.832,11.841,129.733z"/> -->
                        </g>

                        <g @click='setRegion("Asia")' :class='{selected: regionSelected == "Asia"}' class="region asia">
                            <path class="st5" d="M348.205,149.978c0,0.887-0.634,2.016-1.57,2.016c-0.687,0-1.225-0.687-1.225-1.379
                                c0-1.426,0.681-2.505,0.681-3.979C346.783,147.815,348.205,148.408,348.205,149.978z"/>
                            <path class="st5" d="M373.906,160.644c-0.145,0-0.049-0.296-0.145-0.442c-0.051-0.097-0.643-0.149-0.786-0.249
                                c-0.249-0.147-0.59-1.324-0.69-1.717c-0.342-1.131-1.475-0.935-1.817-1.623c-0.296-0.588-0.443-0.886-0.739-1.476
                                c-0.195-0.438-0.686-0.343-1.129-0.438c-0.393-0.103-1.18-1.473-1.328-1.968v-0.396c0.099,0,0.197,0,0.297,0
                                c0.491,0,0.589,0.246,0.983,0.446h1.62c1.08,0.342,1.427,1.08,1.82,1.865c0.096,0.249,0.342,0.198,0.54,0.249
                                c0.735,0.242,1.078,0.831,1.569,1.326c0.937,0.935,1.476,1.57,2.853,2.015c0.737,0.244,1.572,0.49,1.572,1.23
                                c0,0.045-0.096,0.193-0.096,0.242c0.145,0,0.244,0,0.392,0c0.194,0,0.343,0.245,0.442,0.443l-0.198,0.493
                                c0,0.638,0.392,0.785,0.932,0.785c0,1.033,0.594,1.325,1.133,1.866c0.295,0.296,0.985,0.442,0.985,0.934c0,0.638,0,1.032,0,2.114
                                c-0.49,0.199-0.249,0.885-0.249,1.375c0,0.543-0.983,0.689-1.325,0.689c-0.246,0-1.131-1.279-1.181-1.329
                                c-0.931-0.933-1.82-1.324-2.701-2.211c-0.985-0.981-1.376-1.866-1.917-2.996C374.644,161.625,374.055,160.644,373.906,160.644z"/>
                            <path class="st5" d="M390.563,164.867c-0.093,0-0.048-0.538-0.093-0.738c-0.396-0.048-0.641,0.249-1.034,0.249
                                c-1.818,0-1.033-1.719-1.673-2.703c0-0.049,0.05-0.196,0.05-0.246c-0.098-0.246-0.391-0.246-0.638-0.296
                                c-0.245-0.05-0.735-1.821-0.735-2.063c0-0.835,1.033-0.489,1.72-0.489c1.521,0,1.327-1.525,2.013-2.216
                                c0.343-0.341,0.837-0.341,1.377-0.442c0.737-0.145,0.737-0.639,1.129-1.029c-0.194-0.196,0.343-0.592,0.343-0.592
                                c0.298-0.146,0.249-0.248,0.492-0.345c0.149-0.048,0.199-0.293,0.296-0.391c0.444-0.443,1.572-0.195,1.768-0.591
                                c0.392-0.785,1.475-2.652,2.263-2.652c0.834,0,0.735,0.492,0.735,1.226c0,1.033,2.065,0.445,2.065,1.426
                                c0,0.443-1.082,0.148-1.082,0.591c0,0.098,0.101,0.246,0.197,0.246c-0.096,0.146-1.424,0.688-1.424,0.688
                                c0.047,0.147,0.148,0.294,0.197,0.442c-0.15,0.048-0.344,0.195-0.344,0.343c0,0.74,1.325,3.196,2.209,3.196
                                c-0.147,0.737-0.543,0.493-1.08,0.493c-0.143,0-0.345,0.045-0.49,0.097l-0.247,0.492c-0.245,1.081-0.638,1.377-0.638,2.209
                                c-0.591,0.099-1.08,0.835-1.08,1.133c0,0.341,0.242,0.439,0.242,0.686c0,0.396-0.587,1.129-0.587,1.67
                                c-0.787,0.197-0.884,0.787-1.526,0.787c-0.493,0-0.639-0.392-0.639-0.686c-0.787-0.201-1.328-0.788-2.163-0.788
                                c-0.391,0-0.637,0.538-1.228,0.538C390.811,165.112,390.714,164.867,390.563,164.867z"/>
                            <path class="st5" d="M403.686,161.625c-0.197,0.246-0.246,0.491-0.541,0.491c-0.737,0-1.031-0.933-1.031-1.669
                                c0-0.982,0.784-0.789,1.618-0.789c0.836,0,1.182-0.193,1.87-0.193c0.637,0,1.08,0.34,1.719,0.34c0.983,0,1.326-0.832,1.476-1.57
                                c0.148,0,0.196-0.247,0.246-0.396h-0.294c-0.595,0.396-1.278,0.835-1.574,1.133h-0.391l-3.443-0.591
                                c-1.274,0-1.866,1.622-1.866,2.801c0,0.295-0.393,0.538-0.44,0.738c-0.347,1.128-0.885,1.575-0.885,2.652
                                c0,0.587,0.683,0.587,0.983,0.638c0,0.786,0,1.817,0,2.357c0,0.295,0.393,0.641,0.784,0.641c0.544,0,0.738-1.72,0.738-1.964
                                c0-0.642-0.441-0.985-0.441-1.623c0-0.347,0.394-0.59,0.737-0.59c0.147,0,0.246,0.047,0.391,0l-0.196,1.034
                                c0,0.637,0.787,0.882,0.787,1.519c0,0.149,0.048,0.3,0,0.443h0.636c0-0.539,1.084-0.487,1.084-1.031
                                c-0.197-0.048-0.687-0.587-0.687-0.886c0-0.094,0.147-0.191,0.193-0.292c-0.292-0.149-1.227-1.915-1.227-2.065
                                c1.376,0,1.475-1.079,2.604-1.424v-0.39c-0.297-0.052-2.898,0.735-2.947,0.88L403.686,161.625z"/>
                            <path class="st5" d="M395.332,172.436c-0.489,0-0.444-0.346-1.032-0.346c-0.148,0-0.047,0.346,0,0.493
                                c-1.622-0.64-3.291-0.738-5.208-0.738c-0.59,0-0.737-0.638-1.229-0.638c-0.933,0-1.721-0.05-2.704-0.342
                                c-0.735-0.247-1.472,0.244-1.965-0.247c-0.489-0.492-0.881-1.083-1.62-1.083c0.051-0.146,0.934-1.033,1.227-1.033
                                c0.149,0,0.246,0.2,0.393,0.2c0.295,0,0.493-0.295,0.789-0.295c1.473,0,2.112,1.571,3.732,1.571c0.739,0,0.885-0.736,1.473-0.736
                                c0.985,0,1.328,0.884,2.215,0.884l1.473-0.196h0.393c-0.049,0.248-1.72,0.392-1.817,0.346c0.539,0.538,1.175,0.637,2.06,0.637
                                c0.296,0,0.296,0.345,0.395,0.442c0.345,0.347,0.738-0.05,1.178,0.097c0.443,0.149,0.443,0.297,0.787,0.441
                                C395.774,172.09,395.43,172.436,395.332,172.436z"/>
                            <path class="st5" d="M404.619,172.733c-0.687,0-1.378,0-1.769,0c-0.344,0-1.082,0.047-1.082-0.493c0-0.395,0.542-0.347,0.886-0.347
                                c0.887,0,1.229,0.347,1.965,0.347c0.739,0,0.983-0.297,1.426-0.492C405.7,172.389,405.258,172.534,404.619,172.733z"/>
                            <path class="st5" d="M400.491,172.682h-0.44l-0.393-0.197c-0.49,0.197-0.692,0.491-1.181,0.491c-0.395,0-0.984-0.148-0.984-0.344
                                c0-0.542,0.392-0.491,0.837-0.491c0.441,0,0.687,0.099,0.934,0.198c0-0.148,0.048-0.249,0.048-0.396h1.179
                                C400.491,172.339,400.392,172.583,400.491,172.682z"/>
                            <path class="st5" d="M402.26,174.796c-0.244,0-0.294-0.493-0.294-0.639c-0.491,0-1.278-0.049-1.278-0.538
                                c0-0.396,0.59-0.3,0.936-0.3c0.144,0,0.342,0,0.49,0c0,0.689,0.736,0.541,0.736,1.131c0,0.15-0.243,0.346-0.39,0.346
                                C402.408,174.796,402.31,174.796,402.26,174.796z"/>
                            <path class="st5" d="M396.362,171.893c0.151,0,0.298,0,0.443,0c0.247,0,0.247,0.248,0.446,0.347
                                c-0.05,0.294-0.251,0.493-0.491,0.493c-0.15,0-0.398-0.199-0.398-0.344C396.362,172.24,396.362,172.044,396.362,171.893z"/>
                            <path class="st5" d="M408.945,173.419c-0.245,0,0,0.489-0.148,0.641c-0.244,0.244-1.08,0.831-1.623,0.831
                                c-0.193,0-0.342-0.24-0.342-0.441c0-1.083,0.931-1.083,1.669-1.325c0.146-0.049,0.146-0.344,0.247-0.443
                                c0.49-0.491,2.652-0.686,3.093-0.395C411.356,173.025,409.73,173.419,408.945,173.419z"/>
                            <path class="st5" d="M411.748,165.067c0,0.094-0.347,0.637-0.495,0.637c-0.295,0-0.881-0.543-0.881-0.934
                                C410.372,164.178,411.748,164.574,411.748,165.067z"/>
                            <path class="st5" d="M416.363,164.473c0.051,0.393,0.591,0.495,0.591,0.888c0,0.096-0.195,0.096-0.293,0.096
                                c-0.693,0-1.032-0.49-1.723-0.49c-0.492,0-0.734,0-1.131,0c-0.197,0-0.882-0.199-0.882-0.199c0-0.244,0.244-0.34,0.344-0.441h2.212
                                c0.099,0.342,0.44,0.197,0.734,0.197L416.363,164.473z"/>
                            <path class="st5" d="M412.777,160.053v-0.345c0.392,0,0.934-0.049,1.127-0.098c-0.044-0.393-0.391-0.488-0.391-0.739
                                c0-0.29,0.49-0.344,0.49-0.635c-0.834-0.196-1.177-0.59-1.275-1.28c-0.395,0.2-0.442,0.592-0.442,0.984
                                c0,0.641,0.391,0.832,0.391,1.371c0,0.494,0,0.347,0,0.641c0,0.295,0.148,0.935,0.638,0.935
                                C413.071,160.543,412.972,160.348,412.777,160.053z"/>
                            <g>
                                <path class="st5" d="M410.227,150.561c-0.044-0.126-0.174-0.214-0.263-0.346c-0.185,0.255-0.41,0.339-0.411,0.593
                                    c-0.007,0.3,0.527,0.437,0.52,0.906c-0.004,0.342-0.189,0.595-0.502,0.592c-0.137,0-0.222-0.22-0.222-0.346
                                    c-0.137,0.08-0.315,0.036-0.447,0.034c-0.626-0.011-1.152-0.704-1.139-1.518c0.008-0.297,0.096-0.426,0.231-0.507
                                    c-0.044-0.131-0.128-0.301-0.172-0.43l-0.626-0.013c-0.001,0.126-0.049,0.255-0.008,0.383c-0.268-0.003-0.356-0.133-0.443-0.221
                                    c0,0-0.094,0.172-0.138,0.214c-0.134-0.046-0.44-0.396-0.44-0.396c-0.457,0.422-0.393,1.835-0.887,1.824
                                    c-0.36-0.005,0.016-0.81,0.016-0.983c0.005-0.128-0.172-0.303-0.169-0.43c0.008-0.427,0.19-0.681,0.288-1.106
                                    c0.94,0.019,0.733-0.927,1.541-0.913c0.582,0.01,0.479,0.691,0.793,0.696c0.09,0,0.177,0.006,0.267,0.007
                                    c0.006-0.3,0.144-0.512,0.415-0.55c0.014-0.599,0.814-0.414,0.954-0.667c0.142-0.298,0.106-0.896,0.147-1.022
                                    c0.318,0.004,0.539,0.224,0.715,0.395c0.352,0.349,1.071,2.886,1.065,3.268c-0.01,0.473-0.553,0.72-0.565,1.445
                                    c-0.179-0.046-0.568-0.994-0.562-1.079L410.227,150.561z"/>
                                <path class="st5" d="M399.374,148.066l-0.311-0.006c0.469-1.276,2-1.764,2.512-2.692c0.091-0.212,0.097-0.427,0.234-0.549
                                    c-0.009,0.425,0.215,0.517,0.389,0.687c-0.18,0.127-0.226,0.256-0.45,0.253c-0.096,0.381-0.413,0.462-0.77,0.455
                                    C400.779,147.192,399.919,147.733,399.374,148.066z"/>
                                <path class="st5" d="M401.846,137.546c0.186-0.293,0.282-0.89,0.29-1.276c0.01-0.513-0.21-0.861-0.198-1.373
                                    c0.008-0.426,0.104-0.852,0.461-0.848c0.493,0.01,0.394,0.778,0.793,0.784c0.27,0.003,0.409-0.337,0.592-0.416
                                    c0,0.038,0.085,0.172,0.082,0.213c0,0.087,0.087,0.218,0.085,0.303c-0.01,0.557,0.564,1.207,0.552,1.931
                                    c-0.02,0.945-0.826,1.014-0.841,1.911c-0.011,0.643,0.596,1.722,1.088,1.942c0.001-0.04,0.001-0.169,0.004-0.214
                                    c0.001-0.166,0.229-0.336,0.497-0.332c0.536,0.012,0.655,0.997,0.924,1.001c0.179,0.001,0.227-0.292,0.408-0.291
                                    c0.089,0,0.177,0.086,0.268,0.09c-0.054,0.254-0.274,0.291-0.277,0.507c0,0.081,0.221,0.047,0.312,0.006
                                    c-0.006,0.339,0.261,0.517,0.573,0.521c-0.003,0.132-0.004,0.216-0.004,0.347c-0.005,0.083-0.095,0.254-0.181,0.25
                                    c-0.136-0.002-0.178-0.216-0.222-0.344c-0.258-0.521-1.329-0.922-1.591-1.31c-0.227,0.29-0.097,0.464,0.032,0.809
                                    c-0.628-0.009-0.658-0.949-1.286-0.963c-0.266-0.001-0.451,0.254-0.719,0.248c-0.312-0.008-0.668-0.398-0.664-0.61
                                    c0.004-0.26,0.188-0.385,0.278-0.469l0.006-0.427l-0.264-0.005c-0.096,0.126-0.189,0.298-0.323,0.293
                                    c-0.18-0.003-0.978-0.527-0.973-0.784C401.56,138.354,401.402,137.454,401.846,137.546z"/>
                                <path class="st5" d="M409.473,144.948l-0.671-0.01c0.038,0.342,0.21,0.773,0.387,0.86l-0.007,0.257
                                    c-0.133,0-0.266-0.007-0.4-0.007c-0.317-0.003-0.399-0.349-0.214-0.602l0.005-0.254c-0.315-0.005-0.623-0.443-0.665-0.568
                                    l0.004-0.258c0.224,0.004,0.539,0.009,0.583,0.009c0.087,0,0.226-0.168,0.228-0.251c0.015-0.598-0.97-0.914-1.055-1.26
                                    c0.138-0.039,0.275-0.165,0.409-0.164c0.357,0.007,0.626,0.183,0.983,0.19c0,0.127,0.04,0.299-0.008,0.426
                                    c0.092,0.002,0.18,0.002,0.269,0.007c0.04,0.254-0.01,0.681-0.01,0.681s0.304,0.392,0.349,0.392
                                    C409.609,144.652,409.523,144.779,409.473,144.948z"/>
                                <path class="st5" d="M405.057,143.631c0.178,0.219,1.294,0.367,1.288,0.75c-0.01,0.727-1.053,1.093-1.457,1.344
                                    c0.012-0.727-0.154-1.542-0.146-1.928c0-0.088,0.183-0.21,0.273-0.21c0.09,0.001,0.22,0.175,0.264,0.219L405.057,143.631z"/>
                                <path class="st5" d="M407.223,145.466c-0.01,0.514-0.508,0.846-0.519,1.359c-0.008,0.387,0.221,0.301,0.216,0.56
                                    c0,0.084-0.184,0.21-0.27,0.21c-0.182-0.003-0.977-0.829-0.971-1.129c0.004-0.129,0.274-0.252,0.406-0.251
                                    c0.006-0.126,0.249-1.192,0.474-1.234C407.01,144.861,407.229,145.214,407.223,145.466z"/>
                                <path class="st5" d="M407.813,145.046c-0.013,0.77-0.6,0.974-0.611,1.617c-0.047-0.002-0.181-0.002-0.223-0.002
                                    c0.139-0.511,0.416-0.85,0.433-1.617C407.544,145.044,407.677,145.046,407.813,145.046z"/>
                                <path class="st5" d="M408.373,146.128c-0.005,0.472-0.326,0.853-0.728,0.839c-0.089,0-0.266-0.13-0.266-0.217
                                    c0.003-0.253,0.276-0.377,0.417-0.634C408.017,146.206,408.237,146.295,408.373,146.128z"/>
                                <path class="st5" d="M406.319,143.311c0.005-0.301,0.005-0.468,0.011-0.639l0.402,0.006c0.13,0.389,0.711,0.529,0.701,1.081
                                    c-0.004,0.216-0.492-0.221-0.487-0.434C406.721,143.279,406.543,143.316,406.319,143.311z"/>
                                <path class="st5" d="M403.859,142.972c-0.271-0.004-1.272-1.647-1.272-1.647l0.446,0.007c0.533,0.349,1.027,0.273,1.151,0.789
                                    C404.268,142.465,404.037,142.972,403.859,142.972z"/>
                                <path class="st5" d="M404.328,141.611c0.089,0.086,0.358,0.048,0.449,0.005C404.693,141.532,404.422,141.569,404.328,141.611z"/>
                            </g>
                            <path class="st5" d="M402.702,128.161c-0.883,0-0.737-0.639-0.737-1.379c0-1.031,1.036-2.948,1.72-2.948
                                C404.914,123.834,403.831,128.161,402.702,128.161z"/>
                            <path class="st5" d="M385.898,132.68c0.391,0,2.065-1.225,2.702-1.225c0.247,0,0.587,0.442,0.587,0.735
                                c0,0.688-1.473,2.21-2.208,2.21c-0.74,0-1.082-0.341-1.082-0.98C385.898,133.123,385.651,132.68,385.898,132.68z"/>
                            <path class="st5" d="M382.556,164.033c-0.097,0-0.294-0.536-0.345-0.689c-0.194-0.391-0.983-0.148-0.983-0.538
                                c0-0.247,0.688-0.593,1.034-0.64c0.246,0.739,0.538,1.67,1.226,1.67c0,0.152,0,0.343,0.048,0.493c-0.095,0-0.241,0-0.343,0
                                C382.899,164.328,382.752,164.033,382.556,164.033z"/>
                            <path class="st5" d="M384.767,163.787c0.146,0,0.344,0,0.488,0c0.151,0,0.3,0.2,0.449,0.246c-0.052,0.295-0.248,0.59-0.545,0.59
                                c-0.292,0-0.391-0.347-0.391-0.59C384.767,163.987,384.716,163.787,384.767,163.787z"/>
                            <path class="st5" d="M372.486,161.625c0.391,0.491,0.734,0.784,0.685,1.375c-0.244-0.048-0.885-0.835-0.885-1.181
                                c0-0.047,0-0.145,0-0.194h0.395c0,0.049,0,0.246,0,0.246L372.486,161.625z"/>
                            <path class="st5" d="M370.763,158.629c0.295,0.191,1.079,1.176,0.688,1.176c-0.198,0-0.836-0.788-0.886-1.08h-0.044
                                L370.763,158.629z"/>
                            <path class="st5" d="M416.661,113.124l0.192-0.687c-0.293-0.1-0.342-0.395-0.393-0.641c0,0.149-0.097,0.296,0,0.442
                                c-0.246,0-0.439,0.2-0.59,0.2c-0.241,0-0.489-0.297-0.489-0.541c0-0.297,1.377-1.229,1.521-1.229c0.393,0,0.691,0.392,1.279,0.392
                                c0,0.145-0.097,0.49,0.05,0.49l0.443,0.395c0,0.147-0.097,0.342,0,0.493c-0.148,0-0.295,0.096-0.443,0
                                c-0.146,0.883-0.54,2.406-1.229,2.406c-0.148,0-0.148-0.292-0.148-0.441v0.293c-0.393,0-0.686-0.586-0.686-0.782
                                C416.168,113.811,416.661,113.124,416.661,113.124z"/>
                            <path class="st5" d="M421.817,111.405c-0.39,0-0.591-0.297-0.734-0.346c-0.443,0.443-0.538,1.227-1.178,1.227
                                c-0.251,0-0.346-0.491-0.298-0.737c-0.296-0.195-0.392-0.295-0.392-0.537c0,0,0.93-0.69,0.93-0.74
                                c0.839,0.249,0.985-0.588,1.672-0.588C422.9,109.684,422.31,111.405,421.817,111.405z"/>
                            <path class="st5" d="M423.341,110.716c-0.148,0,0-0.246,0-0.394c0-0.295,0.151-0.638,0.151-0.835
                                c-0.592-0.148-0.887-0.443-1.476-0.443c-0.297,0-0.35,0.342-0.491,0.394c-0.395,0.194-0.69-0.052-1.082,0.149
                                c-0.641,0.293-1.525,0.684-2.555,0.684c-0.346,0-0.691,0-0.691-0.193c0-0.15,0.446-0.248,0.639-0.345
                                c1.032-0.689,1.474-1.92,2.751-1.92c1.475,0,1.92,0,3.048,0c0.837,0,1.13-1.521,1.573-1.521c0.049,0,0.688-1.179,0.834-1.227
                                c-0.146,0.147-0.244,0.341-0.244,0.538c0,0.1,0.149,0.247,0.244,0.247c0.74,0,0.84-0.49,1.427-0.688
                                c1.03-0.344,2.704-2.407,2.704-3.982c0-0.342-0.2-0.342-0.2-0.591c0-0.588,0.494-1.816,1.133-1.816c0.096,0,0.047,0.64,0.638,0.148
                                c-0.047,0-0.096-0.048-0.149-0.048c-0.147,0-0.246-0.445-0.096-0.445c0.343,0,0.538,0.1,0.589,0.248
                                c0.15,0.345,0.101,0.639,0.101,0.981c0,0.69,0.684,1.031,0.684,1.72c0,0.789-0.684,0.886-0.684,1.525c0,0.097,0,0.196,0,0.296
                                c-0.152,0.145-0.299,0.049-0.444,0.095c-0.491,0.147-0.346,1.671-0.346,2.21c0,0.444-0.538,0.74-0.538,1.377
                                c0,0.442,0.146,0.639,0.247,0.933c-0.59,0.299-0.59,0.791-1.18,0.984c-0.197-0.393,0-0.443,0.047-0.784
                                c-0.685,0.441-1.129,0.491-1.325,1.176c-0.098,0-0.246-0.146-0.246-0.245h-0.491c-0.297,0.295-0.588,0.593-0.983,0.593
                                c-1.032,0-0.981-0.838-1.425-0.838c-0.147,0-0.296,0.245-0.296,0.394c0,0.193,0.442,0.786,0.491,0.786
                                c-0.147,0.048-0.343,0.048-0.491,0.048c-0.343,0-0.932,0.787-1.179,1.275C423.636,111.108,423.684,110.716,423.341,110.716z"/>
                            <path class="st5" d="M431.006,95.089c0.148,0.049,0.344,0,0.493,0c0.835,0,1.179-1.916,1.179-2.552c0-0.2-0.247-0.297-0.247-0.493
                                c0-0.346,0.2-0.492,0.295-0.641c1.328,0.884,1.918,2.458,3.834,2.458c0.391,0,0.734-0.292,1.033-0.098
                                c-0.098,0.249-0.3,0.344-0.3,0.59c0,0.295,0.397,0.593,0.594,0.64c-0.443,0.294-0.787,0.444-1.525,0.444
                                c-1.033,0-1.374,1.127-1.769,1.716c-0.786-0.491-1.274-0.589-2.113-1.129c-0.147,0.047-0.343,0.244-0.343,0.395
                                c-0.639,0-1.473-0.492-1.473,0.343c0,0.491,0.734,0.491,0.931,0.687c-0.392,0.54-0.784,0.343-1.18,0.737h-0.292
                                c0-0.293,0-1.032,0-1.129c0-0.196-0.244-0.295-0.244-0.492c0-0.54,0.787-0.886,0.883-1.227v-0.391
                                c0.099,0.093,0.194,0.144,0.345,0.241L431.006,95.089z"/>
                            <path class="st5" d="M438.822,93.076l0.246,0.194c-0.198,0.3-0.789,1.032-1.229,1.032c-0.147,0-0.099-0.29,0-0.441
                                c0.198-0.292,0.736-0.739,1.13-0.739L438.822,93.076z"/>
                            <path class="st5" d="M442.31,91.647c-1.132,0-1.132,0.984-1.819,0.984c-0.047,0,0-0.197,0-0.246c0,0,0.495-1.083,1.082-1.083
                                c0.295,0,0.539,0.051,0.737,0V91.647z"/>
                            <path class="st5" d="M443.882,90.568c0.148,0,0-0.249,0.052-0.393c0.096-0.296,0.682-0.296,0.784-0.296
                                c-0.048,0.296-0.442,0.983-0.836,0.983C443.784,90.863,443.784,90.568,443.882,90.568z"/>
                            <path class="st5" d="M435.33,84.279c-1.325,0-1.474,1.573-1.474,2.85c0,1.13,1.032,1.671,1.43,2.509c0,0-0.103,0.048-0.151,0.048
                                c-0.198,0.047-0.442-0.297-0.49-0.494c-0.147,0-0.345,0-0.492,0c-0.59,0-0.59,1.084-1.18,1.084c-0.146,0,0-1.181,0-1.424
                                c0-0.888,0.2-1.475,0.2-2.213c0-0.69-0.3-0.786-0.3-1.374c0-0.741,0.247-0.936,0.247-1.524c0-1.279,0-1.673,0-2.9
                                c0-0.737-0.736-1.033-0.736-1.77c0-1.032,0.295-2.36,1.031-2.508c0-0.293,0.2-0.393,0.2-0.635c0-0.494,0-0.642,0-1.035
                                c0.097,0,0.242,0,0.344,0c0,0.984,0.39,1.624,0.59,2.262c0.195,0.686-0.199,1.178,0.146,1.866v1.179
                                c-0.099,0.491,0.244,1.034,0.343,1.329c0.395,1.227,1.032,2.212,1.326,3.391C436.119,84.573,435.772,84.279,435.33,84.279z"/>
                            <path class="st5" d="M314.4,39.463c-1.082-1.083-2.852-1.476-2.852-3.342c0-3.144,3.39-6.438,5.899-7.127
                                c0.881-0.244,1.128-1.327,2.356-1.327c0.592,0,0.788-0.983,1.082-1.128c1.621-0.788,2.848-0.393,4.57-1.228
                                c1.23-0.588,5.011-0.884,5.011-2.557c0-0.539-1.374-1.227-1.964-1.227c-2.458,0-4.768,2.799-7.373,2.799
                                c-0.588,0-0.884-0.589-1.473-0.589c-0.936,0-0.936,0.589-1.57,0.589c-1.821,0-3.196,2.358-4.426,2.358
                                c-0.738,0-1.228,0.346-1.962,0.346c-0.345,0-0.492,0.293-0.492,0.491c0,0.294,0.342,0.493,0.342,0.885
                                c0,1.375-2.554,2.112-2.554,2.949c0,0.391,0.688,0.491,0.737,1.079c-0.787,0.197-2.359,0.543-2.359,1.379c0,0.538,0,0.735,0,0.735
                                c0,0.884-1.473,0.786-1.473,1.967c0,0.59,0.884,0.982,0.884,0.982s1.324-0.048,1.476,0c0.832,0.246,0.391,1.032,0.735,1.722
                                c0.097,0.246,0.884,0,1.228,0c0.145,0,0.246,0.295,0.343,0.342h3.835c-0.15-0.294-0.346-0.489-0.494-0.59L314.4,39.463z"/>
                            <path class="st5" d="M484.424,38.48c-0.295,0-0.493-0.246-0.493-0.493c0-0.737,1.473-1.62,2.31-1.62
                                c0.739,0,2.604,0.492,2.604,1.228C488.845,38.235,484.962,38.48,484.424,38.48z"/>
                            <path class="st5" d="M470.518,41.528c0.243,0,0.49-0.248,0.49-0.491c0-0.298-0.295-0.396-0.344-0.592h-1.62
                                C469.335,41.037,469.826,41.528,470.518,41.528z"/>
                            <path class="st5" d="M424.226,25.31l-0.493-0.492c-0.195,0.248-0.392,0.441-0.392,0.739c0,0.293,0.151,0.392,0.392,0.392
                                c0.393,0,0.441-0.44,0.493-0.737h-0.149L424.226,25.31z"/>
                            <path class="st5" d="M392.532,29.389c-0.346,0.196-0.687,0.344-1.129,0.344c-0.542,0-1.083-0.148-1.083-0.592
                                c0-0.491,0.591-0.735,1.083-0.735c0.686,0,1.129,0.148,1.129,0.735c0,0.298-0.247,0.443-0.396,0.592L392.532,29.389z"/>
                            <path class="st5" d="M373.71,18.919c-0.592,0-0.735-0.243-0.735-0.734c0-0.787,0.588-0.787,0.882-1.082
                                c1.13-1.131,1.573-2.947,3.538-2.947c0.441,0,0.441,0,0.638,0c0,0.688,0.25,0.688-0.147,1.082c0.346-0.101,0.544-0.491,0.887-0.491
                                c0.684,0,2.699,1.275,2.699,1.964c0,1.178-2.555,1.374-3.439,1.226c-0.197,0-1.717,0.248-1.717,0.248L373.71,18.919z"/>
                            <path class="st5" d="M362.409,11.797c0.246,0,1.621,0.64,1.962,0.737c-0.096,0.886-1.716,0.983-2.307,0.983
                                c-0.293,0-0.982-0.787-0.982-1.228C361.082,11.797,362.114,11.797,362.409,11.797z"/>
                            <path class="st5" d="M373.22,15.629c0,0.442-0.539,0.737-0.984,0.737c-2.161,0-3.735-0.884-5.505-0.884
                                c-0.587,0-1.867-1.377-2.602-1.769c1.621-0.541,2.112-1.08,3.681-1.573c-0.491,0-1.766-0.095-2.06-0.095
                                c-1.379,0-2.312-0.395-3.587-1.033c2.063-0.589,2.799-2.899,5.156-2.899c1.378,0,2.165,1.328,2.952,1.916
                                c-0.494,0.197-0.394,0.1-0.59,0.05c0.047,0.587,0,0.736,0,0.982c0,0.294-0.542,0.688-0.934,0.886c0.491-0.148,0.639,0,1.03,0
                                c0.591,0,0.886,0.492,1.475,0.492c0.347,0,0.64,0,0.984,0c0.984,0,0.984,0.341,1.622,0.983c-0.49,0.392-0.392,0.491-0.392,0.98
                                c0,0.493-0.689,0.541-0.98,0.638C372.827,15.136,373.22,15.286,373.22,15.629z"/>
                            <path class="st5" d="M367.223,21.626c0.295-0.149,0.536-0.344,0.737-0.491h0.737c-0.097,0.686-0.394,0.982-0.981,0.982
                                C367.469,22.117,367.272,21.868,367.223,21.626z"/>
                            <path class="st5" d="M361.817,8.748c-0.736,0.1-1.669,0.249-1.965-0.391h1.817L361.817,8.748z"/>
                            <path class="st5" d="M384.669,17.841c-0.049,0.344-0.295,0.736-0.637,0.736c-0.591,0-0.839-0.342-0.985-0.59
                                C384.079,17.793,384.229,18.038,384.669,17.841z"/>
                            <path class="st5" d="M304.177,11.059c-0.834,0.638-1.275-0.049-2.062,0.394c-0.445,0.243-0.541,1.13-0.984,1.08
                                c-0.246-0.047-0.394-0.296-0.537-0.49h0.043c-0.734,0-1.225-0.148-1.372-0.59c0.39-0.198,0.638-0.298,1.03-0.394
                                c-0.441,0-0.686-0.049-0.882-0.096c0.242-0.15,0.491-0.248,0.735-0.444c-0.44-0.147-0.687-0.442-1.129-0.442
                                c-0.538,0-1.473,0.638-2.213,0.638c-0.395,0-0.685-0.146-1.08-0.196c0.44-0.787,2.754-0.933,3.786-0.933
                                c1.225,0,1.912,0.638,2.847,0.638c0.74,0,1.083-0.638,1.819-0.638c0.59,0,1.18,0.393,1.474,0.491
                                c-0.098,0.886-0.734,0.442-1.323,0.886c-0.151,0.047-0.347,0.047-0.496,0v0.491L304.177,11.059z"/>
                            <path class="st5" d="M322.362,9.831c-0.593,0-0.84-0.246-0.84-0.737c0-0.687,2.018-1.475,2.754-1.475l0.687,1.571
                                C324.619,9.438,322.702,9.831,322.362,9.831z"/>
                            <path class="st5" d="M320.638,9.489c0.049,0.441,0,0.537,0,0.735c-0.293,0.441-0.787,0.246-1.227,0.246l0.244,0.589
                                c-0.292,0.147-2.699,0.247-2.699,0c0-0.738,0.39-1.129,0.98-1.327c-0.05-0.393,0-0.543,0-0.638h1.622
                                c-0.098,0.246-0.394,0.395-0.49,0.395C319.364,9.538,320.198,9.586,320.638,9.489z"/>
                            <path class="st5" d="M314.25,12.288c-0.294,0-0.686-0.492-0.984-0.492c-0.093,0.492-0.293,0.492-0.735,0.492
                                c-0.247,0-0.493-0.15-0.493-0.343c0-0.69,1.969-0.982,2.456-0.982c0.645,0,1.135,0.243,1.378,0.588
                                C314.89,11.551,314.938,12.288,314.25,12.288z"/>
                            <path class="st5" d="M314.25,9.979c-0.59,0-1.372,0.59-1.965,0.59c-0.345,0-2.358-0.884-2.455-0.933
                                c0.292-0.247,0.391-0.346,0.637-0.541h2.064c-0.541-0.196-0.341-0.392-0.834-0.097v-0.64c1.475,0,1.523-0.983,2.949-0.983
                                c0.049-0.443,0.391-0.837,0.831-0.837c0.103,0,0.151,0.444,0.151,0.591c-0.2,0.148-0.445,0.392-0.445,0.637
                                c-0.784,0.492-1.77,0.79-2.263,1.281c1.379,0.193,1.725,0.293,2.804,0.736C315.33,10.077,314.793,9.979,314.25,9.979z"/>
                            <path class="st5" d="M434.548,26.931c-0.395,0-0.641,0.492-1.227,0.492c-0.348,0-0.544-0.492-0.889-0.492
                                c-0.395,0-0.59,0.342-0.983,0.342c-0.294,0-0.588,0.393-1.079,0.393c-0.442,0-0.639-0.193-0.888-0.491
                                c-0.342,0.298-0.586,0.739-1.079,0.739c-1.278,0-2.456-1.032-2.456-2.215c0-1.125,1.178-1.963,2.208-1.963
                                c1.525,0,1.624,1.573,2.951,1.573c0-0.736,0.243-1.326,0.834-1.326c0.885,0,3.784,1.033,4.815,1.326
                                c-0.195,0.688-0.884,1.228-0.884,1.621H434.548z"/>
                            <path class="st5" d="M280,109.588c-0.146,0-0.341-0.1-0.341-0.248c-0.102,0-0.249-0.149-0.249-0.244
                                c0.74-0.345,2.014-0.838,2.851-0.982c-0.099,0.492-0.344,0.292-0.635,0.441c-0.152,0.052-0.051,0.341-0.1,0.492
                                C281.376,109.34,280.442,109.588,280,109.588z"/>
                            <path class="st5" d="M308.55,143.003c0.052,0.243,0.394,0.341,0.541,0.341c0.392,0,0.787-0.392,0.883-0.588
                                C309.34,142.756,308.994,142.851,308.55,143.003z"/>
                            <path class="st5" d="M301.624,42.511c0.244,0,0.341-0.492,0.587-0.492c0.691,0,1.034,0.639,1.722,0.735v0.639
                                c-0.64,0.343-1.229,0.834-2.065,0.834c-0.592,0-0.882-0.095-0.882-0.588C300.986,43.443,301.526,42.511,301.624,42.511z"/>
                            <path class="st5" d="M318.086,41.428c0,0.342-0.639,0.246-0.639,0.246c-0.643,0-1.722-0.637-1.722-1.228
                                C315.725,39.463,318.086,40.446,318.086,41.428z"/>
                            <path class="st5" d="M331.843,33.073c0.589,0,1.722-0.341,1.964-0.54c-0.492-0.392-0.638-0.934-1.225-0.934
                                c-0.443,0-0.739,0.542-0.739,0.983C331.843,32.731,331.7,33.073,331.843,33.073z"/>
                            <path class="st5" d="M430.223,31.598c0.588-0.782,1.226-1.474,2.356-1.474c1.276,0,2.26,0.837,2.308,1.965h-1.473
                                c-0.884-0.492-2.016-0.392-2.947-0.734L430.223,31.598z"/>
                            <path class="st5" d="M439.068,25.558c0.444,0.342,5.601,0.981,5.796,0.981c0.296,0,0.495,0.246,0.495,0.492
                                c0,0.539-1.425,0.736-2.215,0.736c-1.275,0-3.586-0.59-3.586-1.817L439.068,25.558z"/>
                            <path class="st5" d="M431.449,29.488c0,0.393-0.393,0.637-0.736,0.637c-0.539,0-0.59-0.193-0.59-0.637
                                C430.123,28.7,431.449,28.799,431.449,29.488z"/>
                            <path class="st6" d="M432.459,164.352l-1.893,7.642c-0.286-0.225-0.608-0.392-1.032-0.392c-0.197,0-0.737,0.048-0.737,0.292h-1.033
                                c-0.345,0-0.786,0-0.786-0.391c0-0.59,0.934-0.837,1.229-0.983c-0.688-0.689-0.391-1.376-0.641-2.113
                                c-0.191-0.59-1.571-1.129-2.062-1.378c-0.541-0.242-1.868-0.487-2.651-0.487c-0.74,0-1.036-1.281-1.82-1.035v-0.44
                                c-0.15,0.391-0.344,0.882-0.739,0.882c-0.149,0-0.443,0-0.492-0.148c-0.243-0.489,0-0.69-0.243-1.082
                                c-0.249-0.391-0.788-0.195-0.886-0.591c0.686-0.341,2.162-0.242,2.507-0.834h-0.345c-0.541,0.15-0.884,0.202-1.427,0.202
                                c-0.243,0-0.44-0.102-0.686-0.153c-0.146-0.049-0.246-0.295-0.297-0.439c-0.342-0.692-1.524-0.394-1.524-1.086
                                c0-0.292,0.393-0.438,0.691-0.438c0.588,0,0.932-0.838,1.815-0.838c0.836,0,1.475,0.786,2.36,0.984v1.767
                                c0,0.248,0.294,0.442,0.739,0.442c0,0.2,0.685,0.983,0.984,0.983c0.342,0,0.784-0.732,0.881-0.833
                                c0.392-0.389,0.492-0.441,1.179-0.641c0.443-0.146,1.034-1.033,1.622-1.129c0.05,0.248,0.441,0.195,0.59,0.248
                                c0.587,0.195,0.985,0.783,1.276,0.931h1.33c0.2,0.204,0.603,0.339,0.848,0.407l-0.11-0.111c0.039,0.039,0.083,0.078,0.132,0.117
                                c0.044,0.012,0.082,0.022,0.111,0.029v0.054C431.631,163.989,432.022,164.185,432.459,164.352z"/>
                            <path class="st5" d="M422.31,167.965c0.1,0.395,0.198,0.195,0.198,0.536c0,0.301-0.149,0.492-0.394,0.492
                                c0,0.493,0.097,1.035-0.297,1.035c-0.15,0-0.292-0.245-0.292-0.394c0-0.491,0.491-0.543,0.491-0.832
                                C422.016,168.456,421.967,168.011,422.31,167.965z"/>
                            <path class="st5" d="M417.837,176.17v0.737c-0.146,0-0.344,0-0.49,0c-0.588,0-1.278-0.292-1.476-0.492
                                C416.759,176.221,417.101,176.124,417.837,176.17z"/>
                            <path class="st6" d="M389.532,29.389c1.081,1.768,9.584,2.752,11.206,3.193h5.405c0-1.524,0.693-2.072,1.962-1.965
                                c10.116,0.838,6.146,3.341,6.146,3.688c0,1.375,0.882,3.93,2.946,3.93c0.788,0,1.081-1.522,1.229-2.214
                                c1.624,0.1,1.818,1.23,3.196,1.23h7.859c0.299-1.917,0.839-3.927,4.671-3.927c3.835,0,7.075,0.734,10.074,1.715
                                c1.425,0.443,1.475,1.624,2.459,2.458c1.08,0.934,3.83,0.246,5.647,0.246c3.341,0,5.014,0.885,5.651,3.44l11.3,0.246
                                c0.936-0.047,4.718,4.772,3.199,0c-0.211-0.654,0.883-0.982,2.456-0.982c5.7,0,10.858,2.408,14.739,4.67
                                c2.803,1.618,8.555,2.942,10.321,5.304c-1.325,0.444-2.112,0.346-2.948,1.328c-0.542,0.639-0.736,2.458-1.718,2.458
                                c-2.214,0-4.769-4.181-7.863-4.181c-0.984,0-0.492,1.231-0.738,1.723c-0.588,1.176-3.44,0.836-3.44,2.951
                                c0,0.782,5.821,3.539-1.473,3.683c-3.833,0.073-6.926,5.649-11.3,5.649c-1.474,0-5.117-4.563-9.092,3.933
                                c-0.48,1.021,0,1.621,0,2.212c0,0.883-1.721,1.325-1.721,2.949c0,1.029-2.21,1.325-1.965,2.699c-2.014,0.491-2.7,3.195-3.684,4.67
                                c-1.38-1.082-1.967-7.028-1.967-8.845c0-2.114,1.718-3.245,2.951-3.931c1.473-0.835,2.405-2.703,3.439-4.178
                                c0.882-1.278,2.095-0.856,3.437-1.719c3.853-2.477,1.28-3.539,0.736-3.439c-5.361,6.615-5.01,2.356-4.173,1.718
                                c-1.671-0.247-2.065,0-2.947,0c-3.393,0-4.866,1.919-5.657,5.161h-2.702c-1.227,0-2.164-0.983-4.175-0.983
                                c-1.621,0-7.47,0.492-9.336,0.983c-2.117,0.592-4.129,3.143-5.655,4.668c-1.031,1.032-3.686,2.554-3.686,3.93
                                c0,0.295,1.515,3.669,4.426,1.473c0.981-0.737,3.112,1.853,3.112,3.178c0,1.083-0.575,2.195-0.863,2.704
                                c-0.765,1.363-0.186,2.278-0.53,3.705c-0.294,1.278-1.387,2.881-2.17,3.666c-1.97,1.966-4.613,7.883-7.415,7.883
                                c-1.523,0-3.439-0.495-3.439-0.495c-1.181,0.937-1.574,1.721-2.212,3.05c-0.196,0.392-1.078,0.884-1.426,1.227
                                c-0.391,0.394-0.882,0.737-1.474,0.737c-0.094,0.837,0.395,1.277,0.836,1.72c1.082,1.08,1.817,2.211,1.817,4.127
                                c0,1.131-0.292,1.672-0.983,1.868c-0.292,0.098-1.525,0.146-1.525,0.587c-0.587,0.151-0.682,0.151-0.979,0.446h-0.739
                                c0-0.246,0.244-0.395,0.296-0.639c-0.244-0.05-0.244-0.297-0.244-0.49c0-0.348,0.441-0.983,0.441-1.132
                                c0-0.297-0.248-0.541-0.248-0.837c-0.292-0.047-0.391-0.248-0.492-0.343v-0.491c0.396,0,0.79-0.298,0.79-0.639
                                c0-0.59-0.739-0.982-1.33-0.982c-0.345,0-0.54,0.243-0.687,0.243c-0.343,0-0.491-0.587-0.491-0.834c0-0.59,0.543-0.541,0.543-1.033
                                c0-0.589-0.249-0.589,0-1.082c-0.742,0-0.987-0.538-1.576-0.538c-1.572,0-2.701,1.475-4.077,1.475c0-0.89,0.933-1.231,0.933-2.016
                                c0-0.295-0.543-0.934-0.933-0.934c-0.491,0-0.685,0.492-0.788,0.639c-0.392,0.638-1.523,1.374-2.112,1.818
                                c-0.687,0.493-2.112,0.147-2.112,1.179c0,0.492,0.835,0.687,1.031,0.834c0.59,0.445,0.394,0.933,0.984,1.329
                                c0.393,0.243,0.887,0.099,1.277,0.099c0.293,0,0.293-1.082,0.688-1.082c0.542,0,0.738,0.393,1.078,0.587
                                c0.692,0.443,1.427-0.096,1.82,0.443c-0.984,0.984-3.093,0.984-3.537,2.313c-0.196,0.586-1.032,0.635-1.032,1.225
                                c0,0.691,0.932,1.181,1.326,1.232l0.047,0.933h0.102l-0.052,0.047c0.442,0.248,0.442,0.837,0.594,1.082
                                c0.882,1.329,1.371,2.113,1.371,4.227c0,1.425-0.736,1.916-0.736,2.995c-0.739,0.148-2.061,2.112-2.359,2.705
                                c-0.445,0.931-0.787,2.751-1.965,2.751c-0.097,0.491-0.346,0.689-0.738,0.884c-1.373,0.788-1.914,2.162-4.176,2.162
                                c-0.491,0-0.737-0.392-1.228-0.392c-0.1,0-0.593,0.933-0.593,0.933c-0.587,0.591-1.522,0.394-2.454,0.836
                                c-0.393,0.199-1.379,0.343-1.721,0.69c-0.392,0.39-0.099,1.032-0.64,1.032c-0.442,0-0.291-0.984-0.243-1.23
                                c-0.935-0.444-1.525-0.148-2.362-0.542c-0.981,1.525-3.39,1.872-3.39,3.981c0,0.443,0.248,0.54,0.442,0.735
                                c1.129,1.129,1.623,2.41,2.852,3.195c1.177,0.788,1.968,2.95,1.968,4.569c0,1.131-0.592,1.575-0.592,2.458
                                c-1.573,0.396-1.723,0.933-2.998,1.571c-0.592,0.301-0.493,1.084-1.083,1.084c-0.148,0-0.148-0.293-0.148-0.44v0.541
                                c0,0.491-0.983,1.227-1.178,1.227c-0.196,0-0.392-0.686-0.392-0.983c0-0.395,0-0.589,0.196-0.685
                                c-0.196-0.099-0.295-0.492-0.345-0.742c-0.734,0-1.718-0.244-1.718-0.981c-1.084,0-0.541-0.734-1.134-1.325
                                c-0.686-0.689-1.374-0.737-2.161-0.983c-0.098,0-0.245-0.64-0.245-0.64c-0.15-0.542-0.738-0.635-1.276-0.635
                                c-0.395,0-0.298,0.293-0.298,0.535c0,0.445,0.197,0.542,0.197,0.939c0,1.474-1.424,1.865-1.424,3.189
                                c0,1.526,1.669,0.934,1.669,2.36h-0.095c0.196,0.345,0.245,1.231,0.589,1.572c1.326,1.328,4.227,1.622,4.227,4.08
                                c0,0.492-0.197,0.492-0.197,0.935c0,0.882,0.735,1.177,0.931,1.769c0.201,0.641,0.201,0.884,0.441,1.426
                                c-0.392,0.048-0.441,0.048-0.637,0.048c-0.147,0-0.195,0.196-0.344,0.196c-0.491,0-0.54-0.344-0.833-0.442
                                c-1.523-0.491-2.9-1.965-3.44-3.586c-0.443-1.426-0.541-2.457-1.083-3.589c-0.342-0.686-0.685-2.457-1.475-2.457
                                c-0.489,0-0.193,0.297-0.638,0c-0.097,0,0-0.193,0-0.297c0-0.686,0.099-1.177,0.246-1.864h0.099h0.148c-0.394-0.2,0-1.326,0-1.866
                                c0-2.168-1.278-3.441-1.278-5.016c-0.639,0,0.197-1.473-0.295-2.209c-0.148-0.246-0.54-0.146-0.639-0.246
                                c-0.295-0.296-0.148-0.493-0.443-0.787c-0.834,0.835-1.179,2.26-2.556,2.26c-0.736,0-0.786-0.592-0.786-1.228
                                c0-0.641,0.246-0.934,0.246-1.476c0-0.244-0.246-0.392-0.295-0.638c-0.343-1.033-0.935-1.818-1.423-2.605
                                c-0.688-0.542-1.23-0.933-1.624-1.572c-0.393-0.589-0.293-1.377-0.494-1.965c-0.191-0.688-1.029-0.983-1.767-1.131
                                c0,1.473-1.373,1.769-2.652,1.769c-0.785,0-0.785-0.245-1.182-0.491c-0.343,0.737-1.371,0.787-1.521,1.229
                                c-0.195,0.686-0.149,1.227-0.592,1.669c-0.882,0.886-2.309,1.131-3.093,1.916c-0.984,0.981-1.867,1.967-2.801,2.897
                                c-0.391,0.398-0.933,0.983-1.275,1.085c-0.884,0.295-1.92,0.441-1.92,1.473c0,0.885,0.343,1.378,0.343,2.161
                                c0,1.231-0.585,2.016-0.585,2.949l0.241,1.278l-0.241,0.049c0,0.148-0.346,0.148-0.493,0.199c-0.689,0.196-0.542,1.472-0.939,1.669
                                c-0.585,0.291-0.682,0.1-0.93,0.593c-0.193,0.391-0.246,0.982-0.638,0.982c-0.395,0.048-0.688-0.15-0.74-0.491
                                c-0.246,0-0.392-0.345-0.44-0.491c-0.395-1.185-0.936-2.167-1.276-3.199c-0.199-0.683-0.888-1.178-1.084-1.57
                                c-0.392-0.787-0.147-1.428-0.542-2.012c-0.391-0.641-0.391-0.939-0.588-1.574c-0.244-0.738-1.128-2.357-1.474-3.441
                                c-0.295-0.981-0.836-1.916-0.836-2.848c0-0.149-0.079-0.555-0.079-0.701l0.03,0.027c0.017-0.661,0.246-0.777,0.246-1.392
                                c0-0.785-0.493-1.374-0.493-2.015c0-0.145,0.296-0.491,0.296-0.684h-0.394c-0.638,0.636-0.689,2.307-1.965,2.307
                                c-1.521,0-2.115-1.525-2.849-2.259c0.443-0.298,0.786-0.047,1.226-0.199c0.394-0.099,0.44-0.539,0.642-0.735
                                c-0.391-0.098-0.742-0.098-1.134-0.098c-0.979,0-2.405-0.982-2.896-1.719c-0.396-0.638-0.689-1.77-1.277-1.969
                                c-0.543-0.146-0.934-0.045-1.477-0.045c-0.588,0-0.738,0.39-1.229,0.39l-1.276-0.344c-0.197,0.101-0.343,0.249-0.44,0.344
                                l-1.724,0.1c0,0.05-0.194,0-0.243,0c-0.934,0-3.097-0.59-4.422-0.59c-0.248,0-1.672-0.392-1.723-0.442
                                c-0.489-0.489-0.489-1.966-1.52-1.966c-0.541,0-0.74,0.445-1.032,0.738h-2.459c-0.788-0.246-1.181-0.884-1.673-1.374
                                c-0.586-0.594-1.374-0.296-1.916-0.839c-0.293-0.295-0.148-0.686-0.244-0.884c-0.54-1.078-1.622-2.602-2.999-2.602
                                c-0.689,0-0.985,0.883-0.985,1.568c0,0.493,0,0.738,0.102,1.132c0.294,1.182,1.673,3.289,2.804,3.538
                                c0,0.54,0.291,0.689,0.337,1.131c0.592-0.147,0.493-0.787,0.984-0.884c0.048,0.195,0.048,0.345,0.048,0.539
                                c0,0.593-0.341,0.985-0.341,1.573c0,0.69,1.274,1.035,2.014,1.035c3.292,0,3.539-2.016,5.309-3.343v1.818
                                c0.687,1.423,1.62,1.673,3.044,2.114c0.54,0.147,0.587,0.982,0.933,1.176v-0.045c0.246,0.441,0.935,0.344,0.935,0.787
                                c0,1.126-1.425,1.521-1.425,2.699c-0.74,0-1.231,0.197-1.426,0.789c-0.195,0.588-0.147,1.427-0.735,1.427
                                c-0.741,0-0.69,1.422-1.424,1.422c-0.443,0-1.377,0.834-1.377,1.424c-1.328,0.243-4.324,0.787-4.324,1.869
                                c0,0.15,0.099,0.344,0.194,0.442c-1.128,0.539-2.651,0.883-3.831,1.178c-0.59,0.147-2.556,1.523-3.193,1.822h-1.526
                                c-0.836,0.588-0.967,0.994-2.002,0.994l-0.304,0.182c-0.15,0-1.403-0.607-1.535-0.676c-0.123-0.059,0.265-0.062-0.058-0.551
                                c-0.511-0.769-0.863-1.574-0.863-2.509c-0.544-0.048-0.161-0.982-0.059-1.423c0.244-1.085-0.58-1.524-1.123-2.065
                                c-0.588-0.589-1.952-2.284-1.952-3.466c-1.718-0.391-2.127-1.397-2.127-3.266c0-1.129-0.638-1.376-0.838-2.31
                                c-0.241-0.195-0.493-0.489-0.638-0.637c-0.197-0.197-0.836-0.1-0.983-0.245c-0.297-0.298-0.196-1.034-0.297-1.33
                                c-0.146-0.491-0.491-0.885-0.687-1.178c-0.587-0.882-1.08-1.622-1.621-2.457c-0.15-0.245-0.833-1.033-0.833-1.179
                                c0-0.444,0.279-1.407,0.333-2.134l-1.072-2.585c0-0.343,0.345-0.343,0.393-0.541c0.346-1.08,0.492-1.621,0.786-2.604
                                c0.297-0.983,1.278-1.227,1.278-2.359c0-0.835-0.242-1.522-0.242-2.11c0-0.297,0.242-0.395,0.339-0.492v-0.491
                                c-0.391,0.146-0.538,0.441-0.931,0.441c-0.495,0-0.787-0.198-0.984-0.391c-0.786,0.586-1.033,1.225-2.457,1.225
                                c-1.032,0-1.423-1.225-2.458-1.225c-1.032,0-0.835,1.128-1.72,1.128c-0.931,0-1.425-1.327-2.357-0.888h-0.541
                                c0.145-0.048,0.344-0.195,0.393-0.291c-0.298-0.296-1.722-1.032-1.231-1.525c-0.441-0.64-0.982-0.591-1.664-0.933
                                c0.488-0.491,1.027-0.393,1.027-1.328c0-0.344-0.096-0.344,0-0.588c-0.345,0-0.838,0.05-0.838-0.249
                                c0-1.521,3.54-1.226,4.424-1.666c-0.097-0.394-0.391-0.25-0.391-0.592c0-0.442,0.931-0.245,1.177-0.245h1.524
                                c0.587,0,1.474-0.638,2.014-0.984c0.639-0.392,2.014-0.441,2.851-0.441c1.328,0,1.279,0.738,2.407,0.738
                                c0,0.59,1.917,0.932,2.46,0.983h2.799c0.989,0,1.57-0.736,2.41-1.114l-0.198,0.031c0-3.883-5.65-3.39-6.883-6.29
                                c2.856-0.729,1.574-2.065,1.475-2.358c0.246-0.098,0.887-0.834,0.887-0.834c-1.33,0.489-4.914,1.817-4.914,2.209
                                c0,0.592,0.64,0.736,0.836,0.736c0,0,1.276,0.197,1.373,0.197c-0.391,0.492-2.751,1.525-3.439,1.525
                                c-0.687,0-2.946-3.684-3.83-3.684c-1.084,0-1.182,1.275-2.262,1.571c-0.346,0.047-0.935,0.295-1.18,0.295l-0.244-0.295
                                c-0.098-0.098-0.247-0.195-0.247-0.345v-2.014c-1.229,0-0.933-2.555-2.257-2.555c-1.181,0-1.919,0.638-3.493,0.638
                                c-1.131,0-2.115,0.295-2.115-1.081c0-1.178,0.202-1.964,0.789-2.852c0.196-0.292,1.129-0.491,1.129-1.078
                                c0-0.934-0.934-1.572-0.934-2.606c0-0.638,0.691-0.981,0.691-1.622c0-1.08-0.492-2.919-1.524-2.919
                                c-0.985,0-1.584,0.066-2.521,0.066c-0.5,0-1.107,0.072-1.759,0.138c0.097-1.72,1.674-1.46,1.674-3.376
                                c0-1.181,1.082-3.44,1.965-3.44c0.838,0,0.982,1.228,1.967,1.228c0.59,0,1.893-0.897-0.59-3.213
                                c-0.754-0.705,1.721-1.702,2.801-1.702c1.082,0,1.62,0.347,2.459,0.347c0.637,0,2.112-0.692,2.702-1.083
                                c-0.343-0.933-0.835-1.179-1.23-1.72c1.033-1.377,4.425-3.194,4.425-4.814c0-1.082-1.132-1.277-1.573-2.063
                                c0.146-0.098,0.59-0.347,0.59-0.64c0-1.083-1.473-1.377-1.473-2.458c0-0.59,0.491-0.882,0.491-1.476
                                c0-0.981-0.983-1.523-0.983-2.454c0-0.836,0.885-1.227,0.885-2.065c0-1.08-1.721-1.032-1.721-1.964
                                c0-1.131,1.767-2.017,2.456-2.508c1.134-0.146,1.182,0.05,1.329,0.05c1.622,0,6.933,2.671,9.335,3.442
                                c8.383,2.681,2.883,6.098,0.152,5.382c-2.044-0.536-3.198-0.472-5.31-0.472c0.981,1.427,0.638,4.425,3.439,4.674v-1.967
                                c5.68,2.434,5.012-2.261,6.386-2.707c1.379-0.443,3.93,0.74,3.93-0.983c0-0.881-0.871-1.051-0.734-1.473
                                c2.118-6.531,3.219-2.23,2.457-0.244c-0.216,0.55,0.639,0.983,1.229,0.983c1.82,0,9.188-6.583,10.317-3.686
                                c2.241,1.084,5.358-0.984,6.882-0.984c0.882,0,2.486,3.052,2.701-0.734c0.018-0.344-2.152-2.627,4.577-0.883
                                c2.434,0.63,8.967,5.403,6.232,1.126c-1.179-1.178-2.208-2.654-2.208-4.913c0-1.867,4.02-5.336,6.141-4.911
                                c4.208,0.836,1.47,1.52,1.47,3.191c0,1.523,0.551,8.948,2.616,8.948c1.596,0-1.441-3.656-0.401-4.772
                                c1.508-1.617-0.589-3.046-0.983-4.424c1.28-0.687,2.31-1.374,2.554-2.944c0.692,0.882,0.426,5.378,3.343,1.471
                                c3.44,0,3.832,0.687,4.913,0c-0.194-0.789-0.736-1.376-0.736-1.964c0-2.803,6.092-2.214,7.714-2.214
                                c1.131,0,1.361-2.026,2.113-2.702c3.599-3.216,10.515-3.686,12.139-4.178c-0.554,2.31,5.8-0.245,6.78-0.983
                                c1.327-1.033,2.36-2.947,4.913-2.947c2.113,0,3.194,1.328,3.683,2.702h5.161c12.203,2.924-2.652,6.931-4.176,8.847
                                C384.521,31.356,383.085,34.651,389.532,29.389L389.532,29.389z M305.31,94.352c-0.295-0.542-1.085-0.491-1.133-1.475
                                c0.151,0.05,2.112-1.229,3.439-1.229c0.888,0,1.867,0.541,2.605-0.045c-0.982-1.475-1.424-2.752-4.08-2.752
                                c-2.974,0-5.158,1.807-5.547,1.57c-0.252,0.295-1.182,2.116-1.182,2.357c0,0.74,1.08,1.476,1.08,1.966
                                c0,1.675,2.015,4.622,3.339,5.358c-0.784,0.392-0.784,1.572-1.373,2.357l-0.69-0.095c0.15,0.702,0.286,0.287,0.639,0.835
                                c0.395,0.587-0.05,0.982,0.247,1.571c0.247,0.539,0.885,0.246,1.424,0.344c0.441,0.1,0.543,0.591,0.838,0.884
                                c0.6,0.597,3.726,0.216,4.566,0v-0.884c-0.983-0.972-0.142-1.591-0.142-2.163c0-0.932-0.69-1.916-1.724-2.061
                                c0.201-1.516,1.199-1.231,2.457-1.231c0-0.736-0.784-1.128-0.833-1.868c-0.343,0.05-1.278,0.542-1.673,0.739
                                C307.567,96.613,306.24,95.972,305.31,94.352z"/>
                        </g>
                        </svg>
                </div>

                <!-- <div class="actions">
                    <input type="search" inputmode="search" class="form form-search" v-model="search.input" :placeholder='getMyLanguage("Search","form.placeholder")' autocomplete="off" />
                    <div class="tooltip-search" v-if="emptySearch">{{getMyLanguage("Search","form.tooltip-empty")}}</div>


                    <div class="btn-primary" v-if="search.input.length < 3" @click="alertSearch()">
                        {{getMyLanguage("Search","region-selection.cta-search")}}
                        <span v-if='regionSelected == "Region"'>&nbsp; ...</span>
                        <span v-else>&nbsp; {{regionSelected}}</span>
                    </div>

                    <div class="btn-primary" v-if="search.input.length >= 3" @click='sendSearch("selected")'>
                        {{getMyLanguage("Search","region-selection.cta-search")}}
                        <span v-if='regionSelected == "Region"'>&nbsp; ...</span>
                        <span v-else>&nbsp; {{regionSelected}}</span>
                    </div>
                </div> -->

                <!-- COM CHECKBOX -->
                <!-- <div class="list-region">
                    <div class="form-group-check" v-for="(region, index) in search.regions" :key="index">
                        <input type="checkbox" :id="region.id" class="form-check" v-model="search.regionsSelected" :value="region.id">
                        <label :for="region.id" class="form-check-label"><span>{{region.name}}</span></label>
                    </div>
                </div>

                <div class="actions">
                    {{search.regionsSelected}}
                    <div class="btn-primary" @click='modal.regionSelection = false, errorsPost.withdraw = ""'>{{getMyLanguage("Search","region-selection.cta-search")}}</div>
                </div> -->
            </div>
        </div>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    import bus from '@/eventBus';

    export default{
        data (){
            return {
                flagSelected: "en",
                emptySearch: false,
                regionSelected: localStorage.getItem("region"),

                modal: {
                    regionSelection: false
                },

                loadSuggestions: false,

                dataSuggestions: [],

                search: {
                    disable: false,
                    input: '',
                    regionsSelected: [],
                    regions: [
                        {id: "r01", name: this.getMyLanguage("Search","region.name.Africa"), value: true},
                        {id: "r02", name: this.getMyLanguage("Search","region.name.America"), value: true},
                        {id: "r05", name: this.getMyLanguage("Search","region.name.Asia"), value: false},
                        {id: "r06", name: this.getMyLanguage("Search","region.name.Europe"), value: true},
                        {id: "r07", name: this.getMyLanguage("Search","region.name.Oceania"), value: true},
                    ]
                    // regions: [
                    //     {id: "r01", name: "África", value: true},
                    //     {id: "r02", name: "América do Norte", value: true},
                    //     {id: "r03", name: "América Central", value: true},
                    //     {id: "r04", name: "América do Sul", value: true},
                    //     {id: "r05", name: "Ásia", value: false},
                    //     {id: "r06", name: "Europa", value: true},
                    //     {id: "r07", name: "Oceania", value: true},
                    // ]
                },

                lnks: ["iPhone","Android","Samsung","Xiaomi"],
                lnksIphone: ["Iphone","iPhone 16","iPhone 15","Iphone 14","Iphone 13","Iphone 12","Iphone 11","Iphone 13 pro","Iphone 14 pro","Iphone 14 pro max","Iphone 13 pro max"]
            }
        },

        async mounted() {
            this.languageSelected()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            openSearch(){
                const anc = document.getElementById("ancSearch");
                if ( anc ){
                    setTimeout(() => {
                        anc.scrollIntoView({ behavior: "smooth" });
                    }, 500);
                }
            },            


            async suggestionsFound(){
                if ( this.search.input.length >= 3 ){
                    this.search.suggestions = true
                    this.loadSuggestions = true

                    await apiMarket.get(`api/v1/market/product/filter?query=${this.search.input}&include_variants=false&include_attachments=true&compressed_response=true`)
                    .then(response => {
                        this.dataSuggestions = response.data

                        this.dataSuggestions.forEach(item => {
                            item.product_variant_slug = item.product_variant_slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                        });

                        setTimeout(() => {
                            this.loadSuggestions = false
                        }, 500);
                    })
                } else {
                    this.dataSuggestions = []
                }
            },

            alertSearch(){
                this.emptySearch = true

                setTimeout(() => {
                    this.emptySearch = false
                }, 4000);
            },

            openModalRegion(){
                this.modal.regionSelection = true
                document.body.classList.add('body-select-region')
            },
            
            closeModalRegion(){
                this.modal.regionSelection = false
                document.body.classList.remove('body-select-region')
                // this.errorsPost.withdraw = ""
            },

            setRegion(value){
                this.regionSelected = value
                localStorage.setItem("region",value)

                this.modal.regionSelection = false

                this.search.suggestions = true
                document.getElementById("searchMarket").focus();
            },

            sendSearch(value){
                this.search.suggestions = false

                if ( value == "full" && this.search.input.length >= 3 ){
                    window.location.href = `/${this.flagSelected}/search/${this.search.input}`
                } else (
                    this.alertSearch()
                )

                if ( value == "selected" && this.search.input.length >= 3 ){
                    window.location.href = `/${this.flagSelected}/search/${this.search.input}?region=${this.regionSelected}`
                } else (
                    this.alertSearch()
                )
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            bus.on('opened-login', (status) => {
                this.search.disable = status
            });

            if ( this.$route.params.query ){
                const searchText = String(window.location.pathname).split("/search/").pop();
                console.log(searchText)
                
                if ( searchText ){
                    this.search.input = decodeURIComponent(searchText.replace(/\+/g, ' '));
                }
            }

            // REGION
            // const urlParams = new URLSearchParams(window.location.search);
            // console.log(urlParams.get('region'));

            if ( localStorage.getItem('region') == null ){
                localStorage.setItem('region',"Global")
            }
        }
    }
</script>

<style src="@/assets/scss/_search.scss" lang="scss" scoped />
<style src="@/assets/scss/_search-filters.scss" lang="scss" scoped />