<template>
    <div class="wishlist">
        <div class="open" :class="{active: dataWishlist.length}" @click="show = true">
            <span>{{dataWishlist.length}}</span>
        </div>

        <div class="modal modal-wishlist" v-if="show">
            <div class="modal-cont">
                <div class="modal-close" @click="closeWishlist()"></div>

                <h2 class="title-secondary">
                    {{getMyLanguage("Product","wishlist.lbl")}}
                </h2>

                <div class="box box-pending"
                    v-if="!dataWishlist.length && statusUserLogin && !loadAdd">
                    
                    <img src="~@/assets/images/icons/wishlist-empty.svg" >
                    <p>{{getMyLanguage("Wishlist","empty")}}</p>
                </div>

                <div class="box box-logged-out" v-if="!statusUserLogin && !loadAdd">
                    <p>{{getMyLanguage("Wishlist","logged-out")}}</p>
                    
                    <div class="btn-primary btn-login" @click="openLogin()">
                        {{ getMyLanguage("Login", "login.sign-in") }}
                    </div>
                </div>

                <div class="load loadAdd" v-if="loadAdd"></div>

                <div class="modal-scroll" v-if="dataWishlist.length && !loadAdd">
                    
                    <div v-for="(item, index) in dataWishlist" :key="index">                        
                        <div class="item">
                            <a :href="`/${item.product_variant_slug}`" class="photo">
                                <img :src="item.product_variant_attachment" :alt="item.product_variant_name">
                            </a>

                            <div class="details">
                                <a :href="`/${item.product_variant_slug}`" class="name">
                                    {{item.product_variant_name}}
                                </a>

                                <a :href="`/${item.store_slug}`" class="partner">
                                    {{item.store_name}}
                                </a>

                                <span class="price">
                                    {{item.product_variant_price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                </span>
                            </div>

                            <div class="bts">
                                <div class="add-cart" @click="addToCart(item.product_variant_id)"></div>
                                <div class="delete" @click="removeItem(item.product_variant_id)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
   
    import apiMarket from '@/config/apiMarket.js'
    
    import bus from '@/eventBus';

    export default{
        
        data() {
            return {
                flagSelected: "en",

                show: false,

                loadAdd: false,

                addCart: {
                    qtd: 1,
                    idVariant: {}
                },

                wish: {},
                qtdWish: "",

                updShelf: {
                    idVariant: "",
                    status: ""
                },
                
                dataWishlist: {},

                statusUserLogin: null
            }
        },

        async mounted () {
            if ( localStorage.getItem("showWishilist") ){
                setTimeout(() => {
                    this.show = true
                }, 1000);
            }
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                setTimeout(() => {
                    if ( this.dataWishlist.length ){
                        this.dataWishlist.forEach(item => {
                            item.product_variant_slug = item.product_variant_slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                            item.store_slug = item.store_slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                        });
                    }
                }, 1500);
            },

            addToCart(id){
                this.addCart.idVariant = id
                this.addCart.qtd = 1
                this.loadAdd = true
                
                setTimeout(() => {
                    this.loadAdd = false
                    this.show = false

                    this.removeItem(this.addCart.idVariant)

                    bus.emit('add-to-cart', this.addCart);
                }, 500);
            },

            removeItem(id){
                this.loadAdd = true

                apiMarket.post("/api/v1/market/product/wishlist", {"product_variant_id": id, "quantity": 0})
                .then(response => {
                    this.dataWishlist = response.data
                    
                    setTimeout(() => {
                        this.$toast.warning(this.getMyLanguage("Wishlist","toast.removed"));
                        setTimeout(this.$toast.clear, 6000)

                        this.updShelf.status = false
                        this.updShelf.idVariant = id

                        bus.emit('updateShelfTemplate', this.updShelf);

                        this.loadAdd = false
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)
                    
                    setTimeout(() => {
                        this.loadAdd = false
                    }, 1000);
                })
            },

            closeWishlist(){
                this.show = false

                if ( localStorage.getItem("showWishilist") ){
                    localStorage.removeItem("showWishilist")
                }
            },

            openLogin(){
                this.show = false
                bus.emit('open-login',"wishlist-login");
            },

            async loadList(){
                await apiMarket.get("/api/v1/market/product/wishlist")
                .then(response => {
                    this.dataWishlist = response.data
                })
                .catch(error => {
                    console.log(error)
                    // this.$toast.error("Erro ao carregar a lista");
                    // setTimeout(this.$toast.clear, 6000)
                })
            }
        },

        watch: {
            statusUserLogin: {
                immediate: true,
                handler(newVal) {
                    if (newVal !== null) {
                        this.statusUserLogin = this.$store.state.statusLogin
                        // console.log('Login:', newVal);

                        if ( this.statusUserLogin == true ){
                            this.loadList()
                        }
                    }
                },
            },
            
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.languageSelected();
                    }, 1000);
                }
            }
        },

        created() {
            setTimeout(() => {
                this.statusUserLogin = 'Loading';
            }, 1500);

            
            // OPEN WISHLIST
            bus.on('open-wishlist', () => {
                this.show = true
            })


            // ADD WISHLIST
            bus.on('add-to-wishlist', (item) => {
                // console.log(item)
                this.show = true
                this.wish = item
                this.loadAdd = true

                if ( this.wish.action == "add" ){
                    this.qtdWish = 1
                    this.updShelf.status = true
                } else if ( this.wish.action == "remove" ) {
                    this.qtdWish = 0
                    this.updShelf.status = false
                }

                if ( this.statusUserLogin == true ){
                    apiMarket.post("/api/v1/market/product/wishlist", {"product_variant_id": this.wish.id, "quantity": this.qtdWish})
                    .then(response => {
                        this.dataWishlist = response.data

                        setTimeout(() => {
                            this.$toast.success(this.getMyLanguage("Wishlist","toast.added"));
                            setTimeout(this.$toast.clear, 6000)

                            this.loadAdd = false
                            
                            this.updShelf.idVariant = item.id
                            bus.emit('updateShelfTemplate', this.updShelf);
                        }, 1000);
                    })
                    .catch(error => {
                        console.log(error)

                        setTimeout(() => {
                            this.$toast.error(this.getMyLanguage("Wishlist","toast.error"));
                            setTimeout(this.$toast.clear, 6000)

                            this.loadAdd = false
                        }, 1000);
                    })
                } else {
                    setTimeout(() => {
                        this.loadAdd = false
                    }, 1000);
                }
            })

            // bus.on('wishlist-login', (action) => {
            //     if ( action == "show" ){
            //         this.show = true
            //     }
            // });
        }
    }
</script>

<style src="@/assets/scss/_wishlist.scss" lang="scss" scoped />